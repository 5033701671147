import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// BOOTSTRAP
import { Button, Container, Table } from "react-bootstrap";
import { PencilSquare, ArrowUpSquareFill } from "react-bootstrap-icons";
// STYLES
import classes from "./BrandsList.module.css";
// API
import apiBrands from "../../../api/brands";
// UTILITIES
import handleApiErrors from "../../../utilities/handleApiErrors";
import useInfiniteScroll from "../../../utilities/useInfiniteScroll";
// COMPONENTS
import TextInput from "../../../components/TextInput/TextInput";
import Spinner from "../../../components/Spinner/Spinner";
import PaginationItem from "../../../components/PaginationItem/PaginationItem";

import stringSimilarity from "string-similarity";

//STATES
const states = {
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};
const resultsNumber = 20;

const BrandsList = (props) => {
  const accesToken = useSelector((state) => state.user.access);
  // INPUT
  const [inputValue, setInputValue] = useState("");
  // ARRAYS
  const [allBrands, setAllBrands] = useState([]);
  const [allBrandsUntouched, setAllBrandsUntouched] = useState([]);

  // PAGINATION
  const [pageNumber, setPageNumber] = useState(0);
  // const [showPaginationUnder, setShowPaginationUnder] = useState(true)

  // PROCESS STATE
  const [process, setProcess] = useState(states.LOADING);
  // INFINITE SCROLL
  const [results, setResults] = useState(20);
  // const [loadMore, setLoadMore] = useState(false);
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);

  // BRING DATA FROM API
  useEffect(() => {
    apiBrands
      .getAll(accesToken)
      .then(({ data }) => {
        const items = data.map((item) => ({
          id: item.id,
          name: item.name,
        }));

        // setAllBrands([]);
        // !!!! prueba con array mas corto
        // setAllBrands(items.slice(1, 125));
        setAllBrands(items);
        setAllBrandsUntouched(items);
        setProcess(states.LOADED);
        // return { data: items };
      })
      .catch((e) => {
        handleApiErrors(e);
      });
  }, []);

  // * FUNCTION FOR THE SCROLL INFINITE
  function fetchMoreListItems() {
    setTimeout(() => {
      if (results + 20 > allBrands.length) {
        setResults(allBrands.length);
      } else {
        setResults(results + 20);
      }
      setIsFetching(false);
    }, 2000);
  }

  // EDIT BRAND
  const editBrand = (pId) => {
    props.history.push(`/brand/${pId}`);
  };

  // SPLIT ARRAYS FUNCTION
  const splitArrays = (arr, len) => {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  };
  // * LOGIC FROM SIMILARITY ALGORITHMN
  useEffect(() => {
    // Only works from the second letter on
    const queryedItems = allBrandsUntouched.map((item, index) => {
      const scoredItem = {
        ...item,
        score: stringSimilarity.compareTwoStrings(
          inputValue.toLocaleLowerCase(),
          item.name.toLocaleLowerCase()
        ),
      };
      return scoredItem;
    });

    // TODO prueba a hacer otro mapeo y borrar del array los resultados chungos mediante un array nuevo

    const arrayfilteredSorted = queryedItems.sort((a, b) => {
      if (a.score > b.score) {
        return -1;
      }
      if (a.score < b.score) {
        return 1;
      }
      return 0;
    });

    const exactItems = [];
    const similarItems = [];

    arrayfilteredSorted.forEach((product) => {
      if (product.name.toLocaleLowerCase().includes(inputValue)) {
        exactItems.push(product);
      } else {
        similarItems.push(product);
      }
    });
    // When there are 3 letters or more then it shows the flitered/ordered array, if not the general array
    inputValue.length > 2
      ? setAllBrands([
          ...exactItems,
          ...similarItems.filter((item) => item.score > 0.2),
        ])
      : setAllBrands(allBrandsUntouched);
  }, [inputValue]);

  // TODO pruebas para el responsive /habria que cambiar el results por resultsnumber depenediendo del responsive na mas
  //  * LOGIC FOR RESPONSIVE, IF MATCH WITH THE MOBILE SCREEN THE RESULTS PER PAGE ADD 20 RESULTS AVERY TIME SCROLL TO THE END OF THE VIEWPORT
  let breakPointResponsive = window.matchMedia("(max-width: 65em)");
  let bodyContent;
  function responsiveBreakMatch(breakPointResponsive) {
    // If media query matches, the type of results change to the state

    bodyContent =
      splitArrays(
        allBrands,
        breakPointResponsive.matches ? results : resultsNumber
      )[pageNumber] === undefined
        ? null
        : splitArrays(
            allBrands,
            breakPointResponsive.matches ? results : resultsNumber
          )[pageNumber].map((item, index) => {
            return (
              <>
                {/* <ReactTooltip place="left" type="info" /> */}

                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>

                  <td>
                    <Button variant="light" onClick={() => editBrand(item.id)}>
                      <PencilSquare />
                    </Button>
                  </td>
                </tr>
                {breakPointResponsive.matches && allBrands.length > 20 ? (
                  results <= 20 ? null : (
                    <div className={classes.arrowContainer}>
                      <ArrowUpSquareFill
                        // data-tip="Volver al inicio"
                        className={classes.arrowUp}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      />
                    </div>
                  )
                ) : null}
              </>
            );
          });
  }
  responsiveBreakMatch(breakPointResponsive);

  return (
    <>
      {/* TITLE */}
      <Container className={classes.title}>
        <h1>Lista de marcas</h1>
      </Container>

      <Container className={classes.input}>
        <TextInput
          placeholder="Introduce el nombre de la marca"
          onChange={(event) => {
            setInputValue(event.target.value);
            setPageNumber(0);
          }}
          value={inputValue}
        />
      </Container>
      {/* TABLE */}
      <Container>
        {process === "LOADING" ? (
          <Spinner
            className="mt-5  "
            as="svg"
            animation="border"
            size="lg"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <>
            {/* // PAGINATION */}
            {allBrands.length >= 10 ? (
              <PaginationItem
                //Pongo el mismo array en filtrado para que no cambie la paginación ni de error
                arrayWhole={allBrands}
                resultsNumber={resultsNumber}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                inputValue={inputValue}
              />
            ) : null}

            {/* //END OF PAGINATION */}

            <Table responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>NOMBRE</th>
                  <th>EDITAR</th>
                </tr>
              </thead>
              <tbody>{bodyContent}</tbody>
            </Table>
            {/* //* SPINNER IN MOBILE WHEN LOADING MORE ITEMS */}
            {isFetching ? (
              <div className={classes.hideSpinnerDesktop}>
                <Spinner
                  as="svg"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : null}

            {/* // BOTTOM PAGINATION */}
            {allBrands.length >= 10 ? (
              <PaginationItem
                //Pongo el mismo array en filtrado para que no cambie la paginación ni de error
                arrayWhole={allBrands}
                resultsNumber={resultsNumber}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                inputValue={inputValue}
              />
            ) : null}
            {/* //END OF BOTTOM PAGINATION */}
          </>
        )}
      </Container>
    </>
  );
};

export default BrandsList;
