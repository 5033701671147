import React from "react";

const Accordion = (props) => (
  <div className="accordion" onClick={props.onClick}>
    {props.children}
  </div>
);

const Item = (props) => (
  <div className="accordion-item" onClick={props.onClick}>
    {props.children}
  </div>
);

const Header = (props) => (
  <h2 className="accordion-header">
    <div className={`accordion-button collapsed ${props.className}`}>
      {props.children}
    </div>
  </h2>
);

export default { Accordion, Item, Header };
