import React, { useState } from "react";
import checkSync from "../../utilities/checkSync";
import includeExcludeFromFilter from "../../utilities/includeExcludeFromFilter";
import swal from "sweetalert";
import handleDragAndDrop from "../../utilities/handleDragAndDrop";
import apiAttributes from "../../api/attributes";
import apiProducts from "../../api/products";
import { Col, Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import OptionsButton from "../../components/OptionsButton/OptionsButton";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import AdministerCopies from "../AdministerCopies/AdministerCopies";
import EditAttribute from "../Edit/EditAttribute/EditAttribute";
import AvailableFilters from "../../components/AvailableFilters/AvailableFilters";
import DraggableSortableFilter from "../../components/DraggableSortableFilter/DraggableSortableFilter";
import TabContainer from "../../components/TabContainer/TabContainer";
import TabMainRow from "../../components/TabMainRow/TabMainRow";
import Spinner from "../../components/Spinner/Spinner";
import classes from "./ProductAttributesTab.module.css";
import displayNotification from "../../utilities/displayNotification";

const LOADING = "LOADING";

const ProductAttributesTab = (props) => {
  const {
    productId,
    isSaving,
    availableAttributes,
    attributes,
    setAttributes,
    attributesRef,
    setHasChanged,
    attributesList,
    setAttributesList,
    attributesListRef,
    setAvailableAttributes,
    availableAttributesRef,
    addedAttributes,
    setAddedAttributes,
    addedAttributesRef,
    informativeAttributes,
    setInformativeAttributes,
    informativeAttributesRef,
    setHideFloatingButtons,
    areLinkedArticles,
    clickAddArticles,
    handleEditAttribute,
    handleClickDuplicateAttributeToInfo,
  } = props;

  const accesToken = useSelector((state) => state.user.access);

  const [showAddAttributes, setShowAddAttributes] = useState(false);
  const [showAttribute, setShowAttribute] = useState(false);
  const [showAddInformativeAttributes, setShowAddInformativeAttributes] =
    useState(false);
  const [cannotAddAttributes, setCannotAddAttributes] = useState(false);
  const [cannotAddInfoAttributes, setCannotAddInfoAttributes] = useState(false);

  const removeAttribute = async (attribute, type) => {
    const selectedOption = await swal({
      title: `Está a punto de borrar el atributo "${attribute.name}" y todos sus valores de todos los artículos que cuelgan de este producto. ¿desea continuar?`,
      icon: "warning",
      buttons: {
        cancel: "No",
        catch: {
          text: "Si",
          value: true,
        },
      },
      className: "customSweat",
    });

    if (!selectedOption) {
      return;
    }

    let targetAttributesListRef = attributesListRef;
    let setTargetAttributesList = setAttributesList;
    if (type === "INFO") {
      targetAttributesListRef = informativeAttributesRef;
      setTargetAttributesList = setInformativeAttributes;
    }

    const removeAttribute = (attribute) => {
      const remainingListAttributes = targetAttributesListRef.current.filter(
        (attr) => attr.id !== attribute.id
      );
      setTargetAttributesList(remainingListAttributes);

      const remainingAddedAttributes = addedAttributesRef.current.filter(
        (attr) => attr.id !== attribute.id
      );
      setAddedAttributes(remainingAddedAttributes);

      if (type !== "INFO") {
        const remainingAttributes = attributesRef.current.filter(
          (attr) => attr.id !== attribute.id
        );
        setAttributes(remainingAttributes);

        const remainingAvaialbeAttributes =
          availableAttributesRef.current.filter(
            (attr) => attr.id !== attribute.id
          );
        setAvailableAttributes(remainingAvaialbeAttributes);
      }

      const remainingInfoAttributes = informativeAttributesRef.current.filter(
        (attr) => attr.id !== attribute.id
      );
      setInformativeAttributes(remainingInfoAttributes);
      setAvailableAttributes((attributes) => {
        const updatedAttributes = attributes.filter(
          (attr) => attr.id !== attribute.id
        );

        return updatedAttributes;
      });
      setAttributes((attributes) => {
        const updatedAttributes = attributes.filter(
          (attr) => attr.id !== attribute.id
        );

        return updatedAttributes;
      });
    };

    const loadingAttribute = targetAttributesListRef.current.map((attr) => {
      if (attr.id === attribute.id) {
        return {
          ...attr,
          loading: true,
        };
      }

      return attr;
    });
    setTargetAttributesList(loadingAttribute);

    try {
      await apiProducts.removeAttributes(accesToken, {
        attributeIds: [attribute.id],
        productId: Number(productId),
      });

      removeAttribute(attribute);

      setHasChanged(true);
    } catch (e) {
      const loadingAttribute = targetAttributesListRef.current.map((attr) => {
        if (attr.id === attribute.id) {
          return {
            ...attr,
            loading: false,
          };
        }

        return attr;
      });
      setTargetAttributesList(loadingAttribute);

      if (e.response && e.response.status === 409) {
        const unlinkAttributeModalResponse = await swal({
          title: `El atributos ${attribute.name} aparece en la tabla gama lista completa y serán eliminados está seguro de que desea eliminarlo ?`,
          icon: "warning",
          buttons: {
            cancel: "Cancelar",
            delete: { text: "Eliminar", value: true },
          },
        });

        if (unlinkAttributeModalResponse) {
          await apiProducts.removeAttributes(accesToken, {
            attributeIds: [attribute.id],
            productId: Number(productId),
            forceRemove: true,
          });

          removeAttribute(attribute);
        }
      }
    }
  };

  const clickAddAttributes = () => {
    if (!areLinkedArticles) {
      displayNotification({
        title: "No se pueden añadir atributos si no hay artículos",
        message: "Añada artículos para poder añadir atributos",
        type: "danger",
      });
      setCannotAddAttributes(true);
      return;
    }

    setHideFloatingButtons(true);
    setShowAddAttributes(true);
  };

  const clickAddInfoAttributes = () => {
    if (!areLinkedArticles) {
      displayNotification({
        title: "No se pueden añadir atributos informativos si no hay artículos",
        message: "Añada artículos para poder añadir atributos informativos",
        type: "danger",
      });
      setCannotAddInfoAttributes(true);
      return;
    }

    setHideFloatingButtons(true);
    setShowAddInformativeAttributes(true);
  };

  return (
    <>
      <TabContainer>
        <TabMainRow>
          <Col md={12}>
            <TitleContainer>
              <h3>Atributos</h3>

              <OptionsButton
                variant="success"
                plus={clickAddAttributes}
                click={clickAddAttributes}
                disabled={isSaving}
              >
                Añadir atributos
              </OptionsButton>
            </TitleContainer>
            {cannotAddAttributes && !areLinkedArticles ? (
              <Alert variant="danger">
                No se pueden añadir atributos si no hay artículos
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => {
                      clickAddArticles();
                    }}
                    variant="outline-danger"
                  >
                    Añadir artículo
                  </Button>
                </div>
              </Alert>
            ) : undefined}

            <div className={classes.attributesListContainer}>
              {attributesList === LOADING ? (
                <Spinner />
              ) : (
                <>
                  {attributesList.length > 0 ? (
                    attributesList.map((attribute) => (
                      <OptionsButton
                        key={attribute.id}
                        pencil={() => {
                          setHideFloatingButtons(true);
                          setShowAttribute(attribute.id);
                        }}
                        x={() => {
                          removeAttribute(attribute);
                        }}
                        files={() =>
                          handleClickDuplicateAttributeToInfo(
                            productId,
                            attribute.id
                          )
                        }
                        loading={attribute.loading}
                        disabled={attribute.loading}
                      >
                        {attribute.name}
                      </OptionsButton>
                    ))
                  ) : (
                    <Alert variant="primary">
                      No hay ningun attributo seleccionado
                    </Alert>
                  )}
                </>
              )}
            </div>
          </Col>
          {/* <Col md={12}>
            <h4 className={classes.attributesInTable}>Atributos no en tabla</h4>
          </Col> */}
          {attributes === LOADING || availableAttributes === LOADING ? (
            <Spinner />
          ) : (
            <>
              <Col md={6}>
                <h4 className="mb-3">Atributos que no aparecen en la tabla</h4>
                {availableAttributes.length > 0 ? (
                  <AvailableFilters
                    items={availableAttributes}
                    onClickItem={(id) => {
                      setHasChanged(true);
                      includeExcludeFromFilter(id, {
                        availableFilters: availableAttributes,
                        setAvailableFilters: setAvailableAttributes,
                        filters: attributes,
                        setFilters: setAttributes,
                      });
                    }}
                  />
                ) : (
                  <Alert variant="primary">
                    No hay ningun attributo seleccionado
                  </Alert>
                )}
              </Col>
              <Col md={6}>
                <h4 className="mb-3">Atributos en tabla</h4>
                {attributes.length > 0 ? (
                  <DraggableSortableFilter
                    items={attributes}
                    droppableId="productFilterAttributes"
                    handleDranEnd={(result) => {
                      handleDragAndDrop(true);
                      handleDragAndDrop(attributes, setAttributes)(result);
                    }}
                    onClickRemove={(id) => {
                      setHasChanged(true);
                      includeExcludeFromFilter(id, {
                        availableFilters: availableAttributes,
                        setAvailableFilters: setAvailableAttributes,
                        filters: attributes,
                        setFilters: setAttributes,
                      });
                    }}
                  />
                ) : (
                  <Alert variant="primary">
                    No hay ningun attributo seleccionado
                  </Alert>
                )}
              </Col>
            </>
          )}
          <Col
            md={12}
            className={[
              classes.informativeAttributesTitle,
              classes.marginBottom,
            ]}
          >
            <TitleContainer>
              <h3>Atributos informativos</h3>

              <OptionsButton
                variant="success"
                plus={clickAddInfoAttributes}
                click={clickAddInfoAttributes}
                disabled={isSaving}
              >
                Añadir atributos
              </OptionsButton>
            </TitleContainer>
            {attributesList === LOADING ? (
              <Spinner />
            ) : (
              <>
                {cannotAddInfoAttributes && !areLinkedArticles ? (
                  <Alert variant="danger">
                    No se pueden añadir atributos informativos si no hay
                    artículos
                    <div className="d-flex justify-content-end">
                      <Button
                        onClick={() => {
                          clickAddArticles();
                        }}
                        variant="outline-danger"
                      >
                        Añadir artículo
                      </Button>
                    </div>
                  </Alert>
                ) : undefined}
                {informativeAttributesRef.current.length > 0 ? (
                  informativeAttributesRef.current.map((attribute) => (
                    <OptionsButton
                      key={attribute.id}
                      pencil={() => {
                        setShowAttribute(attribute.id);
                      }}
                      x={() => {
                        removeAttribute(attribute, "INFO");
                      }}
                      loading={attribute.loading}
                      disabled={attribute.loading}
                    >
                      {attribute.name}
                    </OptionsButton>
                  ))
                ) : (
                  <Alert variant="primary">
                    No hay ningun attributo informativo seleccionado
                  </Alert>
                )}
              </>
            )}
          </Col>
        </TabMainRow>
      </TabContainer>
      {showAddAttributes
        ? (() => {
            const selectedItems = [
              ...availableAttributes.map((item) => item.id),
              ...attributes.map((item) => item.id),
            ];

            return (
              <AdministerCopies
                addButtonDescription="hide"
                buttonDescription="hide"
                title="Atributos"
                loadItems={() => {
                  return apiAttributes
                    .getNoInfoAttributes(accesToken)
                    .then(({ data }) => {
                      const items = data.map((item) => ({
                        id: item.id,
                        name: item.name,
                        type: item.attribute_type,
                      }));

                      return {
                        data: items,
                      };
                    });
                }}
                onSave={async (filters) => {
                  const attributesToAdd = filters.map((item) => ({
                    id: item.id,
                    name: item.name,
                  }));

                  setHasChanged(true);
                  setAttributesList([...attributesList, ...attributesToAdd]);
                  setAvailableAttributes([
                    ...availableAttributes,
                    ...attributesToAdd,
                  ]);
                  setAddedAttributes([...addedAttributes, ...attributesToAdd]);
                }}
                onSaved={async () => {}}
                applySelection={async () => {}}
                selectedItems={selectedItems}
                close={() => {
                  setHideFloatingButtons(false);
                  setShowAddAttributes(false);
                }}
                createItem={async (name) => {
                  const response = await apiAttributes.createAttribute(
                    accesToken,
                    {
                      name,
                      type: "selection",
                    }
                  );

                  const attributeId = response.data.attribute[0].id;
                  (async () => {
                    try {
                      await apiAttributes.sync(accesToken, {
                        attributeId,
                      });

                      await checkSync.checkAttributeSync(accesToken, {
                        attributeId,
                      });

                      displayNotification({
                        title: "Se ha sincronizado correctamente",
                        message: "La sync del attributo ha sido exitoso",
                        type: "success",
                      });
                    } catch (error) {
                      displayNotification({
                        title: "Ha habido un error en la sync",
                        message: "La sync del atributo ha fallado",
                        type: "danger",
                      });
                    }
                  })();

                  return {
                    data: {
                      id: response.data.attribute[0].id,
                      name: response.data.attribute[0].name,
                    },
                  };
                }}
                multiSelect
              />
            );
          })()
        : undefined}
      {showAttribute
        ? (() => {
            const attributeId = Number(showAttribute);

            return (
              <EditAttribute
                modal
                id={attributeId}
                onLoad={() => {
                  return apiAttributes
                    .filter(accesToken, {
                      filtername: "id",
                      value: attributeId,
                    })
                    .then((response) => {
                      return {
                        name: response.data[0].name,
                        description: response.data[0].description,
                        type: response.data[0].attribute_type,
                      };
                    });
                }}
                onSave={async (payload) => {
                  return apiAttributes.edit(accesToken, {
                    id: attributeId,
                    ...payload,
                  });
                }}
                onSync={async () => {
                  return apiAttributes.sync(accesToken, {
                    attributeId,
                  });
                }}
                onCheckSync={async () => {
                  return checkSync.checkAttributeSync(accesToken, {
                    attributeId,
                  });
                }}
                loadSyncCheck={async () => {
                  const response = await apiAttributes.checkSync(accesToken, {
                    attributeId,
                  });

                  return response.data.attribute[0].sync;
                }}
                beforeClose={({ name }) => {
                  console.log("Whololo!");

                  handleEditAttribute({ id: attributeId, name });
                }}
                close={() => {
                  setHideFloatingButtons(false);
                  setShowAttribute(false);
                }}
              />
            );
          })()
        : undefined}
      {showAddInformativeAttributes
        ? (() => {
            const selectedItems = informativeAttributes.map((item) => item.id);

            return (
              <AdministerCopies
                addButtonDescription="hide"
                buttonDescription="hide"
                title="Atributos"
                loadItems={() => {
                  return apiAttributes
                    .getAttributesInfo(accesToken)
                    .then(({ data }) => {
                      const items = data.map((item) => ({
                        id: item.id,
                        name: item.name,
                      }));

                      return {
                        data: items,
                      };
                    });
                }}
                onSave={async (filters) => {
                  const attributesToAdd = filters.map((item) => ({
                    id: item.id,
                    name: item.name,
                    type: "info",
                  }));

                  setHasChanged(true);
                  setInformativeAttributes([
                    ...informativeAttributesRef.current,
                    ...attributesToAdd,
                  ]);
                  setAddedAttributes([...addedAttributes, ...attributesToAdd]);
                  setAvailableAttributes((attributes) => [
                    ...attributes,
                    ...attributesToAdd,
                  ]);
                }}
                onSaved={async () => {}}
                applySelection={async () => {}}
                selectedItems={selectedItems}
                close={() => {
                  setHideFloatingButtons(false);
                  setShowAddInformativeAttributes(false);
                }}
                createItem={async (name) => {
                  const response = await apiAttributes.createAttribute(
                    accesToken,
                    {
                      name,
                      type: "info",
                    }
                  );

                  const attributeId = response.data.attribute[0].id;
                  (async () => {
                    try {
                      await apiAttributes.sync(accesToken, {
                        attributeId,
                      });

                      await checkSync.checkAttributeSync(accesToken, {
                        attributeId,
                      });

                      displayNotification({
                        title: "Se ha sincronizado correctamente",
                        message: "La sync del atributo ha sido exitosa",
                        type: "success",
                      });
                    } catch (error) {
                      displayNotification({
                        title: "Ha habido un error en la sync",
                        message: "La sync del atributo ha fallado",
                        type: "danger",
                      });
                    }
                  })();

                  return {
                    data: {
                      id: response.data.attribute[0].id,
                      name: response.data.attribute[0].name,
                    },
                  };
                }}
                multiSelect
              />
            );
          })()
        : undefined}
    </>
  );
};

export default ProductAttributesTab;
