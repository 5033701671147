const includeExcludeFromFilter = (
  id,
  { availableFilters, setAvailableFilters, filters, setFilters }
) => {
  const filterinActive = filters.find((filter) => filter.id === id);

  if (filterinActive) {
    // If filter is already included, remove it from active filters and add it to available filters
    setAvailableFilters([...availableFilters, filterinActive]);

    const updatededFilters = filters.filter((filter) => filter.id !== id);
    setFilters(updatededFilters);
  } else {
    // If filter is not include it, add it to filters and remove it from available filters
    const filterToInclude = availableFilters.find((filter) => filter.id === id);
    setFilters([...filters, filterToInclude]);

    const updatedAvailableFilters = availableFilters.filter(
      (filter) => filter.id !== id
    );
    setAvailableFilters(updatedAvailableFilters);
  }
};

export default includeExcludeFromFilter;
