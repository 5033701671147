import { store } from "react-notifications-component";

const displayNotification = ({ title, message, type, dismiss }) => {
  store.addNotification({
    title,
    message,
    type,
    insert: "top",
    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
    animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
    dismiss: {
      duration: 8000,
      pauseOnHover: true,
      ...dismiss,
    },
    container: "bottom-left",
  });
};

export default displayNotification;
