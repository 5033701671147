import React from "react";
import AdministerCopies from "../AdministerCopies/AdministerCopies";

const SelectFatherCategory = (props) => {
  return (
    <AdministerCopies
      title="Cambiar categoría padre a la categoría"
      addTableTitle="Categoría a añadir"
      copiesTableTitle="Categoría original"
      props={props}
      close={() =>
        props.history.push(`/search/edit-category/${props.match.params.id}`)
      }
    />
  );
};

export default SelectFatherCategory;
