import { useState } from "react";
import { useSelector } from "react-redux";
import apiSync from "../../../../api/sync";

interface SearchOptions {
  syncFail: boolean;
  needSync: boolean;
  syncing: boolean;
  noPsId: boolean;
}

const useSyncCategories = () => {
  const [categories, setCategories] = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(0);

  const accesToken = useSelector((state: any) => state.user.access);

  const handleClickPage = async (value: number) => {
    try {
      const productsFromPageResponse = await apiSync.getSyncErrors(accesToken, {
        filtername: "categories",
        syncFail: false,
        needSync: false,
        synchronizing: false,
        noId: false,
        page: value + 1,
      });

      setCategories(productsFromPageResponse.data.results);
      setActivePage(value);
    } catch (error) {
      console.log(error);
    }
  };

  const totalPages = Math.ceil(total / 20);

  return {
    categories,
    setCategories,
    totalCategories: total,
    setTotalCategories: setTotal,
    activeCategoriesPage: activePage,
    handleClickCategoryPage: handleClickPage,
    totalCategoryPages: totalPages,
  };
};

export default useSyncCategories;
