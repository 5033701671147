import axios from "axios";


const sendDataRegister =() =>{
  return axios({
    method: "post",
    url: "https://5ff73226e7164b0017e1a437.mockapi.io/data", //Mock API para probar la llamada, sin body
    // data: {
    //   username,
    //   email,
    //   password,
    // },
  });

};









export default {sendDataRegister};
