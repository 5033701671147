import { useState } from "react";
import { useSelector } from "react-redux";

import apiCategories from "../../../../api/categories";

import reorder from "../../../../utilities/reorder";

const LOADING = "LOADING";

const useProducts = (categoryId: number) => {
  const [products, setProducts] = useState<any>(LOADING);

  const accesToken = useSelector((state: any) => state.user.access);

  const handleOnDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedProducts = reorder(
      products,
      result.source.index,
      result.destination.index
    );

    setProducts(reorderedProducts);

    const productIds = reorderedProducts.map((prd: any) => prd.id);

    try {
      await apiCategories.reorderCategoryProducts(accesToken, {
        categoryId,
        productIds,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    products,
    setProducts,
    handleOnDragEndProduct: handleOnDragEnd,
  };
};

export default useProducts;
