import { useState } from "react";

const photosGroupToShow = 6;

const usePhotos = () => {
  const [photos, setPhotos] = useState("LOADING");
  const [numberOfPhotosToDisplay, setNumberOfPhotosToDisplay] =
    useState(photosGroupToShow);

  let numberOfPhotos = 0;
  if (Array.isArray(photos)) {
    numberOfPhotos = photos.length;
  }

  let photosToDisplay = photos.slice(0, numberOfPhotosToDisplay);

  const remainingPhotos = numberOfPhotos - numberOfPhotosToDisplay;

  const handleClickMorePhotos = () => {
    setNumberOfPhotosToDisplay((prev) => prev + photosGroupToShow);
  };

  const showMorePhotosButton = numberOfPhotos > numberOfPhotosToDisplay;

  return {
    photos,
    setPhotos,
    numberOfPhotos,
    photosToDisplay,
    remainingPhotos,
    handleClickMorePhotos,
    showMorePhotosButton,
  };
};

export default usePhotos;
