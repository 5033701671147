import classes from "./KeyWordsList.module.css";
import KeyWordsListData from "./KeyWordsListData.json";
import { Container, Col, Row } from "react-bootstrap";
import { Circle, Check2Circle } from "react-bootstrap-icons";

import GearDropDown from "./GearDropDown/GearDropDown";

import useSemIdeas from "./hooks/use-semIdeas";

import Idea from "./types/Idea";

const KeyWordsList = () => {
  const dataArray: Idea[] = KeyWordsListData;

  const {
    ideasTableTitles,
    handleClickIdeaTableTitle,
    filterOptionsContainerRef,
    showIdeasTitlesDropDown,
    handleToggleIdeasTitlesDropDown,
  } = useSemIdeas();

  const content = dataArray.map((item, i) => {
    return (
      <>
        <div
          className={`${classes.content}  ${
            item.selected ? classes.selected : undefined
          }`}
        >
          <li className={classes.selectedWidth}>
            {item.selected ? (
              <button>
                <Check2Circle />
              </button>
            ) : (
              <button>
                <Circle />
              </button>
            )}
          </li>
          {ideasTableTitles[0].active && (
            <li className={classes.keyWordWidth}>{item.text} </li>
          )}
          {ideasTableTitles[1].active && (
            <li className={classes.avgWidth}>{item.avg_monthly}</li>
          )}
          {ideasTableTitles[2].active && (
            <li className={classes.cpcMinWidth}>{item.top_of_page_low_cpc}</li>
          )}
          {ideasTableTitles[3].active && (
            <li className={classes.cpcMaxWidth}>{item.top_of_page_high_cpc}</li>
          )}
          {ideasTableTitles[4].active && (
            <li className={classes.compWidth}>{item.competition}</li>
          )}
          {ideasTableTitles[5].active && (
            <li className={classes.adGroupWidth}>
              {item.group ?? "Sin grupo"}
            </li>
          )}
        </div>
      </>
    );
  });

  return (
    <Container>
      <Row>
        <Col>
          {/* Menu de la lista */}
          <div className={classes.list}>
            <div className={classes.listTitle}>
              <h4>Ideas</h4>
              <GearDropDown
                ideasTableTitles={ideasTableTitles}
                handleClickIdeaTableTitle={handleClickIdeaTableTitle}
                optionsContainerRef={filterOptionsContainerRef}
                show={showIdeasTitlesDropDown}
                handleClick={handleToggleIdeasTitlesDropDown}
              />
            </div>
            <div className={classes.header}>
              <li className={classes.selectedWidth}></li>
              {ideasTableTitles[0].active && (
                <li className={classes.keyWordWidth}>
                  Keyword
                  <select className="ml-5">
                    <option onClick={() => {}} selected>
                      Todas
                    </option>
                    <option onClick={() => {}}>Cualificadas</option>
                    <option onClick={() => {}}>No cualificadas</option>
                  </select>
                </li>
              )}
              {ideasTableTitles[1].active && (
                <li className={classes.avgWidth}>AVG</li>
              )}
              {ideasTableTitles[2].active && (
                <li className={classes.cpcMinWidth}>CPC min</li>
              )}
              {ideasTableTitles[3].active && (
                <li className={classes.cpcMaxWidth}>CPC max</li>
              )}
              {ideasTableTitles[4].active && (
                <li className={classes.compWidth}>Comp</li>
              )}
              {ideasTableTitles[5].active && (
                <li className={classes.adGroupWidth}>Ad Group</li>
              )}
            </div>
            {content}
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default KeyWordsList;
