import { useState } from "react";
import { useSelector } from "react-redux";

import { PROCESSING } from "../../../../utilities/processStates";

import apiArticles from "../../../../api/articles";

import later from "../../../../utilities/later";

interface Orphan {
  id: number;
  ref: string;
  name: string;
  active: boolean;
}

const useOrphans = () => {
  const [allOrphans, setAllOrphans] = useState<Orphan[]>([]);
  const [allOrphansUntouched, setAllOrphansUntouched] = useState<Orphan[]>([]);
  const [processUpdateFromErp, setProcessUpdateFromErp] = useState<string>();

  const accesToken = useSelector((state: any) => state.user.access);

  const handleClickReloadOrphans = async () => {
    try {
      setProcessUpdateFromErp(PROCESSING);

      await apiArticles.updateFromErp(accesToken);
      const { data } = await apiArticles.getOrphans(accesToken);

      const items = data.map((item: any) => ({
        id: item.id,
        ref: item.ref,
        name: item.description ?? item.description_erp,
        active: item.active,
      }));

      setAllOrphans(items);
      setAllOrphansUntouched(items);

      setProcessUpdateFromErp(undefined);
    } catch (error) {
      console.log(error);
    }
  };

  const isProcessingUpdateFromErp = processUpdateFromErp === PROCESSING;

  return {
    allOrphans,
    setAllOrphans,
    handleClickReloadOrphans,
    allOrphansUntouched,
    setAllOrphansUntouched,
    isProcessingUpdateFromErp,
  };
};

export default useOrphans;
