import React from "react";
import { useSelector } from "react-redux";
import apiAttributes from "../../../api/attributes";
import checkSync from "../../../utilities/checkSync";
import EditAttributeValue from "../EditAttributeValue/EditAttributeValue";

const AttributeValue = (props) => {
  const valueId = props.match ? props.match.params.id : props.id;
  const accesToken = useSelector((state) => state.user.access);

  return (
    <EditAttributeValue
      modal={props.modal}
      close={props.onHide}
      title="Valor de atributo: "
      onLoad={async () => {
        const response = await apiAttributes.getValues(accesToken, {
          filtername: "id",
          value: valueId,
        });

        return response.data;
      }}
      onSave={({ color, value }) => {
        return apiAttributes.editValue(accesToken, {
          id: valueId,
          value,
          color,
        });
      }}
      beforeClose={(value) => {}}
      onSync={async () => {
        return apiAttributes.syncValue(accesToken, {
          attributeValueId: valueId,
        });
      }}
      onCheckSync={async () => {
        return checkSync.checkAttributeValueSync(accesToken, {
          attributeValueId: valueId,
        });
      }}
      loadSyncCheck={async () => {
        const response = await apiAttributes.valueCheckSync(accesToken, {
          attributeValueId: valueId,
        });

        return response.data.attributeValue[0];
      }}
    />
  );
};

export default AttributeValue;
