import React from "react";
import AdministerCopies from "../AdministerCopies/AdministerCopies";

const AddArticleToProduct = (props) => {
  return (
    <AdministerCopies
    buttonDescription="Unificar categorías"

      title="Añadir artículo al producto"
      addTableTitle="artículo a añadir"
      copiesTableTitle="artículo original"
      props={props}
      multiSelect={true}
      close={() => props.history.push(`/product/${props.match.params.id}`)}
    />
  );
};

export default AddArticleToProduct;
