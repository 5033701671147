import React from "react";

import useUploadFiles from "./hooks/use-uploadFiles";
import { Alert, Button } from "react-bootstrap";
import { CloudArrowUp } from "react-bootstrap-icons";

import LoadingMessage from "../../../../../components/LoadingMessage/LoadingMessage";
import PdfsList from "../components/UpgradedFilesList/UpgradedFilesList";
import UploadFilesDropDown from "../../../../../components/UploadFilesDropDown/UploadFilesDropDown";

import classes from "./UploadPdfs.module.scss";

const UploadPdfs: React.FC<{ productId: number }> = ({ productId }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    filesToUpload,
    handleClickRemoveFile,
    isUploadingPdf,
    areErrors,
    errorMessage,
    handleOpenPdf,
    isProcessingSomething,
    isProcessingFirstLoad,
  } = useUploadFiles(productId);

  if (isProcessingFirstLoad) {
    return <LoadingMessage message="Cargando pdfs" />;
  }

  return (
    <>
      {/* <h3 className={classes.sectionTitle}>Pdfs:</h3> */}
      <UploadFilesDropDown
        rootProps={getRootProps()}
        inputProps={getInputProps()}
        isDragActive={isDragActive}
        disabled={isProcessingSomething}
        processing={isUploadingPdf}
      />
      {areErrors && (
        <span className={classes.errorMessage}>{errorMessage}</span>
      )}
      {filesToUpload.length > 0 ? (
        <div className="mt-3">
          <PdfsList
            files={filesToUpload}
            handleClickOpen={handleOpenPdf}
            handleClickRemoveFile={handleClickRemoveFile}
            disabled={isProcessingSomething}
            type="pdf"
          />
        </div>
      ) : (
        <Alert variant="info" className="mt-2">
          Ho hay ningun pdf asociado a este producto
        </Alert>
      )}
    </>
  );
};

export default UploadPdfs;
