import { store } from "react-notifications-component";

const displayErrorNotification = () => {
  store.addNotification({
    title: "ERROR",
    message: (
      <div className="mb-3">
        <p>
          Se ha producido un error, si el problema persiste pongase en contacto
          con el servicio técnico
        </p>
      </div>
    ),
    type: "danger",
    insert: "top",
    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
    animationOut: ["animate__animated animate__fadeOut"], // `animate.css v4` classes
    dismiss: {
      duration: 8000,
      pauseOnHover: true,
    },
    container: "bottom-left",
  });
};

export default displayErrorNotification;
