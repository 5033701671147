import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import InputErrorMessage from "../InputErrorMessage/InputErrorMessage";
import classes from "./TextEditor.module.css";

const TextEditor = (props) => (
  <div className={props.error ? classes.inputError : undefined}>
    <Editor
      value={props.value}
      init={{
        selector: "textarea",
        forced_root_block: "",
        height: 500,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
          "image imagetools",
          "code",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help | \
                          image media | code",
      }}
      initialValue={props.initialValue}
      onEditorChange={props.onEditorChange}
      onBlur={props.onBlur}
      onKeyUp={props.onKeyUp}
      disabled={props.disabled}
      onInit={props.onInit}
      onChange={props.onChange}
    />
    {props.error ? (
      <InputErrorMessage>{props.errorMessage}</InputErrorMessage>
    ) : undefined}
  </div>
);

export default TextEditor;
