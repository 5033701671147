import { useState } from "react";
import { useSelector } from "react-redux";

import apiSeeds from "../../../../../api/sem/sem-seeds";
import apiSeedKeywords from "../../../../../api/sem/keywords";

import { PROCESSING } from "../../../../../utilities/processStates";

import Keyword from "../../types/Keyword";

interface Seed {
  id: number;
  name: string;
  keywordStrings: Keyword[];
}

const useSeedGroup = (campaignId: number) => {
  const [seedsData, setseedsData] = useState<Seed[]>([]);
  const [modalLangage, setModalLanguage] = useState<boolean>(false);
  const [modalKwStrings, setModalKwStrings] = useState<boolean>(false);

  // Process states
  const [addSeedGroupProcess, setAddSeedGroupProcess] = useState<string>();
  const [createKeywordProcess, setCreateKeywordProcess] = useState<string>();

  const accessToken = useSelector((state: any) => state.user.access);

  const [selectedSeedGroupIndex, setSelectedGroupIndex] = useState<
    number | undefined
  >();

  let selectedseedGroup: Seed | undefined = undefined;
  if (selectedSeedGroupIndex !== undefined) {
    selectedseedGroup = seedsData[selectedSeedGroupIndex];
  }

  // SEED GROUP related functions
  const addSeedgroup = async () => {
    setModalLanguage(false);
    setAddSeedGroupProcess(PROCESSING);

    try {
      const response: any = await apiSeeds.add(accessToken, {
        campaign_pk: campaignId,
        keyword_string_pks: [],
        locations: [],
        language: 1,
      });

      const seedGroupId = response.data.pk;

      setseedsData((prevSeeds) => {
        const newSeedGroup: Seed = {
          id: seedGroupId,
          name: `seed group ${prevSeeds.length + 1}`,
          keywordStrings: [],
        };

        return [...prevSeeds, newSeedGroup];
      });
    } catch (error) {
      console.log(error);
    }

    setAddSeedGroupProcess(undefined);
  };

  const removeSeedGroup = async (index: number) => {
    const seedGroupToRemoveId = seedsData[index].id;

    setseedsData((prevSeeds: Seed[]) => {
      const newSeedsData = [...prevSeeds];
      newSeedsData.splice(index, 1);

      return [...newSeedsData];
    });

    try {
      await apiSeeds.remove(accessToken, {
        seedGroup_pk: seedGroupToRemoveId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // KEYWORD related functions
  const addKeywordString = async (keywordString: string) => {
    if (
      selectedSeedGroupIndex === undefined ||
      selectedseedGroup === undefined
    ) {
      return;
    }

    setCreateKeywordProcess(PROCESSING);

    try {
      const response = await apiSeedKeywords.add(accessToken, {
        string: keywordString,
        language: 1,
      });

      const createdKeywordId = response.data.pk;

      const seedGroupKeywordsIds = selectedseedGroup.keywordStrings.map(
        (group) => group.id
      );

      await apiSeeds.update(accessToken, {
        seedGroup_pk: selectedseedGroup.id,
        keyword_string_pks: [...seedGroupKeywordsIds, createdKeywordId],
        locations: [],
      });

      setseedsData((prev) => {
        const updatedPrev = prev.map((seedGroup) => ({
          ...seedGroup,
          keywordStrings: seedGroup.keywordStrings.map((kw) => ({ ...kw })),
        }));

        updatedPrev[selectedSeedGroupIndex].keywordStrings = [
          ...updatedPrev[selectedSeedGroupIndex].keywordStrings,
          {
            id: createdKeywordId,
            name: keywordString,
          },
        ];

        return updatedPrev;
      });
    } catch (error) {
      console.log(error);
    }

    setCreateKeywordProcess(undefined);
  };

  const removeKeywordString = async (index: number) => {
    if (
      selectedSeedGroupIndex === undefined ||
      selectedseedGroup === undefined
    ) {
      return;
    }

    const seedGroupRemainingKeywordIds = seedsData[
      selectedSeedGroupIndex
    ].keywordStrings.map((group) => group.id);
    seedGroupRemainingKeywordIds.splice(index, 1);

    setseedsData((prev) => {
      const updatedPrev = prev.map((seedGroup) => ({
        ...seedGroup,
        keywordStrings: seedGroup.keywordStrings.map((kw) => ({ ...kw })),
      }));

      updatedPrev[selectedSeedGroupIndex].keywordStrings.splice(index, 1);

      return updatedPrev;
    });

    try {
      await apiSeeds.update(accessToken, {
        seedGroup_pk: selectedseedGroup.id,
        keyword_string_pks: seedGroupRemainingKeywordIds,
        locations: [],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickAvailableKeyword = async (keywordToAdd: any) => {
    if (
      selectedSeedGroupIndex === undefined ||
      selectedseedGroup === undefined
    ) {
      return;
    }

    const formattedKeywordToAdd = {
      id: keywordToAdd.id,
      name: keywordToAdd.string,
    };

    setseedsData((prev) => {
      const updatedPrev = prev.map((seedGroup) => ({
        ...seedGroup,
        keywordStrings: seedGroup.keywordStrings.map((kw) => ({ ...kw })),
      }));

      updatedPrev[selectedSeedGroupIndex].keywordStrings.push(
        formattedKeywordToAdd
      );

      return updatedPrev;
    });

    const selectedSeedGroupKeywordIds = selectedseedGroup.keywordStrings.map(
      (key) => key.id
    );

    try {
      await apiSeeds.update(accessToken, {
        seedGroup_pk: selectedseedGroup.id,
        keyword_string_pks: [
          ...selectedSeedGroupKeywordIds,
          formattedKeywordToAdd.id,
        ],
        locations: [],
      });
    } catch (error) {
      console.log(error);
    }
  };

  // MODALS related functions
  const onModalLanguageExit = () => {
    setModalLanguage(false);
  };

  const onOpenModalLanguage = () => {
    setModalLanguage(true);
  };

  const onModalKwStringsExit = () => {
    setModalKwStrings(false);
  };

  const onOpenModalKwStrings = () => {
    setModalKwStrings(true);
  };

  const handleSelectSeedGroup = (index: number) => {
    setSelectedGroupIndex(index);
  };

  // Is processing booleans
  const isProcessingAddSeedGroup = addSeedGroupProcess === PROCESSING;
  const isProcessingCreateKeyword = createKeywordProcess === PROCESSING;

  return {
    seedsData,
    modalLangage,
    modalKwStrings,
    selectedseedGroup,
    addSeedgroup,
    removeSeedGroup,
    removeKeywordString,
    onModalLanguageExit,
    onOpenModalLanguage,
    onModalKwStringsExit,
    onOpenModalKwStrings,
    setseedsData,
    setModalLanguage,
    selectedSeedGroupIndex,
    handleSelectSeedGroup,
    addKeywordString,
    handleClickAvailableKeyword,
    isProcessingAddSeedGroup,
    isProcessingCreateKeyword,
  };
};

export default useSeedGroup;
