import customAxios from "../../customAxios";

const createBudget = (
  access: string,
  data: { account_pk: number; amount: number }
) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/sem/budget/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};
const campaign = (
  access: string,
  data: {
    account_pk: number;
    entity_pk: number;
    entity_type: number;
    budget_pk: number;
    maxCpc: number;
  }
) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/sem/campaign/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};
const campaignLocation = (
  access: string,
  data: { account_pk: number; campaign_pk: number; locations: Array<number> }
) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/sem/campaign-location/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};
const campaignLanguages = (
  access: string,
  data: { account_pk: number; campaign_pk: number; languages: Array<number> }
) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/sem/campaign-language/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

export default {
  createBudget,
  campaign,
  campaignLocation,
  campaignLanguages,
};
