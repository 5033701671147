import React from "react";
import classes from "./Error404.module.css";
import { Container } from "react-bootstrap";


const Error404 = () => {
  return (
    <Container fluid>
      <div className={classes.hitthefloor}>
        <p>404</p>
        <p>Page Not found</p>
         </div>
      </Container>
  );
};

export default Error404;
