import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PROCESSING } from "../../../../../../../utilities/processStates";

import apiCertificates from "../../../../../../../api/certificates";
import apiProducts from "../../../../../../../api/products";

import dsiplayNoCertificatePdfNotification from "../../../../../../../utilities/notifications/noCertificatePdf";

const useSelectCertificateFromList: (
  productId: number,
  linkedCertificates: number[],
  handleLinkCertificates: (certificatesToAdd: any[]) => void
) => any = (productId, linkedCertificates, handleLinkCertificates) => {
  const [certificatesList, setCertificatesList] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [processLoadCertificates, setProcessLoadCertificates] = useState<
    string | undefined
  >(PROCESSING);
  const [processLinkCertificate, setProcessLinkCertificate] = useState<
    string | undefined
  >();

  const accesToken = useSelector((state: any) => state.user.access);

  useEffect(() => {
    (async () => {
      try {
        setProcessLoadCertificates(PROCESSING);

        setProcessLoadCertificates(PROCESSING);
        const response = await apiCertificates.getCertificates(accesToken);

        const allCertificates = response.data;
        const availableCertificates = allCertificates.filter(
          (cert: any) => !linkedCertificates.includes(cert.id)
        );

        setCertificatesList(availableCertificates);
      } catch (e) {
        console.log(e);
      }

      setProcessLoadCertificates(undefined);
    })();
  }, []);

  const handleClickSelectCertificate = (id: number) => {
    setCertificatesList((certificates: any[]) => {
      const updatedCertificates = certificates.map((cert) => {
        if (cert.id === id) {
          return {
            ...cert,
            selected: !cert.selected,
          };
        }

        return { ...cert };
      });

      return updatedCertificates;
    });
  };

  const handleClickLinkCertificates = async () => {
    setProcessLinkCertificate(PROCESSING);

    try {
      const certificatesToLink = certificatesList.filter(
        (cert) => cert.selected
      );

      const certificateIdsToLink = certificatesToLink.map((cert) => cert.id);

      await apiProducts.addCertificate(accesToken, {
        productId,
        certificateIds: certificateIdsToLink,
      });

      setCertificatesList((certs) => {
        const items = certs.filter(
          (cert) => !certificateIdsToLink.includes(cert.id)
        );

        return items;
      });

      const processedCertificatesToLink = certificatesToLink.map((cert) => {
        delete cert.selected;

        return cert;
      });

      handleLinkCertificates(processedCertificatesToLink);
    } catch (e) {
      console.log(e);
    }

    setProcessLinkCertificate(undefined);
  };

  const handleClickCertificatePdf = async (id: number) => {
    try {
      const selectedCertificate = certificatesList.find(
        (cert) => cert.id === id
      );

      const response = await apiCertificates.openPdf(accesToken, {
        filename: selectedCertificate.name + ".pdf",
      });

      const file = new Blob([response.data], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      dsiplayNoCertificatePdfNotification();

      console.log(error);
    }
  };

  const handleTypeOnSearch = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const isLoadingCertificates = processLoadCertificates === PROCESSING;
  const isLinkingCertificates = processLinkCertificate === PROCESSING;

  let certificatesToDisplay = [];

  if (searchQuery.length > 0) {
    certificatesToDisplay = certificatesList.filter((cert) => {
      return (
        String(cert.name).toLowerCase().includes(searchQuery.toLowerCase()) ||
        String(cert.description)
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    });
  } else {
    certificatesToDisplay = certificatesList;
  }

  return {
    certificatesList: certificatesToDisplay,
    handleClickSelectCertificate,
    handleClickLinkCertificates,
    handleClickCertificatePdf,
    isLoadingCertificates,
    isLinkingCertificates,
    handleTypeOnSearch,
    searchQuery,
  };
};

export default useSelectCertificateFromList;
