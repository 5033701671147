import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Alert, Container, Row, Col, Button } from "react-bootstrap";
import api from "./api";
import Accordion from "./components/Accordion/Accordion";
import CardItem from "./components/CardItem/CardItem";
import ListGroup from "./components/ListGroup/ListGroup";
import { Grid3x3GapFill, CaretLeftFill } from "react-bootstrap-icons";
import classes from "./Filter.module.css";

// axiosTiming(axios, (time) => console.log(`axios timing has been ${time}`));

const processFiltersFromDB = ({
  attributes,
  features,
  openedFilters,
  selectedFeatures,
  selectedAttributes,
}) => {
  const featuresFilters = features.map((feature) => {
    let values = [];

    if (feature.feature_values) {
      values = feature.feature_values.map((value) => {
        return {
          id: "f" + value.prestashop_id,
          cid: value.id,
          value: value.value,
          selected: selectedFeatures.find(
            (valId) => valId === "f" + value.prestashop_id
          ),
          type: "feature",
        };
      });
    }

    return {
      id: "f" + feature.prestashop_id,
      name: feature.name,
      selected: openedFilters.find(
        (option) => option === "f" + feature.prestashop_id
      ),
      values,
    };
  });

  const attributesFilters = attributes.map((attr) => {
    const values = attr.attribute_values.map((value) => {
      return {
        id: "a" + value.prestashop_id,
        cid: value.id,
        value: value.value,
        selected: selectedAttributes.find(
          (valId) => valId === "a" + value.prestashop_id
        ),
        type: "attribute",
      };
    });

    return {
      id: "a" + attr.prestashop_id,
      name: attr.name,
      selected: openedFilters.find(
        (option) => option === "a" + attr.prestashop_id
      ),
      values,
    };
  });

  return featuresFilters.concat(attributesFilters);
};

const PROCESSING = "PROCESSING";
const SUCCESS = "SUCCESS";

const Filter = (props) => {
  const [filterOptions, setFilterOptions] = useState([]);
  const [openedOptions, setOpenedOptions] = useState([]);
  const [openedHorizontallOptions, setOpenedHorizontallOptions] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [products, setProducts] = useState([]);
  const [mainCategory, setMainCategory] = useState({});
  const [categories, setCategories] = useState([]);
  const [productsPhotos, setProductPhotos] = useState([]);
  const [processFiltersStatus, setProcessFiltersStatus] = useState();

  // Param id
  // let { id } = props.match.params.id;
  let id = Number(props.match.params.id);

  // AUTH
  const accessToken = useSelector((state) => state.user.access);

  const [showMenu, setShowMenu] = useState(false);

  // This should be an error but I am to lazy to do it right now :/
  const [errorMessage, setErrorMessage] = useState();

  const getItemsProcess = (
    accessToken,
    mainCategoryId,
    updatedSelectedFilters = [],
    times = 1
  ) => {
    const selectedFeatures = updatedSelectedFilters.filter(
      (id) => id.charAt(0) === "f"
    );
    const selectedAttributes = updatedSelectedFilters.filter(
      (id) => id.charAt(0) === "a"
    );

    const processedArticleValues = [];
    filterOptions.forEach((filter) => {
      if (filter.id.charAt(0) === "a") {
        const hasValuesSelected = filter.values.find((val) => val.selected);
        if (!hasValuesSelected) {
          return;
        }

        const selectedValues = filter.values.filter((val) => val.selected);
        const selectedValuesIds = selectedValues.map((val) =>
          Number(val.id.slice(1))
        );

        processedArticleValues.push({
          id: Number(filter.id.slice(1)),
          valuesIds: selectedValuesIds,
        });
      }
    });

    const processedFeatureValues = [];
    filterOptions.forEach((filter) => {
      if (filter.id.charAt(0) === "f") {
        const hasValuesSelected = filter.values.find((val) => val.selected);
        if (!hasValuesSelected) {
          return;
        }

        const selectedValues = filter.values.filter((val) => val.selected);
        const selectedValuesIds = selectedValues.map((val) =>
          Number(val.id.slice(1))
        );

        processedFeatureValues.push({
          id: Number(filter.id.slice(1)),
          valuesIds: selectedValuesIds,
        });
      }
    });

    console.log("UPD", updatedSelectedFilters);

    return api
      .getItems(
        accessToken,
        Number(id),
        processedArticleValues,
        processedFeatureValues
      )
      .then(({ data }) => {
        setErrorMessage();
        setProcessFiltersStatus(SUCCESS);

        if (
          (data.attributes || data.features) &&
          data.products &&
          data.products.length > 0
        ) {
          setFilterOptions(
            processFiltersFromDB({
              attributes: data.attributes,
              features: data.features,
              openedFilters: openedOptions,
              selectedFeatures,
              selectedAttributes,
            })
          );
        }

        setProcessFiltersStatus(SUCCESS);

        if (data.products) {
          setProducts(data.products);
          const productIds = data.products.map((product) => product.id);
          api
            .getProductsWithPhotos(accessToken, productIds)
            .then(({ data }) => {
              setProductPhotos(data.products);
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => {
        setErrorMessage("Ha habido un error");

        setTimeout(() => {
          getItemsProcess(
            accessToken,
            mainCategoryId,
            updatedSelectedFilters,
            times + 1
          );
        }, 2000 * times);
      });
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.getItems(accessToken, id, [], []);

        if (data.categories && data.categories.length > 0) {
          const updatedCategorie = data.categories.map((item) => ({
            id: item.prestashop_id,
            name: item.name,
            url: item.url,
          }));

          setCategories(updatedCategorie);
          setMainCategory(id);

          return;
        }

        if (data.products.length > 0 || data.attributes.length > 0) {
          setFilterOptions(
            processFiltersFromDB({
              attributes: data.attributes,
              features: data.features,
              openedFilters: openedOptions,
              selectedFeatures: [],
              selectedAttributes: [],
            })
          );

          if (data.products) {
            setProducts(data.products);
          }

          setCategories([]);
          setMainCategory(id);

          return;
        }

        setCategories([]);
        setProducts([]);
      } catch (error) {
        console.log("PROBLEMON", error);
      }
    })();
  }, [id, accessToken]);

  useEffect(() => {
    if (accessToken) {
      const productIds = products.map((product) => product.id);
      api
        .getProductsWithPhotos(accessToken, productIds)
        .then(({ data }) => {
          setProductPhotos(data.products);
        })
        .catch((e) => console.log(e));
    }
  }, [products, accessToken]);

  const clickFilterValue = (optionPosition, valuePostion) => {
    let updatedSelectedFilters = [...selectedFilters];
    const updatedFilters = filterOptions.map((filter) => ({
      values: filter.values.map((val) => ({ ...val })),
      ...filter,
    }));

    const selectedFilter = updatedFilters[optionPosition].values[valuePostion];

    if (selectedFilter.selected) {
      delete selectedFilter.selected;
      updatedSelectedFilters = updatedSelectedFilters.filter(
        (value) => value !== selectedFilter.id
      );
    } else {
      selectedFilter.selected = true;
      updatedSelectedFilters.push(selectedFilter.id);
    }

    setProcessFiltersStatus(PROCESSING);
    setSelectedFilters(updatedSelectedFilters);

    getItemsProcess(accessToken, mainCategory.id, updatedSelectedFilters);
  };

  const expandFilter = (optionPosition) => {
    const updatedFilters = filterOptions.map((filter) => ({
      values: filter.values.map((val) => ({ ...val })),
      ...filter,
    }));
    let updatedOpenedOptions = [...openedOptions];

    const clickedOption = updatedFilters[optionPosition];

    if (clickedOption.selected) {
      updatedOpenedOptions = updatedOpenedOptions.filter(
        (optId) => optId !== clickedOption.id
      );
      delete clickedOption.selected;
    } else {
      updatedOpenedOptions.push(clickedOption.id);
      clickedOption.selected = true;
    }

    setOpenedOptions(updatedOpenedOptions);
    setFilterOptions(updatedFilters);
  };

  const clickCategory = (category) => {
    props.history.push(`/filter/${category.id}`);
  };

  let content;

  if (categories.length > 0) {
    content = (
      <>
        {categories.map((category, cK) => (
          <Col md={3} sm={12} key={cK}>
            <CardItem
              onClick={() => clickCategory(category)}
              title={category.name}
              src={category.url}
            ></CardItem>
          </Col>
        ))}
      </>
    );
  } else {
    let productsContent = products.map((product) => {
      let photoUrl;
      if (productsPhotos && productsPhotos.length > 0) {
        const photo = productsPhotos.find((pp) => pp.id === product.erp_id);
        if (photo) {
          photoUrl = photo.url;
        }
      }

      return (
        <Col
          className={classes.productCol}
          key={product.id}
          lg={4}
          md={6}
          sm={6}
        >
          <a
            className={classes.productLink}
            href={product["product-url"]}
            target="_blank"
          >
            <CardItem title={product.name} src={photoUrl}>
              <div className={classes.titlePrice}>
                <h5>12€</h5>
                <Button variant="success">Comprar</Button>
              </div>
            </CardItem>
          </a>
        </Col>
      );
    });

    if (products.length <= 0) {
      productsContent = (
        <Alert variant="secondary" className={classes.noProducts}>
          No hay productos que cuandren con los filtros elegidos. Por favor,
          elija otros.
        </Alert>
      );
    }

    console.log("FILTERED OPTIONS", filterOptions);

    content = (
      <>
        <Col
          md={3}
          sm={6}
          className={`${classes.menu} ${showMenu ? classes.showMenu : ""}`}
        >
          <div className={classes.filterOptionsContainer}>
            <Accordion.Container>
              {filterOptions.map((option, optK) => (
                <Accordion.Item
                  collapsed={!option.selected}
                  key={optK}
                  title={option.name}
                  onClick={() => expandFilter(optK)}
                >
                  <ListGroup.Container>
                    {option.values.map((val, vlK) => (
                      <ListGroup.Item key={`${optK} ${vlK}`}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`${optK}-${vlK}`}
                            onChange={() => clickFilterValue(optK, vlK)}
                            checked={Boolean(val.selected)}
                            disabled={processFiltersStatus === PROCESSING}
                          ></input>
                          <label
                            className="form-check-label"
                            htmlFor={`${optK}-${vlK}`}
                          >
                            {val.value}
                          </label>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup.Container>
                </Accordion.Item>
              ))}
            </Accordion.Container>
          </div>
        </Col>
        <Col>
          {selectedFilters.length > 0 ? (
            <div className={`${classes.activatedFilters} activeFilters`}>
              {filterOptions.map((option, optK) => {
                if (option.values.find((val) => val.selected)) {
                  return (
                    <Accordion.Container key={optK}>
                      <Accordion.Item
                        title={option.name}
                        collapsed={
                          !openedHorizontallOptions.includes(option.id)
                        }
                        onClick={() => {
                          console.log(
                            "OPENED",
                            openedHorizontallOptions,
                            option.id
                          );

                          if (openedHorizontallOptions.includes(option.id)) {
                            setOpenedHorizontallOptions(
                              openedHorizontallOptions.filter((optId) => {
                                return optId !== option.id;
                              })
                            );
                          } else {
                            setOpenedHorizontallOptions([
                              ...openedHorizontallOptions,
                              option.id,
                            ]);
                          }
                        }}
                      >
                        <ListGroup.Container>
                          {option.values.map((val, vlK) => (
                            <ListGroup.Item key={`${optK} ${vlK}`}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`hrz${optK}-${vlK}`}
                                  onChange={() => clickFilterValue(optK, vlK)}
                                  checked={Boolean(val.selected)}
                                  disabled={processFiltersStatus === PROCESSING}
                                ></input>
                                <label
                                  className="form-check-label"
                                  htmlFor={`hrz${optK}-${vlK}`}
                                >
                                  {val.value}
                                </label>
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup.Container>
                      </Accordion.Item>
                    </Accordion.Container>
                  );
                }
              })}
            </div>
          ) : undefined}
          <Row>{productsContent}</Row>
        </Col>
      </>
    );
  }

  if (categories.length === 0 && products.length === 0) {
    return (
      <Alert className="m-2" variant="info">
        Esta categoría no tiene contenido
      </Alert>
    );
  }

  return (
    <>
      <div className={classes.titlesContainer}>
        <h1>{mainCategory.name}</h1>
        {products && products.length > 0 ? (
          <h2>Se han encontrato {products.length} productos</h2>
        ) : undefined}
      </div>
      {!categories ? (
        <button
          className={classes.menuButton}
          onClick={() => setShowMenu(!showMenu)}
        >
          {!showMenu ? <Grid3x3GapFill /> : <CaretLeftFill />}
        </button>
      ) : undefined}
      <Container fluid className={classes.container}>
        <Row>
          {errorMessage && (
            <Col md={12}>
              <Alert variant="danger">{errorMessage}</Alert>
            </Col>
          )}
          {content}
        </Row>
      </Container>
    </>
  );
};

export default Filter;
