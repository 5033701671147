import React from "react";

import { Modal, Button, Spinner, Form } from "react-bootstrap";

import CertificatesList from "../../components/CertificatesList/CertificatesList";

import useSelectedCertificates from "./hooks/use-selectCertificateFromList";

import classes from "./SelectCertificateFromList.module.scss";

const SelectCertificateFromList: React.FC<{
  productId: number;
  linkedCertificatesIds: number[];
  handleLinkCertificates: (certificatesToLink: any[]) => void;
  handleClickEditCertificate: (id: number) => void;
  handleClose: () => void;
}> = ({
  productId,
  linkedCertificatesIds,
  handleLinkCertificates,
  handleClickEditCertificate,
  handleClose,
}) => {
  const {
    certificatesList,
    handleClickSelectCertificate,
    handleClickLinkCertificates,
    handleClickCertificatePdf,
    isLoadingCertificates,
    isLinkingCertificates,
    handleTypeOnSearch,
    searchQuery,
  } = useSelectedCertificates(
    productId,
    linkedCertificatesIds,
    handleLinkCertificates
  );

  return (
    <>
      <Modal.Body className={classes.modalBody}>
        {isLoadingCertificates ? (
          <Spinner animation="border" />
        ) : (
          <>
            <Form.Group className="mb-3" controlId="searchCertificates">
              <Form.Label>Buscador</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={handleTypeOnSearch}
                value={searchQuery}
              />
            </Form.Group>
            <CertificatesList
              certificates={certificatesList}
              selectAvailable
              handleCliclSelectCertificate={handleClickSelectCertificate}
              handleClickPdf={handleClickCertificatePdf}
              disalowRemove
              handleClickEdit={handleClickEditCertificate}
              disabled={isLinkingCertificates}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={isLinkingCertificates}
          onClick={handleClose}
        >
          Cerrar
        </Button>
        <Button
          variant="primary"
          onClick={handleClickLinkCertificates}
          disabled={isLinkingCertificates}
        >
          Vincular
        </Button>
      </Modal.Footer>
    </>
  );
};

export default SelectCertificateFromList;
