import React, { useState } from "react";
import { useSelector } from "react-redux";
// import apiBrands from "../../../api/brands";
import apiBrands from "../../../api/brands";
import {
  Accordion,
  Spinner,
  Col,
  Tabs,
  Badge,
  Card,
  Modal,
} from "react-bootstrap";
import OptionsButton from "../../../components/OptionsButton/OptionsButton";
import TabErrorIcon from "../../../components/TabErrorIcon/TabErrorIcon";
import TextInput from "../../../components/TextInput/TextInput";
import CheckSphere from "../../../components/CheckSphere/CheckSphere";
import classes from "./CreateBrand.module.css";
import displayNotification from "../../../utilities/displayNotification";
import checkSync from "../../../utilities/checkSync";
import { Tab } from "bootstrap";
import SEOTab from "../../SEOTab/SEOTab";
import DataTitle from "../../../components/DataTitle/DataTitle";
import TextEditor from "../../../components/TextEditor/TextEditor";
import MainTitle from "../../../components/MainTitle/MainTitle";
import TabContainer from "../../../components/TabContainer/TabContainer";
import TabMainRow from "../../../components/TabMainRow/TabMainRow";
import InputErrorMessage from "../../../components/InputErrorMessage/InputErrorMessage";
import ResyncWhenErrorButton from "../../../components/ResyncWhenErrorButton/ResyncWhenErrorButton";
import generateUrlValidation from "../../../utilities/GenerateUrlValidation";

const states = {
  LOADING: "LOADING",
  SUCCEDDED: "SUCCEDDED",
  PROCESSING: "PROCESSING",
  ERROR: "ERROR",
};

const CreateBrand = (props) => {
  const accesToken = useSelector((state) => state.user.access);
  // * Regular expressions
  const regExpURL =
    /^[^~"<+>^.:;\\,%\]_`!\[{|}&€‚ƒ$=…†'‡ˆ‰Š?‹ŒŽ‘’“”•)*#(–—˜™š@›œžŸ¡¢£¥|§¨©ª«¬¯®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ]+$/;
  const regExpURL2 =
    /^[^~"<+>^.:;\\,%\]_`!\[{|}&€‚ƒ$=…†'‡ˆ‰?‹Œ‘’“”•)*#(–—˜™@›œ¡¢£¥|§¨©ª«¬¯®¯°±²³´µ¶·¸¹º»¼½¾¿Þ÷þ]+$/;
  // const [specialCharacters, setSpecialCharacters] = useState(true);
  const [specialCharactersURL, setSpecialCharactersURL] = useState(true);
  const [specialCharactersName, setSpecialCharactersName] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  // * SEO
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [reWrite, setReWrite] = useState("");

  const [key, setKey] = useState(0);
  const [description, setDescription] = useState("");

  const [name, setName] = useState("");
  const [active, setActive] = useState();
  const [shortDescription, setShortDescription] = useState("");

  const [formProcessPhase, setFormProcessPhase] = useState(states.LOADING);
  const [proccessErrorTab, setProccessErrorTab] = useState(false);

  const [id, setId] = useState();

  const [duplicateNameError, setDuplicateNameError] = useState(false);
  const [process, setProcess] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  const [errors, setErrors] = useState(false);
  const [syncError, setSyncError] = useState(false);
  const [noSyncUnactive, setNoSyncNoUnactive] = useState(false);

  const invalidFieldMessage = () => {
    displayNotification({
      title: "Hay campos inválidos",
      message: `Completa correctamente todos los campos requeridos para continuar`,
      // message: `No es posible seguir con la creación, por favor rellena los campos de manera correcta. `,
      type: "danger",
    });
  };

  const syncBrand = (brandId) => {
    setIsSyncing(true);
    apiBrands.sync(accesToken, { brandId }).then(() => {
      checkSync
        .checkBrandSync(accesToken, {
          brandId,
        })
        .then(() => {
          setIsSyncing(false);
          setSyncError(false);
        })
        .catch((e) => {
          setIsSyncing(false);
          setSyncError(true);
        });
    });
  };

  const switchPhase = async () => {
    switch (Number(key)) {
      case 0:
        if (name.length <= 0 || !specialCharactersName || duplicateNameError) {
          setErrors(true);
          throw new Error();
        } else {
          setProcess(true);

          let brandId = id;
          // * Set reWrite with the name in the first tab / Generate friendly URL with translation of some special characters
          const generatedUrl = generateUrlValidation({
            specialCharactersName: specialCharactersName,
            name: name,
            reWrite: reWrite,
            setSpecialCharactersURL: setSpecialCharactersURL,
          });

          if (generatedUrl) {
            setReWrite(generatedUrl);
          }
          try {
            if (!brandId) {
              const createBrandResponse = await apiBrands.add(accesToken, {
                name,
              });
              if (createBrandResponse.data.error) {
                throw "DUPLICATED NAME";
              }

              brandId = createBrandResponse.data.id;
              setId(createBrandResponse.data.id);

              syncBrand(brandId);

              setErrors(false);
            } else {
              await apiBrands.edit(accesToken, {
                id: brandId,
                name,
              });

              setErrors(false);
            }

            setProcess(false);
            setKey(1);
          } catch (e) {
            if (e.response && e.response.status === 409) {
              setDuplicateNameError(true);
              setErrors(true);
              setProcess(false);
              throw new Error();
            } else if (e === "SYNC ERROR") {
              displayNotification({
                title: "Ha habido un error en la sync",
                message:
                  "Ha ocurrido un error en la sincronización, prueba otra vez dentro de unos minutos, si el problema persiste ponte en contacto con el servicio técnico",
                type: "error",
              });
            }

            setProcess(false);

            throw new Error();
          }
        }
        break;

      case 1:
        if (
          !active ||
          description.length <= 0 ||
          description.length > 50000 ||
          shortDescription.length <= 0
        ) {
          setErrors(true);
          throw new Error();
        } else {
          setProcess(true);
          setErrors(false);

          let brandId = id;

          try {
            await apiBrands.edit(accesToken, {
              id: brandId,
              name,
              description,
              shortDescription,
              reWrite,
              metaTitle,
              metaDescription,
              active,
            });

            setKey(2);
            setProcess(false);
          } catch (e) {
            if (e.response && e.response.status === 409) {
              setDuplicateNameError(true);
              setErrors(true);
              setProcess(false);
              displayNotification({
                title: "El nombre está duplicado",
                message: `No es posible crear una marca con un nombre ya existente, por favor modifíquelo`,
                type: "danger",
              });
              throw new Error();
            }
          }
        }
        break;

      default:
        setKey(Number(key) + 1);
        break;
    }
  };

  const nextPhase = async () => {
    try {
      // setProccessErrorTab(true)
      await switchPhase(key);
      setKey(key + 1);
    } catch (e) {
      invalidFieldMessage();
    }
  };

  const prevPhase = () => {
    // setProccessErrorTab(true);
    setKey(key - 1);
  };

  const saveBrand = async () => {
    setProccessErrorTab(true);
    setProcess(true);
    try {
      if (
        duplicateNameError ||
        name.length <= 0 ||
        !specialCharactersName ||
        !active ||
        description.length <= 0 ||
        description.length > 50000 ||
        shortDescription.length > 400 ||
        shortDescription.length <= 0 ||
        metaTitle.length <= 0 ||
        metaDescription.length <= 0 ||
        metaTitle.length >= 70 ||
        metaDescription.length >= 160 ||
        !specialCharactersName ||
        !specialCharactersURL
      ) {
        throw new Error();
      }

      await apiBrands.edit(accesToken, {
        id,
        name,
        description,
        shortDescription,
        reWrite,
        metaTitle: metaTitle.substring(0, 71),
        metaDescription: metaDescription.substring(0, 300),
        active,
      });
      displayNotification({
        title: "Marca creada",
        message: `La marca ${name} se ha creado de forma correcta`,
        type: "success",
      });
    } catch (e) {
      if (e.response && e.response.status === 409) {
        setDuplicateNameError(true);
        setErrors(true);
        setProcess(false);
        throw new Error();
      } else {
        setProcess(false);
        setErrors(true);
        invalidFieldMessage();
        throw new Error();
      }
    }
  };
  const saveAndSyncBrand = async () => {
    setProccessErrorTab(true);
    try {
      await saveBrand();

      if (!active) {
        setNoSyncNoUnactive(true);
        throw new Error();
      }

      apiBrands.sync(accesToken, { brandId: id });
    } catch (e) {
      setProcess(false);

      displayNotification({
        title: "Ha ocurrido un error en la sincronización",
        message:
          "Vuelve a intentarlo y si el error persiste ponte en contacto con el departamento técnico",
        type: "danger",
      });

      throw new Error();
    }
  };

  const saveButtons = (
    <>
      <OptionsButton
        check={async () => {
          try {
            await saveBrand();

            if (!props.modal) {
              props.history.push(`/brand/${id}`);
            } else {
              props.handleFinishCreate({ id, name });
              props.handleClose();
            }
          } catch (e) {}
        }}
        click={async () => {
          try {
            await saveBrand();

            if (!props.modal) {
              props.history.push(`/brand/${id}`);
            } else {
              props.handleFinishCreate({ id, name });
              props.handleClose();
            }
          } catch (e) {}
        }}
        disabled={process}
        loading={process}
      >
        Guardar
      </OptionsButton>
      <OptionsButton
        checkSquare={async () => {
          try {
            await saveAndSyncBrand();

            if (!props.modal) {
              props.history.push(`/brand/${id}`);
            } else {
              props.handleFinishCreate({ id, name });
              props.handleClose();
            }
          } catch (e) {}
        }}
        click={async () => {
          try {
            await saveAndSyncBrand();

            if (!props.modal) {
              props.history.push(`/brand/${id}`);
            } else {
              props.handleFinishCreate({ id, name });
              props.handleClose();
            }
          } catch (e) {}
        }}
        disabled={process}
        loading={process}
      >
        Guardar y sincronizar
      </OptionsButton>
    </>
  );

  let content;

  const disabled = formProcessPhase === states.PROCESSING;

  let nameErrorMessage;

  if (name.length <= 0) {
    nameErrorMessage = "No puede estar el valor vacío";
  } else if (!specialCharactersName) {
    nameErrorMessage = "No está permitido usar caracteres especiales";
  } else if (duplicateNameError) {
    nameErrorMessage = "No puede haber 2 marcas con el mismo nombre";
  }

  let activeErrorMessage = "El campo es necesario";
  if (noSyncUnactive) {
    activeErrorMessage = "No se pueden sincronizar marcas no activas";
  }

  const tabsNavigationCreateBrand = async (pK) => {
    switch (Number(pK)) {
      case 0:
        setKey(Number(pK));
        break;
      case 1:
        try {
          await switchPhase(key);
          setKey(Number(pK));
        } catch (e) {
          invalidFieldMessage();
        }
        break;
      case 2:
        if (pK < key) {
          await switchPhase(key);
          setKey(Number(pK));
        } else if (
          duplicateNameError ||
          name.length <= 0 ||
          !specialCharactersName ||
          !active ||
          description.length <= 0 ||
          description.length > 50000 ||
          shortDescription.length <= 0
        ) {
          setErrors(true);
          setProcess(false);
          invalidFieldMessage();
        } else {
          await switchPhase(key);
          setKey(Number(pK));
        }

        break;

      default:
        break;
    }
  };

  const nameTitle = (
    <div className="d-flex align-items-center">
      {(syncError && !isSyncing) ||
      (duplicateNameError && proccessErrorTab) ||
      (name.length <= 0 && proccessErrorTab) ||
      (!specialCharactersName && proccessErrorTab) ? (
        <TabErrorIcon />
      ) : undefined}
      Nombre
      {isSyncing ? (
        <Badge className="ml-2 p-2" pill variant="primary">
          <Spinner animation="border" size="sm" />
        </Badge>
      ) : undefined}
    </div>
  );

  const nameBody = (
    <TabContainer>
      <TabMainRow>
        <Col md={12}>
          <DataTitle firstTitle>Nombre de la marca</DataTitle>
          <TextInput
            controlId="editBrand"
            placeholder={name}
            value={name}
            onChange={(event) => {
              setSpecialCharactersName(regExpURL2.test(event.target.value)); //devuelve false si coincide con alguno de los caracteres de la regex
              setName(event.target.value);
              setDuplicateNameError(false);
            }}
            error={
              (name.length <= 0 && errors) ||
              duplicateNameError ||
              !specialCharactersName
            }
            errorMessage={nameErrorMessage}
            disabled={process}
          />
        </Col>
      </TabMainRow>
    </TabContainer>
  );

  const dataTitle = (
    <div className="d-flex align-items-center">
      {proccessErrorTab &&
      (noSyncUnactive ||
        !active ||
        description.length <= 0 ||
        description.length > 50000 ||
        shortDescription.length <= 0 ||
        shortDescription.length > 400) ? (
        <TabErrorIcon />
      ) : undefined}
      Datos
    </div>
  );

  const dataBody = (
    <TabContainer>
      <TabMainRow>
        <Col md={6}>
          <CheckSphere
            check={active === true}
            x={active === false}
            onClick={() => {
              setNoSyncNoUnactive(false);
              if (active === undefined) {
                setActive(true);
              } else {
                setActive(!active);
              }
            }}
            error={(errors && !active) || noSyncUnactive}
            errorMessage="Es necesario que la marca esté activa para poder crearla"
            disabled={process}
          >
            Activo
          </CheckSphere>
          {/* ERROR MESSAGE IN ACTIVE BUTTON */}
          {/* {errors === true && !active ? (
          <span className={classes.errorMessage}>
            "Es necesario que la marca esté activa para poder crearla, por favor modifica el campo"
          </span>
        ) : null} */}
        </Col>
        <Col md={12}>
          <DataTitle className={classes.title}>Descripción:</DataTitle>
          <TextEditor
            value={description}
            onEditorChange={(content) => setDescription(content)}
            disabled={process}
            error={
              (description.length <= 0 || description.length > 50000) && errors
            }
            errorMessage="Necesita introducir una descripción de entre 1 y 50000 caracteres para guardar la marca"
          />
        </Col>
        <Col md={12}>
          <DataTitle className={classes.title}>Descripción corta:</DataTitle>
          <TextEditor
            // initialValue={shortDescription}
            // onKeyUp={(event) => {
            //   const value = event.target.textContent;

            //   if (value !== shortDescription) {
            //     setShortDescription(value);
            //   }
            // }}
            value={shortDescription}
            onEditorChange={(content) => setShortDescription(content)}
            disabled={process}
            error={shortDescription.length <= 0 && errors}
            errorMessage="Necesita introducir una descripción corta para guardar el producto"
          />
        </Col>
      </TabMainRow>
    </TabContainer>
  );

  const seoTitle = (
    <div className="d-flex align-items-center">
      {proccessErrorTab &&
      (reWrite.length <= 0 ||
        !specialCharactersURL ||
        metaTitle.length <= 0 ||
        metaDescription.length <= 0 ||
        metaTitle.length >= 70 ||
        metaDescription.length > 160) ? (
        <TabErrorIcon />
      ) : undefined}
      SEO
    </div>
  );

  const seoBody = (
    <SEOTab
      // Nuevos props
      reWrite={reWrite}
      setSpecialCharactersURL={setSpecialCharactersURL}
      regExpURL={regExpURL}
      setReWrite={setReWrite}
      setHasChanged={setHasChanged}
      specialCharactersURL={specialCharactersURL}
      specialCharactersName={specialCharactersName}
      name={name}
      // Fin de nuevos props
      metaTitle={metaTitle}
      setMetaTitle={(newValue) => {
        setMetaTitle(newValue);
        setHasChanged(true);
      }}
      metaDescription={metaDescription}
      setMetaDescription={(newValue) => {
        setMetaDescription(newValue);
        setHasChanged(true);
      }}
      error={errors}
    />
  );

  content = (
    <div className="position-relative">
      {key === 2 ? (
        <div className={classes.topSaveOptions}>
          {saveButtons}
          {noSyncUnactive ? (
            <div className="text-right">
              <InputErrorMessage>
                No se pueden sincronizar categorías no activas
              </InputErrorMessage>
            </div>
          ) : undefined}
        </div>
      ) : undefined}
      {/* Principio de la paginación */}
      {!props.modal ? (
        <MainTitle>
          {/* // TODO Margin top for not overlaping save buttons with TItle  */}
          <h1 className={classes.marginTop}>Crear nueva marca: {name}</h1>
        </MainTitle>
      ) : undefined}
      {syncError ? (
        <ResyncWhenErrorButton
          onClick={() => syncBrand(id)}
          isSyncing={isSyncing}
        />
      ) : undefined}
      <div className="hideOnMobile">
        <Tabs
          className={classes.tabs}
          id="brandPhases"
          activeKey={key}
          onSelect={(k) => {
            tabsNavigationCreateBrand(k);
          }}
        >
          {/* Datos iniciales de producto */}

          <Tab
            eventKey={0}
            //  TODO in tittle check if admiration error works
            title={nameTitle}
          >
            {nameBody}
          </Tab>

          <Tab
            eventKey={1}
            //  TODO in tittle check if admiration error works
            title={dataTitle}
            disabled={!id}
          >
            {dataBody}
          </Tab>

          {/* Datos de SEO */}

          <Tab
            eventKey={2}
            //  TODO in tittle check if admiration error works
            title={seoTitle}
            disabled={!id}
          >
            {seoBody}
          </Tab>
        </Tabs>
      </div>
      <div className={classes.hideOnPC}>
        <Accordion
          defaultActiveKey="0"
          activeKey={String(key)}
          onSelect={(k) => {
            tabsNavigationCreateBrand(k);
          }}
        >
          <Card id="accordion-card-0">
            <Accordion.Toggle as={Card.Header} eventKey="0">
              {nameTitle}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"0"}>
              <Card.Body>{nameBody}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card id="accordion-card-1">
            <Accordion.Toggle as={Card.Header} eventKey="1">
              {dataTitle}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"1"}>
              <Card.Body>{dataBody}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card id="accordion-card-2">
            <Accordion.Toggle as={Card.Header} eventKey="2">
              {seoTitle}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"2"}>
              <Card.Body>{seoBody}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>

      {noSyncUnactive ? (
        <div className="text-right">
          <InputErrorMessage>
            No se pueden sincronizar categorías no activas
          </InputErrorMessage>
        </div>
      ) : undefined}
      <div className={classes.actionButtons}>
        {key !== 0 ? (
          <div className={classes.reverseButton}>
            <OptionsButton
              variant="success"
              arrowLeft={prevPhase}
              click={prevPhase}
              disabled={process}
              loading={process}
            >
              Fase previa
            </OptionsButton>
          </div>
        ) : undefined}
        {key !== 2 ? (
          <OptionsButton
            variant="success"
            arrowRight={nextPhase}
            click={async () => {
              await nextPhase();
            }}
            disabled={process}
            loading={process}
          >
            Siguiente fase
          </OptionsButton>
        ) : (
          saveButtons
        )}
      </div>
    </div>
  );

  if (props.modal) {
    return (
      <>
        <Modal
          className="bigModal"
          show={true}
          onHide={() => props.handleClose()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Crear marca</Modal.Title>
          </Modal.Header>
          <Modal.Body>{content}</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    );
  }

  return <>{content}</>;
};

export default CreateBrand;
