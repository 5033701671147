import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import auth from "../../api/auth";
import { Redirect } from "react-router-dom";
import {
  List,
  X,
  Search,
  PlusSquare,
  ArrowClockwise,
  BoxArrowLeft,
  PersonLinesFill,
  ChevronRight,
  BadgeAdFill,
} from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import displayNotification from "../../utilities/displayNotification";
import classes from "./VerticalNavBar.module.css";
// API
import apiArticles from "../../api/articles";
// UTILITIES
import handleApiErrors from "../../utilities/handleApiErrors";
import OrphanNavBar from "../OrphanNavBar/OrphanNavBar";

import ReactTooltip from "react-tooltip";
import swal from "sweetalert";
//STATES
const states = {
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};

const VerticalNavBar = (props) => {
  const accesToken = useSelector((state) => state.user.access);
  const refreshToken = useSelector((state) => state.user.refresh);
  // ORPHANS
  const [orphans, setOrphans] = useState();
  // PROCESS STATE
  const [process, setProcess] = useState(states.LOADING);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  // STATE FOR DROPDOWN MENU
  const [expandable, setExpandable] = useState(false);
  const [createToggle, setCreateToggle] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const [syncToggle, setSyncToggle] = useState(false);
  const [orphanToggle, setOrphanToggle] = useState(false);
  const [logoutToggle, setLogoutToggle] = useState(false);
  // STATE FOR HANDLE MENU FUNCTIONS AND STYLES
  const [openKey, setOpenKey] = useState("");
  const [hoverKey, setHoverKey] = useState("");
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [createDropdown, setCreateDropdown] = useState(false);
  const [adDropdown, setAdDropdown] = useState(false);
  // ARRAYS AND STYLES FOR HANDLE STYLES OF THE ICON BAR (LENGTH)
  const searchingArray = [
    "Todo",
    "Productos",
    "Categorías",
    "Atributos",
    "Artículos",
    "Características",
    "Marcas",
    "Literals",
  ];
  const numberSearching = searchingArray.length * 55;
  const searchStyle = {
    marginBottom: `${numberSearching}px`,
    transition: "all 0.5s ease-in-out",
  };
  const searchStyleClose = {
    marginBottom: `0px`,
    transition: "all 0.5s ease-in-out",
  };

  const creatingArray = ["Crear Producto", "Crear Categoría", "Crear Marca"];
  const numberCreating = creatingArray.length * 55;
  const createStyle = {
    marginBottom: `${numberCreating}px`,
    transition: "all 0.5s ease-in-out",
  };
  const createStyleClose = {
    marginBottom: `0px`,
    transition: "all 0.5s ease-in-out",
  };

  // ACCESS
  const dispatch = useDispatch();
  const setAccess = useCallback((access) =>
    dispatch({ type: "SET_ACCESS_TOKEN", access }, [dispatch])
  );
  const setRefresh = useCallback((access) =>
    dispatch({ type: "SET_REFRESH_TOKEN", access }, [dispatch])
  );

  // BRING DATA FROM API
  useEffect(() => {
    if (expandable) {
      apiArticles
        .getOrphansLength(accesToken)
        .then(({ data }) => {
          setOrphans(data.length);

          setProcess(states.LOADED);
        })
        .catch((e) => {
          handleApiErrors(e);
        });
    }
  }, []);

  const onClickLogOut = async () => {
    const exitMessage = await swal({
      title: "¿Estás seguro que quieres cerrar la sesión?",
      icon: "warning",
      buttons: {
        cancel: "Cancelar",
        exit: { text: "Cerrar sesión", value: true, closeModal: false },
      },
      className: "customSweat",
    });
    if (exitMessage) {
      await auth.refreshToken(refreshToken);
      setAccess(undefined);
      setRefresh(undefined);

      displayNotification({
        title: "Ha cerrado sesión de forma adecuada",
        message: `Hasta pronto!`,
        type: "success",
      });
      swal.close();
      props.history.push("/logIn");
    }
  };

  const closeNavBar = () => {
    setIsOptionsOpen(false);
    setSearchDropdown(false);
    setCreateDropdown(false);
    setAdDropdown(false);
    setExpandable(false);
    setOpenKey("");
    setHoverKey("");
  };

  if (!accesToken) {
    return <Redirect to="/logIn" />;
  }

  return (
    <>
      {!expandable ? (
        <ReactTooltip delayShow={1000} place="top" type="info" />
      ) : null}

      {/* CODE FOR THE TOP NAVBAR ALWAYS VISIBLE */}
      <Container fluid className={classes.topNavbar}>
        <div className={classes.logoAndOptions}>
          <Link to="/search">
            <img src={Logo} alt="UP" />
          </Link>
          <button
            onClick={() => {
              setIsOptionsOpen(!isOptionsOpen);
            }}
          >
            {isOptionsOpen ? (
              <X
                onClick={() => {
                  setExpandable(false);
                }}
              />
            ) : (
              <List
                onClick={() => {
                  setExpandable(true);
                }}
              />
            )}
          </button>
        </div>
      </Container>
      {/* LATERAL MENU DROPDOWN */}
      <Container fluid>
        <Row>
          <Col
            md="auto"
            sm={12}
            className={`${!expandable ? classes.positionLeft : ""} ${
              classes.containerZeroPadding
            }`}
          >
            <div
              className={`${isOptionsOpen ? classes.open : ""} ${
                classes.lateralNavbarContainer
              }`}
            >
              {/* //* ICONS COLUMN */}
              <div className={classes.iconsLateralNavbar}>
                {/* SEARCH */}
                <div
                  // style for handle the position of the icons when dropdown menus happen
                  style={searchDropdown ? searchStyle : searchStyleClose}
                  className={classes.iconContainer}
                  onClick={() => {
                    if (expandable) {
                      closeNavBar();
                    } else {
                      setExpandable(true);
                      setSearchDropdown(true);
                      setOpenKey(0);
                    }
                    // if (
                    //   (expandable && !searchDropdown) ||
                    //   (expandable && !createDropdown)
                    // ) {
                    //   closeNavBar();
                    // } else {
                    //   setExpandable(!expandable);
                    //   setOpenKey(0);
                    //   if (openKey > 1) {
                    //     setSearchDropdown(false);
                    //   } else {
                    //     setSearchDropdown(!searchDropdown);
                    //     setCreateDropdown(false);
                    //   }
                    // }
                  }}
                  data-tip="Buscar"
                >
                  <Search
                    className={`${
                      openKey === 0 && expandable ? classes.green : ""
                    } ${hoverKey === 0 ? classes.green : ""}`}
                  ></Search>
                </div>
                {/* CREATE */}
                <div
                  // style for handle the position of the icons when dropdown menus happen
                  style={createDropdown ? createStyle : createStyleClose}
                  className={classes.iconContainer}
                  onClick={() => {
                    if (expandable) {
                      closeNavBar();
                    } else {
                      setExpandable(true);
                      setCreateDropdown(true);
                      setOpenKey(1);
                    }

                    // if (
                    //   (expandable && !searchDropdown) ||
                    //   (expandable && !createDropdown)
                    // ) {
                    //   closeNavBar();
                    // } else {
                    //   setExpandable(!expandable);
                    //   setOpenKey(1);
                    //   if (openKey > 1) {
                    //     setCreateDropdown(false);
                    //   } else {
                    //     setCreateDropdown(!createDropdown);
                    //     setSearchDropdown(false);
                    //   }
                    // }
                  }}
                  data-tip="Crear"
                >
                  <PlusSquare
                    className={`${
                      openKey === 1 && expandable ? classes.green : ""
                    } ${hoverKey === 1 ? classes.green : ""}`}
                  ></PlusSquare>
                </div>
                {/* SYNC */}
                <div
                  className={classes.iconContainer}
                  onClick={() => {
                    if (expandable) {
                      closeNavBar();
                    } else {
                      setExpandable(true);
                      setOpenKey(2);
                    }
                  }}
                  data-tip="Sincronización"
                >
                  <ArrowClockwise
                    className={`${
                      openKey === 2 && expandable ? classes.green : ""
                    } ${hoverKey === 2 ? classes.green : ""}`}
                  ></ArrowClockwise>
                </div>
                {/* ORPHANS */}
                <div
                  className={classes.iconContainer}
                  onClick={() => {
                    if (expandable) {
                      closeNavBar();
                    } else {
                      setExpandable(true);
                      setOpenKey(3);
                    }
                  }}
                  data-tip="Huérfanos"
                >
                  <PersonLinesFill
                    className={`${
                      openKey === 3 && expandable ? classes.green : ""
                    } ${hoverKey === 3 ? classes.green : ""}`}
                  ></PersonLinesFill>
                </div>
                {/* SEM */}
                <div
                  // style for handle the position of the icons when dropdown menus happen
                  style={adDropdown ? createStyle : createStyleClose}
                  className={classes.iconContainer}
                  onClick={() => {
                    if (expandable) {
                      closeNavBar();
                    } else {
                      setExpandable(true);
                      setAdDropdown(true);
                      setOpenKey(4);
                    }
                  }}
                  data-tip="SEM"
                >
                  <BadgeAdFill
                    className={`${
                      openKey === 4 && expandable ? classes.green : ""
                    } ${hoverKey === 4 ? classes.green : ""}`}
                  ></BadgeAdFill>
                </div>

                {/* LOGOUT */}
                <div
                  className={classes.iconContainer}
                  onClick={() => {
                    if (expandable) {
                      closeNavBar();
                    } else {
                      setExpandable(true);
                      setOpenKey(5);
                    }
                  }}
                  data-tip="Cerrar sesión"
                >
                  <BoxArrowLeft
                    className={`${
                      openKey === 5 && expandable ? classes.green : ""
                    } ${hoverKey === 5 ? classes.green : ""}`}
                  ></BoxArrowLeft>
                </div>
              </div>
              {/* //* LINKS COLUMN */}
              <div
                className={`${classes.linksLateralNavbar} ${
                  expandable
                    ? classes.transitionLeftIn
                    : classes.transitionLeftOut
                }`}
              >
                {/* SEARCH div que contiene el link y la flecha */}
                <div
                  eventKey="0"
                  onClick={() => {
                    setOpenKey(0);
                    setSearchDropdown(!searchDropdown);
                    setCreateDropdown(false);
                    setAdDropdown(false);
                  }}
                  className={`${classes.link} ${
                    openKey === 0 && expandable
                      ? classes.linkTitleActive
                      : classes.linkTitle
                  }`}
                  // event for green icon when hovering the link
                  onMouseEnter={() => {
                    setHoverKey(0);
                  }}
                  onMouseLeave={() => {
                    setHoverKey("");
                  }}
                >
                  <p>Buscar</p>
                  <ChevronRight
                    className={`${
                      searchDropdown
                        ? classes.transitionArrowOpen
                        : classes.transitionArrowClose
                    }`}
                  />
                </div>

                {/*SEARCH div que se muestra  o se esconde al hacer click */}
                <div
                  className={`${classes.linkDropdown} ${
                    openKey === 0 && searchDropdown
                      ? classes.transitionDropdownOpenSearch
                      : classes.transitionDropdownCloseSearch
                  }`}
                >
                  <Link
                    // className={`${classes.linkDropdown}`}
                    onClick={() => {
                      setExpandable(!expandable);
                      closeNavBar();
                    }}
                    to="/search"
                  >
                    Todo
                  </Link>
                  <Link
                    onClick={() => {
                      setExpandable(!expandable);
                      closeNavBar();
                    }}
                    to="/productsList"
                  >
                    Productos
                  </Link>
                  <Link
                    onClick={() => {
                      setExpandable(!expandable);
                      closeNavBar();
                    }}
                    to="/categoriesList"
                  >
                    Categorías
                  </Link>
                  <Link
                    onClick={() => {
                      setExpandable(!expandable);
                      closeNavBar();
                    }}
                    to="/attributesList"
                  >
                    Atributos
                  </Link>
                  <Link
                    onClick={() => {
                      setExpandable(!expandable);
                      closeNavBar();
                    }}
                    to="/articlesList"
                  >
                    Artículos
                  </Link>
                  <Link
                    onClick={() => {
                      setExpandable(!expandable);
                      closeNavBar();
                    }}
                    to="/featuresList"
                  >
                    Características
                  </Link>
                  <Link
                    onClick={() => {
                      setExpandable(!expandable);
                      closeNavBar();
                    }}
                    to="/brandsList"
                  >
                    Marcas
                  </Link>
                  <Link
                    onClick={() => {
                      setExpandable(!expandable);
                      closeNavBar();
                    }}
                    to="/literalsList"
                  >
                    Literals
                  </Link>
                </div>

                {/* CREATE div que contiene el link y la flecha */}
                <div
                  eventKey="1"
                  onClick={() => {
                    setOpenKey(1);
                    setSearchDropdown(false);
                    setAdDropdown(false);
                    setCreateDropdown(!createDropdown);
                  }}
                  className={`${classes.link} ${
                    openKey === 1 && expandable
                      ? classes.linkTitleActive
                      : classes.linkTitle
                  }`}
                  // event for green icon when hovering the link
                  onMouseEnter={() => {
                    setHoverKey(1);
                  }}
                  onMouseLeave={() => {
                    setHoverKey("");
                  }}
                >
                  <p>Crear</p>
                  <ChevronRight
                    className={`${
                      createDropdown
                        ? classes.transitionArrowOpen
                        : classes.transitionArrowClose
                    }`}
                  />
                </div>
                {/* CREATE div que se muestra  o se esconde al hacer click */}
                <div
                  className={`${classes.linkDropdown} ${
                    openKey === 1 && createDropdown
                      ? classes.transitionDropdownOpenCreate
                      : classes.transitionDropdownCloseCreate
                  }`}
                >
                  <Link
                    // className={`${classes.linkDropdown}`}
                    onClick={() => {
                      closeNavBar();
                    }}
                    to="/create/product"
                  >
                    Crear Producto
                  </Link>
                  <Link
                    onClick={() => {
                      closeNavBar();
                    }}
                    to="/create/category"
                  >
                    Crear Categoría
                  </Link>
                  <Link
                    onClick={() => {
                      closeNavBar();
                    }}
                    to="/create/brand"
                  >
                    Crear Marca
                  </Link>
                </div>
                {/* //*SYNCHRONIZATION LIST */}
                <div
                  eventKey="2"
                  onClick={() => {
                    props.history.push("/syncList");
                    closeNavBar();
                  }}
                  className={`${classes.link} ${
                    openKey === 2 && expandable
                      ? classes.linkSimpleActive
                      : classes.linkTitle //! de momento se queda asi para no hacer otra clase mas
                  }`}
                  // event for green icon when hovering the link
                  onMouseEnter={() => {
                    setHoverKey(2);
                  }}
                  onMouseLeave={() => {
                    setHoverKey("");
                  }}
                >
                  <p>Sincronización</p>
                  <ChevronRight />
                </div>
                {/* //* ORPHANS LIST */}
                <div
                  eventKey="3"
                  onClick={() => {
                    props.history.push("/orphansList");
                    closeNavBar();
                  }}
                  className={`${classes.link} ${
                    openKey === 3 && expandable
                      ? classes.linkSimpleActive
                      : classes.linkTitle //! de momento se queda asi para no hacer otra clase mas
                  }`}
                  // event for green icon when hovering the link
                  onMouseEnter={() => {
                    setHoverKey(3);
                  }}
                  onMouseLeave={() => {
                    setHoverKey("");
                  }}
                >
                  <p>Huérfanos</p>
                  <OrphanNavBar />
                  <ChevronRight />
                </div>

                {/* SEM que se abre */}
                <div
                  eventKey="4"
                  onClick={() => {
                    setOpenKey(4);
                    setSearchDropdown(false);
                    setCreateDropdown(false);
                    setAdDropdown(!adDropdown);
                  }}
                  className={`${classes.link} ${
                    openKey === 4 && expandable
                      ? classes.linkTitleActive
                      : classes.linkTitle
                  }`}
                  // event for green icon when hovering the link
                  onMouseEnter={() => {
                    setHoverKey(4);
                  }}
                  onMouseLeave={() => {
                    setHoverKey("");
                  }}
                >
                  <p>Anuncios</p>
                  <ChevronRight
                    className={`${
                      adDropdown
                        ? classes.transitionArrowOpen
                        : classes.transitionArrowClose
                    }`}
                  />
                </div>
                {/* SEM div que se muestra  o se esconde al hacer click */}
                <div
                  className={`${classes.linkDropdown} ${
                    openKey === 4 && adDropdown
                      ? classes.transitionDropdownOpenCreate
                      : classes.transitionDropdownCloseCreate
                  }`}
                >
                  <Link
                    // className={`${classes.linkDropdown}`}
                    onClick={() => {
                      closeNavBar();
                    }}
                    to="/createCampaign"
                  >
                    Crear Campaña
                  </Link>
                  <Link
                    onClick={() => {
                      closeNavBar();
                    }}
                    to="/campaignTable"
                  >
                    Codigo Xavier
                  </Link>
                  <Link
                    onClick={() => {
                      closeNavBar();
                    }}
                    to="/search"
                  >
                    Tercera opción
                  </Link>
                </div>
                {/* //* SEM */}
                {/* <div
                  eventKey="4"
                  onClick={() => {
                    props.history.push("/sem");
                    closeNavBar();
                  }}
                  className={`${classes.link} ${
                    openKey === 4 && expandable
                      ? classes.linkSimpleActive
                      : classes.linkTitle //! de momento se queda asi para no hacer otra clase mas
                  }`}
                  // event for green icon when hovering the link
                  onMouseEnter={() => {
                    setHoverKey(4);
                  }}
                  onMouseLeave={() => {
                    setHoverKey("");
                  }}
                >
                  <p>Anuncios</p>
                  <ChevronRight />
                </div> */}
                {/* //* LOGOUT */}
                <div
                  eventKey="5"
                  onClick={onClickLogOut}
                  className={`${classes.link} ${
                    openKey === 5 && expandable
                      ? classes.linkSimpleActive
                      : classes.linkTitle //! de momento se queda asi para no hacer otra clase mas
                  }`}
                  // event for green icon when hovering the link
                  onMouseEnter={() => {
                    setHoverKey(5);
                  }}
                  onMouseLeave={() => {
                    setHoverKey("");
                  }}
                >
                  <p>Cerrar sesión</p>
                  <ChevronRight />
                </div>
              </div>
              {/* //* PUTTING THE X ICON TO CLOSE THE DROPDOWN MENU OUTSIDE NAVBAR */}
              <div
                className={`${classes.iconxContainer} ${
                  expandable ? classes.displayXIn : classes.displayXOut
                }`}
                onClick={() => {
                  setExpandable(false);
                  closeNavBar();
                }}
              >
                <X data-tip="Cerrar menú" />
              </div>
            </div>
          </Col>
          <Col className={classes.scrollGeneral}>{props.children}</Col>
        </Row>
      </Container>
    </>
  );
};

export default VerticalNavBar;
