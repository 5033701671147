import React from "react";
import { Button } from "react-bootstrap";
import PseudoTable from "../PseudoTable/PseudoTable";
import classes from "./ProblemsTable.module.css";

const ProblemsTable = (props) => {
  return (
    <PseudoTable.Table>
      <PseudoTable.Header className={`${classes.header} hideOnMobile`}>
        <div>
          <strong>Id</strong>
        </div>
        <div>
          <strong>Características</strong>
        </div>
        <div>
          <strong>Producto</strong>
        </div>
        <div>
          <strong>Opciones</strong>
        </div>
      </PseudoTable.Header>
      {props.items.map((problem, key) => {
        // console.log("PROBLEM", problem);
        return (
          <PseudoTable.Row key={key} className={classes.row}>
            <div>
              {problem.feature.id} - {problem.product.id}
            </div>
            <div>{problem.feature.name}</div>
            <div>{problem.product.name}</div>
            <div>
              <Button
                disabled={props.disabled}
                onClick={() => props.onClickAddValueAndSolve(problem)}
                disabled={props.disabled}
              >
                Añadir valor y solucionar
              </Button>
            </div>
          </PseudoTable.Row>
        );
      })}
    </PseudoTable.Table>
  );
};

export default ProblemsTable;
