import React, { useState } from "react";

import { Modal, Button, Form } from "react-bootstrap";
import { ArrowLeft } from "react-bootstrap-icons";
import ReactCrop from "react-image-crop";

import useCropImage from "./hooks/use-cropImage";

import classes from "./SelectImageRatioModal.module.scss";

const SelectImageRatioModal: React.FC<{
  handleClose: () => void;
  src: string;
  crop: any;
  setCrop: any;
  handleClickSave: any;
}> = ({ handleClose, src, crop, setCrop, handleClickSave }) => {
  const { handleSubmitForm, userDidNotCropImage } = useCropImage(
    crop,
    handleClickSave
  );

  return (
    <>
      <Modal.Body>
        <button onClick={handleClose} className={classes.goBack}>
          <ArrowLeft />
        </button>
        <div className="d-flex justify-content-center">
          <ReactCrop
            src={src}
            crop={crop}
            onChange={(newCrop: any) => setCrop(newCrop)}
          />
        </div>
        {userDidNotCropImage ? (
          <div className={classes.errorMessage}>
            <Form.Control.Feedback type="invalid">
              Debe especificar un recorte en la imagen
            </Form.Control.Feedback>
          </div>
        ) : undefined}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Volver a la edición
        </Button>
        <Button variant="primary" onClick={handleSubmitForm}>
          Guardar recorte
        </Button>
      </Modal.Footer>
    </>
  );
};

export default SelectImageRatioModal;
