import { useState } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";

import { useDropzone } from "react-dropzone";

import apiCertificates from "../../../../../../../api/certificates";
import apiProducts from "../../../../../../../api/products";

import { PROCESSING } from "../../../../../../../utilities/processStates";

interface HasBeenTouched {
  name: boolean;
  description: boolean;
}

function renameFile(originalFile: File, newName: string) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const force20Decimals = (value: number) => {
  return value.toFixed(20);
};

const useCreateCertificate: (
  initialId: number | any,
  initialName: string | any,
  initalDescription: string | any,
  initialImage: any,
  initialPdf: any,
  handleClose: () => void,
  productId?: number,
  handleFinishCreate?: (cert: any) => void,
  handleOpenCropImage?: () => void,
  handleCloseCropImage?: () => void
) => any = (
  initialId,
  initialName = "",
  initalDescription = "",
  initialImage,
  initialPdf,
  handleClose,
  productId,
  handleFinishCreate,
  handleOpenCropImage,
  handleCloseCropImage
) => {
  const [id] = useState<any>(initialId);
  const [name, setName] = useState<string>(initialName);
  const [description, setDescription] = useState<string>(initalDescription);
  const [image, setImage] = useState<any>(initialImage);
  const [pdf, setPdf] = useState<any>(initialPdf);
  const [showSelectPhotoRatio, setShowSelectPhotoRatio] =
    useState<boolean>(false);
  const [allowCropUploadedImage, setAllowCropUploadedImage] =
    useState<boolean>(false);
  const [realImageCrop, setRealImageCrop] = useState<any>();
  const [duplicateNameError, setDuplicateNameError] = useState(false);

  const [crop, setCrop] = useState<any>({ aspect: 1 / 1 });

  const [hasBeenTouched, setHasBeenTouched] = useState<HasBeenTouched>({
    name: false,
    description: false,
  });
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);

  const [processSaveCertificate, setProcessSaveCertificate] =
    useState<string>();

  const accesToken = useSelector((state: any) => state.user.access);

  const handleDropImage = (files: File[]) => {
    setImage(() => {
      const file = files[0];

      return {
        name: file.name,
        size: file.size,
        fullFile: file,
        src: URL.createObjectURL(file),
      };
    });

    setAllowCropUploadedImage(true);
    setShowSelectPhotoRatio(true);

    if (handleOpenCropImage) {
      handleOpenCropImage();
    }
  };

  const handleDropPdf = (files: File[]) => {
    setPdf(() => {
      const file = files[0];

      return {
        name: file.name,
        size: file.size,
        fullFile: file,
      };
    });
  };

  const handleRemoveImage = async () => {
    setImage(undefined);

    if (id) {
      try {
        await apiCertificates.removeImage(accesToken, {
          filename: `${name}.jpg`,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleRemovePdf = async () => {
    setPdf(undefined);

    if (id) {
      try {
        await apiCertificates.removePdf(accesToken, {
          filename: `${name}.pdf`,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const uploadImg = async () => {
    const uploadImageData = new FormData();
    uploadImageData.append("image", renameFile(image.fullFile, `${name}.jpg`));
    uploadImageData.append("x", force20Decimals(realImageCrop.x));
    uploadImageData.append("y", force20Decimals(realImageCrop.y));
    uploadImageData.append("width", force20Decimals(realImageCrop.width));
    uploadImageData.append("height", force20Decimals(realImageCrop.height));
    uploadImageData.append("aspect", force20Decimals(crop.aspect));
    uploadImageData.append("unit", crop.unit);

    await apiCertificates.uploadImage(accesToken, {
      data: uploadImageData,
    });
  };

  const uploadPdf = async () => {
    const uploadPdfData = new FormData();
    uploadPdfData.append("pdf", renameFile(pdf.fullFile, `${name}.pdf`));
    await apiCertificates.uploadPdf(accesToken, { data: uploadPdfData });
  };

  const uploadFiles = async () => {
    await uploadImg();
    await uploadPdf();
  };

  const imageDropZone = useDropzone({ onDrop: handleDropImage });
  const pdfDropzone = useDropzone({ onDrop: handleDropPdf });

  const handleSaveErrors = (error: any) => {
    console.log(error);

    setProcessSaveCertificate(undefined);

    if (error.response.status === 409) {
      setDuplicateNameError(true);
    }

    return false;
  };

  const handleSaveCertificate = async () => {
    setHasBeenSubmitted(true);

    if (!isValid) {
      return false;
    }

    setProcessSaveCertificate(PROCESSING);

    if (id) {
      try {
        await apiCertificates.edit(accesToken, {
          id,
          name,
          description,
        });

        if (image.fullFile !== undefined) {
          await uploadImg();
        }

        if (pdf && pdf.fullFile !== undefined) {
          await uploadPdf();
        }
      } catch (error) {
        return handleSaveErrors(error);
      }
    } else {
      try {
        const response = await apiCertificates.createCertificate(accesToken, {
          name,
          description,
        });

        const createdCertificateId = response.data.id;

        await apiProducts.addCertificate(accesToken, {
          productId,
          certificateIds: [createdCertificateId],
        });

        await uploadFiles();

        if (handleFinishCreate) {
          handleFinishCreate({
            id: createdCertificateId,
            name,
            description,
          });
        }
      } catch (error: any) {
        return handleSaveErrors(error);
      }
    }

    setProcessSaveCertificate(undefined);
    return true;
  };

  const handleTypeName = (val: string) => {
    setDuplicateNameError(false);
    setName(val);
  };

  const handleClickPdf = async () => {
    try {
      if (!pdf.fullFile) {
        const response = await apiCertificates.openPdf(accesToken, {
          filename: initialName + ".pdf",
        });

        const file = new Blob([response.data], { type: "application/pdf" });

        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } else {
        const fileURL = URL.createObjectURL(pdf.fullFile);
        window.open(fileURL);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isSavingCertificate = processSaveCertificate === PROCESSING;

  const validation = {
    name: true,
    description: true,
    image: true,
    pdf: true,
  };

  const errorMessage = {
    name: "",
    description: "",
    image: "",
    pdf: "",
  };

  if (name.length === 0) {
    validation.name = false;
    errorMessage.name = "Debe especificar un nombre";
  } else if (name.length > 70) {
    validation.name = false;
    errorMessage.name = "El nombre no puede tener mas de 70 caracteres";
  } else if (duplicateNameError) {
    validation.name = false;
    errorMessage.name =
      "No puede haber 2 certificados con el mismo nombre (recuerde que el sistema no diferencia mayusculas de minisculas)";
  }

  if (!description || description.length === 0) {
    validation.description = false;
    errorMessage.description = "Debe especificar una descripción";
  } else if (description.length > 400) {
    validation.description = false;
    errorMessage.description =
      "La descripción no puede tener mas de 400 caracteres";
  }

  if (image === undefined) {
    validation.image = false;
    errorMessage.image = "Debe subir una imagen";
  }

  // if (pdf === undefined) {
  //   validation.pdf = false;
  //   errorMessage.pdf = "Debe subir un pdf";
  // }

  if (
    allowCropUploadedImage &&
    (crop.x === undefined ||
      crop.y === undefined ||
      crop.width === undefined ||
      crop.height === undefined)
  ) {
    validation.image = false;
    errorMessage.image = "Debe especificar un ratio para la imagen";
  }

  let isValid = true;

  Object.keys(validation).forEach((_, i) => {
    const d = Object.values(validation)[i];
    if (!d) {
      isValid = false;
      return;
    }
  });

  const handleTouchInput = (prop: any) => {
    setHasBeenTouched((prevState: any) => {
      prevState[prop] = true;

      return { ...prevState };
    });
  };

  const handleOpenSelectPhotoRatio = () => {
    setShowSelectPhotoRatio(true);
  };

  const handleCloseSelectPhotoRatio = () => {
    if (handleCloseCropImage) {
      handleCloseCropImage();
    }

    setShowSelectPhotoRatio(false);
    setImage(undefined);
    setCrop({ aspect: 1 / 1 });
  };

  const handleCropImage = async () => {
    const htmlImage: any = document.querySelector(".ReactCrop__image");

    const x = (crop.x * htmlImage.naturalWidth) / htmlImage.width;
    const y = (crop.y * htmlImage.naturalHeight) / htmlImage.height;
    const width = (crop.width * htmlImage.naturalWidth) / htmlImage.width;
    const height = (crop.height * htmlImage.naturalHeight) / htmlImage.height;

    setRealImageCrop({
      x,
      y,
      width,
      height,
    });

    setShowSelectPhotoRatio(false);

    if (handleCloseCropImage) {
      handleCloseCropImage();
    }
  };

  const showCropImageButton = !!allowCropUploadedImage;

  const handleClickCloseModal = async () => {
    if (isValid) {
      handleClose();
    } else {
      const response = await swal({
        title: "Esta saliendo de un certificado incompleto",
        text: "Si sale de este certificado sin solucionar los problemas que tiene se pueden generar conflictos y errores. Procure solucionarlos antes de cerrarlo.",
        icon: "warning",
        buttons: {
          close: { text: "Cerrar igualmente", value: true },
          solve: { text: "Solucionar problemas", value: false },
        },
      });

      if (response) {
        handleClose();
      }
    }
  };

  return {
    name,
    setName: handleTypeName,
    description,
    setDescription,
    imageDropZone,
    pdfDropzone,
    image,
    pdf,
    handleRemoveImage,
    handleClickPdf,
    handleRemovePdf,
    handleSaveCertificate,
    isSavingCertificate,
    validation,
    errorMessage,
    isValid,
    hasBeenTouched,
    handleTouchInput,
    hasBeenSubmitted,
    showSelectPhotoRatio,
    handleOpenSelectPhotoRatio,
    handleCloseSelectPhotoRatio,
    crop,
    setCrop,
    handleCropImage,
    showCropImageButton,
    handleClickCloseModal,
  };
};

export default useCreateCertificate;
