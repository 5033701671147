import React from "react";
import { ListGroup, Badge } from "react-bootstrap";
import Spinner from "../Spinner/Spinner";
import classes from "./ItemsList.module.css";

const ItemsList = (props) => {
  return (
    <ListGroup className={`${classes.listGroup} ${classes[props.hide]}`}>
      <ListGroup.Item
        onClick={props.clickFoldUnfold}
        className={`d-flex justify-content-between align-items-center ${classes.firstItem}`}
      >
        {props.title}
        <Badge pill variant="primary">
          {props.topCount}
        </Badge>
      </ListGroup.Item>
      {props.show ? (
        <div className={classes.itemsContainer}>
          {props.items.map((item) => (
            <ListGroup.Item
              onClick={() => props.clickItem(item.id)}
              key={item.id}
              className={`d-flex justify-content-between align-items-center ${classes.item}`}
            >
              {item.name}
              <span className={`${classes.edit} text-info`}>Edit</span>
            </ListGroup.Item>
          ))}
          {props.showEndSpinner ? <Spinner /> : undefined}
        </div>
      ) : undefined}
    </ListGroup>
  );
};

export default ItemsList;
