import customAxios from "../customAxios";

const getSyncErrors = (
  access,
  { filtername, syncFail, needSync, synchronizing, noId, page }
) => {
  let pageToShow = page;

  if (page === undefined) {
    pageToShow = 1;
  }

  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/sync/error-sync/?page=${pageToShow}`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      filtername,
      sync_fail: syncFail,
      need_sync: needSync,
      synchronizing,
      no_id: noId,
    }),
  });
};

export default { getSyncErrors };
