import React from "react";
import classes from "./CardItem.module.css";

const CardItem = (props) => (
  <div onClick={props.onClick} className={classes.cardItem}>
    {!props.src ? (
      <div className={classes.photoPlaceholder}></div>
    ) : (
      <img src={props.src} alt="" />
    )}
    <h4>{props.title}</h4>
    {props.children}
  </div>
);

export default CardItem;
