import apiProducts from "../api/products";
import displayNotification from "./displayNotification";

const syncPricesPromise = async (accesToken, { productId }) => {
  try {
    apiProducts.syncPrices(accesToken, { productId });
  } catch (error) {
    displayNotification({
      title: "Ha habido un error al sincronizar los precios",
      message:
        "Intenelo de nuevo mas tarde y conacte con asistencia tecnica si el problema persiste",
      type: "error",
    });
  }
};

export default syncPricesPromise;
