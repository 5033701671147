import React, { Fragment } from "react";
import {
  Files,
  ArrowUpShort,
  ArrowDownShort,
  ChevronExpand,
  PencilSquare,
  ArrowLeftRight,
  X,
  CloudArrowUpFill,
  TrashFill,
  Plus,
  ArrowLeft,
  ArrowRight,
  ArrowRepeat,
  Check2Circle,
  Check2Square,
  Fullscreen,
  FullscreenExit,
} from "react-bootstrap-icons";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import ButtonSpinner from "../ButtonSpinner/ButtonSpinner";
import classes from "./OptionsButton.module.css";

const OptionsButton = (props) => {
  let optionsContent;

  if (props.loading) {
    optionsContent = <ButtonSpinner />;
  } else {
    optionsContent = (
      <Fragment>
        {props.files ? (
          <button onClick={props.files} disabled={props.disabled}>
            <Files className={classes.smallIcon} />
          </button>
        ) : undefined}
        {props.down ? (
          <button onClick={props.down} disabled={props.disabled}>
            <ArrowDownShort />
          </button>
        ) : undefined}
        {props.arrowLeftRight ? (
          <button onClick={props.arrowLeftRight} disabled={props.disabled}>
            <ArrowLeftRight />
          </button>
        ) : undefined}
        {props.plus ? (
          <button onClick={props.plus} disabled={props.disabled}>
            <Plus />
          </button>
        ) : undefined}
        {props.up ? (
          <button onClick={props.up} disabled={props.disabled}>
            <ArrowUpShort />
          </button>
        ) : undefined}
        {props.chevronExpand ? (
          <button onClick={props.chevronExpand} disabled={props.disabled}>
            <ChevronExpand />
          </button>
        ) : undefined}
        {props.pencil ? (
          <button onClick={props.pencil} disabled={props.disabled}>
            <PencilSquare className={classes.smallIcon} />
          </button>
        ) : undefined}
        {props.upload ? (
          <button onClick={props.pencil} disabled={props.disabled}>
            <CloudArrowUpFill />
          </button>
        ) : undefined}
        {props.trash ? (
          <button onClick={props.pencil} disabled={props.disabled}>
            <TrashFill />
          </button>
        ) : undefined}
        {props.arrowLeft ? (
          <button onClick={props.arrowLeft} disabled={props.disabled}>
            <ArrowLeft />
          </button>
        ) : undefined}
        {props.arrowRight ? (
          <button onClick={props.arrowRight} disabled={props.disabled}>
            <ArrowRight />
          </button>
        ) : undefined}
        {props.arrowRepeat ? (
          <button onClick={props.arrowRepeat} disabled={props.disabled}>
            <ArrowRepeat />
          </button>
        ) : undefined}
        {props.check ? (
          <button onClick={props.check} disabled={props.disabled}>
            <Check2Circle />
          </button>
        ) : undefined}
        {props.checkSquare ? (
          <button onClick={props.checkSquare} disabled={props.disabled}>
            <Check2Square />
          </button>
        ) : undefined}
        {props.full ? (
          <button onClick={props.full} disabled={props.disabled}>
            <Fullscreen className={classes.exception} />
          </button>
        ) : undefined}
        {props.fullExit ? (
          <button onClick={props.fullExit} disabled={props.disabled}>
            <FullscreenExit className={classes.exception} />
          </button>
        ) : undefined}
        {typeof props.processing === "number" ? (
          <div className={classes.loadingBar}>
            <CircularProgressbar
              value={props.processing}
              strokeWidth={20}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathColor: `rgba(230, 242, 255, 0.966)`,
                trailColor: "rgba(230, 242, 255, 0.1)",
              })}
            />
          </div>
        ) : undefined}
        {!props.noX && props.x ? (
          <button onClick={props.x} disabled={props.disabled}>
            <X />
          </button>
        ) : undefined}
      </Fragment>
    );
  }

  return (
    <div
      className={`btn btn-${props.variant ? props.variant : "primary"} ${
        classes.buttonWithOptions
      } ${
        props.disabled ? classes[`disabled-${props.variant || "primary"}`] : ""
      }`}
      onClick={!props.disabled ? props.click : () => {}}
    >
      <div>
        <span>{props.children}</span>
        <div className={classes.dupliatedCategoryesOptions}>
          {optionsContent}
        </div>
      </div>
    </div>
  );
};

export default OptionsButton;
