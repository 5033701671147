import { useState } from "react";
import { useSelector } from "react-redux";

import apiCategories from "../../../../../api/categories";

const useSubcategories = (categories: any[]) => {
  const [categoryIdToEdit, setCategoryIdToEdit] = useState<
    number | undefined
  >();
  const [showLinkCategories, setShowLinkCategories] = useState(false);

  const accesToken = useSelector((state: any) => state.user.access);

  const handleClickEditCategory = (id: number) => {
    setCategoryIdToEdit(id);
  };

  const handleCloseEditCategoryModal = () => {
    setCategoryIdToEdit(undefined);
  };

  const showEditCategoryModal = categoryIdToEdit !== undefined;

  const handleClickLinkCategories = () => {
    setShowLinkCategories(true);
  };

  const handleCloseLinkCategories = () => {
    setShowLinkCategories(false);
  };

  const linkedCategoriesIds = categories.map((cat) => cat.id);

  const getAllCategories = async () => {
    const response = await apiCategories.listAll(accesToken);

    return { data: response.data };
  };

  return {
    showLinkCategories,
    handleClickLinkCategories,
    handleCloseLinkCategories,
    linkedCategoriesIds,
    getAllCategories,
    categoryIdToEdit,
    showEditCategoryModal,
    handleClickEditCategory,
    handleCloseEditCategoryModal,
  };
};

export default useSubcategories;
