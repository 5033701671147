import React from "react";
import { Pagination } from "react-bootstrap";

import ReactTooltip from "react-tooltip";
import classes from "./PaginationItem.module.css";

// Cambiado todos los props.arrayFilters (y quitado de lo padres) por arrayWhole en las segundas partes de los ternarios
const PaginationItem = (props) => {
  // SPLIT ARRAYS FUNCTION
  const splitArrays = (arr, len) => {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  };
  return (
    <div className={classes.hideOnMobile}>
      <Pagination 
        className={`${
          props.arrayWhole.length > props.resultsNumber ||
          props.arrayWhole.length === 0
            ? classes.pagination
            : classes.hide}`
        }
      >
        <ReactTooltip delayShow={1000} place="top" type="info" />
        <Pagination.First
          data-tip="Primera página"
          onClick={() => {
            props.setPageNumber(0);
          }}
        />
        <Pagination.Prev
          data-tip="Página anterior"
          onClick={() => {
            props.setPageNumber(
              props.pageNumber === 0 ? 0 : props.pageNumber - 1
            );
          }}
        />
        <Pagination.Ellipsis
          data-tip="Ir 5 páginas atrás"
          onClick={() => {
            props.setPageNumber(
              props.pageNumber < 5 ? props.pageNumber : props.pageNumber - 5
            );
          }}
        />

        {splitArrays(
          props.inputValue === "" ? props.arrayWhole : props.arrayWhole,
          props.resultsNumber
        ).map((item, index) => {
          return (
            <Pagination.Item
              className={
                props.pageNumber < 14
                  ? index > 14
                    ? classes.hide
                    : null
                  : index > props.pageNumber + 7 || index < props.pageNumber - 7
                  ? classes.hide
                  : null
              }
              key={index}
              active={props.pageNumber === index}
              onClick={() => {
                props.setPageNumber(index);
              }}
            >
              {index + 1}
            </Pagination.Item>
          );
        })}
        <Pagination.Ellipsis
          data-tip="Ir 5 páginas hacia delante"
          onClick={() => {
            props.setPageNumber(
              props.pageNumber >=
                (props.inputValue === ""
                  ? splitArrays(props.arrayWhole, props.resultsNumber)
                  : splitArrays(props.arrayWhole, props.resultsNumber)
                ).length -
                  5
                ? props.pageNumber
                : props.pageNumber + 5
            );
          }}
        />
        <Pagination.Next
          data-tip="Página siguiente"
          onClick={() => {
            props.setPageNumber(
              props.pageNumber ===
                (props.inputValue === ""
                  ? splitArrays(props.arrayWhole, props.resultsNumber)
                  : splitArrays(props.arrayWhole, props.resultsNumber)
                ).length -
                  1
                ? (props.inputValue === ""
                    ? splitArrays(props.arrayWhole, props.resultsNumber)
                    : splitArrays(props.arrayWhole, props.resultsNumber)
                  ).length - 1
                : props.pageNumber + 1
            );
          }}
        />
        <Pagination.Last
          data-tip="Última página"
          onClick={() => {
            props.setPageNumber(
              (props.inputValue === ""
                ? splitArrays(props.arrayWhole, props.resultsNumber)
                : splitArrays(props.arrayWhole, props.resultsNumber)
              ).length - 1
            );
          }}
        />
      </Pagination>
    </div>
  );
};

export default PaginationItem;
