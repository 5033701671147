import React from "react";

import { Button } from "react-bootstrap";
import { FilePdf, X, AspectRatio } from "react-bootstrap-icons";

import classes from "./UpgradedFilesList.module.scss";

const disabled: React.FC<{
  files: {
    name: string;
    size?: number;
    src?: string;
  }[];
  handleClickOpen: (val: number) => void;
  handleClickRemoveFile: (val: number) => void;
  disabled: boolean;
  type?: "pdf" | "image" | undefined;
}> = ({ files, handleClickOpen, handleClickRemoveFile, disabled, type }) => {
  return (
    <ul className={classes.filesList}>
      {files.map((file, k) => {
        return (
          <li key={k}>
            <div>{file.name}</div>
            <div>{file.size ? file.size.toFixed(2) + " MB" : "---"}</div>
            <div>
              {type === "pdf" && (
                <Button
                  variant="light"
                  onClick={() => handleClickOpen(k)}
                  disabled={disabled}
                >
                  <FilePdf />
                </Button>
              )}
              {file.src && (
                <Button
                  variant="light"
                  onClick={() => handleClickOpen(k)}
                  disabled={disabled}
                >
                  <img src={file.src} height={30} width={30} />
                </Button>
              )}
              <Button
                variant="light"
                onClick={() => handleClickRemoveFile(k)}
                disabled={disabled}
              >
                <X />
              </Button>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default disabled;
