import React from "react";
import InputErrorMessage from "../InputErrorMessage/InputErrorMessage";
import { Dash, Check, X } from "react-bootstrap-icons";
import classes from "./CheckSphere.module.css";

const CheckSphere = (props) => {
  let icon;
  let style;

  if (props.check) {
    icon = <Check />;
  } else if (props.x) {
    icon = <X />;
    style = classes.red;
  } else {
    icon = <Dash />;
    style = classes.grey;
  }

  const activeClasses = [classes.sphereContainer];

  if (props.disabled) {
    activeClasses.push(classes.disabled);
  }

  return (
    <>
      <div
        className={activeClasses.join(" ")}
        onClick={!props.disabled ? props.onClick : () => {}}
        disabled={props.disabled}
      >
        <div className={`${classes.sphere} ${style}`}>{icon}</div>
        {props.children ? (
          <span className={classes.title}>{props.children}</span>
        ) : undefined}
      </div>
      {props.error ? (
        <div className={classes.errorMessage}>
          <InputErrorMessage>{props.errorMessage}</InputErrorMessage>
        </div>
      ) : undefined}
    </>
  );
};

export default CheckSphere;
