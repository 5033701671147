import customAxios from "../customAxios";

const createCertificate = (
  access: string,
  { name, description }: { name: string; description: string }
) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/certificates/add/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: {
      name,
      description,
    },
  });
};

const uploadImage = (access: string, { data }: { data: FormData }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/certificates/images/upload/`,
    headers: {
      Authorization: `Bearer ${access}`,
      'Content-Type': 'multipart/form-data'
    },
    data,
  });
};

const uploadPdf = (access: string, { data }: { data: FormData }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/certificates/pdf/upload/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data,
  });
};

const getCertificates: (access: string) => any = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/certificates/get/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const filter: (
  access: string,
  data: { id: number; filterName: string }
) => any = (access, { id, filterName }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/certificates/filter-by/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: {
      id,
      filtername: filterName,
    },
  });
};

const openImage: (access: string, data: { filename: string }) => any = (
  access,
  { filename }
) => {
  return customAxios({
    method: "post",
    responseType: "blob",
    url: `${process.env.REACT_APP_API_SERVER}/api/certificates/images/read/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: {
      filename,
    },
  });
};

const openPdf: (access: string, data: { filename: string }) => any = (
  access,
  { filename }
) => {
  return customAxios({
    method: "post",
    responseType: "blob",
    url: `${process.env.REACT_APP_API_SERVER}/api/certificates/pdf/read/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: {
      filename,
    },
  });
};

const removeImage: (access: string, data: { filename: string }) => any = (
  access,
  { filename }
) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/certificates/images/delete/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: {
      filename,
    },
  });
};

const removePdf: (access: string, data: { filename: string }) => any = (
  access,
  { filename }
) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/certificates/pdf/delete/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: {
      filename,
    },
  });
};

const edit: (
  access: string,
  data: { id: number; name: string; description: string }
) => any = (access, { id, name, description }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/certificates/update/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: {
      id,
      name,
      description,
    },
  });
};

// const getImage = (access, {}) => {
//   return customAxios({
//     method: "post",
//     url: `${process.env.REACT_APP_API_SERVER}/api/certificates/filter-by/`,
//     headers: {
//       Authorization: `Bearer ${access}`,
//     },
//     data: {},
//   });
// };

export default {
  createCertificate,
  uploadImage,
  uploadPdf,
  getCertificates,
  filter,
  openImage,
  openPdf,
  removePdf,
  removeImage,
  edit,
};
