import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// BOOTSTRAP
import { Button, Container, Table } from "react-bootstrap";
import { ArrowUpSquareFill, PencilSquare } from "react-bootstrap-icons";
// STYLES
import classes from "./FeatureValuesList.module.css";
// API
import apiFeatures from "../../../api/features";
// UTILITIES
import handleApiErrors from "../../../utilities/handleApiErrors";
import useInfiniteScroll from "../../../utilities/useInfiniteScroll";
// COMPONENTS
import TextInput from "../../../components/TextInput/TextInput";
import Spinner from "../../../components/Spinner/Spinner";
import MainTitle from "../../../components/MainTitle/MainTitle";
import PaginationItem from "../../../components/PaginationItem/PaginationItem";
import stringSimilarity from "string-similarity";
import ReactTooltip from "react-tooltip";
// CONTAINERS

//STATES
const states = {
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};
const resultsNumber = 20;

const FeatureValuesList = (props) => {
  const featureId = props.match.params.id;

  const accesToken = useSelector((state) => state.user.access);
  // TITLE
  const [title, setTitle] = useState("");
  // INPUT
  const [inputValue, setInputValue] = useState("");
  // ARRAYS
  const [allFeaturesValues, setAllFeaturesValues] = useState([]);
  const [allFeaturesValuesUntouched, setAllFeaturesValuesUntouched] = useState(
    []
  );
  // PAGINATION
  const [pageNumber, setPageNumber] = useState(0);
  // PROCESS STATE
  const [process, setProcess] = useState(states.LOADING);
  // INFINITE SCROLL
  const [results, setResults] = useState(20);
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreListItems);

  // BRING DATA FROM API
  useEffect(() => {
    apiFeatures
      .filterValues(accesToken, { filtername: "featureId", value: featureId })
      .then(({ data }) => {
        const items = data.map((item) => ({
          id: item.id,
          name: item.value,
        }));

        setAllFeaturesValues(items);
        setAllFeaturesValuesUntouched(items);
        setProcess(states.LOADED);
      })
      .catch((e) => {
        handleApiErrors(e);
      });

    apiFeatures
      .filter(accesToken, { filtername: "id", value: featureId })
      .then(({ data }) => {
        const name = data[0].name;

        setTitle(name);
      })
      .catch((e) => {
        handleApiErrors(e);
      });
  }, []);
  // * FUNCTION FOR THE SCROLL INFINITE
  function fetchMoreListItems() {
    setTimeout(() => {
      if (results + 20 > allFeaturesValues.length) {
        setResults(allFeaturesValues.length);
      } else {
        setResults(results + 20);
      }
      setIsFetching(false);
    }, 500);
  }
  // EDIT FEATURE VALUE
  const editFeatureValue = (pId) => {
    props.history.push(`/feature-value/${pId}`);
  };
  // SPLIT ARRAYS FUNCTION
  const splitArrays = (arr, len) => {
    var chunks = [],
      i = 0,
      n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
  };

  // * LOGIC FROM SIMILARITY ALGORITHMN
  useEffect(() => {
    let queryedItems = allFeaturesValues.map((item) => {
      const scoredItem = {
        ...item,
        score: stringSimilarity.compareTwoStrings(
          inputValue.toLocaleLowerCase(),
          item.name.toLocaleLowerCase()
        ),
      };
      return scoredItem;
    });
    let arrayfilteredSorted = queryedItems.sort((a, b) => {
      if (a.score > b.score) {
        return -1;
      }
      if (a.score < b.score) {
        return 1;
      }
      return 0;
    });

    const exactItems = [];
    const similarItems = [];

    arrayfilteredSorted.forEach((product) => {
      if (product.name.toLocaleLowerCase().includes(inputValue)) {
        exactItems.push(product);
      } else {
        similarItems.push(product);
      }
    });
    // When there are 3 letters or more then it shows the flitered/ordered array, if not the general array
    inputValue.length > 2
      ? setAllFeaturesValues([
          ...exactItems,
          ...similarItems.filter((item) => item.score > 0.2),
        ])
      : setAllFeaturesValues(allFeaturesValuesUntouched);
  }, [inputValue]);

  //  * LOGIC FOR RESPONSIVE, IF MATCH WITH THE MOBILE SCREEN THE RESULTS PER PAGE ADD 20 RESULTS AVERY TIME SCROLL TO THE END OF THE VIEWPORT
  let breakPointResponsive = window.matchMedia("(max-width: 65em)");
  let bodyContent;
  function responsiveBreakMatch(breakPointResponsive) {
    // If media query matches, the type of results change to the state
    bodyContent =
      splitArrays(
        allFeaturesValues,
        breakPointResponsive.matches ? results : resultsNumber
      )[pageNumber] === undefined
        ? null
        : (
            splitArrays(
              allFeaturesValues,
              breakPointResponsive.matches ? results : resultsNumber
            )[pageNumber] ?? []
          ).map((item, index) => {
            return (
              <>
                {/* <ReactTooltip place="left" type="info" /> */}
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>
                    <Button
                      variant="light"
                      onClick={() => editFeatureValue(item.id)}
                    >
                      <PencilSquare />
                    </Button>
                  </td>
                </tr>
                {breakPointResponsive.matches &&
                allFeaturesValues.length > 20 ? (
                  results <= 20 ? null : (
                    <div className={classes.arrowContainer}>
                      <ArrowUpSquareFill
                        // data-tip="Volver al inicio"
                        className={classes.arrowUp}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      />
                    </div>
                  )
                ) : null}
              </>
            );
          });
  }
  responsiveBreakMatch(breakPointResponsive);

  return (
    <>
      <Container className={classes.firstTitle}>
        <MainTitle>
          <h1> Característica: {title}</h1>
        </MainTitle>
      </Container>

      {/* TITLE */}
      <Container className={classes.title}>
        <h1>Lista de valores de características</h1>
      </Container>

      <Container className={classes.input}>
        <TextInput
          placeholder="Introduce el nombre del valor de la característica"
          onChange={(event) => {
            setInputValue(event.target.value);
            setPageNumber(0);
          }}
          value={inputValue}
        />
      </Container>

      {/* TABLE */}
      <Container>
        {process === "LOADING" ? (
          <Spinner
            className="mt-5"
            as="svg"
            animation="border"
            size="lg"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <>
            {/* // PAGINATION */}
            {allFeaturesValues.length >= 10 ? (
              <PaginationItem
                //Pongo el mismo array en filtrado para que no cambie la paginación ni de error
                arrayWhole={allFeaturesValues}
                resultsNumber={resultsNumber}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                inputValue={inputValue}
              />
            ) : null}
            {/* //END OF PAGINATION */}

            <Table responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>VALOR</th>
                  <th>EDITAR</th>
                </tr>
              </thead>
              <tbody>{bodyContent}</tbody>
            </Table>
            {/* //* SPINNER IN MOBILE WHEN LOADING MORE ITEMS */}
            {/* {isFetching ? (
              <div className={classes.hideSpinnerDesktop}>
                <Spinner
                  as="svg"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : null} */}
            {/* //BOTTOM PAGINATION */}
            {allFeaturesValues.length >= 10 ? (
              <PaginationItem
                //Pongo el mismo array en filtrado para que no cambie la paginación ni de error
                arrayWhole={allFeaturesValues}
                resultsNumber={resultsNumber}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                inputValue={inputValue}
              />
            ) : null}

            {/* //END OF BOTTOM PAGINATION */}
          </>
        )}
      </Container>
    </>
  );
};

export default FeatureValuesList;
