import { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { PROCESSING } from "../../../../../../../utilities/processStates";

import apiCertificates from "../../../../../../../api/certificates";

const useEditCertificate: (certificateId: number) => any = (
  certificateId: number
) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState<any>();
  const [pdf, setPdf] = useState<any>();

  const [processLoadingCertificate, setProcessLoadingCertificate] =
    useState<string>();

  const accesToken = useSelector((state: any) => state.user.access);

  useEffect(() => {
    (async () => {
      if (certificateId === undefined) {
        return;
      }
      setProcessLoadingCertificate(PROCESSING);

      try {
        const certificateInfoResponse = await apiCertificates.filter(
          accesToken,
          {
            filterName: "id",
            id: certificateId,
          }
        );

        const databaseName = certificateInfoResponse.data[0].name;

        try {
          const imageResponse = await apiCertificates.openImage(accesToken, {
            filename: `${databaseName}.jpg`,
          });

          setImage({
            name: `${databaseName}.jpg`,
            size: imageResponse.data.size,
          });
        } catch (error) {
          setImage(undefined);
          console.log(error);
        }

        try {
          const pdfResponse = await apiCertificates.openPdf(accesToken, {
            filename: `${databaseName}.pdf`,
          });

          setPdf({
            name: `${databaseName}.pdf`,
            size: pdfResponse.data.size,
          });
        } catch (error) {
          setPdf(undefined);
          console.log(error);
        }

        if (!certificateInfoResponse.data[0]) {
          return;
        }

        const description = certificateInfoResponse.data[0].description;

        const nameToDisplay = databaseName === null ? "" : databaseName;
        const descriptionToDisplay = description === null ? "" : description;

        setName(nameToDisplay);
        setDescription(descriptionToDisplay);
        setProcessLoadingCertificate(undefined);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [certificateId]);

  const isLoadingCertificate = processLoadingCertificate === PROCESSING;

  return {
    name,
    description,
    image,
    pdf,
    isLoadingCertificate,
  };
};

export default useEditCertificate;
