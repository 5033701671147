import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import apiSem from "../../../api/sem/createCampaign";

import { Button, Col, Form, Row } from "react-bootstrap";
// hooks
import useCreateCampaign from "./hooks/use-createCampaign";

import swal from "sweetalert";


import classes from "./CreateCampaign.module.css";

interface Props {
  setProccessNumber: any;
  productId: any;
  afterCreateCampaign: (id: number) => void;
}
const CreateCampaign: React.FC<Props> = ({
  setProccessNumber,
  productId,
  afterCreateCampaign,
}) => {
  // reducer for accestoken tanto de user ocmo de gads
  const accesToken = useSelector((state: any) => state.user.access);

  // reducer for profiles tanto de user como de gads
  const profiles = useSelector((state: any) => state.profiles);

  // state for the budget_pk (id)
  const [budgetId, setBudgetId] = useState<number>();
  // state for the campaign pk
  const [campaignId, setCampaignId] = useState<number>();
  // state for the campaign location pk
  const [campaignLocationId, setCampaignLocationId] = useState<number>();
  // states for the inputs
  // const [inputAmount, setinputAmount] = useState<number>(100000);
  // const [inputMaxCPC, setInputMaxCPC] = useState<number>(0.2);

  const { inputAmount, inputMaxCPC, handleInputAmount, handleInputMaxCPC } =
    useCreateCampaign();

  const gadsAcountId: number = parseInt(profiles.gads.id, 10);
  //

  const createCampaignProcess = () => {
    // console.log(inputAmount);
    // console.log(inputMaxCPC);

    (async () => {
      try {
        //  primer endpoint
        const responseCreateBudget = await apiSem.createBudget(accesToken, {
          account_pk: gadsAcountId,
          amount: inputAmount * 100000,
        });
        console.log(
          "🚀 ~ file: CreateCampaign.tsx ~ line 41 ~ responseCreateBudget",
          responseCreateBudget.data
        );

        const budgetId = responseCreateBudget.data.budget.ddbb.pk;
        setBudgetId(budgetId);

        // segundo endpoint
        console.log("gadsAcountId", gadsAcountId);
        console.log("productId", productId);
        console.log("budgetId", budgetId);
        console.log("inputMaxCPC", Number(inputMaxCPC));
        const responseCreateCampaign = await apiSem.campaign(accesToken, {
          account_pk: gadsAcountId,
          entity_pk: productId,
          entity_type: 1,
          budget_pk: budgetId,
          maxCpc: Number(inputMaxCPC),
        });
        console.log(
          "🚀 ~ file: CreateCampaign.tsx ~ line 54 ~ responseCreateCampaign",
          responseCreateCampaign.data
        );

        const campaignId = responseCreateCampaign.data.campaign.ddbb.pk;
        setCampaignId(campaignId);
        afterCreateCampaign(campaignId);

        // tercer endpoint
        const responseCampaignLocation = await apiSem.campaignLocation(
          accesToken,
          {
            account_pk: gadsAcountId,
            campaign_pk: campaignId,
            locations: [1], //por defecto españa es el 1
          }
        );
        console.log(
          "🚀 ~ file: CreateCampaign.tsx ~ line 74 ~ responseCampaignLocation",
          responseCampaignLocation.data
        );
        const campaignLocationId =
          responseCampaignLocation.data.location_Spain.ddbb.pk;
        setCampaignLocationId(campaignLocationId);

        // cuarto endpoint
        const responseCampaignLanguage = await apiSem.campaignLanguages(
          accesToken,
          {
            account_pk: gadsAcountId,
            campaign_pk: campaignId,
            languages: [1], //por defecto españa es el 1
          }
        );
        console.log(
          "🚀 ~ file: CreateCampaign.tsx ~ line 101 ~ responseCampaignLanguage",
          responseCampaignLanguage.data
        );
      } catch (e) {
        console.log(
          "🚀 ~ file: CreateCampaign.js ~ line 105 ~ sendInfo ~ e",
          e
        );
      }
    })();
  };
  return (
    <>
      <Form>
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="3">
            Amount
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="number"
              placeholder="0"
              value={inputAmount}
              onChange={handleInputAmount}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="3">
            Max CPC
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="number"
              placeholder="0.0"
              step="0.1"
              value={inputMaxCPC}
              onChange={handleInputMaxCPC}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="3">
            Idiomas
          </Form.Label>
          <Col sm="6">
            <Form.Control
              plaintext
              readOnly
              defaultValue="Castellano por defecto"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="3">
            Países
          </Form.Label>
          <Col sm="6">
            <Form.Control
              plaintext
              readOnly
              defaultValue="España por defecto"
            />
          </Col>
        </Form.Group>
      </Form>
      <div className={classes.nextButton}>
        <Button
          onClick={() => {
            if (inputAmount <=0 || inputMaxCPC <= 0) {
              return swal({
                title: "Hay campos incorrectos",
                text: `Por favor introduce un Amount y un Max CPC mayor que 0`,
                icon: "error",
                button: "Entendido",
              });
            } else {
              createCampaignProcess();
              setProccessNumber(3);
            }
          }}
          variant="success"
        >
          Siguiente paso
        </Button>
      </div>
    </>
  );
};
export default CreateCampaign;
