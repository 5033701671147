import { useState } from "react";
import { useSelector } from "react-redux";

import displayNotification from "../../../../utilities/displayNotification";

import apiCategories from "../../../../api/categories";
import checkSync from "../../../../utilities/checkSync";

const useCategories = () => {
  const [categories, setCategories] = useState<any[]>([]);
  const [mainCategory, setMainCategory] = useState<any>({});

  const accesToken = useSelector((state: any) => state.user.access);

  const handleEditCategory = (category: any) => {
    const newCategory = {
      id: category.id,
      name: category.name,
    };

    if (mainCategory.id === category.id) {
      setMainCategory(newCategory);
    }

    setCategories((cats: any) => {
      const updatedCategories = cats.map((cat: any) => {
        if (cat.id === category.id) {
          return newCategory;
        } else {
          return cat;
        }
      });

      return updatedCategories;
    });
  };

  const handleCreateCategory = async (name: string) => {
    const responseCreateCategory = await apiCategories.add(accesToken, {
      name,
    });

    (async () => {
      const createdCategoryId = responseCreateCategory.data.id;

      try {
        await apiCategories.sync(accesToken, {
          categoryId: createdCategoryId,
        });

        await checkSync.checkCategorySync(accesToken, {
          categoryId: createdCategoryId,
        });

        displayNotification({
          title: "Se ha sincronizado correctamente",
          message: "La sync de la categoría creada ha sido exitosa",
          type: "success",
          dismiss: undefined,
        });
      } catch (error) {
        displayNotification({
          title: "Ha habido un error en la sync",
          message: "La sync de la categoría creada ha fallado",
          type: "danger",
          dismiss: undefined,
        });
      }
    })();

    return responseCreateCategory;
  };

  return {
    categories,
    setCategories,
    mainCategory,
    setMainCategory,
    handleEditCategory,
    handleCreateCategory,
  };
};

export default useCategories;
