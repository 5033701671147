import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Form, ListGroup } from "react-bootstrap";
import classes from "./AdTypeETA.module.css";

const AdTypeETA = () => {
  const [buttonEditActive, setButtonEditActive] = useState(true);
  const [textTest, setTextTest] = useState<string>("lolo lololo lol");
  return (
    <div className={classes.container}>
      <div className={classes.list}>
        <div className={classes.headlines}>
          <h2>Headlines</h2>
          <ListGroup>
            <ListGroup.Item action>Headline 1</ListGroup.Item>
            <ListGroup.Item action>Headline 2</ListGroup.Item>
            <ListGroup.Item action>Headline 3</ListGroup.Item>
          </ListGroup>
        </div>
        <div className={classes.descriptions}>
          <h2>Descriptions</h2>
          <ListGroup>
            <ListGroup.Item action>Description 1</ListGroup.Item>
            <ListGroup.Item action>Description 2</ListGroup.Item>
          </ListGroup>
        </div>
      </div>
      <div className={classes.inputs}>
        <div className={classes.textArea}>
        {buttonEditActive ? (
            <>
              <h2>Introduce el texto</h2>
              <Form>
                <Form.Group className="mb-3 pl-5 pr-5">
                  {/* <Form.Label>Example textarea</Form.Label> */}
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={(event) => {
                      setTextTest(event.target.value);
                    }}
                    value={textTest}
                  />
                </Form.Group>
              </Form>
            </>
          ) : (
            <>
              <h2>Previsualización</h2>
              <h4>{textTest}</h4>
            </>
          )}
        </div>
        {buttonEditActive ? (
          <div className={classes.suggestions}>
            <h2>Sugerencias</h2>
            <div className={classes.suggestionsList}>
              <li
                onClick={() => {
                  setTextTest("cajas de cartón");
                }}
                className={classes.suggestionsListLi}
              >
                cajas de cartón
              </li>
              <li
                onClick={() => {
                  setTextTest("cajitas de cartón");
                }}
                className={classes.suggestionsListLi}
              >
                cajitas de cartón
              </li>
              <li
                onClick={() => {
                  setTextTest("cajas de papel");
                }}
                className={classes.suggestionsListLi}
              >
                cajas de papel
              </li>
              <li
                onClick={() => {
                  setTextTest("cajones de los gordos");
                }}
                className={classes.suggestionsListLi}
              >
                cajones de los gordos
              </li>
              <li
                onClick={() => {
                  setTextTest("caja cajera");
                }}
                className={classes.suggestionsListLi}
              >
                caja cajera
              </li>
              <li
                onClick={() => {
                  setTextTest("cajas refacheras");
                }}
                className={classes.suggestionsListLi}
              >
                cajas refacheras
              </li>
            </div>
          </div>
        ) : null}
      </div>
      <div className={classes.buttons}>
      <ButtonGroup>
          <Button
            onClick={() => {
              setButtonEditActive(false);
            }}
            variant={buttonEditActive ? "light" : "primary"}
          >
            Previsualizar
          </Button>
          <Button
            onClick={() => {
              setButtonEditActive(true);
            }}
            variant={buttonEditActive ? "primary" : "light"}
          >
            Editar
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};
export default AdTypeETA;
