import React from "react";

import { Spinner } from "react-bootstrap";

import classes from "./LoadingMessage.module.scss";

const LoadingMessage: React.FC<{ message: string }> = ({ message }) => {
  return (
    <span className={classes.loadingMessage}>
      {message}... <Spinner animation="border" size="sm" />
    </span>
  );
};

export default LoadingMessage;
