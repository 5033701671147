import customAxios from "../customAxios";

const filter = (acces, { filtername, value }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/filter-by/`,
    headers: {
      Authorization: `Bearer ${acces}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ filtername, value }),
  });
};
const listFilter = (acces, { filtername, value }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/list-filter-by/`,
    headers: {
      Authorization: `Bearer ${acces}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ filtername, value }),
  });
};
const all = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};
const listAll = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/list-all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};
const removeAttributes = (access, { articleId, attributeIds, forceRemove }) => {
  return customAxios({
    method: "delete",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/remove-attributes/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      articleId,
      attributeIds,
      forceRemove,
    }),
  });
};

const addAttribute = (access, articleId, attributeId) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/add-attribute/${articleId}/${attributeId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const checkAttribute = (access, { productId }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/check-article-attribute-product/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      productId,
    }),
  });
};

const edit = (access, { id, brandId, description, active, activeSales }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/edit/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      id,
      brandId,
      description,
      active,
      active_sales: activeSales,
    }),
  });
};

const assignProduct = (access, { id, productId }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/edit/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      id,
      productId,
    }),
  });
};

const getOrphans = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/orphaned/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};
const getOrphansLength = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/orphaned-length/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const orphanize = (access, { articleIds }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/orphanize/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      articleIds,
    }),
  });
};

const addAttributeValue = (access, { articleId, attributeValueId }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/add-attribute-value/${articleId}/${attributeValueId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const removeAttributeValue = (access, { articleId, attributeValueId }) => {
  return customAxios({
    method: "delete",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/remove-attribute-value/${articleId}/${attributeValueId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const getPhotos = (access, { articleId }) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/combination/images/${articleId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const sync = (access, { articleId }) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/combinations/sinc/id/${articleId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const checkSync = (access, { articleId }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/check-sync/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      articleId,
    }),
  });
};

const choosePhotos = (access, { combinationId, images }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/combination/images/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      combinationId,
      images,
    }),
  });
};

const syncAll = (access, { productId }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/sync-all-from-product/${productId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
  });
};

const updateFromErp = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/update-from-erp/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
  });
};

export default {
  filter,
  listFilter,
  getOrphansLength,
  all,
  listAll,
  removeAttributes,
  addAttribute,
  checkAttribute,
  edit,
  assignProduct,
  getOrphans,
  orphanize,
  addAttributeValue,
  removeAttributeValue,
  getPhotos,
  sync,
  checkSync,
  choosePhotos,
  syncAll,
  updateFromErp,
};
