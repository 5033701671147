import customAxios from "../../customAxios";

const filter = (
  access: string,
  data: {
    filter: "languageId" | "all" | "search";
    value: string;
    similarity?: number;
    page?: number;
  }
) => {
  let page = data.page;

  if (page === undefined) {
    page = 0;
  }

  return customAxios({
    method: "post",
    url: `${
      process.env.REACT_APP_API_SERVER
    }/api/sem/keyword-string-filter/?page=${page + 1}`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

const add = (
  access: string,
  data: {
    string: string;
    language: number;
  }
) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/sem/keyword-string/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

export default {
  filter,
  add,
};
