import customAxios from "../customAxios";

const getAll = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/literals/get-all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const editLiteral = (access,  id, singular, plural ) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/literals/edit/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ id, singular, plural }),
  });
};
const newLiteral = (access,   singular, plural ) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/literals/new/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ singular, plural }),
  });
};

const filter = (access, { filtername, id }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/literals/filter-by/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ filtername, id }),
  });
};

const getProductsList = (access, id) => {
    return customAxios({
      method: "post",
      url: `${process.env.REACT_APP_API_SERVER}/api/literals/products-list/`,
      headers: {
        Authorization: `Bearer ${access}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ id }),
    });
  };
export default { getAll, filter, editLiteral,getProductsList,newLiteral };
