import React, { useEffect } from "react";

import { Form, Button } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ButtonSpinner from "../../../../../components/ButtonSpinner/ButtonSpinner";

import useUploadVideos from "./hooks/use-uploadVideos";

import classes from "./UploadVideos.module.scss";

const UploadVideos: React.FC<{
  productId: number;
  handleUpdateVideosNumber: (val: number) => void;
}> = ({ productId, handleUpdateVideosNumber }) => {
  const {
    videoUrl,
    handleTypeUrl,
    isValid,
    errorMessage,
    hasBeenSubmitted,
    hasBeenTouched,
    handleTouchInput,
    videosList,
    getVideosFromProduct,
    videoEmbeddedUrls,
    handleSubmitForm,
    handleRemoveVideo,
    isProcessingUploadVideo,
    isRemovingVideo,
    removeVideoIndex,
    onDragEnd,
  } = useUploadVideos(productId);

  useEffect(() => {
    getVideosFromProduct();
  }, []);

  useEffect(() => {
    handleUpdateVideosNumber(videosList.length);
  }, [videosList.length]);

  return (
    <>
      <Form
        className={classes.uploadVideosForm}
        onSubmit={(e) => {
          e.preventDefault();

          handleSubmitForm();
        }}
      >
        <Form.Group className="mb-3" controlId="video_url">
          <Form.Label>URL del video a añadir</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            value={videoUrl}
            onChange={(event) => {
              handleTypeUrl(event.target.value);
            }}
            isValid={isValid && (hasBeenSubmitted || hasBeenTouched)}
            isInvalid={!isValid && (hasBeenSubmitted || hasBeenTouched)}
            onBlur={handleTouchInput}
            disabled={isProcessingUploadVideo || isRemovingVideo}
          />
          <Form.Control.Feedback type="invalid">
            {errorMessage}
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          disabled={isProcessingUploadVideo || isRemovingVideo}
        >
          {isProcessingUploadVideo ? <ButtonSpinner /> : "Subir video"}
        </Button>
      </Form>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="videosDroppable" direction="horizontal">
          {(provided: any, snapshot: any) => (
            <div
              className={classes.videoContainer}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {videoEmbeddedUrls.map((video, index) => (
                <Draggable key={video} draggableId={video} index={index}>
                  {(provided: any, snapshot: any) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <iframe
                        width="300"
                        height="240"
                        src={video}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>

                      <div className={classes.optionsContainer}>
                        <button
                          disabled={isProcessingUploadVideo || isRemovingVideo}
                          onClick={() => {
                            console.log(index);
                            handleRemoveVideo(index);
                          }}
                        >
                          {removeVideoIndex !== index ? (
                            <X />
                          ) : (
                            <ButtonSpinner />
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default UploadVideos;
