import customAxios from "../customAxios";

const getTokens = (username, password) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/token/`,
    data: {
      username,
      password,
    },
  });
};

const refreshToken = (refresh) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/token/refresh/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ refresh }),
  });
};

export default { getTokens, refreshToken };
