import React, { useState } from "react";
import {
  Table,
  Button,
  Accordion,
  Card,
  Badge,
  Alert,
  Collapse,
} from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import OptionsButton from "../../components/OptionsButton/OptionsButton";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import CheckSphere from "../../components/CheckSphere/CheckSphere";
import Spinner from "../../components/Spinner/Spinner";
import PseudoTable from "../../components/PseudoTable/PseudoTable";
import later from "../../utilities/later";
import classes from "./ProductProblemsAccordeon.module.css";

const LOADING = "LOADING";

const ProductProblemsAccordeon = (props) => {
  const {
    problems,
    syncProblemsList,
    onClickSolveProblem,
    getProblemsFromApi,
    onClickSyncProblems,
    onClickEdit,
    checkingProblems,
    onClickSyncAllProblems,
    syncingAll,
    setSyncingAll,
    totalPercentatge,
    setKey,
    hasPhotos,
    checkSyncProblems,
  } = props;
    

  const [openProblems, setOpenProblems] = useState([]);

  const openCloseTab = (identifier) => {
    setOpenProblems((openedProblems) => {
      if (openedProblems.includes(identifier)) {
        return openedProblems.filter(
          (problemIdentifier) => problemIdentifier !== identifier
        );
      } else {
        return [...openedProblems, identifier];
      }
    });
  };

  let syncProblemsNumber = 0;

  if (Array.isArray(syncProblemsList)) {
    syncProblemsList.forEach((problem) => {
      syncProblemsNumber += problem.values.length;
    });

    const processingProblems = syncProblemsList.filter(
      (problem) => problem.processing !== false
    );

    if (processingProblems.length > 0) {
      let totalPercentatge = 0;

      processingProblems.forEach((problem) => {
        totalPercentatge += problem.processing;
      });

      // console.log(100 * (totalPercentatge / (processingProblems.length * 100)));
    }
  }
  // !!!!!! dario
  //  ya está. falta cambiar los estilos porque salen descuadradas las columnas
  // Hay que meter ebn probles la referencia tb para ponerla en la tabla
  // console.log("🚀 ~ file: ProductProblemsAccordeon.js ~ line 70 ~ processingProblems.forEach ~ problem", problems)
  let articlesNoAttributeValueContent;
  if (problems === LOADING) {
    articlesNoAttributeValueContent = <Spinner />;
  } else if (problems.length > 0) {
    
    articlesNoAttributeValueContent = (
      <>
        <TitleContainer>
          <h3>Artículos sin valores de atributo</h3>
          <OptionsButton
            variant="primary"
            arrowRepeat={getProblemsFromApi}
            click={getProblemsFromApi}
            disabled={problems === LOADING}
            loading={problems === LOADING}
          >
            Comprobar errores
          </OptionsButton>
        </TitleContainer>
        <PseudoTable.Table>
          <PseudoTable.Header className={classes.header}>
            <div>Id</div>
            <div>Referencia</div>
            <div>Artículo</div>
            <div>Atributo</div>
            <div>Opciones</div>
          </PseudoTable.Header>
          {problems.map((problem, k) => (<>
            
            <PseudoTable.Row key={k} className={classes.row}>
              <div>
                {problem.article.id}-{problem.attribute.id}
              </div>
              <div>{problem.article.ref}</div>
              <div>{problem.article.name}</div>
              <div>{problem.attribute.name}</div>
              <div className={classes.problemsTableOptions}>
                <Button
                  onClick={() => {
                    onClickSolveProblem(problem);
                  }}
                >
                  Añadir valor y solucionar
                </Button>
              </div>
            </PseudoTable.Row></>
          ))}
        </PseudoTable.Table>
      </>
    );
  } else {
    articlesNoAttributeValueContent = (
      <>
        <TitleContainer>
          <h3>Artículos sin valores de atributo</h3>
          <OptionsButton
            variant="primary"
            arrowRepeat={getProblemsFromApi}
            click={getProblemsFromApi}
            disabled={problems === LOADING}
            loading={problems === LOADING}
          >
            Comprobar errores
          </OptionsButton>
        </TitleContainer>
        <Alert variant="primary">
          No hay ningún problema detectado en este producto
        </Alert>
      </>
    );
  }

  let syncErrorsContent;

  const onClickSyncAll = async () => {
    setSyncingAll(true);
    await onClickSyncAllProblems();
    await later(2000);
    await checkSyncProblems();
    setSyncingAll(false);
  };

  if (syncProblemsList !== LOADING) {
    syncErrorsContent = (
      <>
        <div className="d-flex justify-content-end">
          <OptionsButton
            variant="primary"
            arrowRepeat={props.checkSyncProblems}
            click={props.checkSyncProblems}
            disabled={checkingProblems || syncingAll}
            loading={checkingProblems || syncingAll}
          >
            Comprobar errores
          </OptionsButton>
          <OptionsButton
            variant="primary"
            arrowRepeat={!totalPercentatge ? onClickSyncAll : false}
            click={onClickSyncAll}
            disabled={checkingProblems || syncingAll}
            processing={totalPercentatge}
          >
            Sincronizar todo
          </OptionsButton>
        </div>
        {syncProblemsList.map((problem, k) => {
          if (problem.values.length <= 0) {
            return;
          }

          const isOpen = openProblems.includes(problem.identifier);

          return (
            <div key={k} className={classes.problemItemContainer}>
              <TitleContainer>
                <div onClick={() => openCloseTab(problem.identifier)}>
                  <h4>
                    {problem.title}
                    <Badge
                      variant="danger"
                      pill
                      className={classes.problemCountBadge}
                    >
                      {problem.values.length}
                    </Badge>
                    {isOpen ? (
                      <ChevronUp className={classes.chevronIcon} />
                    ) : (
                      <ChevronDown className={classes.chevronIcon} />
                    )}
                  </h4>
                </div>
                <OptionsButton
                  variant="primary"
                  arrowRepeat={
                    problem.processing !== false
                      ? false
                      : () => {
                          onClickSyncProblems(problem.identifier);
                        }
                  }
                  click={() => {
                    onClickSyncProblems(problem.identifier);
                  }}
                  disabled={problem.processing !== false || checkingProblems}
                  processing={problem.processing || checkingProblems}
                >
                  Sincronizar
                </OptionsButton>
              </TitleContainer>
              <Collapse in={isOpen}>
                <div>
                  <Table>
                    <thead>
                      <tr>
                        {problem.valuesHeader.map((headerValue, k) => {
                          const title = headerValue[0].toUpperCase();

                          return (
                            <td
                              key={k}
                              className={k === 0 ? classes.idHeader : ""}
                            >
                              {title + headerValue.slice(1)}
                            </td>
                          );
                        })}
                        <td className={classes.optionsHeader}>Opciones</td>
                      </tr>
                    </thead>
                    <tbody>
                      {problem.values.map((value) => {
                        let valueFieldsList = [];

                        for (const key in value) {
                          valueFieldsList.push(value[key]);
                        }

                        valueFieldsList = valueFieldsList.slice(
                          0,
                          problem.valuesHeader.length
                        );

                        return (
                          <tr>
                            {valueFieldsList.map((field, k) => {
                              if (typeof field === "boolean") {
                                return (
                                  <td key={k}>
                                    <CheckSphere check={field} x={!field} />
                                  </td>
                                );
                              }

                              return <td key={k}>{field}</td>;
                            })}
                            <td>
                              <OptionsButton
                                variant="primary"
                                pencil={() => {
                                  onClickEdit(
                                    valueFieldsList[0],
                                    problem.identifier
                                  );
                                }}
                                click={() => {
                                  onClickEdit(
                                    valueFieldsList[0],
                                    problem.identifier
                                  );
                                }}
                                disabled={
                                  problem.processing !== false ||
                                  checkingProblems
                                }
                              >
                                Editar
                              </OptionsButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
          );
        })}
      </>
    );
  } else {
    syncErrorsContent = (
      <Alert variant="primary">
        No hay ningún problema detectado en este producto
      </Alert>
    );
  }

  let otherProblemsCount = 0;
  if (!hasPhotos) {
    otherProblemsCount += 1;
  }

  const otherProblemsContent = (
    <>
      {!hasPhotos ? (
        <Alert variant="danger">
          El producto no tiene fotografías
          <div className="d-flex justify-content-end">
            <Button onClick={() => setKey(3)} variant="outline-danger">
              Añadir fotografías
            </Button>
          </div>
        </Alert>
      ) : undefined}
    </>
  );

  return (
    <Accordion className={classes.marginBottom} defaultActiveKey="">
      <Card>
        <Accordion.Toggle
          className={classes.cursorBehavior}
          as={Card.Header}
          eventKey="0"
        >
          Artículos sin valor de atributo{" "}
          <Badge pill variant="danger" className="ml-1">
            {problems.length}
          </Badge>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>{articlesNoAttributeValueContent}</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle
          className={classes.cursorBehavior}
          as={Card.Header}
          eventKey="1"
        >
          Sincronización
          <Badge pill variant="danger" className="ml-1">
            {syncProblemsNumber}
          </Badge>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>{syncErrorsContent}</Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle
          className={classes.cursorBehavior}
          as={Card.Header}
          eventKey="2"
        >
          Otros
          <Badge pill variant="danger" className="ml-1">
            {otherProblemsCount}
          </Badge>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>{otherProblemsContent}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default ProductProblemsAccordeon;
