import React from "react";
import {
  ListGroup,
  Badge,
  Table,
  Button,
  Accordion,
  Card,
} from "react-bootstrap";
import { PencilSquare, ChevronRight } from "react-bootstrap-icons";
import CheckSphere from "../CheckSphere/CheckSphere";
import classes from "./ItemListTable.module.css";

import UpgradedPagination from "../UpgradedPagination/UpgradedPagination";

const ItemsListTable = (props) => {
  const chevronClasses = [classes.chevronIcon];

  if (props.activeKey === props.accordionKey) {
    chevronClasses.push(classes.open);
  }

  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        className={classes.accordeonHeader}
        onClick={props.clickFoldUnfold}
      >
        <div className={classes.accordeonHeaderInfo}>
          <ChevronRight className={chevronClasses.join(" ")} />
          <span>{props.title}</span>
          {props.handleClickSyncAll !== undefined ? (
            <Button
              className="d-block mb-2"
              onClick={(e) => {
                e.stopPropagation();
                props.handleClickSyncAll();
              }}
              disabled={props.disabled}
            >
              Sincronizar todo
            </Button>
          ) : undefined}
        </div>
        <Badge pill variant="primary">
          {props.topCount}
        </Badge>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={String(props.accordionKey)}>
        <Card.Body>
          <div className={classes.itemsContainer} activePage={0}>
            <Table responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>NOMBRE</th>
                  {/* Para lista de valores de atributo */}
                  {props.attValueColumn ? <th>ATRIBUTO</th> : null}
                  {/* Para lista de artículos */}
                  {props.articleColumn ? <th>ACTIVO</th> : null}
                  <th>Fallo sync</th>
                  <th>Necesario sync</th>
                  <th>Sincronizando</th>
                  <th>Prestashop id</th>
                </tr>
              </thead>
              <tbody>
                {props.items.map((item) => {
                  const rowClassName = [];

                  if (item.processing) {
                    rowClassName.push(classes.disabled);
                  }

                  return (
                    <tr key={item.id} className={rowClassName}>
                      <td className="pt-4">{item.id}</td>
                      <td>
                        <ListGroup.Item
                          onClick={() => props.clickItem(item.id)}
                          key={item.id}
                          className={`d-flex justify-content-between align-items-center ${classes.item}`}
                        >
                          {item.name}
                          <PencilSquare className={classes.edit} />
                        </ListGroup.Item>
                      </td>
                      {/* Para lista de artículos */}
                      {props.articleColumn ? (
                        <td>
                          <CheckSphere
                            check={item.active}
                            x={!item.active}
                            disabled={item.processing}
                          />
                        </td>
                      ) : null}
                      {/* Para lista de valores de atributo */}
                      {props.attValueColumn ? (
                        <td>
                          <ListGroup.Item
                            onClick={() => props.clickItem2(item.attribute__id)}
                            key={item.id}
                            className={`d-flex justify-content-between align-items-center ${classes.item}`}
                          >
                            {item.attribute__name}_
                            {item.attribute__attribute_type}
                            <PencilSquare className={classes.edit} />
                          </ListGroup.Item>
                        </td>
                      ) : null}
                      <td>
                        <CheckSphere
                          check={item.sync_fail}
                          x={!item.sync_fail}
                          disabled={item.processing}
                        />
                      </td>
                      <td>
                        <CheckSphere
                          check={item.need_sync}
                          x={!item.need_sync}
                          disabled={item.processing}
                        />
                      </td>
                      <td>
                        <CheckSphere
                          check={item.synchronizing}
                          x={!item.synchronizing}
                          disabled={item.processing}
                        />
                      </td>
                      <td>
                        <CheckSphere
                          check={item.prestashop_id}
                          x={item.prestashop_id === null}
                          disabled={item.processing}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          {props.totalPagesNumber !== undefined && (
            <UpgradedPagination
              totalPagesNumber={props.totalPagesNumber}
              activepage={props.activepage}
              handleClickPage={props.handleClickPage}
            />
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default ItemsListTable;
