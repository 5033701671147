import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Form,
  ListGroup,
  Tab,
  Tabs,
} from "react-bootstrap";
import classes from "./AdTypeRSA.module.css";
import { useSelector } from "react-redux";
import apiSem from "../../../api/sem/descriptions";
import Spinner from "../../../components/Spinner/Spinner";

interface Props {
  productId: any;
}
const AdTypeRSA: React.FC<Props> = ({ productId }) => {
   // reducer for profiles tanto de user como de gads
   const profiles = useSelector((state: any) => state.profiles);
   const gadsAcountId: number = parseInt(profiles.gads.id, 10);
  const accesToken = useSelector((state: any) => state.user.access);
  // State for the button group for the preview and edit view
  const [buttonEditActive, setButtonEditActive] = useState(true);
  // State for determining wich tab is the user (headlines or descriptions)
  const [showHeadlinesTab, setShowHeadlinesTab] = useState<Boolean>(true);
  //States for ID headlines & descriptions
  const [headlineId, setHeadlineId] = useState<number>(1);
  const [descriptionId, setDescriptionId] = useState<number>(1);
  //State for processing
  const [translateProccess, setTranslateProccess] = useState(false);
  // State for positions
const [descriptionPosition, setDescriptionPosition] = useState<number>(1)
const [headlinesPosition, setHeadlinesPosition] = useState<number>(1)
 
  //
  const [textTest, setTextTest] = useState<string>("");
  const [previewtextTest, setPreviewTextTest] = useState<string>("");
  // States for the api call list of sugegstions
  const [descriptionsList, setDescriptionsList] = useState([]);
  const [text, setText] = useState<String>();
  const [similarity, setSimilarity] = useState<String>();

  useEffect(() => {
    (async () => {
      try {
        const descriptions: any = await apiSem.descriptionsList(accesToken, {
          data: "search",
          text: textTest,
          similarity:""
        });
        setDescriptionsList(descriptions.data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [textTest]);

  //
  useEffect(() => {
    console.log(headlineId);
    console.log(descriptionId);
  }, [headlineId, descriptionId]);
  // !!!! dario endpoint para traducir el texto de el input, funciona el input pero hay q pensar donde y como se monta todo
  // los valores estan por defecto en el archivo apiSem
  const translateDescription = async () => {
    // (async () => {
    try {
      const descriptionTranslate: any = await apiSem.descriptionsTranslatorText(
        accesToken,
        // { text: textTest }
        { account_pk:gadsAcountId,
          entity_pk: productId,
          entity_type :1,
          text: textTest,
          position: descriptionPosition,}
      );
      setPreviewTextTest(
        descriptionTranslate.data.description_1.description_copy
      );
      setTranslateProccess(true);

      console.log(
        "🚀 ~ file: AdTypeRSA.tsx ~ line 43 ~ descriptionTranslate",
        descriptionTranslate.data.description_1.description_copy
      );
    } catch (e) {
      console.log(e);
    }
    // })();
  };
  // Contenido de la lista de headlines y descriptions en TABS
  const listTabs = (
    <>
      <Tabs defaultActiveKey={0}>
        {/* //*HEADLINES */}
        <Tab eventKey={0} title={"Headlines"}>
          <div className={classes.headlines}>
            {/* <h2>Headlines</h2> */}
            <ListGroup>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(1);
                }}
                action
              >
                <input type="checkbox" /> Headline 1
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(2);
                }}
                action
              >
                <input type="checkbox" /> Headline 2
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(3);
                }}
                action
              >
                <input type="checkbox" /> Headline 3
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(4);
                }}
                action
              >
                Headline 4
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(5);
                }}
                action
              >
                Headline 5
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(6);
                }}
                action
              >
                Headline 6
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(7);
                }}
                action
              >
                Headline 7
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(8);
                }}
                action
              >
                Headline 8
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(9);
                }}
                action
              >
                Headline 9
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(10);
                }}
                action
              >
                Headline 10
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(11);
                }}
                action
              >
                Headline 11
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(12);
                }}
                action
              >
                Headline 12
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(13);
                }}
                action
              >
                Headline 13
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(14);
                }}
                action
              >
                Headline 14
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setHeadlineId(15);
                }}
                action
              >
                Headline 15
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Tab>
        {/* //* DESCRIPTIONS */}
        <Tab eventKey={1} title={"Descriptions"}>
          <div className={classes.descriptions}>
            {/* <h2>Descriptions</h2> */}
            <ListGroup>
              <ListGroup.Item
                onClick={() => {
                  setDescriptionId(1);
                }}
                action
              >
                <input type="checkbox" /> Description 1
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setDescriptionId(2);
                }}
                action
              >
                <input type="checkbox" /> Description 2
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setDescriptionId(3);
                }}
                action
              >
                Description 3
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  setDescriptionId(4);
                }}
                action
              >
                Description 4
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Tab>
      </Tabs>
    </>
  );
  // Contenido de las sugerencias de descripciones
  const descriptionsSuggestionsList = (
    <>
      <div className={classes.suggestions}>
        <h2>Sugerencias</h2>
        <div className={classes.suggestionsList}>
          {descriptionsList.map((item: any, i) => {
            return (
              <li
                onClick={() => {
                  setTextTest(item.text);
                }}
                className={classes.suggestionsListLi}
              >
                {item.text}
              </li>
            );
          })}
        </div>
      </div>
    </>
  );
  return (
    <div className={classes.container}>
      <div className={classes.list}>{listTabs}</div>
      <div className={classes.inputs}>
        <div className={classes.textArea}>
          {buttonEditActive ? (
            <>
              <h2>Introduce el texto</h2>
              <Form>
                <Form.Group className="mb-3 pl-5 pr-5">
                  {/* <Form.Label>Example textarea</Form.Label> */}
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={(event) => {
                      setTextTest(event.target.value);
                      setPreviewTextTest(event.target.value);
                    }}
                    value={textTest}
                  />
                </Form.Group>
              </Form>
            </>
          ) : (
            <>
              <h2>Previsualización</h2>
              <h4>
                {previewtextTest !== "" && translateProccess ? (
                  previewtextTest
                ) : (
                  <Spinner />
                )}
              </h4>
            </>
          )}
        </div>
        {/* It show the list of suggestions if in edit view, in poreview it is not displayed */}
        {buttonEditActive ? descriptionsSuggestionsList : null}
      </div>
      <div className={classes.buttons}>
        <ButtonGroup>
          <Button
            onClick={() => {
              setButtonEditActive(false);
              translateDescription();
            }}
            variant={buttonEditActive ? "light" : "primary"}
          >
            Previsualizar
          </Button>
          <Button
            onClick={() => {
              setButtonEditActive(true);
              setPreviewTextTest("");
              setTranslateProccess(false);
            }}
            variant={buttonEditActive ? "primary" : "light"}
          >
            Editar
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};
export default AdTypeRSA;
