import { Plus } from "react-bootstrap-icons";
import SeedDropdownList from "../SeedDropdownList/SeedDropdownList";
import ModalSelectLanguage from "../ModalSelectLanguage/ModalSelectLanguage";
import ModalKeywordStringList from "../ModalKeywordStringList/ModalKeywordStrigList";
import classes from "./SeedGroup.module.scss";
import Button from "react-bootstrap/Button";

import useSeedGroup from "./hooks/use-seedGroup";

const Seedgroup = ({ campaignId }) => {
  const {
    seedsData,
    modalLangage,
    modalKwStrings,
    selectedseedGroup,
    addSeedgroup,
    removeSeedGroup,
    removeKeywordString,
    onModalLanguageExit,
    onOpenModalLanguage,
    onModalKwStringsExit,
    onOpenModalKwStrings,
    selectedSeedGroupIndex,
    handleSelectSeedGroup,
    addKeywordString,
    handleClickAvailableKeyword,
    isProcessingAddSeedGroup,
    isProcessingCreateKeyword,
  } = useSeedGroup(campaignId);

  return (
    <>
      {modalLangage && (
        <ModalSelectLanguage
          show={modalLangage}
          handleClose={onModalLanguageExit}
          handleClickNext={addSeedgroup}
        />
      )}
      {modalKwStrings && (
        <ModalKeywordStringList
          open={modalKwStrings}
          close={onModalKwStringsExit}
          selectedseedGroup={selectedseedGroup}
          handleSubmitt={addKeywordString}
          handleClickKeyword={removeKeywordString}
          handleClickAvailableKeyword={handleClickAvailableKeyword}
          isProcessing={isProcessingCreateKeyword}
        />
      )}
      {seedsData.length === 0 ? (
        <Button variant="ligth" onClick={onOpenModalLanguage}>
          <Plus className={classes.plusaddseed} />
          Añadir seed group
        </Button>
      ) : undefined}
      <SeedDropdownList
        seedsData={seedsData}
        activeSeedIndex={selectedSeedGroupIndex}
        handleClickGroup={handleSelectSeedGroup}
        handleClickGroupX={removeSeedGroup}
        handleClickPlus={onOpenModalKwStrings}
        handleClickKeywordX={removeKeywordString}
        isProcessing={isProcessingAddSeedGroup}
      />
    </>
  );
};

export default Seedgroup;
