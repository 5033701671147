import customAxios from "../customAxios";

const all = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};
const listAll = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/list-all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};
const filter = (access, { filtername, value }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/filter-by/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ filtername, value }),
  });
};
const listFilter = (access, { filtername, value }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/list-filter-by/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ filtername, value }),
  });
};

const addCategories = (access, { productId, categoryIds }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/add-categories/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ productId, categoryIds }),
  });
};

const addCategoriesToMultipleProducts = (
  access,
  { productIds, categoryIds }
) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/add-categories-multiple-products/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ productIds, categoryIds }),
  });
};

const removeCategories = (access, { productIds, categoryIds }) => {
  return customAxios({
    method: "delete",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/remove-categories/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ productIds, categoryIds }),
  });
};

const edit = (
  access,
  {
    id,
    name,
    description,
    shortDescription,
    brandId,
    active,
    reWrite,
    showPrice,
    onRequest,
    mainCategoryId,
    metaTitle,
    metaDescription,
    literalId,
  }
) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/edit/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      id,
      name,
      description,
      short_description: shortDescription,
      active,
      brandId,
      see_price: showPrice,
      on_demand: onRequest,
      categoryId: mainCategoryId,
      url_rewrite: reWrite,
      meta_title: metaTitle,
      meta_description: metaDescription,
      literalId: literalId,
    }),
  });
};

const addFeatures = (access, { productId, featureIds }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/add-features/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ productId, featureIds }),
  });
};

const removeFeatures = (access, { productIds, featureIds }) => {
  return customAxios({
    method: "delete",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/remove-features/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ productIds, featureIds }),
  });
};

const addFeatureValue = (access, { productId, valueId }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/add-feature-value/${productId}/${valueId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const removeFeatureValue = (access, { productId, valueId }) => {
  return customAxios({
    method: "delete",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/remove-feature-value/${productId}/${valueId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const create = (access, { name }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/new/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ name }),
  });
};

const getPhotos = (access, { productId }) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/product/images/${productId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const addPhoto = (access, data) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/upload/images/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: data,
  });
};

const removePhoto = (access, { images, productId }) => {
  return customAxios({
    method: "delete",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/products/images/delete/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ images, productId }),
  });
};

const makeCover = (access, { productId, photoId }) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/product/image-cover/sinc/${productId}/${photoId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const syncWithPrestashop = (access, { productId }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/silence-sync/${productId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const removeDuplicatedPhotos = (access, { productId }) => {
  return customAxios({
    method: "delete",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/products/images/deleteduplicates/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ productId }),
  });
};

const sync = (access, { productId }) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/products/sinc/id/${productId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const checkSync = (access, { productId }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/check-sync/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      productId,
    }),
  });
};

const checkProblems = (access, { productId }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/check-article-attribute-product/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      productId,
    }),
  });
};

const getProblems = (access, { productId }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/attribute-values-none/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      productId,
    }),
  });
};

const saveAtributeFilters = (access, { productId, attributeIds }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/change-attribute-table-order/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      productId,
      attributeIds,
    }),
  });
};

const removeAttributes = (access, { attributeIds, productId, forceRemove }) => {
  return customAxios({
    method: "delete",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/remove-attributes/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      productId,
      attributeIds,
      forceRemove,
    }),
  });
};

const checkProductFeatureCategory = (access, { categoryId }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/check-product-features-category/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      categoryId,
    }),
  });
};

const getTasks = (access, { productId }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/tasks/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      productId,
    }),
  });
};

const syncPrices = (access, { productId }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/sync-prices/${productId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
  });
};

const registerProductVideos = (access, { productId, videos }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/put-videos/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      productId,
      videos,
    }),
  });
};

const listAllProductVideos = (access, { productId }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/get-videos/${productId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
  });
};

const uploadPdf = (access, { data }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/pdf/product-upload-pdf/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data,
  });
};

const getPdfs = (access, { productId }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/pdf/get/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: {
      productId,
    },
  });
};

const removePdf = (access, { productId, fileName }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/pdf/delete-product-pdf/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: {
      productId,
      filename: fileName,
    },
  });
};

const openPdf = (access, { productId, fileName }) => {
  return customAxios({
    method: "post",
    responseType: "blob",
    url: `${process.env.REACT_APP_API_SERVER}/api/pdf/product-pdf/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: {
      productId,
      filename: fileName,
    },
  });
};

const addCertificate = (access, { productId, certificateIds }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/add-certificates/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: {
      productId,
      certificateIds,
    },
  });
};

const unlinkCertificates = (access, { productIds, certificateIds }) => {
  return customAxios({
    method: "delete",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/remove-certificates/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
    data: {
      productIds,
      certificateIds,
    },
  });
};

export default {
  all,
  filter,
  listFilter,
  addCategories,
  addCategoriesToMultipleProducts,
  removeCategories,
  create,
  edit,
  addFeatures,
  addFeatureValue,
  removeFeatures,
  removeFeatureValue,
  getPhotos,
  addPhoto,
  removePhoto,
  makeCover,
  syncWithPrestashop,
  removeDuplicatedPhotos,
  listAll,
  sync,
  checkSync,
  checkProblems,
  getProblems,
  saveAtributeFilters,
  removeAttributes,
  checkProductFeatureCategory,
  getTasks,
  syncPrices,
  listAllProductVideos,
  registerProductVideos,
  uploadPdf,
  getPdfs,
  removePdf,
  openPdf,
  addCertificate,
  unlinkCertificates,
};
