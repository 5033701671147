import React, { useState } from "react";

import { Button, Badge } from "react-bootstrap";

import classes from "./DisplayMorePhotosButton.module.scss";

const DisplayMorePhotosButton: React.FC<{
  handleClickMorePhotos: () => void;
  remainingPhotos: number;
}> = ({ handleClickMorePhotos, remainingPhotos }) => {
  return (
    <Button
      variant="primary"
      className={classes.moreItemsButton}
      onClick={handleClickMorePhotos}
    >
      Mostrar mas fotos <Badge>({remainingPhotos} restantes)</Badge>
    </Button>
  );
};

export default DisplayMorePhotosButton;
