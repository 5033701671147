import React from "react";
import { Button } from "react-bootstrap";
import ButtonSpinner from "../ButtonSpinner/ButtonSpinner";
import InputErrorMessage from "../InputErrorMessage/InputErrorMessage";
import classes from "./ModalSaveAndSyncButtons.module.css";

const ModalSaveAndSyncButton = (props) => {
  let syncButtonVariant;
  let textSync;

  if (props.isSyncing) {
    syncButtonVariant = "warning";
    textSync = "Sincronizando";
  } else if (props.mustSync) {
    syncButtonVariant = "danger";
    textSync = "Sincronizar";
  } else if (props.syncError) {
    syncButtonVariant = "danger";
    textSync = "Reintentar sincronización";
  } else {
    syncButtonVariant = "success";
    textSync = "Sincronizado";
  }

  let saveButtonVariant;
  let textSave;

  if (props.hasChanged) {
    saveButtonVariant = "danger";
    textSave = "Guardar";
  } else {
    saveButtonVariant = "success";
    textSave = "Guardado";
  }

  return (
    <>
      {props.error ? (
        <div className={classes.errorMessage}>
          <InputErrorMessage>
            Ha ocurrido un error, revise los campos correspondientes
          </InputErrorMessage>
        </div>
      ) : undefined}
      {props.hideSyncButton ? null : (
        <Button variant={syncButtonVariant} onClick={props.onSync}>
          {props.isSyncing ? (
            <div>
              <ButtonSpinner /> <span className="ml-2">{textSync}</span>
            </div>
          ) : (
            <span>{textSync}</span>
          )}
        </Button>
      )}

      <Button variant={saveButtonVariant} onClick={props.onClick}>
        {props.isSaving ? <ButtonSpinner /> : textSave}
      </Button>
    </>
  );
};

export default ModalSaveAndSyncButton;
