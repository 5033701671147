import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Form, ListGroup } from "react-bootstrap";
import { Check2, X } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import apiSem from "../../../api/sem/descriptions";
import apiSemAd from "../../../api/sem/ads";
import Spinner from "../../../components/Spinner/Spinner";
import classes from "./AdTypeDSA.module.css";
interface Props {
  productId: any;
}
const AdTypeDSA: React.FC<Props> = ({ productId }) => {
  // reducer for profiles tanto de user como de gads
  const profiles = useSelector((state: any) => state.profiles);
  const gadsAcountId: number = parseInt(profiles.gads.id, 10);
  // State for auth
  const accesToken = useSelector((state: any) => state.user.access);
  // State for the edit/preview buttons
  const [buttonEditActive, setButtonEditActive] = useState(true);
  // State for the text inside of the input
  const [textTest, setTextTest] = useState<string>("");
  const [previewtextTest, setPreviewTextTest] = useState<string>("");
  // State for positions
  const [descriptionPosition, setDescriptionPosition] = useState<number>(1);
  // States for the api call list of sugestions
  const [descriptionsList, setDescriptionsList] = useState([]);
  const [similarity, setSimilarity] = useState<String>();
  // State for the list of all descriptions
  const [allSuggestions, setAllSuggestions] = useState();
  //State for processing
  const [translateProccess, setTranslateProccess] = useState(false);
  // Array of descriptions titles, in this case only 2
  const [descriptionsTitleArray, setDescriptionsTitleArray] = useState([
    { position: 1, id: "", saved: true, text: "" },
    { position: 2, id: "", saved: false, text: "" },
  ]);

  // const descriptionsTitleArray = [
  //   { position: 1, id: "", saved: true, text: "" },
  //   { position: 2, id: "", saved: false, text: "" },
  // ];
  // Funcion para sacar todas las descripciones para luego comparar si esta dentro lo que se usa como descripcion
  // si existe no se crea y debes saber la id, si no existe se crea y se devolvera la siguiente id nueva para alamacenar
  useEffect(() => {
    (async () => {
      try {
        const descriptions: any = await apiSem.descriptionsList(accesToken, {
          data: "all",
          text: "",
          similarity: undefined,
        });
        setAllSuggestions(descriptions.data);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  // Con el objeto de toda la lista se buscara por la clave valor .text y devolvera la clave valor .id

  //
  useEffect(() => {
    (async () => {
      try {
        const descriptions: any = await apiSem.descriptionsList(accesToken, {
          data: "search",
          text: textTest,
          similarity: undefined,
        });
        setDescriptionsList(descriptions.data.results);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [textTest]);

  // Contenido de las sugerencias de descripciones
  const descriptionsSuggestionsList = (
    <>
      <div className={classes.suggestions}>
        <h2>Sugerencias:</h2>
        <div className={classes.suggestionsList}>
          {descriptionsList.map((item: any, i) => {
            return (
              <li
                onClick={() => {
                  setTextTest(item.text);
                }}
                className={classes.suggestionsListLi}
              >
                {item.text}
              </li>
            );
          })}
        </div>
      </div>
    </>
  );
  // !!!! dario endpoint para traducir el texto de el input, funciona el input pero hay q pensar donde y como se monta todo
  // los valores estan por defecto en el archivo apiSem
  const translateDescription = async () => {
    // (async () => {
    if (textTest === undefined || textTest === "") {
      setPreviewTextTest("EL CAMPO NO DEBE ESTAR VACIO, VUELVA AL MODO EDITAR");
      setTranslateProccess(true);
    } else {
      try {
        const descriptionTranslate: any =
          await apiSem.descriptionsTranslatorText(
            accesToken,
            // { text: textTest }
            {
              account_pk: gadsAcountId,
              entity_pk: productId,
              entity_type: 1,
              text: textTest,
              position: descriptionPosition,
            }
          );
        setPreviewTextTest(
          descriptionTranslate.data.description_1.description_copy
        );
        setTranslateProccess(true);

        console.log(
          "🚀 ~ file: AdTypeRSA.tsx ~ line 43 ~ descriptionTranslate",
          descriptionTranslate.data.description_1.description_copy
        );
      } catch (e) {
        console.log(e);
      }
    }

    // })();
  };
  // Contenido de la lista de descripciones, donde sale la posicion, el contenido en texto, si esta guardado o no y muestra diferentes iconos
  // y la id cuando se de al boton de guardar texto, que te lo devolvera el endpoint
  const descriptionsTitles = (
    <ListGroup>
      {descriptionsTitleArray.map((item, i) => {
        return (
          <ListGroup.Item
            action
            variant={descriptionPosition === item.position ? "secondary" : ""}
            onClick={() => {
              setDescriptionPosition(item.position);
            }}
          >
            Description {item.position}{" "}
            {item.saved ? (
              <Check2 className={classes.green} />
            ) : (
              <X className={classes.red} />
            )}
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );

  // Codigo para crear anuncio
  const createAd = async () => {
    try {
      const response: any = await apiSemAd.createAd(accesToken, {
        ad_type: 3,
        descriptions: [11, 2],
        promotional: true,
        adGroup_pk: 1,
        entity_pk: productId,
        entity_type: 1,
        account_pk: gadsAcountId,
        // headlines
      });
      console.log(
        "🚀 ~ file: AdTypeDSA.tsx ~ line 173 ~ createAd ~ response",
        response.data
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.list}>
        <div className={classes.descriptions}>
          <h2>Descriptions</h2>
          {descriptionsTitles}
        </div>
      </div>
      <div className={classes.inputs}>
        <div className={classes.textArea}>
          {buttonEditActive ? (
            <>
              <h2>Introduce el texto:</h2>
              <Form>
                <Form.Group className="mb-3 pl-5 pr-5">
                  {/* <Form.Label>Example textarea</Form.Label> */}
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={(event) => {
                      setTextTest(event.target.value);
                      setPreviewTextTest(event.target.value);
                      // !!!! lko que hace es llenar el array con el texto pero lo ideal seria que el texto escrito al darle aguardar texto
                      // !!!! te hiciera la llamada a la api de crear la descripcion y con la id q te devuelve la metas al array para luego mandarlo todo
                      // !!!! en la llamada de crear anuncio
                      descriptionsTitleArray[descriptionPosition - 1].text =
                        event.target.value;
                    }}
                    value={textTest}
                  />
                </Form.Group>
              </Form>
              <div className={classes.saveTextButton}>
                <Button
                  variant="success"
                  size="lg"
                  onClick={() => {
                    console.log(textTest);
                    // console.log(allSuggestions)
                    // !!!!! dario prueba para ver si puedo modificar el array de objetos de descripciones
                    // !!!!! Lo cambia pero no en el momento, hasta que no haces click en el titulo no te cambia el icono de v a x
                    console.log(descriptionsTitleArray);

                    descriptionsTitleArray[descriptionPosition - 1].saved =
                      !descriptionsTitleArray[descriptionPosition - 1].saved;
                  }}
                >
                  Guardar texto
                </Button>
              </div>
            </>
          ) : (
            <>
              <h2>Previsualización</h2>
              <h4>
                {previewtextTest !== "" && translateProccess ? (
                  previewtextTest
                ) : (
                  <Spinner />
                )}
              </h4>
            </>
          )}
        </div>
        {/* It show the list of suggestions if in edit view, in poreview it is not displayed */}
        {buttonEditActive ? descriptionsSuggestionsList : null}
      </div>
      <div className={classes.butonsContainers}>
        <div className={classes.buttons}>
          <ButtonGroup>
            <Button
              onClick={() => {
                setButtonEditActive(false);
                translateDescription();
              }}
              variant={buttonEditActive ? "light" : "primary"}
            >
              Previsualizar
            </Button>
            <Button
              onClick={() => {
                setButtonEditActive(true);
                setPreviewTextTest("");
                setTranslateProccess(false);
              }}
              variant={buttonEditActive ? "primary" : "light"}
            >
              Editar
            </Button>
          </ButtonGroup>
        </div>
        <div className={classes.finishButton}>
          <Button
            variant="warning"
            onClick={() => {
              // console.log(textTest);
              console.log(allSuggestions);
              createAd();
            }}
          >
            Crear anuncio
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AdTypeDSA;
