import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import checkSync from "../../../../utilities/checkSync";
import apiArticles from "../../../../api/articles";
import apiProducts from "../../../../api/products";
import apiLiterals from "../../../../api/literals";
import api from "../../../../api/api";
import later from "../../../../utilities/later";
import AdministerCopies from "../../../AdministerCopies/AdministerCopies";
import Article from "../../Article/Article";
import SaveButton from "../../../../components/SaveButton/SaveButton";
import TabErrorIcon from "../../../../components/TabErrorIcon/TabErrorIcon";
import OptionsButton from "../../../../components/OptionsButton/OptionsButton";
import PhotoCard from "../../../../components/PhotoCard/PhotoCard";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import TitleContainer from "../../../../components/TitleContainer/TitleContainer";
import TabContainer from "../../../../components/TabContainer/TabContainer";
import TabMainRow from "../../../../components/TabMainRow/TabMainRow";
import {
  Tabs,
  Tab,
  Alert,
  Col,
  Badge,
  Button,
  Modal,
  Accordion,
  Card,
} from "react-bootstrap";
import saveBeforeExitAlert from "../../../../utilities/saveBeforeExitAlert";
import swal from "sweetalert";
import { Prompt } from "react-router-dom";
import ArticlesTable from "../../../../components/ArticlesTable/ArticlesTable";
import Spinner from "../../../../components/Spinner/Spinner";
import SEOTab from "../../../SEOTab/SEOTab";
import SEMTab from "../../../Sem/SemTab/SemTab";
import classes from "./Product.module.css";
import handleApiErrors from "../../../../utilities/handleApiErrors";
import ButtonSpinner from "../../../../components/ButtonSpinner/ButtonSpinner";
import Category from "../../Category/Category";
import Brand from "../../Brand/Brand";
import Literals from "../../Literals/Literals";
import ProductAttributesTab from "../../../ProductAttributesTab/ProductAttributesTab";
import ProductMainDetails from "../ProductMainDetails/ProductMainDetails";
import ProductProblems from "../../../ProductProblems/ProductProblems";
import CampaignTab from "../../../../components/CampaignTab/CampaignTab.tsx";
import ReactTooltip from "react-tooltip";
import solveErrorMessage from "../../../../utilities/notifications/solveErrorMessage";
import displayNotification from "../../../../utilities/displayNotification";
import syncPrices from "../../../../utilities/syncPrices";

import useCategories from "./hooks/use-categories";
import useAttributes from "./hooks/use-attributes";
import useProduct from "./hooks/use-product";
import usePhotos from "./hooks/use-photos";

import UploadVideos from "./UploadVideos/UploadVideos";
import DisplayMorePhotosButton from "../../../../components/DisplayMorePhotosButton/DisplayMorePhotosButton";

import CreateCategory from "../../../Create/CreateCategory/CreateCategory";
import CreateBrand from "../../../Create/CreateBrand/CreateBrand";

const LOADING = "LOADING";
const PROCESSING = "PROCESSING";
const ERROR = "ERROR";

// const processAttributes = () => {}

const photosGroupToShow = 6;

const Product = (props) => {
  // console.log("🚀 ~ file: Product.js ~ line 72 ~ Product ~ props", props.prueba2)

  const accesToken = useSelector((state) => state.user.access);
  const productId = props.modal
    ? Number(props.id)
    : Number(props.match.params.id);

  // REDUX
  const dispatch = useDispatch(); // * ALLOWS TO SET THE VALUE IN ANY PLACE
  const setOrphanNumber = useCallback(
    (orphanNumber) => dispatch({ type: "CHANGE_NUMBER", orphanNumber }),
    [dispatch]
  );

  // * REGULAR EXPRESSIONS
  const regExpURL =
    /^[^~"<+>^.:;\\,%\]_`!\[{|}&€‚ƒ$=…†'‡ˆ‰Š?‹ŒŽ‘’“”•)*#(–—˜™š@›œžŸ¡¢£¥|§¨©ª«¬¯®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ]+$/;
  const [specialCharactersURL, setSpecialCharactersURL] = useState(true);
  const [specialCharactersName, setSpecialCharactersName] = useState(true);
  // !!!!  estados para mandar a la url de prestashop

  const [prestashopURL, setPrestashopURL] = useState(LOADING);
  // !!!!
  // * SEO
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [reWrite, setReWrite] = useState();

  // * LITERALS
  const [literalId, setLiteralId] = useState(LOADING);
  const [singular, setSingular] = useState(LOADING);
  const [plural, setPlural] = useState(LOADING);
  const [showLiterals, setShowLiterals] = useState();
  const [literalMessage, setLiteralMessage] = useState(false);

  const [error, setError] = useState(false);
  const [duplicateName, setDuplicateName] = useState(false);
  const [key, setKey] = useState(0);

  const [id, setId] = useState();
  const [idErp, setIdErp] = useState();
  const [prestashopId, setPrestashopId] = useState();
  const [name, setName] = useState();
  const [active, setActive] = useState();
  const [description, setDescription] = useState();
  const [shortDescription, setShortDescription] = useState();

  const {
    availableAttributes,
    setAvailableAttributes,
    availableAttributesRef,
    attributes,
    setAttributes,
    attributesRef,
    attributesList,
    setAttributesList,
    attributesListRef,
    addedAttributes,
    setAddedAttributes,
    addedAttributesRef,
    informativeAttributes,
    setInformativeAttributes,
    informativeAttributesRef,
    handleEditAttribute,
    handleClickDuplicateAttribute,
    loadTableAttributes,
    loadAttributesList,
    loadAvailableAttributes,
    getAttributesFromDb,
  } = useAttributes({});

  const {
    categories,
    setCategories,
    mainCategory,
    setMainCategory,
    processDbCategories,
    handleChangeMainCategory,
    handleEditCategory,
    setEmpytCategories,
    handleCreateCategory,
  } = useCategories(id);

  const [problems, setProblems] = useState(LOADING);
  const [checkingProblems, setCheckingProblems] = useState(false);

  const [totalPercentatge, seTotalPercentatge] = useState();
  const [tasks, setTasks] = useState([]);
  const [syncProblemsList, setSyncProblemsList] = useState(LOADING);
  const [brand, setBrand] = useState();

  const [characteristics, setCharacteristics] = useState(LOADING);
  const [removeFeatureProcessing, setRemoveFeatureProcessing] = useState();

  const [articles, setArticles] = useState(LOADING);
  const [processingArticles, setProcessingArticles] = useState(false);

  const photoInput = useRef(null);

  const {
    photosToDisplay,
    photos,
    setPhotos,
    numberOfPhotos,
    remainingPhotos,
    handleClickMorePhotos,
    showMorePhotosButton,
  } = usePhotos();

  const [loadingPhotos, setLoadingPhotos] = useState(false);
  const [numberOfVideos, setNumberOfVideos] = useState(0);
  const [loadingPhotosError, setLoadingPhotosError] = useState(false);

  const [saveProductProcessPhase, setSaveProductProcessPhase] = useState();

  const [showSaveError, setShowSaveError] = useState(false);

  const [mustSync, setMustSync] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncError, setSyncError] = useState(false);
  const [correctSync, setCorrectSync] = useState(false);

  const {
    hasChanged,
    setHasChanged,
    onRequest,
    setOnRequest,
    showPrice,
    setShowPrice,
    handleClickShowPrice,
    handleClickUnderRequest,
    isValid,
    validation,
    errorMessages,
  } = useProduct();

  const [hasSaved, setHasSaved] = useState(false);

  const [showCreateCategory, setShowCreateCategory] = useState(false);

  const [solveProblem, setSolveProblem] = useState(false);
  // const [featuresValuesMenu, setFeaturesValuesMenu] = useState(false);
  // const [showChangeMainCategory, setShowChangeMainCategory] = useState(false);
  // const [showAddCategories, setShowAddCategories] = useState(false);
  const [showAddArticles, setShowAddArticles] = useState(false);
  const [showArticle, setShowArticle] = useState(false);
  // const [showAttribute, setShowAttribute] = useState();

  const [showFatherCategory, setShowFatherCategory] = useState();
  // const [showCategoryInfo, setShowCategoryInfo] = useState();
  const [showBrand, setShowBrand] = useState();
  const [showChangeBrand, setShowChangeBrand] = useState(false);
  const [showCategoryInfo, setShowCategoryInfo] = useState(false);
  const [showAddFeatures, setShowAddFeatures] = useState(false);
  const [showFeatureValueEdit, setShowFeatureValueEdit] = useState(false);
  const [showEditFeature, setShowEditFeature] = useState(false);
  const [showChangeLiterals, setShowChangeLiterals] = useState(false);
  const [featuresValuesMenu, setFeaturesValuesMenu] = useState(false);
  const [showChangeMainCategory, setShowChangeMainCategory] = useState(false);
  const [showAddCategories, setShowAddCategories] = useState(false);
  const [showChangeSoloFeatureValue, setShowChangeSoloFeatureValue] =
    useState(false);
  const [showCreateBrand, setShowCreateBrand] = useState(false);

  const [hideFloattingButtons, setHideFloatingButtons] = useState(false);

  const [exitPath, setExitPath] = useState(false);

  // const [
  //   showChangeSoloFeatureValue,
  //   setShowChangeSoloFeatureValue,
  // ] = useState();
  // const [showChangeBrand, setShowChangeBrand] = useState();
  // const [showAddFeatures, setShowAddFeatures] = useState(false);
  // const [showFeatureValueEdit, setShowFeatureValueEdit] = useState(false);
  // const [showEditFeature, setShowEditFeature] = useState(false);

  useEffect(() => {
    if (exitPath) {
      props.history.push(exitPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exitPath]);

  useEffect(() => {
    const saveTimer = setTimeout(async () => {
      if (
        isValid &&
        !isSaving &&
        hasChanged &&
        !duplicateName &&
        !(
          name.length <= 0 ||
          active === undefined ||
          description.length <= 0 ||
          description.length > 50000 ||
          shortDescription.length <= 0 ||
          shortDescription.length > 400 ||
          metaDescription.length <= 0 ||
          metaTitle.length <= 0 ||
          metaDescription.length > 300 ||
          metaTitle.length > 70 ||
          !specialCharactersName ||
          !specialCharactersURL ||
          categories.length <= 0 ||
          singular.length <= 0 ||
          plural.length <= 0 ||
          !literalId
        )
      ) {
        try {
          await saveProduct();
        } catch (e) {}
      }
    }, 10000);

    return () => clearTimeout(saveTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasChanged,
    isSaving,
    id,
    name,
    description,
    shortDescription,
    brand,
    categories,
    active,
    reWrite,
    showPrice,
    onRequest,
    metaTitle,
    metaDescription,
    availableAttributes,
    attributes,
    singular,
    plural,
    literalId,
    specialCharactersName,
    specialCharactersURL,
    isValid,
  ]);

  useEffect(() => {
    if (hasChanged) {
      window.onbeforeunload = () => {
        saveBeforeExitAlert().then(async (value) => {
          switch (value) {
            case "save":
              try {
                await saveProduct();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "saveSync":
              try {
                await saveProduct();
                await syncProduct();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "exit":
              swal.close();
              break;

            default:
              break;
          }
        });

        return true;
      };
    } else {
      window.onbeforeunload = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanged]);

  const notificationErrorMessage = ({ title, key }) => {
    solveErrorMessage({
      title,
      onClickSolve: () => {
        setKey(key);
        if (window.screen.width < 500) {
          document.getElementById(`accordion-card-${key}`).scrollIntoView();
        }
      },
    });
  };

  // Save atributes
  const saveAttributes = async () => {
    try {
      // console.log(
      //   "🚀 ~ file: Product.js ~ line 366 ~ saveAttributes ~ attributes",
      //   attributes
      // );

      await apiProducts.saveAtributeFilters(accesToken, {
        productId: Number(productId),
        attributeIds: attributes.map((item) => item.id),
      });
      // !!!! salva los atributos de attributes que no se actualiza cuando remueves uno de articulo, arreglado en article seteando el estado de attributes con el updated attributes
      const firstArticle = articles[0];
      if (firstArticle) {
        for (const attribute of addedAttributes) {
          await apiArticles.addAttribute(
            accesToken,
            firstArticle.id,
            attribute.id
          );
        }
        await apiArticles.checkAttribute(accesToken, { productId });

        syncPrices(accesToken, { productId });
      }

      setAddedAttributes([]);
    } catch (error) {
      throw new Error(error);
    }
  };

  // Save product
  const saveProduct = async () => {
    let hasErrors = false;

    if (!isValid) {
      hasErrors = true;
    }

    if (name.length <= 0) {
      hasErrors = true;

      notificationErrorMessage({
        title: "No se puede guardar un producto sin nombre",
        key: 0,
      });
    }
    if (active === undefined) {
      hasErrors = true;

      notificationErrorMessage({
        title:
          "No se puede guardar un producto si el campo activo está sin seleccionar",
        key: 0,
      });
    }

    if (description.length <= 0 || description.length > 50000) {
      hasErrors = true;

      notificationErrorMessage({
        title:
          "La descripción es necesaria y debe tener menos de 50000 caracteres",
        key: 0,
      });
    }

    if (shortDescription.length <= 0 || shortDescription.length > 400) {
      hasErrors = true;

      notificationErrorMessage({
        title:
          "La descripción corta es necesaria y debe tener menos de 400 caracteres",
        key: 0,
      });
    }

    if (metaTitle.length <= 0 || metaTitle.length > 70) {
      hasErrors = true;

      notificationErrorMessage({
        title: "El metatitle de entre 1 y 70 caracteres es necesario",
        key: 4,
      });
    }

    if (metaDescription.length <= 0 || metaDescription.length > 300) {
      hasErrors = true;

      notificationErrorMessage({
        title:
          "La metadescription es necesaria y no puede tener más de 300 caracteres",
        key: 4,
      });
    }

    if (!specialCharactersName && name.length > 0) {
      hasErrors = true;

      notificationErrorMessage({
        title: "El nombre no puede poseer caracteres especiales",
        key: 0,
      });
    }
    if (reWrite.length <= 0) {
      hasErrors = true;

      notificationErrorMessage({
        title: "No se puede guardar un producto sin URL",
        key: 4,
      });
    }
    if (!specialCharactersURL && reWrite.length > 0) {
      hasErrors = true;

      notificationErrorMessage({
        title: "La URL no puede poseer caracteres especiales",
        key: 4,
      });
    }
    if (categories.length <= 0) {
      hasErrors = true;

      notificationErrorMessage({
        title: "Debe haber al menos una categoría para guardar el producto",
        key: 0,
      });
    }

    if (singular.length <= 0 || plural.length <= 0) {
      hasErrors = true;

      notificationErrorMessage({
        title: "Debe seleccionar un literal",
        key: 0,
      });
    }

    if (hasErrors) {
      setError(true);
      setShowSaveError(true);
      setSaveProductProcessPhase(ERROR);

      throw new Error();
    }

    setIsSaving(true);

    try {
      let createdLiteralid = literalId;

      if (!literalId) {
        await apiLiterals
          .newLiteral(accesToken, singular, plural)
          .then((response) => {
            createdLiteralid = response.data.id;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        await apiLiterals.editLiteral(accesToken, literalId, singular, plural);
      }

      await apiProducts.edit(accesToken, {
        id,
        name,
        description,
        shortDescription,
        active,
        reWrite,
        showPrice,
        onRequest,
        brandId: brand.id,
        mainCategoryId: mainCategory.id,
        metaTitle: metaTitle.substring(0, 71),
        metaDescription: metaDescription.substring(0, 300),
        literalId: createdLiteralid,
      });

      await saveAttributes();

      getProblemsFromApi();

      setMustSync(true);
      setHasChanged(false);
      setIsSaving(false);
      setHasSaved(true);
      setShowSaveError(false);

      return true;
    } catch (e) {
      if (e.response.status === 409) {
        setSaveProductProcessPhase(ERROR);
        setShowSaveError(true);
        setDuplicateName(true);
        setIsSaving(false);

        // TODO I put this logic here to show the error when first trying to save instead at the second time
        hasErrors = true;
        notificationErrorMessage({
          title: "El nombre está duplicado, introduzca uno que no exista ya",
          key: 0,
        });
      }
      return false;
    }
  };

  const editArticle = (articleId) => {
    saveProduct();
    setShowArticle(articleId);
  };

  const checkSyncProblems = async () => {
    setCheckingProblems(true);

    try {
      const responseSyncErrors = await api.entriesNoSync(accesToken, {
        productId,
        type: ["all"],
      });

      const response = await apiProducts.getTasks(accesToken, { productId });
      const tasks = response.data.tasks;

      const syncProblems = responseSyncErrors.data;
      const syncProblemsList = [];

      for (const key in syncProblems.data) {
        const problem = {
          ...syncProblems.data[key],
          values: [],
          identifier: key,
        };

        if (syncProblems.needSync[key]) {
          problem.values = [...problem.values, ...syncProblems.needSync[key]];
        }

        if (syncProblems.syncError[key]) {
          problem.values = [...problem.values, ...syncProblems.syncError[key]];
        }

        const problemProcess = tasks.find(
          (tsk) => tsk.frontIdentifier === problem.identifier
        );

        if (problemProcess) {
          problem.processing = Number(problemProcess.percent);
        } else {
          problem.processing = false;
        }

        syncProblemsList.push(problem);
      }

      setCheckingProblems(false);
      setSyncProblemsList(syncProblemsList);

      if (syncProblemsList.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setCheckingProblems(false);
    }
  };

  const syncProduct = async () => {
    try {
      setIsSyncing(true);

      const hasSavedSuccesFully = await saveProduct();

      if (!hasSavedSuccesFully) {
        throw new Error("SAVE_ERROR");
      }

      await apiProducts.sync(accesToken, {
        productId: id,
      });

      const checkResponse = await checkSync.checkProductSync(accesToken, {
        productId: id,
      });

      if (checkResponse) {
        setMustSync(false);
      } else {
        setMustSync(true);
      }

      await checkSyncProblems();

      setIsSyncing(false);
      setSyncError(false);
      setCorrectSync(true);
    } catch (error) {
      console.log(error);
      displayNotification({
        title: "Ha habido un error en la sync",
        message:
          "Ha ocurrido un error en la sincronización, prueba otra vez dentro de unos minutos, si el problema persiste ponte en contacto con el servicio técnico",
        type: "danger",
      });

      setSyncError(true);
      setIsSyncing(false);
    }
  };

  const getPhotosFromApi = async () => {
    try {
      const response = await apiProducts.getPhotos(accesToken, { productId });
      const coverImageId = response.data.product.id_default_image;

      const sourceImages = response.data.product.associations.images;

      if (sourceImages) {
        const photos = sourceImages.map((photo) => ({
          id: photo.id,
          src: photo.large_default,
          cover: photo.id === coverImageId,
          arrayImages: photo.arrayImages,
        }));

        setPhotos(photos);
      } else {
        setPhotos([]);
      }

      setLoadingPhotosError(false);
    } catch (e) {
      setLoadingPhotosError(true);
      setPhotos([]);
      displayNotification({
        title: "No se ha podido acceder a las fotografías del producto",
        message:
          "El producto debe tener errores que no permiten mostrar las imágenes, corríjalos, guarde el producto y recargue la página",
        type: "danger",
      });
    }
  };

  const getProblemsFromApi = async () => {
    setProblems(LOADING);
    try {
      await apiProducts.checkProblems(accesToken, {
        productId: Number(productId),
      });

      const { data } = await apiProducts.getProblems(accesToken, {
        productId: Number(productId),
      });
      // !!!! aqui tengo que meter en articulo la referencia para poder utilizarla luego ne la lista de problemas
      // console.log(
      //   "🚀 ~ file: Product.js ~ line 726 ~ processedProblems ~ data.attribute_values",
      //   data
      // );

      const processedProblems = data.attribute_values.map((problem) => ({
        article: {
          id: problem.article_id,
          name: problem.article_name ?? problem.description_erp,
          ref: problem.article_ref,
        },
        attribute: {
          id: problem.attribute_id,
          name: problem.attribute_name,
        },
      }));
      setProblems(processedProblems);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    apiProducts
      .filter(accesToken, {
        filtername: "id",
        value: productId,
      })
      .then(async (response) => {
        const product = response.data[0];
        // console.log(
        //   "🚀 ~ file: Product.js ~ line 749 ~ .then ~ product",
        //   product
        // );

        setPrestashopId(product.prestashop_id);

        setBrand({
          id: product.brand,
          name: product.brand__name,
        });
        // !!!!!!! montar estados para mandar a la url de prestashop
        setPrestashopURL(product["product-url"]);
        // !!!
        setId(product.id);
        setIdErp(product.erp_id);
        setName(product.name);
        setDescription(product.description);
        setShortDescription(product.short_description);
        setActive(product.active);
        setOnRequest(product.on_demand);
        setShowPrice(product.see_price);
        // * LITERALS SETTING
        setLiteralId(product.unit_literal__id);
        setSingular(product.unit_literal__singular ?? "");
        setPlural(product.unit_literal__plural ?? "");
        // * SEO SETTING
        setReWrite(product.url_rewrite ?? "");
        setMetaTitle(product.meta_title ?? "");

        setMetaDescription(product.meta_description ?? "");

        loadTableAttributes(product.table_attributes);
        loadAttributesList(product.article_attributes);
        loadAvailableAttributes(product.not_in_table_attributes);

        const processedInformativeAttributes = product.info_attributes.map(
          (item) => ({
            id: item.id,
            name: item.name,
          })
        );
        setInformativeAttributes(processedInformativeAttributes);

        const processedArticles = product.articles.map((article) => ({
          id: article.id,
          reference: article.ref,
          description: article.description ?? article.description_erp,
          active: article.active,
          activeSales: article.active_sales,
        }));
        setArticles(processedArticles);

        processDbCategories(product);

        const updatedCharacteristics = product.features.map((feature) => {
          return {
            id: feature.id,
            name: feature.name,
            values: feature.feature_values.map((value) => {
              return {
                id: value.id,
                name: value.value,
              };
            }),
          };
        });

        setCharacteristics(updatedCharacteristics);

        try {
          const responseSyncErrors = await api.entriesNoSync(accesToken, {
            productId,
            type: ["all"],
          });

          const syncProblems = responseSyncErrors.data;
          const syncProblemsList = [];

          for (const key in syncProblems.data) {
            const problem = {
              ...syncProblems.data[key],
              values: [],
              identifier: key,
            };

            if (syncProblems.needSync[key]) {
              problem.values = [
                ...problem.values,
                ...syncProblems.needSync[key],
              ];
            }

            if (syncProblems.syncError[key]) {
              problem.values = [
                ...problem.values,
                ...syncProblems.syncError[key],
              ];
            }

            const problemProcess = product.tasks.find(
              (tsk) => tsk.frontIdentifier === problem.identifier
            );

            if (problemProcess) {
              problem.processing = Number(problemProcess.percent);
            } else {
              problem.processing = false;
            }

            syncProblemsList.push(problem);
          }

          setSyncProblemsList(syncProblemsList);
        } catch (error) {}
      })
      .catch((e) => {
        console.log(e);
        handleApiErrors(e);
      });

    (async () => {
      try {
        const firstCheckSync = await apiProducts.checkSync(accesToken, {
          productId,
        });

        if (firstCheckSync.data.product[0].sync) {
          setMustSync(false);
          setCorrectSync(true);

          return;
        }

        if (firstCheckSync.data.product[0].synchronizing) {
          setIsSyncing(true);
        }

        const checkResponse = await checkSync.checkProductSync(accesToken, {
          productId,
        });

        if (checkResponse) {
          setMustSync(false);
          setCorrectSync(true);
        } else {
          setMustSync(true);
        }

        setSyncError(false);
        setIsSyncing(false);
      } catch (error) {
        setSyncError(true);
        setIsSyncing(false);
      }
    })();

    getPhotosFromApi();
    getProblemsFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeArticle = async (id) => {
    // * REDUX VALUE SETTING
    await apiArticles
      .getOrphansLength(accesToken)
      .then(({ data }) => {
        setOrphanNumber(data.length);
      })
      .catch((e) => {
        handleApiErrors(e);
      });
    const updatedArticles = articles.map((article) => {
      if (article.id === id) {
        article.processing = true;
      }

      return article;
    });

    setProcessingArticles(true);
    setArticles(updatedArticles);

    await apiArticles.orphanize(accesToken, { articleIds: [id] });
    syncPrices(accesToken, { productId });

    const reupdatedArticles = articles.filter((article) => article.id !== id);

    setProcessingArticles(false);
    setArticles(reupdatedArticles);
  };

  const selectToUnlink = (id) => {
    const updatedArticles = articles.map((art) => {
      if (art.id === id) {
        return {
          ...art,
          remove: !art.remove,
        };
      }

      return art;
    });

    setArticles(updatedArticles);
  };

  const clickSelectAll = () => {
    const allAreSelected = !articles.find((article) => !article.remove);

    if (allAreSelected) {
      const updatedArticles = articles.map((article) => {
        return {
          ...article,
          remove: false,
        };
      });

      setArticles(updatedArticles);
    } else {
      const updatedArticles = articles.map((article) => {
        return {
          ...article,
          remove: true,
        };
      });

      setArticles(updatedArticles);
    }
  };

  const unlinkArticles = async () => {
    setProcessingArticles(true);

    try {
      const articlesToUnlink = articles.filter((article) => article.remove);

      await apiArticles.orphanize(accesToken, {
        articleIds: articlesToUnlink.map((item) => item.id),
      });

      const updatedArticles = articles.filter((article) => !article.remove);
      setArticles(updatedArticles);
      setProcessingArticles(false);

      getProblemsFromApi();
      checkSyncProblems();

      // * REDUX VALUE SETTING
      await apiArticles
        .getOrphansLength(accesToken)
        .then(({ data }) => {
          setOrphanNumber(data.length);
        })
        .catch((e) => {
          handleApiErrors(e);
        });

      syncPrices(accesToken, { productId });
    } catch (e) {
      handleApiErrors(e);
    }
  };

  const makeCover = (id) => {
    const updatedPhotos = photos.map((photo) => {
      if (photo.id === id) {
        return { ...photo, loading: true };
      }

      return photo;
    });

    setLoadingPhotos(true);
    setPhotos(updatedPhotos);

    apiProducts
      .makeCover(accesToken, {
        productId,
        photoId: id,
      })
      .then(() => {
        const updatedPhotos = photos.map((photo) => {
          delete photo["cover"];

          if (photo.id === id) {
            delete photo["loading"];
            return { ...photo, cover: true };
          }

          return photo;
        });

        setLoadingPhotos(false);
        setPhotos(updatedPhotos);
      })
      .catch((e) => {
        handleApiErrors(e);
      });
  };

  const removePhoto = (id) => {
    const updatedPhotos = photos.map((photo) => {
      if (photo.id === id) {
        return { ...photo, loading: true };
      }

      return photo;
    });

    apiProducts
      .removePhoto(accesToken, { images: [id], productId })
      .then(() => {
        let updatedPhotos = photos.filter((photo) => photo.id !== id);
        updatedPhotos = updatedPhotos.map((photo) => {
          return photo;
        });

        setLoadingPhotos(false);
        setPhotos(updatedPhotos);
      })
      .catch((e) => {
        handleApiErrors(e);
      });

    setLoadingPhotos(true);
    setPhotos(updatedPhotos);
  };

  const controlSyncingAndupdateValues = async (identifier) => {
    while (true) {
      const result = await apiProducts.getTasks(accesToken, { productId });

      const taskGroups = result.data.taskGroups;
      let totalPercent = undefined;
      if (taskGroups.null) {
        totalPercent = Number(taskGroups.null.percent);
      }

      const tasks = result.data.tasks;

      const problemTask = tasks.find(
        (tsk) => tsk.frontIdentifier === identifier
      );

      if (!problemTask) {
        const { data } = await api.entriesNoSync(accesToken, {
          productId,
          type: [identifier],
        });

        setSyncProblemsList((problemsList) => {
          const updatedProblemsList = problemsList.map((problem) => {
            if (problem.identifier === identifier) {
              if (
                Object.keys(data.needSync).includes(identifier) ||
                Object.keys(data.syncError).includes(identifier)
              ) {
                problem.values = [];

                if (data.needSync[identifier]) {
                  problem.values = [...data.needSync[identifier]];
                }

                if (data.syncError[identifier]) {
                  problem.values = [
                    ...problem.values,
                    ...data.syncError[identifier],
                  ];
                }
              }

              problem.processing = false;
            }

            return problem;
          });

          return updatedProblemsList;
        });
        seTotalPercentatge(totalPercent);

        break;
      } else {
        setSyncProblemsList((problemsList) => {
          const updatedProblemsList = problemsList.map((problem) => {
            if (problem.identifier === identifier) {
              return {
                ...problem,
                processing: Number(problemTask.percent),
              };
            }

            return problem;
          });

          return updatedProblemsList;
        });
        seTotalPercentatge(totalPercent);
      }

      await later(3000);
    }
  };

  const onClickSyncProblems = async (identifier) => {
    setSyncProblemsList((problemsList) => {
      const updatedProblemsList = problemsList.map((problem) => {
        if (problem.identifier === identifier) {
          return {
            ...problem,
            processing: 0,
          };
        }

        return problem;
      });

      return updatedProblemsList;
    });

    try {
      api.entriesNoSync(accesToken, {
        productId,
        type: [identifier],
        sync: true,
      });

      controlSyncingAndupdateValues(identifier);
    } catch (e) {
      setSyncProblemsList((problemsList) => {
        const updatedProblemsList = problemsList.map((problem) => {
          if (problem.identifier === identifier) {
            return {
              ...problem,
              processing: false,
            };
          }

          return problem;
        });

        return updatedProblemsList;
      });
    }
  };

  const onClickSyncAllProblems = async () => {
    try {
      const identifiers = [];

      syncProblemsList.forEach((problem) => {
        if (problem.values.length > 0) {
          identifiers.push(problem.identifier);
        }
      });

      await Promise.allSettled(
        identifiers.map((identifier) => onClickSyncProblems(identifier))
      );

      await checkSyncProblems();
    } catch (error) {
      console.log(error);
    }
  };

  if (
    name === undefined ||
    description === undefined ||
    shortDescription === undefined ||
    mainCategory === undefined ||
    reWrite === undefined
  ) {
    if (props.modal) {
      return (
        <Modal
          show={true}
          className={`bigModal ${classes.modifyModal}`}
          size="lg"
          onHide={() => {
            console.log("Whololololo!");
            console.log(characteristics);

            if (hasSaved) {
              props.beforeClose({
                id,
                name,
                sync: correctSync || !mustSync,
                active,
                characteristics,
              });
            }

            props.onHide();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className={classes.modifyBottomModal}>
            <Spinner />
          </Modal.Body>
        </Modal>
      );
    } else {
      return <Spinner />;
    }
  }

  let photosContent;
  if (photos === LOADING) {
    photosContent = <Spinner />;
  } else if (photos.length > 0) {
    photosContent = (
      <>
        <div className="d-flex flex-wrap">
          {photosToDisplay.map((photo) => (
            <PhotoCard
              key={photo.id}
              active={photo.cover}
              arrayImages={photo.arrayImages}
              src={photo.src}
              makeCover={() => makeCover(photo.id)}
              remove={() => removePhoto(photo.id)}
              loading={photo.loading}
              disabled={loadingPhotos && !photo.loading}
            />
          ))}

          {showMorePhotosButton ? (
            <DisplayMorePhotosButton
              handleClickMorePhotos={handleClickMorePhotos}
              remainingPhotos={remainingPhotos}
            />
          ) : undefined}
        </div>
      </>
    );
  } else {
    photosContent = (
      <Alert variant="warning">
        El producto no tiene fotos, recuerde subir alguna
      </Alert>
    );
  }

  const articlesTabTitle = (
    <>
      Artículos
      <Badge
        className="ml-2"
        pill
        variant={articles.length === 0 ? "danger" : "primary"}
      >
        {articles.length === 0 ? 0 : articles.length}
      </Badge>
    </>
  );

  let allAreSelected;
  if (Array.isArray(articles)) {
    allAreSelected = !articles.find((article) => !article.remove);
  }

  const handleSyncAllArticles = async () => {
    setProcessingArticles(true);

    try {
      await apiArticles.syncAll(accesToken, { productId });
      await syncProduct();

      syncPrices(accesToken, { productId });
    } catch (e) {
      console.log(e);
    }

    setProcessingArticles(false);
  };

  let articlesContentTab;
  if (articles === LOADING) {
    articlesContentTab = <Spinner />;
  } else {
    articlesContentTab = (
      <TabContainer>
        <TabMainRow>
          <Col md={12}>
            <TitleContainer>
              <h3>Artículos</h3>
              <div>
                <OptionsButton
                  arrowRepeat={() => {}}
                  click={handleSyncAllArticles}
                  disabled={
                    saveProductProcessPhase === PROCESSING || processingArticles
                  }
                  loading={processingArticles}
                >
                  Sincronizar todo
                </OptionsButton>
                {articles.find((item) => item.remove) ? (
                  <OptionsButton
                    variant="danger"
                    x={() => {}}
                    click={unlinkArticles}
                    disabled={
                      saveProductProcessPhase === PROCESSING ||
                      processingArticles
                    }
                    loading={processingArticles}
                  >
                    Desvincular artículos
                  </OptionsButton>
                ) : undefined}
                <OptionsButton
                  variant="success"
                  plus={() => {}}
                  click={() => {
                    setShowAddArticles(true);
                  }}
                  disabled={
                    saveProductProcessPhase === PROCESSING || processingArticles
                  }
                  loading={processingArticles}
                >
                  Añadir artículos
                </OptionsButton>
                {Array.isArray(articles) ? (
                  <OptionsButton
                    variant="primary"
                    full={!allAreSelected ?? clickSelectAll}
                    fullExit={allAreSelected ?? clickSelectAll}
                    click={clickSelectAll}
                    disabled={
                      saveProductProcessPhase === PROCESSING ||
                      processingArticles
                    }
                    loading={processingArticles}
                  >
                    {allAreSelected ? "Deseleccionar todo" : "Seleccionar todo"}
                  </OptionsButton>
                ) : undefined}
              </div>
            </TitleContainer>
            {articles.length > 0 ? (
              <ArticlesTable
                productId={productId}
                x={removeArticle}
                articles={articles}
                clickEditArticle={editArticle}
                processing={processingArticles}
                onClickRemoveArticle={selectToUnlink}
              />
            ) : (
              <Alert variant="warning">
                No hay artículos creados, recuerde adjuntar alguno antes de
                guardar
              </Alert>
            )}
          </Col>
        </TabMainRow>
      </TabContainer>
    );
  }

  const editCategory = (categoryId) => {
    setShowFatherCategory(categoryId);
  };

  const productInfoTab = (
    <ProductMainDetails
      id={id}
      productId={productId}
      setHideFloatingButtons={setHideFloatingButtons}
      idErp={idErp}
      setHasChanged={setHasChanged}
      saveProductProcessPhase={saveProductProcessPhase}
      name={name}
      setName={setName}
      active={active}
      setActive={setActive}
      duplicateName={duplicateName}
      setDuplicateName={setDuplicateName}
      showPrice={showPrice}
      setShowPrice={setShowPrice}
      onRequest={onRequest}
      setOnRequest={setOnRequest}
      singular={singular}
      setSingular={setSingular}
      plural={plural}
      setPlural={setPlural}
      setShowLiterals={setShowLiterals}
      setShowBrand={setShowBrand}
      brand={brand}
      setBrand={setBrand}
      literalId={literalId}
      setLiteralId={setLiteralId}
      description={description}
      setDescription={setDescription}
      shortDescription={shortDescription}
      setShortDescription={setShortDescription}
      editCategory={editCategory}
      mainCategory={mainCategory}
      setMainCategory={setMainCategory}
      characteristics={characteristics}
      setCharacteristics={setCharacteristics}
      categories={categories}
      setCategories={setCategories}
      specialCharactersName={specialCharactersName}
      setSpecialCharactersName={setSpecialCharactersName}
      removeFeatureProcessing={removeFeatureProcessing}
      setRemoveFeatureProcessing={setRemoveFeatureProcessing}
      literalMessage={literalMessage}
      setLiteralMessage={setLiteralMessage}
      showChangeBrand={showChangeBrand}
      setShowChangeBrand={setShowChangeBrand}
      showCategoryInfo={showCategoryInfo}
      setShowCategoryInfo={setShowCategoryInfo}
      showAddFeatures={showAddFeatures}
      setShowAddFeatures={setShowAddFeatures}
      showFeatureValueEdit={showFeatureValueEdit}
      setShowFeatureValueEdit={setShowFeatureValueEdit}
      showEditFeature={showEditFeature}
      setShowEditFeature={setShowEditFeature}
      showChangeLiterals={showChangeLiterals}
      setShowChangeLiterals={setShowChangeLiterals}
      featuresValuesMenu={featuresValuesMenu}
      setFeaturesValuesMenu={setFeaturesValuesMenu}
      showChangeMainCategory={showChangeMainCategory}
      setShowChangeMainCategory={setShowChangeMainCategory}
      showAddCategories={showAddCategories}
      setShowAddCategories={setShowAddCategories}
      showChangeSoloFeatureValue={showChangeSoloFeatureValue}
      setShowChangeSoloFeatureValue={setShowChangeSoloFeatureValue}
      handleChangeMainCategory={handleChangeMainCategory}
      handleClickShowPrice={handleClickShowPrice}
      handleClickUnderRequest={handleClickUnderRequest}
      validation={validation}
      errorMessages={errorMessages}
      handleEditCategory={handleEditCategory}
      handleCreateCategory={handleCreateCategory}
      handleOpenCreateCategory={() => setShowCreateCategory(true)}
      handleOpenCreateBrand={() => setShowCreateBrand(true)}
    />
  );

  let totalProblemsNumber = problems.length;
  if (syncProblemsList !== LOADING) {
    syncProblemsList.forEach((problem) => {
      totalProblemsNumber += problem.values.length;
    });
  }

  const hasPhotos = photos !== LOADING && photos.length > 0;

  // Change tab
  const changeTab = async (k) => {
    setKey(Number(k));

    if (key === 6 && k !== 6) {
      await saveAttributes();
    }
  };

  // TABS TITLES AND CONTENT

  const problemsTabTitle = (
    <>
      Problemas
      <Badge className="ml-2" pill variant="danger">
        {problems === LOADING || totalProblemsNumber === 0
          ? null
          : totalProblemsNumber}
      </Badge>
    </>
  );
  const problemsTabContent = (
    <ProductProblems
      productId={productId}
      tasks={tasks}
      setTasks={setTasks}
      productId={productId}
      problems={problems}
      setProblems={setProblems}
      syncProblemsList={syncProblemsList}
      setSyncProblemsList={setSyncProblemsList}
      articles={articles}
      getProblemsFromApi={getProblemsFromApi}
      solveProblem={solveProblem}
      setSolveProblem={setSolveProblem}
      setHideFloatingButtons={setHideFloatingButtons}
      onClickSyncProblems={onClickSyncProblems}
      onClickSyncAllProblems={onClickSyncAllProblems}
      setKey={setKey}
      hasPhotos={hasPhotos}
      checkingProblems={checkingProblems}
      setCheckingProblems={setCheckingProblems}
      checkSyncProblems={checkSyncProblems}
      totalPercentatge={totalPercentatge}
      seTotalPercentatge={seTotalPercentatge}
    />
  );

  const isLoadingPhotos = photos === LOADING;

  const photosTitle = (
    <>
      <div>
        Fotos
        {!isLoadingPhotos ? (
          <Badge
            className="ml-2"
            pill
            variant={numberOfPhotos === 0 ? "danger" : "primary"}
          >
            {numberOfPhotos}
          </Badge>
        ) : undefined}
      </div>
      <div>
        Videos
        <Badge
          className="ml-2"
          pill
          variant={numberOfVideos === 0 ? "danger" : "primary"}
        >
          {numberOfVideos}
        </Badge>
      </div>
    </>
  );

  let administerPhotos = (
    <>
      <div className="d-flex justify-content-end p-3">
        <div>
          <input
            type="file"
            id="file"
            ref={photoInput}
            multiple
            onChange={async (response) => {
              const files = [...response.target.files];

              try {
                await Promise.all(
                  files.map((file) => {
                    const data = new FormData();
                    data.append("image", file);
                    data.append("productId", productId);

                    setLoadingPhotos(true);

                    return apiProducts.addPhoto(accesToken, data);
                  })
                );

                await getPhotosFromApi();

                setLoadingPhotos(false);
              } catch (e) {
                handleApiErrors(e);
              }
            }}
            style={{ display: "none" }}
          />
          <OptionsButton
            upload
            disabled={saveProductProcessPhase === PROCESSING || loadingPhotos}
            loading={loadingPhotos}
            click={() => photoInput.current.click()}
          >
            Subir fotos(s)
          </OptionsButton>
          <OptionsButton
            trash
            loading={loadingPhotos}
            disabled={saveProductProcessPhase === PROCESSING || loadingPhotos}
            click={() => {
              setLoadingPhotos(true);

              apiProducts
                .removeDuplicatedPhotos(accesToken, { productId })
                .then(() => getPhotosFromApi())
                .then(() => setLoadingPhotos(false));
            }}
          >
            Eliminar duplicados
          </OptionsButton>
        </div>
      </div>
      {photosContent}
    </>
  );

  if (loadingPhotosError) {
    administerPhotos = (
      <Alert variant="danger">
        Ha ocurrido un error cargando las imágenes, es posible que sea debido a
        que el producto contiene errores. Corríjalos y recargue la página.
      </Alert>
    );
  }

  const mediaContentTab = (
    <TabContainer>
      <TabMainRow>
        <Col className={classes.marginBottom}>
          {administerPhotos}
          <UploadVideos
            productId={productId}
            handleUpdateVideosNumber={(val) => {
              setNumberOfVideos(val);
            }}
          />
        </Col>
      </TabMainRow>
    </TabContainer>
  );

  const atributesTitle = (
    <>
      Atributos
      <Badge
        className="ml-2"
        pill
        variant={attributes.length === 0 ? "danger" : "primary"}
      >
        {attributesList.length === 0 ? 0 : attributesList.length}
      </Badge>
    </>
  );
  const atributesContentTab = (
    <ProductAttributesTab
      isSaving={saveProductProcessPhase === PROCESSING}
      productId={productId}
      availableAttributes={availableAttributes}
      attributes={attributes}
      setAttributes={setAttributes}
      attributesRef={attributesRef}
      setHasChanged={setHasChanged}
      attributesList={attributesList}
      setAttributesList={setAttributesList}
      attributesListRef={attributesListRef}
      setAvailableAttributes={setAvailableAttributes}
      availableAttributesRef={availableAttributesRef}
      addedAttributes={addedAttributes}
      setAddedAttributes={setAddedAttributes}
      addedAttributesRef={addedAttributesRef}
      informativeAttributes={informativeAttributes}
      setInformativeAttributes={setInformativeAttributes}
      informativeAttributesRef={informativeAttributesRef}
      setHideFloatingButtons={setHideFloatingButtons}
      areLinkedArticles={articles.length > 0}
      clickAddArticles={() => {
        setKey(3);
        setShowAddArticles(true);
      }}
      handleEditAttribute={handleEditAttribute}
      handleClickDuplicateAttributeToInfo={handleClickDuplicateAttribute}
    />
  );

  // TODO Create title for cell tabs
  const productInfoTabTitle = (
    <>
      {duplicateName ||
      (error &&
        (name.length <= 0 ||
          !specialCharactersName ||
          description.length <= 0 ||
          description.length > 50000 ||
          shortDescription.length <= 0 ||
          shortDescription.length > 400 ||
          singular.length <= 0 ||
          plural <= 0)) ? (
        <TabErrorIcon />
      ) : undefined}
      Producto
    </>
  );
  const seoTabTitle = (
    <>
      {error &&
      (reWrite.length <= 0 ||
        !specialCharactersURL ||
        metaTitle.length <= 0 ||
        metaDescription.length <= 0 ||
        metaTitle.length >= 70 ||
        metaDescription.length > 300) ? (
        <TabErrorIcon />
      ) : undefined}
      SEO
    </>
  );

  const seoTabContent = (
    <SEOTab
      // Nuevos props
      reWrite={reWrite}
      setSpecialCharactersURL={setSpecialCharactersURL}
      regExpURL={regExpURL}
      setReWrite={setReWrite}
      setHasChanged={setHasChanged}
      specialCharactersURL={specialCharactersURL}
      specialCharactersName={specialCharactersName}
      name={name}
      // Fin de nuevos props
      metaTitle={metaTitle}
      setMetaTitle={(newValue) => {
        setMetaTitle(newValue);
        setHasChanged(true);
      }}
      metaDescription={metaDescription}
      setMetaDescription={(newValue) => {
        setMetaDescription(newValue);
        setHasChanged(true);
      }}
      error={saveProductProcessPhase === ERROR}
    />
  );
  //
  // !!!!! dario
  // const campaignTabTitle = <>SEM</>;
  // const campaignTabContent = <CampaignTab />;
  const semTabTitle = <>SEM</>;
  const semTabContent = (
    <SEMTab productId={id} eventKey={String(key)} setKey={setKey} />
  );
  // !!!!!

  let content = (
    <>
      <Accordion
        defaultActiveKey="0"
        activeKey={String(key)}
        onSelect={(k) => setKey(k)}
        className={classes.hideAcordionOnPC}
      >
        <Card id="accordion-card-0">
          <Accordion.Toggle
            className={classes.cursorCellTabs}
            as={Card.Header}
            eventKey="0"
          >
            {/* // TODO  Put this that I created in line 1481 */}
            {productInfoTabTitle}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={"0"}>
            <Card.Body>{productInfoTab}</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card id="accordion-card-1">
          <Accordion.Toggle
            className={classes.cursorCellTabs}
            as={Card.Header}
            eventKey="1"
          >
            {articlesTabTitle}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>{articlesContentTab}</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card id="accordion-card-2">
          <Accordion.Toggle
            className={classes.cursorCellTabs}
            as={Card.Header}
            eventKey="2"
          >
            {atributesTitle}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>{atributesContentTab}</Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card id="accordion-card-3">
          <Accordion.Toggle
            className={classes.cursorCellTabs}
            as={Card.Header}
            eventKey="3"
          >
            {photosTitle}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>{mediaContentTab}</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card id="accordion-card-4">
          <Accordion.Toggle
            className={classes.cursorCellTabs}
            as={Card.Header}
            eventKey="4"
          >
            {seoTabTitle}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>{seoTabContent}</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card id="accordion-card-5">
          <Accordion.Toggle
            className={classes.cursorCellTabs}
            as={Card.Header}
            eventKey="5"
          >
            {problemsTabTitle}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body>{problemsTabContent}</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card id="accordion-card-6">
          <Accordion.Toggle
            className={classes.cursorCellTabs}
            as={Card.Header}
            eventKey="6"
          >
            {semTabTitle}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <Card.Body>{semTabContent}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <div className={classes.hideTabsWhenMobile}>
        <Tabs
          className={classes.tabs}
          id="productPhases"
          activeKey={key}
          onSelect={changeTab}
        >
          {/* Datos de producto */}
          <Tab
            eventKey={0}
            title={
              <>
                {duplicateName ||
                (error &&
                  (name.length <= 0 ||
                    !specialCharactersName ||
                    description.length <= 0 ||
                    description.length > 50000 ||
                    shortDescription.length <= 0 ||
                    shortDescription.length > 400 ||
                    singular.length <= 0 ||
                    plural <= 0 ||
                    !validation.showPriceUnderRequest)) ? (
                  <TabErrorIcon />
                ) : undefined}
                <span>Producto</span>
              </>
            }
          >
            {productInfoTab}
          </Tab>

          {/* ARTICULOS */}
          <Tab eventKey={1} title={articlesTabTitle}>
            {articlesContentTab}
          </Tab>
          {/* ATRIBUTOS EN TABLA */}
          <Tab eventKey={2} title={atributesTitle}>
            {atributesContentTab}
          </Tab>
          {/* FOTOS */}
          <Tab eventKey={3} title={photosTitle}>
            {mediaContentTab}
          </Tab>
          {/* SEO */}
          <Tab eventKey={4} title={seoTabTitle}>
            {seoTabContent}
          </Tab>
          {/* PROBLEMAS */}
          <Tab eventKey={5} title={problemsTabTitle}>
            {problemsTabContent}
          </Tab>
          {/* SEM */}
          <Tab eventKey={6} title={semTabTitle}>
            {semTabContent}
          </Tab>
        </Tabs>
      </div>
      {showAddArticles ? (
        <AdministerCopies
          addButtonDescription="hide"
          buttonDescription="hide"
          title="Elegir artículos para el producto"
          addTableTitle="Artìculos a añadir"
          copiesTableTitle="Artìculo original"
          loadItems={() =>
            apiArticles.getOrphans(accesToken).then(({ data }) => {
              const items = data.map((item) => ({
                id: item.id,
                name: String(
                  item.description ?? item.description_erp
                ).toLowerCase(),
              }));

              return { data: items };
            })
          }
          onSave={async (items) => {
            const updatedArticles = [...articles];

            for (let item of items) {
              await apiArticles.assignProduct(accesToken, {
                id: item.id,
                productId: Number(productId),
              });

              const response = await apiArticles.filter(accesToken, {
                filtername: "id",
                value: item.id,
              });

              const article = response.data[0];

              updatedArticles.push({
                id: article.id,
                reference: article.ref,
                description: article.description ?? article.description_erp,
                active: article.active,
                activeSales: article.active_sales,
              });
            }

            syncPrices(accesToken, { productId });

            setArticles(updatedArticles);

            await saveAttributes();

            // Refresh product attributes
            const getProductResponse = await apiProducts.filter(accesToken, {
              filtername: "id",
              value: productId,
            });

            const product = getProductResponse.data[0];

            setAttributesList(
              product.article_attributes.map((attribute) => ({
                id: attribute.id,
                name: attribute.name,
              }))
            );

            const processedAvailableAttributes =
              product.not_in_table_attributes.map((item) => ({
                id: item.id,
                name: item.name,
              }));
            setAvailableAttributes(processedAvailableAttributes);

            const processedInformativeAttributes = product.info_attributes.map(
              (item) => ({
                id: item.id,
                name: item.name,
              })
            );
            setInformativeAttributes(processedInformativeAttributes);

            getProblemsFromApi();
            checkSyncProblems();

            // * REDUX VALUE SETTING
            apiArticles
              .getOrphansLength(accesToken)
              .then(({ data }) => {
                setOrphanNumber(data.length);
              })
              .catch((e) => {
                handleApiErrors(e);
              });
          }}
          close={() => {
            setShowAddArticles(false);
          }}
          onSaved={async () => {
            props.history.push(`/product/${productId}`);
          }}
          applySelection={() => {}}
          selectedItems={[articles.map((article) => article.id)]}
          multiSelect
        />
      ) : undefined}
      {showArticle
        ? (() => {
            const articleId = showArticle;

            return (
              <Article
                // !! prueba para actualizar atributos
                pruebaAtributos={setAttributes}
                modal
                id={articleId}
                onHide={() => setShowArticle(false)}
                beforeClose={({ id, description, active, activeSales }) => {
                  setArticles(
                    articles.map((article) => {
                      if (article.id === id) {
                        return {
                          ...article,
                          description,
                          active,
                          activeSales,
                        };
                      }

                      return article;
                    })
                  );
                }}
                beforeCloseNoSave={() => {
                  (async () => {
                    const response = await apiProducts.filter(accesToken, {
                      filtername: "id",
                      value: productId,
                    });

                    const product = response.data[0];

                    loadTableAttributes(product.table_attributes);
                    loadAttributesList(product.article_attributes);
                    loadAvailableAttributes(product.not_in_table_attributes);
                  })();

                  getProblemsFromApi();
                }}
                afterSync={syncProduct}
              />
            );
          })()
        : undefined}
      {showBrand ? (
        <Brand
          modal
          id={showBrand}
          onHide={() => setShowBrand(false)}
          beforeClose={({ name }) => {
            setBrand({ ...brand, name });
          }}
        />
      ) : undefined}
      {/* Editing literals modal here*/}
      {showLiterals ? (
        <Literals
          modal
          id={showLiterals}
          close={() => setShowLiterals(false)}
          beforeClose={({ singular, plural }) => {
            setHasChanged(true);
            setSingular(singular);
            setPlural(plural);
          }}
        />
      ) : undefined}
      {/* Category modal here*/}
      {showCategoryInfo
        ? (() => {
            const id = showCategoryInfo;

            return (
              <Category
                modal
                id={id}
                onHide={() => {
                  setShowCategoryInfo(false);
                  setHideFloatingButtons(false);
                }}
                beforeClose={(category) => {
                  handleEditCategory(category);
                }}
              />
            );
          })()
        : undefined}
      {showCreateCategory ? (
        <CreateCategory
          modal
          afterClose={(category) => {
            setMainCategory(category);
          }}
          handleClose={() => setShowCreateCategory(false)}
        />
      ) : undefined}
      {showCreateBrand ? (
        <CreateBrand
          modal
          handleFinishCreate={(brand) => setBrand(brand)}
          handleClose={() => setShowCreateBrand(false)}
        />
      ) : undefined}
    </>
  );

  if (props.modal) {
    let syncButtonVariant;
    let textSync;

    if (isSyncing) {
      syncButtonVariant = "warning";
      textSync = "Sincronizando";
    } else if (mustSync) {
      syncButtonVariant = "danger";
      textSync = "Sincronizar";
    } else if (syncError) {
      syncButtonVariant = "danger";
      textSync = "Reintentar sincronización";
    } else {
      syncButtonVariant = "success";
      textSync = "Sincronizado";
    }

    let saveButtonVariant;
    let textSave;

    if (hasChanged) {
      saveButtonVariant = "danger";
      textSave = "Guardar";
    } else {
      saveButtonVariant = "success";
      textSave = "Guardado";
    }

    const modalClass =
      !solveProblem &&
      !showAddArticles &&
      !showArticle &&
      !showFatherCategory &&
      !showBrand &&
      !showChangeBrand &&
      !showCategoryInfo &&
      !showAddFeatures &&
      !showFeatureValueEdit &&
      !showEditFeature &&
      !showChangeLiterals &&
      !featuresValuesMenu &&
      !showChangeMainCategory &&
      !showAddCategories &&
      !showChangeSoloFeatureValue &&
      !showCreateCategory
        ? ""
        : "d-none";

    return (
      <Modal
        show={true}
        className={`bigModal ${modalClass} ${classes.modifyModal}`}
        size="lg"
        onHide={() => {
          if (hasSaved) {
            props.beforeClose({
              id,
              name,
              sync: correctSync && !mustSync,
              characteristics,
            });
          }

          props.onHide();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Editar producto: {name !== LOADING ? name : undefined}
            <Button
              onClick={() => {
                window.open(`/productPreview/${prestashopId}`, "_blank");
              }}
              className="ml-5"
            >
              Previsualización
            </Button>
            {/* !!!! boton para llevar a prestashop */}
            <Button
              onClick={() => {
                window.open(prestashopURL);
              }}
              className="ml-5"
            >
              Ver en prestashop
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer className={classes.modifyBottomModal}>
          <Button variant={syncButtonVariant} onClick={syncProduct}>
            {isSyncing ? (
              <div>
                <ButtonSpinner /> <span className="ml-2">{textSync}</span>
              </div>
            ) : (
              <span>{textSync}</span>
            )}
          </Button>
          <Button
            variant={saveButtonVariant}
            onClick={async () => {
              try {
                await saveProduct();
              } catch (e) {}
            }}
          >
            {isSaving ? <ButtonSpinner /> : textSave}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <Prompt
        when={hasChanged && !exitPath}
        message={({ pathname }) => {
          saveBeforeExitAlert(true).then(async (value) => {
            switch (value) {
              case "save":
                try {
                  await saveProduct();
                  swal.close();
                  props.history.push(pathname);
                } catch (e) {
                  swal.close();
                }
                break;

              case "saveSync":
                try {
                  await saveProduct();
                  await syncProduct();
                  swal.close();
                  props.history.push(pathname);
                } catch (e) {
                  swal.close();
                }
                break;

              case "exit":
                setExitPath(pathname);
                swal.close();
                break;

              default:
                break;
            }
          });

          return false;
        }}
      />
      {key === 6 ? null : (
        <SaveButton
          hidden={hideFloattingButtons}
          correctSync={correctSync}
          mustSave={hasChanged}
          processingSave={isSaving}
          syncError={syncError}
          onClickSave={async () => {
            try {
              await saveProduct();
            } catch (e) {}
          }}
          processingSync={isSyncing}
          mustSync={mustSync}
          onClickSync={syncProduct}
          onClose={() => {
            setShowSaveError(false);
          }}
          error={saveProductProcessPhase === ERROR && showSaveError}
          errorMessage="Hay errores en el formulario,cierre esta ventana, revíselo y corrija los errores antes de guardar"
        />
      )}

      <MainTitle>
        <h1>Producto: {name}</h1>
        <Button
          onClick={() => {
            window.open(`/productPreview/${prestashopId}`, "_blank");
          }}
        >
          Previsualización
        </Button>
        {/* !!!! boton para llevar a prestashop */}
        <Button
          onClick={() => {
            window.open(prestashopURL);
          }}
        >
          Ver en prestashop
        </Button>
      </MainTitle>
      {content}
    </>
  );
};

export default Product;
