import React, { useState } from "react";

import { Modal, Button } from "react-bootstrap";

import DataTitle from "../../../../../components/DataTitle/DataTitle";
import UploadFiles from "../../Product/UploadPdfs/UploadPdfs";

const AdministerPdfsMenu: React.FC<{ productId: number }> = ({ productId }) => {
  const [showPdfsModal, setShowPdfsModal] = useState(false);

  return (
    <>
      <div>
        <DataTitle>Pdfs</DataTitle>
        <Button className="p-2" onClick={() => setShowPdfsModal(true)}>
          Administrar pdfs
        </Button>
      </div>
      <Modal
        show={showPdfsModal}
        onHide={() => setShowPdfsModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Administrar pdfs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UploadFiles productId={productId} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPdfsModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdministerPdfsMenu;
