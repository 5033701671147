import React from "react";
import { Container } from "react-bootstrap";
import PseudoTable from "../PseudoTable/PseudoTable";
import OptionsButton from "../OptionsButton/OptionsButton";
import ButtonSpinner from "../ButtonSpinner/ButtonSpinner";
import SelectRowToDelete from "../SelectRowToDelete/SelectRowToDelete";
import AddButton from "../AddButton/AddButton";
import { X } from "react-bootstrap-icons";
import classes from "./ValuesListList.module.css";

const AttributesTable = (props) => {
  return (
    <PseudoTable.Table>
      <>
        {/* HEADER */}
        <PseudoTable.Header>
          <div className={classes.selectCicle}></div>
          <div className={classes.featureName}>{props.itemTitle}</div>
          <div className={classes.addValuesButton}></div>
          <div></div>
          <div className={classes.valuesContainer}>{props.valueTitle}</div>
        </PseudoTable.Header>
        {props.items.map((item, attributeK) => (
          <PseudoTable.Row
            className={item.remove ? "table-danger " + classes.removeRow : ""}
            key={attributeK}
          >
            <div className={`${classes.selectCicle} hideOnMobile`}>
              <SelectRowToDelete
                onClick={
                  !props.processing
                    ? () => props.onSelectUnselectRow(item.id)
                    : () => {}
                }
                active={item.remove}
              />
            </div>
            <div className={classes.featureName}>
              <OptionsButton
                pencil={() => props.keyEdit(item.id)}
                click={() => props.keyEdit(item.id)}
                disabled={props.processing}
              >
                {item.name}
              </OptionsButton>
            </div>
            <div className={classes.addValuesButton}>
              {props.onClickAddValues ? (
                <div className={classes.addValueButton}>
                  <AddButton onClick={() => props.onClickAddValues(item.id)} />
                </div>
              ) : undefined}
            </div>
            <div className={classes.valuesContainer}>
              {item.values.length >= 1 ? (
                item.values.map((value, valueK) => (
                  <OptionsButton
                    key={valueK}
                    loading={value.processing}
                    disabled={props.processing}
                    pencil={() => props.valueEdit(item.id, value.id)}
                    x={
                      item.values.length > 1
                        ? () => props.onClickUnlinkValue(item.id, value.id)
                        : false
                    }
                    chevronExpand={
                      item.values.length === 1
                        ? () => props.onClickChevronExpand(item.id)
                        : undefined
                    }
                  >
                    {value.name}
                  </OptionsButton>
                ))
              ) : (
                <span className={classes.noValuesMessage}>
                  Este item no tiene ningun valor, seleccione uno o más pulsado
                  "+"
                </span>
              )}
            </div>
            <div className={classes.unlinkButton}>
              <button
                className={classes.attributeOption}
                disabled={props.processing}
                onClick={() => props.removeItem(item.id)}
              >
                {!item.processing && !item.selected ? (
                  <X />
                ) : (
                  <div className={classes.spinner}>
                    <ButtonSpinner />
                  </div>
                )}
              </button>
            </div>
          </PseudoTable.Row>
        ))}
      </>
    </PseudoTable.Table>
  );
};

export default AttributesTable;
