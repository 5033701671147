import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Form, Row } from "react-bootstrap";
import SeedGroup from "../SeedGroups/SeedGroup/SeedGroup";
import KeyWordsList from "../KeyWordsList/KeyWordsList";
import CreateCampaign from "../CreateCampaign/CreateCampaign";
import AdGroups from "../AdGroups/AdGroups";
import AdType from "../AdType/AdType";
import AdTypeETA from "../AdTypeETA/AdTypeETA";
import AdTypeRSA from "../AdTypeRSA/AdTypeRSA";
import AdTypeDSA from "../AdTypeDSA/AdTypeDSA";
import Lista from "../../../assets/lista.png";
import classes from "./SemTab.module.css";

const SEMTab = (props: any) => {
  const [proccessNumber, setProccessNumber] = useState<number>(1);
  const [adTypeSelection, setadTypeSelection] = useState<any>("");
  // ProductId from prodcut in props.productId
  // reducer for profiles tanto de user ocmo de gads
  const profiles = useSelector((state: any) => state.profiles);
  // console.log(profiles);

  // Transform into a hook when possible
  const [createdCampaignId, setCreatedCampaignId] = useState<number>();

  const handleCreateCampaign = (id: number) => {
    setCreatedCampaignId(id);
  };

  const content = (pNumber: number) => {
    switch (pNumber) {
      // * CREAR CAMPAÑA Y VER CAMPAÑAS CREADAS
      case 1:
        return (
          <>
            {/* Si no hay ninguna campaña esto es lo que se debe ver */}
            <h1 className={classes.title}>
              No existen campañas para este producto, crea una si lo deseas
            </h1>
            {/* <h1 className={classes.title}>{profiles.gads["gadsAccountId:"]}</h1> */}
            <div className={classes.createButton}>
              <Button
                size="lg"
                onClick={() => {
                  setProccessNumber(2);
                }}
                variant="success"
              >
                Crear campaña
              </Button>
              {/* //*!!!!! dario */}
              <Button className="ml-4"
                size="sm"
                onClick={() => {
                  setProccessNumber(6);
                }}
                variant="dark"
              >
                Ir a anuncios (Temporal)
              </Button>
            </div>
          </>
        );
      // * CREAR BUDGET
      case 2:
        //   Devuelve el formulario para crear una campaña
        return (
          <>
            <h1 className={classes.title}>Crear campaña</h1>

            <div className={classes.container}>
              <CreateCampaign
                setProccessNumber={setProccessNumber}
                productId={props.productId}
                afterCreateCampaign={handleCreateCampaign}
              />
              {/* <div className={classes.nextButton}>
                <Button
                  onClick={() => {
                    setProccessNumber(3);
                  }}
                  variant="success"
                >
                  Siguiente paso
                </Button>
              </div> */}
            </div>
          </>
        );
      // * SEEDGROUPS
      case 3:
        return (
          <>
            <h2 className={classes.title}>Añadir grupos de ideas/semillas</h2>

            <div className={classes.container}>
              <SeedGroup campaignId={createdCampaignId} />
              <div className={classes.nextButton}>
                <Button
                  onClick={() => {
                    setProccessNumber(4);
                  }}
                  variant="success"
                >
                  Siguiente paso
                </Button>
              </div>
            </div>
          </>
        );
      // * KEYWORDS LIST
      case 4:
        //   La lista de keywords
        return (
          <>
            <h3 className={classes.title}>Lista de keywords</h3>

            <KeyWordsList />

            <div className={classes.nextButtonMoreCentered}>
              <Button
                onClick={() => {
                  setProccessNumber(5);
                }}
                variant="success"
              >
                Siguiente paso
              </Button>
            </div>
          </>
        );
      // * AD GROUPS
      case 5:
        //  Grupos y anuncios
        return (
          <>
            <h1 className={classes.title}>Grupos y anuncios</h1>

            <AdGroups
              setadTypeSelection={setadTypeSelection}
              setProccessNumber={setProccessNumber}
            />
            <div className={classes.twoButtons}>
              <Button
                onClick={() => {
                  setProccessNumber(4);
                }}
                variant="danger"
              >
                Volver a Ideas
              </Button>

              <Button
                onClick={() => {
                  setProccessNumber(1);
                }}
                variant="success"
              >
                Finalizar
              </Button>
            </div>
          </>
        );
      // * TYPES OF ADS LIST
      case 6:
        //  Tipo de anuncio
        return (
          <>
            <h1 className={classes.title}>Elige tipo de anuncio</h1>
            <div className={classes.container}>
              <AdType
                setadTypeSelection={setadTypeSelection}
                adTypeSelection={adTypeSelection}
                setProccessNumber={setProccessNumber}
              />
            </div>
            <div className={classes.createButton}>
              {/* Si no hay seleccion entre los tres anuncios no se muestra el boton */}
              {adTypeSelection === "" ? null : (
                <Button
                  className="mb-5"
                  onClick={() => {
                    // Te lleva al siguiente vista pero depende del estado mostrara un componete u otro mostrara uno u otro componente
                    setProccessNumber(7);
                  }}
                  variant="success"
                >
                  Siguiente paso
                </Button>
              )}
            </div>
          </>
        );
      // * ADTYPES
      case 7:
        return (
          <>
            <h1 className={classes.title}>{adTypeSelection}</h1>
            {adTypeSelection === "ETA" ? <AdTypeETA /> : null}
            {adTypeSelection === "RSA" ? (
              <AdTypeRSA productId={props.productId} />
            ) : null}
            {adTypeSelection === "DSA" ? (
              <AdTypeDSA productId={props.productId} />
            ) : null}
            <div className={classes.nextButton}>
              <Button
                onClick={() => {
                  setProccessNumber(5);
                  setadTypeSelection("");
                }}
                variant="danger"
              >
                Volver a anuncios
              </Button>
            </div>
          </>
        );

      default:
        break;
    }
  };

  return content(proccessNumber);
};
export default SEMTab;
