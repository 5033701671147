import React, { useRef } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import TitleContainer from "../../components/TitleContainer/TitleContainer";
import PhotoCard from "../../components/PhotoCard/PhotoCard";
import OptionsButton from "../../components/OptionsButton/OptionsButton";

const PhotoTab = (props) => {
  const photoInput = useRef(null);

  const { photos, makeCoverPhoto, onUploadPhotos, processForm, isSyncing } =
    props;

  if (isSyncing) {
    return (
      <Alert variant="warning">
        Mientras se procesa la sincronización no se pueden subir fotos. Espere
        unos segundos/minutos a que el proceso termine.
      </Alert>
    );
  }

  return (
    <>
      <TitleContainer>
        <h3>Fotos</h3>
        <input
          type="file"
          id="file"
          ref={photoInput}
          multiple
          onChange={(response) => onUploadPhotos(response)}
          style={{ display: "none" }}
        />
        <div>
          <OptionsButton
            upload={() => {}}
            disabled={processForm}
            loading={processForm}
            click={() => photoInput.current.click()}
          >
            Subir fotos(s)
          </OptionsButton>
        </div>
      </TitleContainer>
      <div className="d-flex flex-wrap">
        {photos.map((photo) => (
          <PhotoCard
            active={photo.cover}
            src={photo.src}
            loading={photo.loading}
            remove={() => props.remove(photo.id)}
            makeCover={() => makeCoverPhoto(photo.id)}
            disabled={processForm}
          />
        ))}
      </div>
    </>
  );
};

export default PhotoTab;
