import React, { Fragment, useState } from "react";
import { Spinner, Modal, Button } from "react-bootstrap";
import {
  Star,
  StarFill,
  X,
  Circle,
  CheckCircleFill,
} from "react-bootstrap-icons";
import displayNotification from "../../utilities/displayNotification";
import classes from "./PhotoCard.module.css";
import ReactTooltip from "react-tooltip";

const PhotosCard = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let content;

  if (props.loading) {
    content = (
      <div className={classes.spinner}>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </div>
    );
  } else {
    content = (
      <Fragment>
        <ReactTooltip delayShow={1000}place="top" type="info" effect="solid" />
        <Modal
          size="lg"
          className={classes.modifyModal}
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Copiar la URL de la foto al portapapeles</Modal.Title>
          </Modal.Header>
          <Modal.Body className={classes.centerStyles}>
            <p>{props.src}</p>
            <Button
              variant="success"
              onClick={() => {
                navigator.clipboard.writeText(props.src);
                displayNotification({
                  title: "URL copiada de manera exitosa",
                  message: `${props.src}`,
                  type: "success",
                });
                setTimeout(() => {
                  handleClose();
                }, 1000);
              }}
            >
              Copia la URL al portapapeles
            </Button>
          </Modal.Body>
          <Modal.Footer className={classes.modifyBottomModal}>
            {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button> */}
          </Modal.Footer>
        </Modal>
        {props.selectOnly ? (
          <>
            {props.active ? (
              <button onClick={props.selectUnselect}>
                <CheckCircleFill className={classes.selectIcon} />
              </button>
            ) : (
              <button onClick={props.selectUnselect}>
                <Circle className={classes.selectIcon} />
              </button>
            )}
          </>
        ) : (
          <>
            <button
              disabled={props.disabled}
              className={classes.star}
              onClick={!props.active ? props.makeCover : undefined}
            >
              {props.active ? <StarFill /> : <Star />}
            </button>
            <button
              disabled={props.disabled}
              className={classes.x}
              onClick={props.remove}
            >
              <X />
            </button>
          </>
        )}
      </Fragment>
    );
  }

  return (
    <>
      <div
        className={`${classes.photoContainer} 
      ${props.active ? classes.active : ""}
      ${props.disabled ? classes.disabled : ""}
      `}
      >
        <div>
          <img
            data-tip="Click para copiar URL"
            onClick={handleShow}
            className={classes.photo}
            src={props.src}
            alt="Notfound"
          />
          <div className={classes.optionsContainer}>{content}</div>
        </div>
      </div>
    </>
  );
};

export default PhotosCard;
