import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Spinner, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import classes from "./OrphanNavBar.module.css";
// API
import apiArticles from "../../api/articles";
// UTILITIES
import handleApiErrors from "../../utilities/handleApiErrors";

//STATES
const states = {
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};

const OrphanNavBar = (props) => {
  const accesToken = useSelector((state) => state.user.access);

  const orphanNumber = useSelector((state) => state.orphans.orphanNumber);

  // * REDUX VALUE SETTING USING DISPATCH
  const dispatch = useDispatch();
  const setOrphanNumber = useCallback((orphanNumber) =>
    dispatch({ type: "CHANGE_NUMBER", orphanNumber }, [dispatch])
  );

  // * PROCESS STATE
  const [process, setProcess] = useState(states.LOADING);

  // * BRING DATA FROM API
  useEffect(() => {
    apiArticles
      .getOrphansLength(accesToken)
      .then(({ data }) => {
        setOrphanNumber(data.length);

        setProcess(states.LOADED);
      })
      .catch((e) => {
        handleApiErrors(e);
      });
  }, []);

  return (
    <>
      {process === "LOADING" ? (
        <Spinner  animation="border" size="sm" />
      ) : (
        <>
          {/* <Link
            // className={classes.noDecoration}
            onClick={() => {
              // props.onClick();
              apiArticles
                .getOrphansLength(accesToken)
                .then(({ data }) => {
                  // * REDUX VALUE SETTING
                  setOrphanNumber(data.length);
                })
                .catch((e) => {
                  handleApiErrors(e);
                });
            }}
            className="nav-link"
            to="/orphansList"
          > */}
            {/* HUÉRFANOS */}
            <Badge className="ml-2" pill variant="danger">
              {orphanNumber === 0 ? null : orphanNumber}
            </Badge>
          {/* </Link> */}
        </>
      )}
    </>
  );
};

export default OrphanNavBar;
