import React, { useState, useEffect } from "react";
import api from "./ApiPreviewProduct";
import { Table, Container } from "react-bootstrap";
import classes from "./ProductPreview.module.css";
import Spinner from "../components/Spinner/Spinner";
// import Spinner from "./components/Spinner/Spinner";

// States
const LOADING = "LOADING";
const LOADED = "LOADED";

const Product = (props) => {
  // Taking the Id from the path
  const productId = props.match.params.id;

  const [processState, setProcessState] = useState(LOADING);
  const [processStateAttributes, setProcessStateAttributes] = useState(LOADING);
  const [productInfo, setProductInfo] = useState(LOADING);
  const [productDetail, setProductDetail] = useState(LOADING);

  // AUTH
  const [refreshToken, setRefreshToken] = useState();
  const [accessToken, setAccessToken] = useState();

  useEffect(() => {
    api
      .getTokens("casadelaswebs", "HDHFBGGT&dfgshg34g")
      .then((response) => {
        const makeRefreshTokenPetition = () => {
          setTimeout(() => {
            api
              .refreshToken(
                accessToken || response.data.access,
                refreshToken || response.data.refresh
              )
              .then(({ data }) => {
                setAccessToken(data.access);
                setRefreshToken(data.refresh);
              })
              .catch((e) => console.log(e));

            makeRefreshTokenPetition();
          }, 270000);
        };

        makeRefreshTokenPetition();

        setAccessToken(response.data.access);
        setRefreshToken(response.data.refresh);

        return { access: response.data.access };
      })
      .catch((error) => console.log(error));
  }, []);

  //Getting the info of the product via api
  useEffect(() => {
    if (!accessToken) return;

    api
      .getProductInfo(accessToken, productId)
      .then((res) => {
        const processedProductInfo = res.data[0];
        // console.log(
        //   "🚀 ~ file: Product.js ~ line 63 ~ .then ~ processedProductInfo",
        //   processedProductInfo
        // );
        setProductInfo(processedProductInfo);
        console.log(
          "🚀 ~ file: ProductPreview.js ~ line 78 ~ .then ~ processedProductInfo",
          processedProductInfo
        );

        // Making de defaultId for the default attributes table
        const processedDefaultId =
          processedProductInfo.table_data[0].prestashop_id;

        //Calling the api to make the default attribute table
        api
          .getTableAttributes(accessToken, processedDefaultId)
          .then((res) => {
            const processedAttributes = res.data[0].attributes_table;

            setProductDetail(processedAttributes);
            setProcessStateAttributes(LOADED);
          })
          .catch((e) => console.log(e));
        //? Instead of repeating getTableAttributes, maybe there is a way of writing less code but I encountered synchrony problems

        setProcessState(LOADED);
      })
      .catch((e) => console.log(e));
  }, [accessToken, productId]);
  // console.log("🚀 ~ file: ProductPreview.js ~ line 137 ~ Product ~ productInfo.table_attributes", productInfo)

  // Show in  another table the article's attributes by doing click in the first table

  const showAttributeArticle = (pId) => {
    api
      .getTableAttributes(accessToken, pId)
      .then((res) => {
        const processedAttributes = res.data[0].attributes_table;

        //Here is the code to make the row selected when click
        const updatedTableData = productInfo.table_data.map((item) => {
          if (item.prestashop_id === pId) {
            return {
              ...item,
              selected: true,
            };
          }

          return { ...item, selected: false };
        });

        setProductInfo({
          ...productInfo,
          table_data: updatedTableData,
        });

        setProductDetail(processedAttributes);
        setProcessStateAttributes(LOADED);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Container className={classes.container}>
      <Container>
        {/* Full range Table */}
        <h1>Lista gama completa</h1>

        {processState === LOADING ? (
          <Spinner />
        ) : (
          <Table responsive bordered>
            <thead>
              <tr>
                {/* As many collumns as the number of attributes in the response */}
                <th>ID</th>
                <th>Referencia</th>
                {productInfo.table_attributes.map((attribute) => (
                  <th key={attribute.id}>{attribute.name}</th>
                ))}
              </tr>
            </thead>
            {/* The table data fills dynamically with de key of the <th> */}
            <tbody>
              {productInfo.table_data.map((data) => {
                console.log(
                  "🚀 ~ file: ProductPreview.js ~ line 146 ~ {productInfo.table_data.map ~ productInfo.table_data",
                  productInfo.table_data
                );
                const columns = (
                  <>
                    {productInfo.table_attributes.map((attribute) => {
                      return <td key={attribute.id}>{data[attribute.name]}</td>;
                    })}
                  </>
                );

                return (
                  <tr
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      showAttributeArticle(data.prestashop_id);
                      console.log(
                        "ID producto:",
                        productInfo.id,
                        "------",
                        "ID nuestra del atributo",
                        data.id
                      );
                    }}
                    key={data.prestashop_id}
                    className={`${data.selected ? "table-primary" : ""}`}
                  >
                    <td>{data.prestashop_id}</td>
                    <td>{data.ref}</td>
                    {columns}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}

        <h1>Atributos</h1>
        {/* Attribute Table */}
        {processStateAttributes === LOADING ? (
          <Spinner />
        ) : (
          <Table variant="dark" responsive striped bordered hover>
            <tbody>
              {Object.keys(productDetail).map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item}</td>
                    <td>{productDetail[item]}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}

        <h1>Características</h1>
        {/* Feature data Table */}
        {processState === LOADING ? (
          <Spinner />
        ) : (
          <Table variant="dark" responsive striped bordered>
            <thead>
              {Object.keys(productInfo.table_features_data).map(
                (item, index) => {
                  return (
                    <tr key={index}>
                      <th>{item}</th>
                      <td>{productInfo.table_features_data[item]}</td>
                    </tr>
                  );
                }
              )}
            </thead>
          </Table>
        )}
      </Container>
    </Container>
  );
};

export default Product;
