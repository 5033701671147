const handleDragAndDrop = (items, setItems) => (result) => {
  if (!result.destination) return;

  const origin = result.source.index;
  const destination = result.destination.index;

  const upatedItems = [...items];
  const [reorderedItems] = upatedItems.splice(origin, 1);
  upatedItems.splice(destination, 0, reorderedItems);

  setItems(upatedItems);
};

export default handleDragAndDrop;
