import React from "react";

import { Modal, Spinner } from "react-bootstrap";

import CreateCertificateModal from "../CreateCertificateModal/CreateCertificateModal";

import useEditCertificate from "./hooks/use-editCertificate";

const EditCertificate: React.FC<{
  show: boolean;
  handleCloseModal: () => void;
  certificateToEditId: number;
  handleSaveAndClose: (data: { name: string; description: string }) => void;
}> = ({ show, handleCloseModal, certificateToEditId, handleSaveAndClose }) => {
  const { name, description, image, pdf, isLoadingCertificate } =
    useEditCertificate(certificateToEditId);

  const handleClickCloseModal = () => {
    const cerrarButton: any = document.querySelector("#cerrarButton");

    cerrarButton.click();
  };

  return (
    <Modal
      show={show}
      onHide={handleClickCloseModal}
      size="lg"
      className="mediumModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar certificado</Modal.Title>
      </Modal.Header>
      {isLoadingCertificate ? (
        <>
          <Modal.Body>
            <Spinner animation="border" />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </>
      ) : (
        <CreateCertificateModal
          id={certificateToEditId}
          initialName={name}
          initialDescription={description}
          initialImage={image}
          initialPdf={pdf}
          whenEndSave={handleSaveAndClose}
          handleClose={handleCloseModal}
        />
      )}
    </Modal>
  );
};

export default EditCertificate;
