import React from "react";
import { Table, Button } from "react-bootstrap";
import OptionsButton from "../OptionsButton/OptionsButton";
import ButtonSpinner from "../ButtonSpinner/ButtonSpinner";
import classes from "./AddsTable.module.css";

const AddsTable = (props) => {
  return (
    <>
      <Table bordered className="mt-3">
        <tbody>
          <tr>
            <td className={classes.title} width={180}>
              {props.title}
            </td>
            <td className={classes.itemsContainer}>
              {props.items.map((item, itemK) => (
                <OptionsButton
                  variant="success"
                  key={itemK}
                  x={() => props.unselectItem(item.id)}
                  disabled={props.processing}
                  loading={props.processing}
                >
                  {item.name}
                </OptionsButton>
              ))}
            </td>
          </tr>
        </tbody>
      </Table>
      <div className={classes.saveButton}>
        <Button
          variant="success"
          onClick={props.onSave}
          disabled={props.processing}
        >
          {!props.processing ? "Guardar" : <ButtonSpinner />}
        </Button>
      </div>
    </>
  );
};

export default AddsTable;
