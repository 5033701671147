import React, { useState } from "react";
import OptionsButton from "../OptionsButton/OptionsButton";
import ButtonSpinner from "../ButtonSpinner/ButtonSpinner";
import { Button, Table } from "react-bootstrap";
import CSSTransition from "react-transition-group/CSSTransition";
import classes from "./DuplicatesTable.module.css";

const DuplicatesTable = (props) => {
  const [animate, setAnimate] = useState(false);

  return (
    <>
      <Table bordered className="mt-3">
        <tbody>
          <tr>
            <td className={classes.originalCategoryTd}>{props.title}</td>
            <td className={`${classes.hightlightFather}`}>
              {props.father ? (
                <CSSTransition
                  in={animate}
                  timeout={1000}
                  classNames={{
                    enter: classes.noAnimation,
                    enterActive: classes.animated,
                    exit: classes.noAnimation,
                    exitActive: classes.animated,
                  }}
                >
                  <OptionsButton
                    down={() => {
                      setAnimate(!animate);
                      props.swap(props.father);
                    }}
                    x={() => {
                      setAnimate(!animate);
                      props.remove(props.father);
                    }}
                    disabled={props.processing}
                    loading={props.processUnifyItems}
                  >
                    {props.father.name}
                  </OptionsButton>
                </CSSTransition>
              ) : undefined}
            </td>
          </tr>
          <tr>
            <td className={classes.originalCategoryTd}>
              Copias del original a fusionar
            </td>
            <td className={classes.duplicatedCategoryes}>
              {props.duplicates && props.duplicates.length > 0
                ? props.duplicates.map((duplicate) => (
                    <OptionsButton
                      key={duplicate.id}
                      up={() => {
                        setAnimate(!animate);
                        props.swap(duplicate);
                      }}
                      x={() => {
                        props.remove(duplicate);
                      }}
                      disabled={props.processing}
                      loading={props.processUnifyItems}
                    >
                      {duplicate.name}
                    </OptionsButton>
                  ))
                : undefined}
            </td>
          </tr>
        </tbody>
      </Table>
      {props.father && props.duplicates.length > 0 ? (
        <div className={classes.saveButton}>
          <Button
            variant="warning"
            onClick={() => {
              const fatherId = props.father.id;
              const copiesIds = props.duplicates.map((item) => item.id);

              props.unifyItems(fatherId, copiesIds);
            }}
            disabled={props.processing}
          >
            {props.processUnifyItems ? <ButtonSpinner /> : "Unificar items"}
          </Button>
        </div>
      ) : undefined}
    </>
  );
};

export default DuplicatesTable;
