import customAxios from "../../customAxios";

const descriptionsList = (
  access: string,
  data: { data: string; text: string; similarity?: string }
) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/sem/description-text-list/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};
const descriptionsTranslatorText = (
  access: string,
  data: {
    account_pk: number;
    entity_pk: number;
    entity_type: number;
    text: string;
    position: number;
  }
) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/sem/description-translator-text/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

export default {descriptionsList,
    descriptionsTranslatorText};
