import displayNotification from "../displayNotification";

const dsiplayNoCertificatePdfNotification = () => {
  displayNotification({
    title: "Este certificado no tiene pdf",
    message: "",
    type: "info",
    dismiss: undefined,
  });
};

export default dsiplayNoCertificatePdfNotification;
