import React from "react";

import UploadFilesDropDown from "../../../../components/UploadFilesDropDown/UploadFilesDropDown";

import usePhotos from "./hooks/use-photos";

import classes from "./Photos.module.scss";

const PhotosTab: React.FC<{ categoryId: number; idPrestashop: number }> = ({
  categoryId,
  idPrestashop,
}) => {
  const { categoryImageUrl, doesImageExist, imageDropZone, isUploadingImage } =
    usePhotos({
      categoryId,
      idPrestashop,
    });

  return (
    <>
      {doesImageExist && (
        <>
          <img src={categoryImageUrl} alt="img" />
          <h6>Cambiar de fotografía</h6>
        </>
      )}
      <UploadFilesDropDown
        rootProps={imageDropZone.getRootProps()}
        inputProps={imageDropZone.getInputProps()}
        isDragActive={imageDropZone.isDragActive}
        disabled={isUploadingImage}
        dropFilesMessage="Arrastre las imágenes aquí"
        searchFile="Búsquela en su ordenador"
        processing={isUploadingImage}
      />
    </>
  );
};

export default PhotosTab;
