import React, { Fragment } from "react";
import { PencilSquare, Globe2 } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import CheckSphere from "../CheckSphere/CheckSphere";
import SelectRowToDelete from "../SelectRowToDelete/SelectRowToDelete";
import ButtonSpinner from "../../components/ButtonSpinner/ButtonSpinner";
import PseudoTable from "../../components/PseudoTable/PseudoTable";
import classes from "./ArticlesTable.module.css";
import ReactTooltip from "react-tooltip";

const ArticlesTable = (props) => (
  <PseudoTable.Table
    className={`${props.processing ? classes.processing : ""}`}
  >
    <PseudoTable.Header className={classes.header}>
      <th>Desvincular</th>
      <th>ID</th>
      <th>Referencia</th>
      <th>Descripción</th>
      <th>Activo</th>
      <th>Opciones</th>
    </PseudoTable.Header>
    <ReactTooltip delayShow={1000} place="top" type="info" />

    {props.articles.map((art) => {
      let optionsContent;

      if (props.processing && art.processing) {
        optionsContent = (
          <div className={classes.spinnerContainer}>
            <ButtonSpinner />
          </div>
        );
      } else {
        optionsContent = (
          <Fragment>
            <Button
              variant="light"
              onClick={() => props.clickEditArticle(art.id)}
              disabled={props.processing}
              data-tip="Editar producto"
            >
              <PencilSquare />
            </Button>
            {props.productId ? (
              <Button
                variant="light"
                disabled={props.processing}
                data-tip="Ver producto en la web"
                onClick={() => {
                  const newWindow = window.open(
                    `http://pimtest.upteimpulsa.es/${props.productId}-${art.id}.html`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                  if (newWindow) newWindow.opener = null;
                }}
              >
                <Globe2 />
              </Button>
            ) : undefined}
            {/* <Button
                variant="light"
                onClick={() => props.x(art.id)}
                disabled={props.processing}
                data-tip="Desvincular artículo"
                >
                <X />
              </Button> */}
          </Fragment>
        );
      }

      return (
        <PseudoTable.Row
          key={art.id}
          className={`${classes.row} ${art.remove ? "table-danger" : ""}`}
        >
          <div>
            <SelectRowToDelete
              onClick={() => props.onClickRemoveArticle(art.id)}
              active={art.remove}
            />
          </div>
          <div>{art.id}</div>
          <div>
            <p>{art.reference}</p>
          </div>
          <div>
            <p>{art.description}</p>
          </div>
          <div className={classes.badgeContainer}>
            <CheckSphere
              check={art.active && art.activeSales}
              x={!art.active || !art.activeSales}
            />
          </div>
          <div className={classes.optionsContainer}>{optionsContent}</div>
        </PseudoTable.Row>
      );
    })}
  </PseudoTable.Table>
);

export default ArticlesTable;
