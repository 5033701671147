import swal from "sweetalert";

const saveBeforeClose = (sholdAllowToExit) => {
  const messageParams = {
    title:
      "Si sale de la página sin guardar se perderán los cambios realizados. ¿Desea guardar antes de continuar?",
    icon: "warning",
    buttons: {
      exit: {
        text: "Salir sin guardar",
        className: "greySweetButton",
        value: "exit",
        closeModal: false,
      },
      cancel: "Cancelar",
      save: { text: "Guardar", value: "save", closeModal: false },
      saveAndExit: {
        text: "Guardar y sincronizar",
        value: "saveSync",
        closeModal: false,
      },
    },
    className: "customSweat",
  };

  if (!sholdAllowToExit) {
    delete messageParams.buttons.exit;
  }

  return swal(messageParams);
};

export default saveBeforeClose;
