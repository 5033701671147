import React, { useState, useEffect } from "react";
import checkSync from "../../../utilities/checkSync";
import { Modal, Col, Accordion, Card, Tab, Tabs } from "react-bootstrap";
import { Prompt } from "react-router-dom";
import saveBeforeExitAlert from "../../../utilities/saveBeforeExitAlert";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import apiBrands from "../../../api/brands";
import CheckSphere from "../../../components/CheckSphere/CheckSphere";
import DataTitle from "../../../components/DataTitle/DataTitle";
import MainTitle from "../../../components/MainTitle/MainTitle";
import SaveButton from "../../../components/SaveButton/SaveButton";
import TabErrorIcon from "../../../components/TabErrorIcon/TabErrorIcon";
import TextEditor from "../../../components/TextEditor/TextEditor";
import TextInput from "../../../components/TextInput/TextInput";
import SEOTab from "../../SEOTab/SEOTab";
import classes from "./Brand.module.css";
import Spinner from "../../../components/Spinner/Spinner";
import TabContainer from "../../../components/TabContainer/TabContainer";
import TabMainRow from "../../../components/TabMainRow/TabMainRow";
import solveErrorMessage from "../../../utilities/notifications/solveErrorMessage";

const states = {
  LOADING: "LOADING",
  SUCCEDDED: "SUCCEDDED",
  PROCESSING: "PROCESSING",
  ERROR: "ERROR",
};

const Brand = (props) => {
  const accesToken = useSelector((state) => state.user.access);

  const id = !props.modal ? Number(props.match.params.id) : props.id;

  // * Regular expressions
  const regExpURL =
    /^[^~"<+>^.:;\\,%\]_`!\[{|}&€‚ƒ$=…†'‡ˆ‰Š?‹ŒŽ‘’“”•)*#(–—˜™š@›œžŸ¡¢£¥|§¨©ª«¬¯®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ]+$/;
  const regExpURL2 =
    /^[^~"<+>^.:;\\,%\]_`!\[{|}&€‚ƒ$=…†'‡ˆ‰?‹Œ‘’“”•)*#(–—˜™@›œ¡¢£¥|§¨©ª«¬¯®¯°±²³´µ¶·¸¹º»¼½¾¿Þ÷þ]+$/;
  // const [specialCharacters, setSpecialCharacters] = useState(true);
  const [specialCharactersURL, setSpecialCharactersURL] = useState(true);
  const [specialCharactersName, setSpecialCharactersName] = useState(true);
  // * SEO
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [reWrite, setReWrite] = useState("");

  const [key, setKey] = useState(0);
  const [description, setDescription] = useState("");

  const [name, setName] = useState(states.LOADING);
  const [active, setActive] = useState();
  const [shortDescription, setShortDescription] = useState("");

  const [formProcessPhase, setFormProcessPhase] = useState(states.LOADING);

  const disabled = formProcessPhase === states.PROCESSING;
  const [syncError, setSyncError] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [mustSync, setMustSync] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [correctSync, setCorrectSync] = useState(false);

  const [showFormError, setShowErrorForm] = useState(true);

  const [duplicateNameError, setDuplicateNameError] = useState(false);

  const [exitPath, setExitPath] = useState(false);

  useEffect(() => {
    if (exitPath) {
      props.history.push(exitPath);
    }
  }, [exitPath]);

  useEffect(() => {
    const saveTimer = setTimeout(async () => {
      if (
        hasChanged &&
        !(
          name.length <= 0 ||
          active === undefined ||
          description.length <= 0 ||
          description.length > 50000 ||
          shortDescription.length <= 0 ||
          reWrite.length <= 0 ||
          metaTitle.length <= 0 ||
          metaDescription.length <= 0 ||
          metaTitle.length >= 70 ||
          metaDescription.length >= 160 ||
          !specialCharactersName ||
          !specialCharactersURL
        )
      ) {
        try {
          await save();
        } catch (e) {}
      }
    }, 10000);

    return () => clearTimeout(saveTimer);
  }, [
    hasChanged,
    id,
    name,
    description,
    shortDescription,
    reWrite,
    metaTitle,
    metaDescription,
    active,
    specialCharactersName,
    specialCharactersURL,
  ]);

  useEffect(() => {
    if (hasChanged) {
      window.onbeforeunload = () =>
        saveBeforeExitAlert().then(async (value) => {
          switch (value) {
            case "save":
              try {
                await save();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "saveSync":
              try {
                await save();
                await sync();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "exit":
              swal.close();
              break;

            default:
              break;
          }
        });
    } else {
      window.onbeforeunload = undefined;
    }
  }, [hasChanged]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await apiBrands.filter(accesToken, {
          filtername: "id",
          value: Number(id),
        });
        const brandName = data[0].name;
        const descriptionFilter = data[0].description ?? "";
        const shortDescriptionFilter = data[0].short_description ?? "";
        const urlRewriteFilter = data[0].url_rewrite ?? "";
        const metaTitleFilter = data[0].meta_title ?? "";
        const metaDescriptionFilter = data[0].meta_description ?? "";
        const activeFilter = data[0].active ?? "";

        setName(brandName);
        setDescription(descriptionFilter);
        setShortDescription(shortDescriptionFilter);
        setReWrite(urlRewriteFilter);
        setMetaTitle(metaTitleFilter);
        setMetaDescription(metaDescriptionFilter);
        setActive(activeFilter);

        setFormProcessPhase(states.SUCCEDDED);
      } catch (e) {
        console.log(e);
      }
    })();

    (async () => {
      try {
        const firstCheckSync = await apiBrands.checkSync(accesToken, {
          brandId: id,
        });

        if (firstCheckSync.data.brand[0].synchronizing) {
          setIsSyncing(true);
        }

        const checkResponse = await checkSync.checkBrandSync(accesToken, {
          brandId: id,
        });

        if (checkResponse) {
          setMustSync(false);
          setCorrectSync(true);
        } else {
          setMustSync(true);
        }

        setSyncError(false);
        setIsSyncing(false);
      } catch (error) {
        setSyncError(true);
        setIsSyncing(false);
      }
    })();
  }, [setName]);

  const notificationErrorMessage = ({ title, key }) => {
    solveErrorMessage({
      title,
      onClickSolve: () => {
        setKey(key);
        if (window.screen.width < 500) {
          document.getElementById(`accordion-card-${key}`).scrollIntoView();
        }
      },
    });
  };

  const save = async () => {
    try {
      let hasErrors = false;

      if (name.length <= 0) {
        hasErrors = true;

        notificationErrorMessage({
          title: "No se puede guardar una marca sin nombre",
          key: 0,
        });
      }

      if (active === undefined) {
        hasErrors = true;

        notificationErrorMessage({
          title:
            "No se puede guardar una marca si el campo activo está sin seleccionar",
          key: 0,
        });
      }

      if (description.length <= 0 || description.length > 50000) {
        hasErrors = true;

        notificationErrorMessage({
          title: "Una descripción de entre 1 i 50.000 caracteres es necesaria",
          key: 0,
        });
      }

      if (shortDescription.length <= 0 || shortDescription.length > 400) {
        hasErrors = true;

        notificationErrorMessage({
          title: "Una descripción corta es necesaria",
          key: 0,
        });
      }

      if (metaTitle.length <= 0) {
        hasErrors = true;

        notificationErrorMessage({
          title: "El metatitle es necesario",
          key: 1,
        });
      }

      if (metaDescription.length <= 0 || metaDescription.length > 160) {
        hasErrors = true;

        notificationErrorMessage({
          title:
            "La metadescription es necesaria y no puede tener más de 160 caracteres",
          key: 1,
        });
      }

      if (!specialCharactersName && name.length > 0) {
        hasErrors = true;

        notificationErrorMessage({
          title: "El nombre no puede poseer caracteres especiales",
          key: 0,
        });
      }
      if (reWrite.length <= 0) {
        hasErrors = true;

        notificationErrorMessage({
          title: "No se puede guardar una marca sin URL",
          key: 1,
        });
      }
      if (!specialCharactersURL && reWrite.length > 0) {
        hasErrors = true;

        notificationErrorMessage({
          title: "La URL no puede poseer caracteres especiales",
          key: 1,
        });
      }

      if (hasErrors) {
        setFormProcessPhase(states.ERROR);
        setShowErrorForm(true);

        throw new Error();
      }

      setIsSaving(true);
      await apiBrands.edit(accesToken, {
        id,
        name,
        description,
        shortDescription,
        reWrite,
        metaTitle,
        metaDescription,
        active,
      });

      setIsSaving(false);
      setHasChanged(false);
      setMustSync(true);
      setFormProcessPhase(states.SUCCEDDED);
      setHasSaved(true);

      return true;
    } catch (e) {
      if (e.response && e.response.status === 409) {
        setDuplicateNameError(true);

        notificationErrorMessage({
          title: "El nombre está duplicado, introduzca uno que no exista ya",
          key: 0,
        });
      }
      setShowErrorForm(true);
      setIsSaving(false);
      setFormProcessPhase(states.ERROR);
      setShowErrorForm(true);

      return false;
    }
  };

  const sync = async () => {
    try {
      setIsSyncing(true);

      const hasSavedSuccesFully = await save();
      if (!hasSavedSuccesFully) {
        setIsSyncing(false);
        return;
      }

      const syncResponse = await apiBrands.sync(accesToken, {
        brandId: id,
      });
      console.log(syncResponse);
      await checkSync.checkBrandSync(accesToken, {
        brandId: id,
        setIsSyncing,
      });

      setIsSyncing(false);
      setMustSync(false);
      setSyncError(false);
    } catch (e) {
      setMustSync(false);
      setSyncError(true);
    }
  };

  let nameErrorMessage;

  if (name.length <= 0) {
    nameErrorMessage = "No puede estar el valor vacío";
  } else if (!specialCharactersName) {
    nameErrorMessage = "No está permitido usar caracteres especiales";
  } else if (duplicateNameError) {
    nameErrorMessage = "No puede haber 2 marcas con el mismo nombre";
  }

  const brandTabTitle = (
    <>
      {formProcessPhase === states.ERROR &&
      (duplicateNameError ||
        name.length <= 0 ||
        description.length <= 0 ||
        shortDescription.length <= 0 ||
        !specialCharactersName) ? (
        <TabErrorIcon />
      ) : undefined}
      Marca
    </>
  );

  const seoTabTitle = (
    <>
      {formProcessPhase === states.ERROR &&
      (reWrite.length <= 0 ||
        !specialCharactersURL ||
        metaTitle.length <= 0 ||
        metaDescription.length <= 0 ||
        metaTitle.length >= 70 ||
        metaDescription.length >= 160) ? (
        <TabErrorIcon />
      ) : undefined}
      SEO
    </>
  );

  const seoTabContent = (
    <SEOTab
      // Nuevos props
      reWrite={reWrite}
      setSpecialCharactersURL={setSpecialCharactersURL}
      regExpURL={regExpURL}
      setReWrite={setReWrite}
      setHasChanged={setHasChanged}
      specialCharactersURL={specialCharactersURL}
      specialCharactersName={specialCharactersName}
      name={name}
      // Fin de nuevos props
      metaTitle={metaTitle}
      setMetaTitle={(newValue) => {
        setMetaTitle(newValue);
        setHasChanged(true);
      }}
      metaDescription={metaDescription}
      setMetaDescription={(newValue) => {
        setMetaDescription(newValue);
        setHasChanged(true);
      }}
      error={formProcessPhase === states.ERROR}
    />
  );

  const brandTabContent = (
    <TabContainer>
      <TabMainRow>
        <Col md={12}>
          <DataTitle>Nombre:</DataTitle>
          <TextInput
            value={name}
            onChange={(event) => {
              setSpecialCharactersName(regExpURL2.test(event.target.value)); //devuelve false si coincide con alguno de los caracteres de la regex
              setName(event.target.value);
              setHasChanged(true);
              setDuplicateNameError(false);
            }}
            error={
              name.length <= 0 || !specialCharactersName || duplicateNameError
            }
            errorMessage={nameErrorMessage}
          />
        </Col>
        <Col md={2}>
          <CheckSphere
            check={active}
            x={!active}
            onClick={() => {
              setActive(!active);
              setHasChanged(true);
            }}
            disabled={disabled}
          >
            Activo
          </CheckSphere>
        </Col>
        <Col md={12}>
          <DataTitle>Descripción:</DataTitle>
          <TextEditor
            // initialValue={description}
            // onKeyUp={(value) => {
            //   if (value.target.innerHTML !== description) {
            //     setDescription(value.target.innerHTML);
            //     setHasChanged(true);
            //   }
            // }}
            value={description}
            onKeyUp={() => setHasChanged(true)}
            onEditorChange={(content) => {
              setDescription(content);
            }}
            error={description.length <= 0 || description.length > 50000}
            errorMessage="Necesita introducir una descripción de entre 1 y 50000 caracteres para guardar la marca"
          />
        </Col>
        <Col md={12}>
          <DataTitle className={classes.title}>Descripción corta:</DataTitle>
          <TextEditor
            value={shortDescription}
            onEditorChange={(content) => {
              setShortDescription(content);
            }}
            onKeyUp={() => setHasChanged(true)}
            error={shortDescription.length <= 0}
            errorMessage="Necesita introducir una descripción para guardar el producto"
          />
        </Col>
      </TabMainRow>
    </TabContainer>
  );

  const content = (
    <>
      <Accordion
        defaultActiveKey="0"
        activeKey={String(key)}
        onSelect={(k) => setKey(k)}
        className={classes.hideAcordionOnPC}
      >
        <Card id="accordion-card-0">
          <Accordion.Toggle as={Card.Header} eventKey="0">
            {brandTabTitle}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>{brandTabContent}</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card id="accordion-card-1">
          <Accordion.Toggle as={Card.Header} eventKey="1">
            {seoTabTitle}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>{seoTabContent}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <div className={classes.hideTabsWhenMobile}>
        <Tabs
          className={classes.tabs}
          id="productPhases"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          {/* Datos iniciales de producto */}

          <Tab eventKey={0} title={brandTabTitle}>
            {brandTabContent}
          </Tab>

          {/* Datos de SEO */}

          <Tab eventKey={1} title={seoTabTitle}>
            {seoTabContent}
          </Tab>
        </Tabs>
      </div>
    </>
  );

  if (formProcessPhase === "LOADING") {
    if (props.modal) {
      return (
        <Modal
          show={true}
          className={`${classes.modifyModal} bigModal`}
          size="lg"
          onHide={() => {
            if (hasSaved) props.beforeClose({ id, name });

            props.onHide();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className={classes.modifyBottomModal}>
            <Spinner />
          </Modal.Body>
        </Modal>
      );
    } else {
      return <Spinner />;
    }
  }

  if (props.modal) {
    return (
      <Modal
        show={true}
        size="lg"
        // className="bigModal"
        className={`${classes.modifyModal} bigModal`}
        onHide={() => {
          if (hasSaved) props.beforeClose({ id, name, active });
          props.onHide();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Marca: {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer className={classes.modifyBottomModal}>
          <SaveButton
            modal
            onClickSave={async () => {
              try {
                await save();
              } catch (e) {}
            }}
            correctSync={correctSync}
            onClickSync={sync}
            syncError={syncError}
            mustSave={hasChanged}
            processingSave={isSaving}
            mustSync={mustSync}
            processingSync={isSyncing}
            onClose={() => setShowErrorForm(false)}
            error={formProcessPhase === states.ERROR && showFormError === true}
            errorMessage="Hay errores en el formulario,cierre esta ventana, revíselo y corrija los errores antes de guardar"
          />
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <Prompt
        when={hasChanged && !exitPath}
        message={({ pathname }) => {
          saveBeforeExitAlert(true).then(async (value) => {
            switch (value) {
              case "save":
                try {
                  await save();
                  swal.close();
                } catch (e) {
                  swal.close();
                }
                break;

              case "saveSync":
                try {
                  await save();
                  await sync();
                  swal.close();
                } catch (e) {
                  swal.close();
                }
                break;

              case "exit":
                setExitPath(pathname);
                swal.close();
                break;

              default:
                break;
            }
          });

          return false;
        }}
      />
      <SaveButton
        onClickSave={async () => {
          try {
            await save();
          } catch (e) {}
        }}
        correctSync={correctSync}
        onClickSync={sync}
        syncError={syncError}
        mustSave={hasChanged}
        processingSave={isSaving}
        mustSync={mustSync}
        processingSync={isSyncing}
        onClose={() => setShowErrorForm(false)}
        error={formProcessPhase === states.ERROR && showFormError === true}
        errorMessage="Hay errores en el formulario,cierre esta ventana, revíselo y corrija los errores antes de guardar"
      />
      <MainTitle>
        <h1>Marca: {name}</h1>
      </MainTitle>
      {content}
    </>
  );
};

export default Brand;
