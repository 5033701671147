import React from "react";

import { Button } from "react-bootstrap";
import { CloudArrowUp } from "react-bootstrap-icons";

import classes from "./UploadFilesDropDown.module.scss";

const UploadFilesDropDown: React.FC<{
  rootProps: any;
  inputProps: any;
  isDragActive: boolean;
  disabled: boolean;
  processing?: boolean;
  dropFilesMessage?: string;
  searchFile?: string;
}> = ({
  rootProps,
  inputProps,
  isDragActive,
  disabled,
  processing,
  dropFilesMessage,
  searchFile,
}) => {
  const dropDownContainerClasses = [classes.dropDownFileContainer];

  let content = (
    <div className={classes.dropHereMessage}>
      <CloudArrowUp />
      <span>
        {dropFilesMessage ? dropFilesMessage : "Arrastre los archivos aquí"}
      </span>
      <span>o</span>
      <Button className="mt-1">
        {searchFile ? searchFile : "Busque el archivo"}
      </Button>
    </div>
  );

  if (isDragActive) {
    content = <span>Aqui, sueltelos aqui...</span>;
  }

  if (processing) {
    content = <span>Procesando...</span>;
  }

  if (processing || disabled) {
    dropDownContainerClasses.push(classes.disabled);
  }

  return (
    <div className={dropDownContainerClasses.join(" ")} {...rootProps}>
      <input {...inputProps} disabled={disabled} />
      {content}
    </div>
  );
};

export default UploadFilesDropDown;
