const initialState = {
    orphanNumber: 69,
  };
  const reducer = (state = initialState, action) => {
    
    switch (action.type) {
      case "CHANGE_NUMBER":
        return {
          ...state,
        orphanNumber: action.orphanNumber,
        };
      
      default:
        return state;
    }
    
    
  };
  
  export default reducer;
  