import React from "react";
import { useSelector } from "react-redux";
import apiAttributes from "../../../api/attributes";
import checkSync from "../../../utilities/checkSync";
import EditAttribute from "../EditAttribute/EditAttribute";

const Attribute = (props) => {
  const attributeId = props.match ? Number(props.match.params.id) : props.id;
  const accesToken = useSelector((state) => state.user.access);

  return (
    <EditAttribute
      id={attributeId}
      modal={props.modal}
      close={props.onHide}
      beforeClose={props.beforeClose}
      onLoad={() => {
        return apiAttributes
          .filter(accesToken, {
            filtername: "id",
            value: attributeId,
          })
          .then((response) => {
            return {
              name: response.data[0].name,
              description: response.data[0].description,
              type: response.data[0].attribute_type,
            };
          });
      }}
      onSave={(payload) => {
        return apiAttributes.edit(accesToken, {
          id: attributeId,
          ...payload,
        });
      }}
      onSync={async () => {
        return apiAttributes.sync(accesToken, {
          attributeId,
        });
      }}
      onCheckSync={async () => {
        return checkSync.checkAttributeSync(accesToken, {
          attributeId,
        });
      }}
      loadSyncCheck={async () => {
        const response = await apiAttributes.checkSync(accesToken, {
          attributeId,
        });

        return response.data.attribute[0];
      }}
    />
  );
};

export default Attribute;
