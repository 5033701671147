import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ItemsList from "../../components/ItemsList/ItemsList";
import Spinner from "../../components/Spinner/Spinner";
import { Card, Form, Col, Button } from "react-bootstrap";
import apiProducts from "../../api/products";
import classes from "./Search.module.css";
import apiCategories from "../../api/categories";
import apiArticles from "../../api/articles";
import handleApiErrors from "../../utilities/handleApiErrors";
import stringSimilarity from "string-similarity";

const LOADING = "LOADING";

const Search = (props) => {
  const accesToken = useSelector((state) => state.user.access);

  const [listChunks, setListChunks] = useState(1);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [articles, setArticles] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchField, setSearchField] = useState("name");
  const [searchMessage, setSearchMessage] = useState("Inserte nombre a buscar");

  const [activeList, setActiveList] = useState(undefined);

  const itemsPerPage = 10;

  const isMobile = window.screen.width < 900;

  const clickCategory = (id) => {
    props.history.push(`/category/${id}`);
  };

  const clickProduct = (id) => {
    props.history.push(`/product/${id}`);
  };

  const clickArticle = (id) => {
    props.history.push(`/article/${id}`);
  };

  //SIMILARITY LOGIC
  const similarityFunction = (pArray, setAllArray, pMinScore) => {
    const queryedItems = pArray.map((item) => {
      const scoredItem = {
        ...item,
        score: stringSimilarity.compareTwoStrings(
          searchQuery.toLocaleLowerCase(),
          item.name.toLocaleLowerCase()
        ),
      };
      return scoredItem;
    });

    const arrayfilteredSorted = queryedItems.sort((a, b) => {
      if (a.score > b.score) {
        return -1;
      }
      if (a.score < b.score) {
        return 1;
      }
      return 0;
    });

    const exactItems = [];
    const similarItems = [];

    arrayfilteredSorted.forEach((product) => {
      if (product.name.toLocaleLowerCase().includes(searchQuery)) {
        exactItems.push(product);
      } else {
        similarItems.push(product);
      }
    });

    setAllArray([
      ...exactItems,
      ...similarItems.filter((item) => item.score > pMinScore),
    ]);
  };
  //END OF SIMILARITY LOGIC

  const onSearch = () => {
    apiCategories
      .listAll(accesToken)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          if (searchField === "name") {
            similarityFunction(data, setCategories, 0.1);
          } else if (searchField === "id") {
            setCategories([]);
            data.map((item) => {
              if (Number(searchQuery) === item.id) {
                setCategories([item]);
              }
            });
          }
        } else {
          setCategories([]);
        }
      })
      .catch((e) => {
        // console.log(e);
      });

    setCategories(LOADING);

    apiProducts
      .listAll(accesToken)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          if (searchField === "name") {
            similarityFunction(data, setProducts, 0.1);
          } else if (searchField === "id") {
            setProducts([]);
            data.map((item) => {
              if (Number(searchQuery) === item.id) {
                setProducts([item]);
              }
            });
          }
        } else {
          setProducts([]);
        }
      })
      .catch((e) => {
        // console.log(e);
      });

    setProducts(LOADING);

    apiArticles
      .listAll(accesToken)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          const updatedArticles = data.map((article) => ({
            ...article,
            name: article.description ?? article.ref,
            id: article.id,
          }));

          if (searchField === "name") {
            similarityFunction(updatedArticles, setArticles, 0.1);
          } else if (searchField === "id") {
            setArticles([]);
            updatedArticles.map((item) => {
              if (Number(searchQuery) === item.id) {
                setArticles([item]);
              }
            });
          } else if (searchField === "ref") {
            setArticles([]);
            setCategories([]);
            setProducts([]);
            updatedArticles.map((item) => {
              if (
                searchQuery.toLocaleLowerCase() === item.ref.toLocaleLowerCase()
              ) {
                setArticles([item]);
              }
            });
          }
        } else {
          setArticles([]);
        }
      })
      .catch((e) => {
        // console.log(e);
      });

    setArticles(LOADING);

    setListChunks(1);
  };

  useEffect(() => {
    const itemToTrigger = document.querySelector(
      `#categories-list .list-group-item:nth-child(${
        listChunks * itemsPerPage
      })`
    );

    if (itemToTrigger !== null) {
      const observer = new IntersectionObserver(
        (entires) => {
          if (entires[0].isIntersecting) {
            setListChunks(listChunks + 1);
          }
        },
        { threshold: [1] }
      );

      observer.observe(itemToTrigger);
      // return observer.disconnect();
    }
  }, [categories, activeList, listChunks]);

  useEffect(() => {
    const itemToTrigger = document.querySelector(
      `#products-list .list-group-item:nth-child(${listChunks * itemsPerPage})`
    );

    if (itemToTrigger !== null) {
      const observer = new IntersectionObserver(
        (entires) => {
          if (entires[0].isIntersecting) {
            setListChunks(listChunks + 1);
          }
        },
        { threshold: [1] }
      );

      observer.observe(itemToTrigger);
      // return observer.disconnect();
    }
  }, [products, activeList, listChunks]);

  useEffect(() => {
    const itemToTrigger = document.querySelector(
      `#articles-list .list-group-item:nth-child(${listChunks * itemsPerPage})`
    );

    if (itemToTrigger !== null) {
      const observer = new IntersectionObserver(
        (entires) => {
          if (entires[0].isIntersecting) {
            setListChunks(listChunks + 1);
          }
        },
        { threshold: [1] }
      );

      observer.observe(itemToTrigger);
      // return observer.disconnect();
    }
  }, [articles, activeList, listChunks]);

  return (
    <>
      <Card className="p-3 mt-2">
        <Form
          className={classes.searchForm}
          onSubmit={
            // * IF THE SEARCH INPUT HAS 0, 1 OR 2 LETTERS THE SEARCH WOULDNT RUN
            searchQuery.length > 1
              ? (e) => {
                  e.preventDefault();
                  onSearch();
                }
              : null
          }
        >
          <Form.Row>
            <Col md={7}>
              <Form.Control
                placeholder={searchMessage}
                value={searchQuery}
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
              />
            </Col>
            <Col md={5} className={classes.searchOptions}>
              <Form.Control
                as="select"
                custom
                value={searchField}
                onChange={(event) => {
                  const searchOption = event.target.value;
                  setSearchField(searchOption);
                  switch (searchOption) {
                    case "name":
                      setSearchMessage("Inserte nombre a buscar");
                      break;
                    case "id":
                      setSearchMessage("Inserte id a buscar");
                      break;
                    case "ref":
                      setSearchMessage("Inserte referencia a buscar");
                      break;

                    default:
                      break;
                  }
                }}
              >
                <option value="name">Nombre</option>
                <option value="id">ID</option>
                <option value="ref">Referencia</option>
              </Form.Control>
              <Button onClick={searchQuery.length <= 2 ? null : onSearch} block>
                Buscar
              </Button>
            </Col>
          </Form.Row>
        </Form>
        <div className={classes.results}>
          {products === LOADING ? (
            <Spinner />
          ) : (
            <div id="products-list">
              <ItemsList
                hide={products.length === 0 ? "hide" : null}
                show={activeList === 1}
                clickFoldUnfold={() => {
                  if (activeList === 1) {
                    setActiveList(undefined);
                  } else {
                    setActiveList(1);
                  }

                  setListChunks(1);
                }}
                title="Productos"
                items={
                  isMobile
                    ? products.slice(0, listChunks * itemsPerPage)
                    : products
                }
                topCount={products.length}
                showEndSpinner={
                  isMobile && products.length > listChunks * itemsPerPage
                }
                clickItem={clickProduct}
              />
            </div>
          )}
          {categories === LOADING ? (
            <Spinner />
          ) : (
            <div id="categories-list">
              <ItemsList
                // hide="hide"
                hide={categories.length === 0 ? "hide" : null}
                show={activeList === 0}
                clickFoldUnfold={() => {
                  if (activeList === 0) {
                    setActiveList(undefined);
                  } else {
                    setActiveList(0);
                  }

                  setListChunks(1);
                }}
                title="Categorías"
                items={
                  isMobile
                    ? categories.slice(0, listChunks * itemsPerPage)
                    : categories
                }
                topCount={categories.length}
                showEndSpinner={
                  isMobile && products.length > listChunks * itemsPerPage
                }
                clickItem={clickCategory}
              />
            </div>
          )}
          {articles === LOADING ? (
            <Spinner />
          ) : (
            <div id="articles-list">
              <ItemsList
                hide={articles.length === 0 ? "hide" : null}
                show={activeList === 2}
                clickFoldUnfold={() => {
                  if (activeList === 2) {
                    setActiveList(undefined);
                  } else {
                    setActiveList(2);
                  }

                  setListChunks(1);
                }}
                title="Articles"
                items={
                  isMobile
                    ? articles.slice(0, listChunks * itemsPerPage)
                    : articles
                }
                topCount={articles.length}
                showEndSpinner={
                  isMobile && products.length > listChunks * itemsPerPage
                }
                clickItem={clickArticle}
              />
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default Search;
