import customAxios from "../../customAxios";

const add = (
  access: string,
  data: {
    campaign_pk: number;
    keyword_string_pks: number[];
    locations: number[];
    language: number;
  }
) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/sem/seed-group/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

const remove = (
  access: string,
  data: {
    seedGroup_pk: number;
  }
) => {
  return customAxios({
    method: "delete",
    url: `${process.env.REACT_APP_API_SERVER}/api/sem/seed-group/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

const update = (
  access: string,
  data: {
    seedGroup_pk: number;
    keyword_string_pks: number[];
    locations: number[];
  }
) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/sem/seed-group/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

export default {
  add,
  remove,
  update,
};
