import React, { Fragment, useState, useEffect } from "react";
import { Alert, Modal, Button, Row, Col, Pagination } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import stringSimilarity from "string-similarity";
import Spinner from "../../components/Spinner/Spinner";
import ButtonSpinner from "../../components/ButtonSpinner/ButtonSpinner";
import TableOptionsAdd from "../../components/TableOptionsAdd/TableOptionsAdd";
import DuplicatesTable from "../../components/DuplicatesTable/DuplicatesTable";
import AddsTable from "../../components/AddsTable/AddsTable";
import TextInput from "../../components/TextInput/TextInput";
import classes from "./AdministerCopies.module.css";
import later from "../../utilities/later";
import handleApiErrors from "../../utilities/handleApiErrors";

const LOADING = "LOADING";
const LOADED = "LOADED";
const PROCESSING = "PROCESSING";
const PROCESSED = "PROCESSED";
const ERROR = "ERROR";

const AdministerCopies = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [items, setItems] = useState(LOADING);
  const [chooseDuplicates, setChooseDuplicates] = useState(false);
  const [duplicateNameError, setDuplicateNameError] = useState(false);

  const [saveItemsProcessPhase, setSaveItemsProcessPhase] = useState();
  const [addNewItemProcessPhase, setAddNewItemsProcessPhase] = useState();
  const [unifyItemsProcessPhase, setUnfyItemsProcessPhase] = useState();

  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const allPagesList = [...Array(Math.ceil(items.length / itemsPerPage))].map(
    (undefined, i) => i
  );

  if (items.length > 0) {
    if (pageNumber < 0) {
      setPageNumber(0);
    } else if (pageNumber > allPagesList.length - 1) {
      setPageNumber(allPagesList.length - 1);
    }
  }

  let pagesList = [];
  if (pageNumber < 6) {
    pagesList = allPagesList.slice(0, 6);
  } else if (pageNumber > allPagesList.length - 6) {
    pagesList = allPagesList.slice(
      allPagesList.length - 6,
      allPagesList.length
    );
  } else {
    pagesList = allPagesList.slice(pageNumber - 3, pageNumber + 3);
  }

  const { selectedItems, createItem } = props;

  useEffect(() => {
    if (items === LOADING) {
      props
        .loadItems()
        .then(({ data }) => {
          // console.log(data);
          const updatedItems = data.filter(
            (item) => !selectedItems.includes(item.id)
          );

          setItems(updatedItems);
        })
        .catch((e) => {
          console.log(e);
          handleApiErrors(e);
        });
    } else {
      const selectedItemInItems = items.find((item) =>
        selectedItems.includes(item.id)
      );

      if (selectedItemInItems) {
        const updatedItems = items.filter(
          (item) => !selectedItems.includes(item.id)
        );

        setItems(updatedItems);
      }
    }
  }, [items, selectedItems]);

  useEffect(() => {
    const itemToTrigger = document.querySelector(
      `.itemsList tr:nth-child(${itemsPerPage})`
    );

    const windowWidth =
      window.screen.width < window.outerWidth
        ? window.screen.width
        : window.outerWidth;
    const mobile = windowWidth < 500;

    if (itemToTrigger !== null && mobile) {
      const observer = new IntersectionObserver(
        (entires) => {
          if (entires[0].isIntersecting) {
            setItemsPerPage((i) => i + 20);
          }
        },
        { threshold: [1] }
      );

      observer.observe(itemToTrigger);
    }
  }, [items, itemsPerPage]);

  useEffect(() => {
    if (addNewItemProcessPhase === PROCESSED) {
      setTimeout(() => {
        setAddNewItemsProcessPhase(undefined);
      }, 500);
    }
  }, [addNewItemProcessPhase]);

  const addOneCategory = (id) => {
    const updatedItems = items.map((category) => {
      if (category.id === id) {
        return {
          ...category,
          selected: true,
        };
      }

      delete category.selected;

      return category;
    });

    setItems(updatedItems);

    setSaveItemsProcessPhase(PROCESSING);

    props.applySelection(updatedItems.find((item) => item.selected));
  };

  const addMultipleItems = (id) => {
    const updatedItems = items.map((category) => {
      if (category.id === id) {
        return {
          ...category,
          selected: true,
        };
      }

      return category;
    });

    props.applySelection(updatedItems.filter((item) => item.selected));

    setItems(updatedItems);
  };

  const unselect = (id) => {
    const updatedItems = items.map((item) => {
      if (item.id === id) {
        delete item["selected"];
      }

      return item;
    });

    if (props.multiSelect) {
      props.applySelection(updatedItems.filter((item) => item.selected));
    } else {
      props.applySelection(updatedItems.find((item) => item.selected));
    }

    setItems(updatedItems);
  };

  const selectDuplicate = (id) => {
    const father = items.find((itm) => itm.father === true);

    const updatedCategories = items.map((itm) => {
      if (itm.id === id) {
        if (!father) {
          return {
            ...itm,
            father: true,
          };
        } else {
          return {
            ...itm,
            copy: true,
          };
        }
      }

      return itm;
    });

    setItems(updatedCategories);
  };

  const removeDuplicate = ({ id }) => {
    let noFather = false;

    let updatedCategories = items.map((itm) => {
      if (itm.id === id) {
        const updatedCat = { ...itm };

        delete updatedCat["father"];
        delete updatedCat["copy"];

        if (itm.father) {
          noFather = true;
        }

        return updatedCat;
      }

      return itm;
    });

    if (noFather) {
      updatedCategories = updatedCategories.map((itm) => {
        const updatedCat = itm;

        if (itm.copy && noFather) {
          delete updatedCat["copy"];
          updatedCat.father = true;
          noFather = false;
        }

        return updatedCat;
      });
    }

    setItems(updatedCategories);
  };

  const swapCategories = ({ id }) => {
    const mainCategoryIndex = items.findIndex((itm) => itm.id === id);
    let updatedCategories = [...items];
    const mainCategory = updatedCategories[mainCategoryIndex];

    if (mainCategory.father) {
      let noFather = true;

      updatedCategories = updatedCategories.map((itm) => {
        const updatedCat = { ...itm };

        if (itm.copy && noFather) {
          updatedCat.father = true;
          delete updatedCat["copy"];
          noFather = false;
        }

        return updatedCat;
      });

      updatedCategories[mainCategoryIndex].copy = true;
      delete updatedCategories[mainCategoryIndex]["father"];
    } else {
      updatedCategories = updatedCategories.map((itm) => {
        const updatedCat = { ...itm };

        if (updatedCat.father) {
          updatedCat.copy = true;
        }

        delete updatedCat["father"];

        return updatedCat;
      });

      updatedCategories[mainCategoryIndex].father = true;
      updatedCategories[mainCategoryIndex].copy = false;
    }

    setItems(updatedCategories);
  };

  const addItem = async () => {
    setDuplicateNameError(false);

    if (inputValue.length > 0) {
      setAddNewItemsProcessPhase(PROCESSING);

      try {
        const response = await createItem(inputValue);

        setItems([
          ...items,
          {
            id: response.data.id,
            name: inputValue,
          },
        ]);
        setAddNewItemsProcessPhase(PROCESSED);
      } catch (e) {
        if (e.response && e.response.status === 409) {
          setDuplicateNameError(true);
        }

        setAddNewItemsProcessPhase(ERROR);
      }
    } else {
      setAddNewItemsProcessPhase(ERROR);
    }
  };

  const onSave = () => {
    setSaveItemsProcessPhase(PROCESSING);

    const itemsToSave = items.filter((item) => {
      return item.selected;
    });

    props
      .onSave(itemsToSave)
      .then(() => {
        props.onSaved(itemsToSave);
      })
      .then(() => {
        setSaveItemsProcessPhase(LOADED);
        props.close();
      })
      .catch((e) => {
        handleApiErrors(e);
      });
  };

  const processing =
    saveItemsProcessPhase === PROCESSING ||
    addNewItemProcessPhase === PROCESSING ||
    unifyItemsProcessPhase === PROCESSING;

  let content;
  if (saveItemsProcessPhase === LOADING || items === LOADING) {
    content = (
      <div className={classes.spinnerContainer}>
        <Spinner />
      </div>
    );
  } else {
    let queryedItems = items.map((item) => {
      const scoredItem = {
        ...item,
        score: stringSimilarity.compareTwoStrings(
          inputValue.toLocaleLowerCase(),
          item.name.toLocaleLowerCase()
        ),
      };
      return scoredItem;
    });
    queryedItems = queryedItems.sort((a, b) => {
      if (a.score > b.score) {
        return -1;
      }
      if (a.score < b.score) {
        return 1;
      }
      return 0;
    });

    const areItemsSelected = items.find((itm) => itm.selected);

    const itemsToDisplay = queryedItems.slice(
      pageNumber * itemsPerPage,
      pageNumber * itemsPerPage + itemsPerPage
    );

    content = (
      <Fragment>
        {areItemsSelected && props.multiSelect ? (
          <AddsTable
            title={props.addTableTitle}
            items={items.filter((itm) => itm.selected)}
            unselectItem={unselect}
            onSave={onSave}
            processing={processing}
          />
        ) : undefined}
        {unifyItemsProcessPhase === PROCESSED ? (
          <Alert className={classes.duplicatesMessage} variant="success">
            Se han unificado los atributos, el proceso tardará unos minutos. Se
            le avisará cuando esté acabado.
          </Alert>
        ) : undefined}
        {chooseDuplicates && unifyItemsProcessPhase !== PROCESSED ? (
          <DuplicatesTable
            title={props.copiesTableTitle}
            father={items.find((itm) => itm.father)}
            duplicates={items.filter((itm) => itm.copy === true)}
            remove={removeDuplicate}
            swap={swapCategories}
            unifyItems={(fatherId, copyIds) => {
              const updatedItems = items.filter(
                (item) => !copyIds.includes(item.id)
              );

              props.unifyItems(fatherId, copyIds);

              setUnfyItemsProcessPhase(PROCESSED);
              setItems(updatedItems);
            }}
            processing={processing}
            processUnifyItems={unifyItemsProcessPhase === PROCESSING}
          />
        ) : undefined}
        <div className="itemsList">
          <TableOptionsAdd
            noResults={
              itemsToDisplay.length <= 0 ||
              (itemsToDisplay[0].score === 0 && inputValue.length > 2)
            }
            items={itemsToDisplay}
            addItem={props.multiSelect ? addMultipleItems : addOneCategory}
            unselect={unselect}
            selectCopiesMode={chooseDuplicates}
            selectCopy={selectDuplicate}
            unselectCopy={removeDuplicate}
            processing={processing}
            processingSaveItems={saveItemsProcessPhase === PROCESSING}
            enableAnimation={
              addNewItemProcessPhase === PROCESSING ||
              addNewItemProcessPhase === PROCESSED
            }
          />
        </div>
        <div className={`d-flex justify-content-center hideOnMobile`}>
          <Pagination className="hideOnMobile">
            <ReactTooltip delayShow={1000} place="top" type="info" />
            <Pagination.First
              onClick={() => setPageNumber(0)}
              data-tip="Primera página"
            />
            <Pagination.Prev
              onClick={() => setPageNumber(pageNumber - 1)}
              data-tip="Página anterior"
            />
            <Pagination.Ellipsis
              onClick={() => setPageNumber(pageNumber - 5)}
              data-tip="Ir 5 páginas hacia atrás"
            />
            {pagesList.map((i) => (
              <Pagination.Item
                key={i}
                active={pageNumber === i}
                onClick={() => setPageNumber(i)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
            <Pagination.Ellipsis
              onClick={() => setPageNumber(pageNumber + 5)}
              data-tip="Ir 5 páginas hacia delante"
            />
            <Pagination.Next
              onClick={() => setPageNumber(pageNumber + 1)}
              data-tip="Página siguiente"
            />
            <Pagination.Last
              onClick={() => setPageNumber(allPagesList.length - 1)}
              data-tip="Última página"
            />
          </Pagination>
        </div>
      </Fragment>
    );
  }

  let errorMessage;

  if (addNewItemProcessPhase === ERROR) {
    errorMessage =
      "Existen errores, por favor rellene el campo de manera correcta";

    if (duplicateNameError) {
      errorMessage =
        "Ya existe un elemento con ese nombre, introduzca otro nombre";
    }
  }

  // El formato de literals introducido no es el correcto, acuerdate de poner la barra separadora

  return (
    <Modal
      className={classes.modifyModal}
      show={props.show === undefined ? true : props.show}
      size="lg"
      onHide={() => {
        props.close();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.modifyBottomModal}>
        <Row>
          <Col
            md={12}
            className={
              addNewItemProcessPhase === ERROR ? classes.inputError : ""
            }
          >
            {errorMessage ? (
              <Alert variant="danger">{errorMessage}</Alert>
            ) : undefined}
            <TextInput
              placeholder="Introduce tu búsqueda aquí"
              onChange={(event) => {
                if (addNewItemProcessPhase === ERROR) {
                  setAddNewItemsProcessPhase(undefined);
                }

                setInputValue(event.target.value);
              }}
              disabled={processing}
              value={inputValue}
              error={addNewItemProcessPhase === ERROR && inputValue.length <= 0}
              errorMessage="Necesitas especificar un valor para crear un nuevo artículo"
            />
          </Col>
          <Col className={classes.searchFormOptions}>
            {/* <Button disabled={processing}>Search</Button> */}
            {props.createItem ? (
              <Button
                // * Tooltip in the "Añadir elemento" button only if we pass (literalTooltip === true) with props
                data-tip={
                  props.literalTooltip === true
                    ? "Introduce primero el singular y luego el plural, divididos por una doble barra: XXXXX // YYYYY"
                    : null
                }
                variant="info"
                onClick={addItem}
                disabled={processing}
              >
                {addNewItemProcessPhase === PROCESSING ? (
                  <ButtonSpinner />
                ) : (
                  "Añadir elemento"
                )}
              </Button>
            ) : undefined}

            <Button
              // className={classes.hidden}
              className={
                props.buttonDescription === "hide"
                  ? classes.hide
                  : classes.optionButton
              }
              variant="warning"
              onClick={() => {
                setChooseDuplicates(!chooseDuplicates);
              }}
              disabled={processing}
            >
              {props.buttonDescription}
              {/* esto hay que mirarlo */}
            </Button>
          </Col>
        </Row>

        {content}
      </Modal.Body>
    </Modal>
  );
};

export default AdministerCopies;
