import displayUnauthorizedNotification from "./displayUnauthorizedNotification";
import displayErrorNotification from "./displayErrorNotification";
import history from "../history";

const handleApiErrors = (e) => {
  console.log(e);

  if (e.response && e.response.status === 401) {
    //  TODO Commented just to not show the pop up message when the login  user & password donesnt exists
    // displayUnauthorizedNotification();
    history.push("/logIn")
  } else {
    displayErrorNotification();
  }
};

export default handleApiErrors;
