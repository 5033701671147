import React, { useState, useEffect } from "react";
import classes from "./AdType.module.css";
import { Button, ButtonGroup, Col, Dropdown, Form, Row } from "react-bootstrap";

interface Props {
  setProccessNumber: any;
  setadTypeSelection: any;
  adTypeSelection: any;
}
const AdType: React.FC<Props> = ({
  setProccessNumber,
  setadTypeSelection,
  adTypeSelection,
}) => {
  // const [adTypeSelection, setadTypeSelection] = useState<number>();
  return (
    <>
      <div className={classes.checkBoxContent}>
        <div className={classes.checkBoxRow}>
          <input
            onClick={() => {
              setadTypeSelection("ETA");
            }}
            className={classes.paddingRigth}
            type="radio"
            value="ETA"
            name="selection"
          />
          <b>ETA - Expanded Text Ads</b> <br />
          Los anuncios de texto expandidos (ETA), que son la nueva generación de
          anuncios de texto de Google, pueden publicarse en la Red de Display en
          un formato diferente al de la Búsqueda. A fin de aumentar su impacto,
          los ETA gráficos pueden, por ejemplo, tener un diseño diferente en sus
          líneas descriptivas.
        </div>
        <div className={classes.checkBoxRow}>
          <input
            onClick={() => {
              setadTypeSelection("RSA");
            }}
            className={classes.paddingRigth}
            type="radio"
            value="RSA"
            name="selection"
          />
          <b>RSA - Responsive Search Ads</b>
          <br />
          Los anuncios de búsqueda adaptables (RSA) usan el aprendizaje
          automático para automatizar la creación de anuncios. Solo tiene que
          proporcionar varios textos de anuncio y el aprendizaje automático
          determinará cuál es el mensaje más adecuado para su audiencia.
        </div>
        <div className={classes.checkBoxRow}>
          <input
            onClick={() => {
              setadTypeSelection("DSA");
            }}
            className={classes.paddingRigth}
            type="radio"
            value="DSA"
            name="selection"
          />{" "}
          <b>DSA - Dynamic Search Ads</b> <br />
          DSA es el acrónimo de Dynamic Search Ads, un tipo de campaña en
          Adwords que permite ampliar el inventario de palabras clave en una
          cuenta de manera automática, además de establecer la URL más propicia
          para cada término de búsqueda siguiendo el criterio de Google.
        </div>
      </div>
    </>
  );
};
export default AdType;
