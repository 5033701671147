import customAxios from "../../customAxios";

const createAd = (
  access: string,
  data: {
    ad_type: number;
    descriptions: Array<number>;
    promotional: boolean;
    adGroup_pk: number;
    entity_pk: number;
    entity_type: number;
    account_pk: number;
    headlines?: Array<number>;
  }
) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}​/api​/sem​/ad​/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

export default { createAd };
