import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import apiCertificates from "../../../../../../api/certificates";
import apiProducts from "../../../../../../api/products";

import dsiplayNoCertificatePdfNotification from "../../../../../../utilities/notifications/noCertificatePdf";

const useCertificatesList: (productId: number) => any = (productId) => {
  const [certificates, setCertificates] = useState<any[]>([]);
  const [showAddCertificateModal, setShowAddCertificateModal] = useState(false);
  const [certificateIdToEdit, setCertificateIdToEdit] = useState<number>();

  const accesToken = useSelector((state: any) => state.user.access);

  useEffect(() => {
    (async () => {
      try {
        if (!productId) {
          return;
        }

        const response = await apiCertificates.filter(accesToken, {
          filterName: "ProductId",
          id: productId,
        });

        setCertificates(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleAddCertificateModal = () => {
    setShowAddCertificateModal(true);
  };

  const handleCloseAddCertificateModal = () => {
    setShowAddCertificateModal(false);
  };

  const handleLinkCertificates = (certificatesToAdd: any[]) => {
    setCertificates((certificates) => {
      return [...certificates, ...certificatesToAdd];
    });
    setShowAddCertificateModal(false);
  };

  const handleClickRemoveCertificate = async (index: number) => {
    try {
      setCertificates((certs) => {
        return certs.filter((_, k) => k !== index);
      });

      await apiProducts.unlinkCertificates(accesToken, {
        productIds: [productId],
        certificateIds: [certificates[index].id],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpenPdf = async (id: number) => {
    try {
      const certificateToShow = certificates.find((cert) => cert.id === id);

      const response = await apiCertificates.openPdf(accesToken, {
        filename: certificateToShow.name + ".pdf",
      });

      const file = new Blob([response.data], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      dsiplayNoCertificatePdfNotification();
      console.log(error);
    }
  };

  const handleClickEditCertificate = (certificateId: number) => {
    setCertificateIdToEdit(certificateId);
  };

  const handleCloseEditCertificateModal = () => {
    setCertificateIdToEdit(undefined);
  };

  const handleSaveAndClose: (data: {
    name: string;
    description: string;
  }) => void = ({ name, description }) => {
    const id = certificateIdToEdit;

    setCertificates((certs) => {
      const updatedCerts: any = certs.map((cert) => {
        if (cert.id === id) {
          return {
            ...cert,
            name,
            description,
          };
        }

        return cert;
      });

      return updatedCerts;
    });

    handleCloseEditCertificateModal();
  };

  const addCertificate = (cert: any) => {
    setCertificates((certs) => [...certs, cert]);
  };

  const showEditCertificateModal = certificateIdToEdit !== undefined;

  const certificateIds = certificates.map((cert) => cert.id);

  return {
    certificates,
    showAddCertificateModal,
    handleAddCertificateModal,
    handleCloseAddCertificateModal,
    handleLinkCertificates,
    certificateIds,
    handleClickRemoveCertificate,
    handleClickOpenPdf,
    handleClickEditCertificate,
    handleCloseEditCertificateModal,
    showEditCertificateModal,
    certificateIdToEdit,
    handleSaveAndClose,
    addCertificate,
  };
};

export default useCertificatesList;
