import { useState } from "react";
import { useSelector } from "react-redux";
import apiSync from "../../../../api/sync";

const useSyncProducts = () => {
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(0);

  const accesToken = useSelector((state: any) => state.user.access);

  const handleClickPage = async (value: number) => {
    try {
      const productsFromPageResponse = await apiSync.getSyncErrors(accesToken, {
        filtername: "products",
        syncFail: false,
        needSync: false,
        synchronizing: false,
        noId: false,
        page: value + 1,
      });

      setProducts(productsFromPageResponse.data.results);
      setActivePage(value);
    } catch (error) {
      console.log(error);
    }
  };

  const totalPages = Math.ceil(total / 20);

  return {
    products,
    setProducts,
    totalProducts: total,
    setTotalProducts: setTotal,
    activeProductPage: activePage,
    handleClickProductPage: handleClickPage,
    totalProductPages: totalPages,
  };
};

export default useSyncProducts;
