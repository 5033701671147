import { useState } from "react";

const useCropImage: (crop: any, handleClickSave: () => void) => any = (
  crop,
  handleClickSave
) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);

  const handleSubmitForm = () => {
    setHasBeenSubmitted(true);

    if (!crop.width) {
      return;
    }

    handleClickSave();
  };

  const userDidNotCropImage = hasBeenSubmitted && !crop.width;

  return {
    handleSubmitForm,
    userDidNotCropImage,
  };
};

export default useCropImage;
