import React from "react";
import { Button } from "react-bootstrap";
import classes from "./ButtonTableOption.module.css";

const ButtonTableOption = (props) => (
  <Button
    className={classes.buttonTableOption}
    variant={props.variant}
    onClick={props.onClick}
    disabled={props.disabled}
  >
    {props.children}
  </Button>
);

export default ButtonTableOption;
