import React from "react";
import classes from "./PseudoTable.module.css";

const Table = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

const Header = ({ children, className }) => {
  return (
    <div className={`${classes.header} hideOnMobile ${className}`}>
      {children}
    </div>
  );
};

const Row = ({ children, className }) => {
  return <div className={`${classes.row} ${className}`}>{children}</div>;
};

export default { Table, Header, Row };
