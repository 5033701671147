import { useState, useEffect, useRef } from "react";

import IdeaTableTitle from "../types/IdeaTableTitle";

import Idea from "../types/Idea";

import mockIdeasList from "../KeyWordsListData.json";

const useSemIdeas = () => {
  const [ideasList, setIdeasList] = useState<Idea[]>();
  const [ideasTableTitles, setIdeasTableTitles] = useState<IdeaTableTitle[]>([
    { name: "Keyword", active: true },
    { name: "AVG", active: true },
    { name: "CPC min", active: true },
    { name: "CPC max", active: true },
    { name: "Comp", active: true },
    { name: "AD_Group", active: true },
  ]);

  const [showIdeasTitlesDropDown, setIdeasTitlesDropDown] =
    useState<boolean>(false);
  const filterOptionsContainerRef = useRef<any>();

  const handleToggleIdeasTitlesDropDown = (val: boolean) => {
    setIdeasTitlesDropDown(val);
  };

  const handleCloseIdeasTitlesDropDown = () => setIdeasTitlesDropDown(false);

  // Hide menu when click outside
  useEffect(() => {
    const clickOutsideOptions = (event: any) => {
      const optionsContainer = filterOptionsContainerRef.current;

      if (
        optionsContainer &&
        showIdeasTitlesDropDown &&
        !optionsContainer.contains(event.target)
      ) {
        handleCloseIdeasTitlesDropDown();
      }
    };

    document.addEventListener("click", clickOutsideOptions);

    // Refresh useEffect
    return () => document.removeEventListener("click", clickOutsideOptions);
  }, [filterOptionsContainerRef, showIdeasTitlesDropDown]);

  const handleClickIdeaTableTitle = (position: number) => {
    setIdeasTableTitles((prevTitles: IdeaTableTitle[]) => {
      const updatedTitles = prevTitles.map((filt, i) => {
        if (i === position) {
          return { ...filt, active: !filt.active };
        }

        return filt;
      });

      const activeTitles = updatedTitles.filter((title) => title.active);

      if (activeTitles.length === 0) {
        return prevTitles;
      }

      return updatedTitles;
    });
  };

  return {
    ideasTableTitles,
    handleClickIdeaTableTitle,
    filterOptionsContainerRef,
    showIdeasTitlesDropDown,
    handleToggleIdeasTitlesDropDown,
  };
};

export default useSemIdeas;
