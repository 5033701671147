import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import AdministerCopies from "../../AdministerCopies/AdministerCopies";
import Article from "../../Edit/Article/Article";
import TitleContainer from "../../../components/TitleContainer/TitleContainer";
import OptionsButton from "../../../components/OptionsButton/OptionsButton";
import ArticlesTable from "../../../components/ArticlesTable/ArticlesTable";
import apiArticles from "../../../api/articles";

const SecondCreateProductPhase = (props) => {
  const {
    productId,
    accesToken,
    articles,
    loadArticles,
    saveArticles,
    unlinkArticle,
    selectToUnlink,
    unlinkArticles,
    showAddArticles,
    setshowAddArticle,
  } = props;
  const [showArticle, setshowArticle] = useState();
  const [processingArticles, setProcessingArticles] = useState(false);

  const handleSyncAllArticles = async () => {
    setProcessingArticles(true);

    try {
      await apiArticles.syncAll(accesToken, { productId });
    } catch (e) {
      console.log(e);
    }

    setProcessingArticles(false);
  };

  return (
    <>
      <TitleContainer>
        <h3>Artículos</h3>
        <div>
          <OptionsButton
            arrowRepeat={() => {}}
            click={handleSyncAllArticles}
            disabled={processingArticles}
            loading={processingArticles}
          >
            Sincronizar todo
          </OptionsButton>
          {articles.find((item) => item.remove) ? (
            <OptionsButton
              variant="danger"
              x={() => {}}
              click={unlinkArticles}
              disabled={processingArticles}
              loading={processingArticles}
            >
              Desvincular artículos
            </OptionsButton>
          ) : undefined}
          <OptionsButton
            variant="success"
            plus={() => {}}
            click={() => {
              setshowAddArticle(true);
            }}
            disabled={processingArticles}
            loading={processingArticles}
          >
            Añadir artículo
          </OptionsButton>
        </div>
      </TitleContainer>
      {articles.length > 0 ? (
        <ArticlesTable
          onClickRemoveArticle={selectToUnlink}
          articles={articles}
          x={unlinkArticle}
          clickEditArticle={(id) => {
            props.openArticle();
            setshowArticle(id);
          }}
        />
      ) : (
        <Alert variant="warning">No hay ningún artículo elegido</Alert>
      )}
      {showAddArticles ? (
        <AdministerCopies
          addButtonDescription="hide"
          buttonDescription="hide"
          title="Añadir artículos"
          addTableTitle="Artículo a añadir"
          copiesTableTitle="Artículo original"
          loadItems={loadArticles}
          applySelection={(item) => {}}
          selectedItems={[]}
          onSave={saveArticles}
          onSaved={async () => {}}
          close={() => {
            setshowAddArticle(false);
          }}
          multiSelect
        />
      ) : undefined}
      {showArticle ? (
        <Article
          id={showArticle}
          modal
          beforeClose={props.beforeCloseArticle}
          beforeCloseNoSave={props.beforeCloseArticleNoSave}
          onHide={() => setshowArticle(false)}
          beforeCloseNoSave={props.beforeCloseArticleNoSave}
        />
      ) : undefined}
    </>
  );
};

export default SecondCreateProductPhase;
