import React, { useEffect } from "react";

import { Modal, Button, Form, Spinner } from "react-bootstrap";

import UpgradedFilesList from "../../components/UpgradedFilesList/UpgradedFilesList";
import SelectImageRatioModal from "../../SelectImageRatioModal/SelectImageRatioModal";
import UploadFilesDropDown from "../../../../../../components/UploadFilesDropDown/UploadFilesDropDown";

import useCreateCertificate from "./hooks/use-createCertificate";

import classes from "./CreateCertificateModal.module.scss";

const CreateCertificateModal: React.FC<{
  id?: number;
  initialName?: string;
  initialDescription?: string;
  initialImage?: any;
  initialPdf?: any;
  productId?: number;
  whenEndSave?: (data: { name: string; description: string }) => void;
  handleFinishCreate?: (cert: any) => void;
  handleClose: () => void;
  handleOpenCropImage?: () => void;
  handleCloseCropImage?: () => void;
}> = ({
  id,
  initialName,
  initialDescription,
  initialImage,
  initialPdf,
  productId,
  whenEndSave,
  handleFinishCreate,
  handleClose,
  handleOpenCropImage,
  handleCloseCropImage,
}) => {
  const {
    name,
    setName,
    description,
    setDescription,
    imageDropZone,
    pdfDropzone,
    image,
    pdf,
    handleRemoveImage,
    handleClickPdf,
    handleRemovePdf,
    handleSaveCertificate,
    isSavingCertificate,
    validation,
    errorMessage,
    hasBeenTouched,
    handleTouchInput,
    hasBeenSubmitted,
    showSelectPhotoRatio,
    handleOpenSelectPhotoRatio,
    handleCloseSelectPhotoRatio,
    crop,
    setCrop,
    handleCropImage,
    handleClickCloseModal,
  } = useCreateCertificate(
    id,
    initialName,
    initialDescription,
    initialImage,
    initialPdf,
    handleClose,
    productId,
    handleFinishCreate,
    handleOpenCropImage,
    handleCloseCropImage
  );

  if (showSelectPhotoRatio) {
    return (
      <SelectImageRatioModal
        handleClose={handleCloseSelectPhotoRatio}
        src={image.src}
        crop={crop}
        setCrop={setCrop}
        handleClickSave={handleCropImage}
      />
    );
  }

  let imageContent;
  if (image === undefined) {
    imageContent = (
      <>
        <UploadFilesDropDown
          rootProps={imageDropZone.getRootProps()}
          inputProps={imageDropZone.getInputProps()}
          isDragActive={imageDropZone.isDragActive}
          disabled={isSavingCertificate}
          dropFilesMessage="Arrastre la imagen aquí"
          searchFile="Busquela en su ordenador"
        />
        {/* "Suelte la imagen aqui"
           "Arrastre la imagen o pulse en este espacio para seleccionarla de su ordenador" */}
        {hasBeenSubmitted ? (
          <Form.Control.Feedback type="invalid" className="d-block">
            {errorMessage.image}
          </Form.Control.Feedback>
        ) : undefined}
      </>
    );
  } else {
    let src = "";

    if (image.fullFile) {
      src = URL.createObjectURL(image.fullFile);
    } else {
      src = `https://prepro3.updirecto.es/img/certificados/${initialName}.jpg`;
    }

    imageContent = (
      <>
        {validation.image && (
          <Form.Control.Feedback type="invalid" className="d-block mb-2">
            {errorMessage.image}
          </Form.Control.Feedback>
        )}
        <UpgradedFilesList
          files={[{ ...image, nosize: true, src }]}
          handleClickOpen={handleOpenSelectPhotoRatio}
          handleClickRemoveFile={handleRemoveImage}
          disabled={isSavingCertificate}
          type="image"
        />
      </>
    );
  }

  let pdfContent;
  if (pdf === undefined) {
    pdfContent = (
      <>
        <UploadFilesDropDown
          rootProps={pdfDropzone.getRootProps()}
          inputProps={pdfDropzone.getInputProps()}
          isDragActive={pdfDropzone.isDragActive}
          disabled={isSavingCertificate}
          dropFilesMessage="Arrastre el pdf aquí"
          searchFile="Busquelo en su ordenador"
        />
        {hasBeenSubmitted ? (
          <Form.Control.Feedback type="invalid" className="d-block">
            {errorMessage.pdf}
          </Form.Control.Feedback>
        ) : undefined}
      </>
    );
  } else {
    pdfContent = (
      <UpgradedFilesList
        files={[pdf]}
        handleClickOpen={handleClickPdf}
        handleClickRemoveFile={handleRemovePdf}
        disabled={isSavingCertificate}
        type="pdf"
      />
    );
  }

  return (
    <>
      <Modal.Body>
        <Form>
          <Form.Group className="" controlId="certificate.name">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={isSavingCertificate}
              isValid={
                validation.name && (hasBeenTouched.name || hasBeenSubmitted)
              }
              isInvalid={
                !validation.name && (hasBeenTouched.name || hasBeenSubmitted)
              }
              onBlur={() => handleTouchInput("name")}
            />
            <Form.Control.Feedback type="invalid">
              {errorMessage.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
        <Form.Group className="" controlId="certificate.description">
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={isSavingCertificate}
            isValid={
              validation.description &&
              (hasBeenTouched.description || hasBeenSubmitted)
            }
            isInvalid={
              !validation.description &&
              (hasBeenTouched.description || hasBeenSubmitted)
            }
            onBlur={() => handleTouchInput("description")}
          />
          <Form.Control.Feedback type="invalid">
            {errorMessage.description}
          </Form.Control.Feedback>
        </Form.Group>
        {imageContent}
        <div className="mt-3">{pdfContent}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="cerrarButton"
          variant="secondary"
          onClick={handleClickCloseModal}
          disabled={isSavingCertificate}
        >
          Cerrar
        </Button>
        <Button
          variant="primary"
          onClick={async () => {
            const hasBeenSavedCorrectlly = await handleSaveCertificate();

            if (hasBeenSavedCorrectlly && whenEndSave) {
              whenEndSave({ name, description });
            }
          }}
          disabled={isSavingCertificate}
        >
          {isSavingCertificate ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Guardar cambios"
          )}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default CreateCertificateModal;
