import React from "react";
import { Circle, XCircleFill } from "react-bootstrap-icons";
import classes from "./SelectRowToDelete.module.css";

const SelectRowToDelete = (props) => (
  <div
    md={1}
    className={`${classes.selectToRemoveCheck} 
    ${props.active ? classes.remove : ""}`}
    onClick={!props.processing ? () => props.onClick(props.id) : () => {}}
  >
    {!props.active ? <Circle /> : <XCircleFill />}
  </div>
);

export default SelectRowToDelete;
