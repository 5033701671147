import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { X } from "react-bootstrap-icons";

import UpgradedPagination from "../../../../components/UpgradedPagination/UpgradedPagination";

import useModalKeywordsList from "./hooks/use-modalKeywordsList";

import classes from "./ModalKeywordStrigList.module.scss";

const ModalKeywordStringList = ({
  close,
  open,
  selectedseedGroup,
  handleSubmitt,
  handleClickKeyword,
  handleClickAvailableKeyword,
  isProcessing,
}) => {
  const selectedKeywords = selectedseedGroup.keywordStrings;
  const {
    searchQuery,
    handleTypeQuery,
    queryedKeywords,
    handleSubmitSearchForm,
    isLoadingKeywords,
    totalKeywordsNumber,
    handleClickPage,
    selectedKeywordsPage,
  } = useModalKeywordsList({ selectedKeywords });

  let keywordsListContent;

  if (isLoadingKeywords) {
    keywordsListContent = <Spinner animation="border" />;
  } else if (queryedKeywords.length > 0) {
    keywordsListContent = (
      <>
        <ListGroup>
          {queryedKeywords.map((keyword, i) => (
            <ListGroup.Item
              key={i}
              action
              onClick={() => handleClickAvailableKeyword(keyword)}
              disabled={keyword.alreadySelected}
            >
              {keyword.string}
              {keyword.alreadySelected && (
                <span className={classes.selectedText}> - selecionado</span>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </>
    );
  } else {
    keywordsListContent = (
      <Alert variant="info">No se han econtrado keywords con este nombre</Alert>
    );
  }

  return (
    <Modal show={open} onHide={close} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{selectedseedGroup.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form
              className={classes.searchCreateForm}
              onSubmit={handleSubmitSearchForm}
            >
              <FormControl
                placeholder="Buscar"
                aria-label="Buscar"
                aria-describedby="basic-addon2"
                value={searchQuery}
                onChange={handleTypeQuery}
              />
              <Button
                variant="primary"
                type="submit"
                className={classes.searchButton}
                disabled={isProcessing}
              >
                Buscar
              </Button>
              <Button
                variant="success"
                onClick={() => handleSubmitt(searchQuery)}
                disabled={isProcessing}
              >
                {isProcessing ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Crear"
                )}
              </Button>
            </Form>
            {keywordsListContent}
            <UpgradedPagination
              totalPagesNumber={Math.ceil(totalKeywordsNumber / 19)}
              activepage={selectedKeywordsPage}
              handleClickPage={handleClickPage}
            />
          </Col>
          <Col>
            <div className={classes.keywordsContainer}>
              {selectedseedGroup.keywordStrings.map((sKey, i) => {
                return (
                  <Button
                    key={i}
                    variant="primary"
                    className={classes.keywordStringButton}
                    onClick={() => handleClickKeyword(i)}
                  >
                    {sKey.name}
                    <X className={classes.xDeletekwstring} />
                  </Button>
                );
              })}
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalKeywordStringList;
