import axios from "../customAxios";

const getItems = (
  accessToken,
  categoryId,
  filterAttributes,
  filterFeatures
) => {
  const data = {
    category: categoryId,
    attributes: filterAttributes,
    features: filterFeatures,
  };

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/categories/filters-test/`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    data: data,
  });
};

const getProductsWithPhotos = (authToken, productIds) => {
  const data = JSON.stringify({ products: productIds });

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/products/images/urls/`,
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    data: data,
  });
};

const getProductInfo = (accessToken, id) =>
  axios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/ps/front/`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    data: {
      id,
    },
  });

const getTableAttributes = (accessToken, id) =>
  axios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/table-attributes-ps/`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    data: {
      id,
    },
  });

const api = {
  getItems,
  getProductsWithPhotos,
  getProductInfo,
  getTableAttributes,
};

export default api;
