import React, { useState } from "react";
import { Link } from "react-router-dom";
//BOOTSTRAP
import { Form, Button, Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
//STYLES
import classes from "./Register.module.css";
//MOCK
import later from "../../utilities/later";
//COMPONENTS
import TextInput from "../../components/TextInput/TextInput";
import Spinner from "../../components/Spinner/Spinner";
import sendData from "./SendData";
//UTILITIES
import handleApiErrors from "../../utilities/handleApiErrors";
import displayNotification from "../../utilities/displayNotification";


// STATES
const LOADING = "LOADING";
const LOADED = "LOADED";
const ERROR = "ERROR";

const Register = (props) => {
  //INPUT VALUES
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  // PROCESS STATE
  const [processState, setProcessState] = useState(LOADED);

  /**
   * @author Darío Barrionuevo <UpTeImpulsaDario@gmx.es>
   * @function sendRegisterInfo
   * @description Send information for registration
   * @returns {Object}  Response from mockApi
   */
  const sendRegisterInfo = () => {
    setProcessState(LOADING);
    if (
      userName.length <= 2 ||
      !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ||
      password.length <= 6 ||
      password !== repeatPassword
    ) {
      setProcessState(ERROR);
    } else {
      sendData
        .sendDataRegister()
        .then(async (res) => {
          await later(3000);
          return res;
        })
        .then((data) => {
          displayNotification({
            title: "Registro realizado",
            message: "Se ha registrado de manera correcta",
            type: "success",
          });
          props.history.push("/login");
        })
        .catch((e) => {
          handleApiErrors(e)
        });
    }
  };

  return (
    <Container className={classes.container}>
      <Row>
        <Col>
          <Form>
            <h1>Register</h1>
            <div className={classes.inputForm}>
              <TextInput
                placeholder="Introduce nombre de usuario"
                onChange={(event) => {
                  setUserName(event.target.value);
                }}
                value={userName}
                error={processState === ERROR && userName.length <= 2}
                errorMessage="El nombre tiene que tener más de dos caracteres"
                disabled={processState === LOADING}
              />
            </div>

            <div className={classes.inputForm}>
              <TextInput
                placeholder="Introduce email válido"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                value={email}
                error={
                  processState === ERROR &&
                  !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
                }
                errorMessage="Introduce un email con el formato xxxxxxxxx@xxx.xxx"
                disabled={processState === LOADING}
              />
            </div>

            <div className={classes.inputForm}>
              <TextInput
                type="password"
                placeholder="Introduce password válido"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                value={password}
                error={processState === ERROR && password.length <= 6}
                errorMessage="El password tiene que tener más de 6 caracteres"
                disabled={processState === LOADING}
              />
            </div>

            <div className={classes.inputForm}>
              <TextInput
                type="password"
                placeholder="Repite el password"
                onChange={(event) => {
                  setRepeatPassword(event.target.value);
                }}
                value={repeatPassword}
                error={processState === ERROR && password !== repeatPassword}
                errorMessage="No coincide con su password"
                disabled={processState === LOADING}
              />
            </div>
            <div>
              {processState === LOADING ? (
                <Spinner />
              ) : (
                <Row>
                  <Col>
                    <Button
                      className={classes.textsizesmall}
                      variant="primary"
                      size="lg"
                      block
                      onClick={sendRegisterInfo}
                    >
                      Crear cuenta
                    </Button>
                  </Col>

                  <Col>
                    <Link to="/login" className="text-decoration-none">
                      <Button
                        variant="success"
                        size="lg"
                        block
                        className={classes.textsizesmall}
                      >
                        Ir a login
                      </Button>
                    </Link>
                  </Col>
                </Row>
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
