import React, { useState } from "react";

import { Dropdown } from "react-bootstrap";
import { GearFill, Check2Circle } from "react-bootstrap-icons";

import IdeaTableTitle from "../types/IdeaTableTitle";

import classes from "./GearDropDown.module.scss";

const GearDropDown: React.FC<{
  ideasTableTitles: any;
  handleClickIdeaTableTitle: (index: number) => void;
  optionsContainerRef: React.MutableRefObject<any>;
  show: boolean;
  handleClick: (val: boolean) => void;
}> = ({
  ideasTableTitles,
  handleClickIdeaTableTitle,
  optionsContainerRef,
  show,
  handleClick,
}) => {
  return (
    <div ref={optionsContainerRef}>
      <Dropdown show={show} onToggle={() => {}}>
        <Dropdown.Toggle
          size="sm"
          variant="light"
          onClick={() => handleClick(!show)}
        >
          <GearFill />
        </Dropdown.Toggle>
        {/* Aqui deberia poner un array con un map y cuando haces clickque cambie el estado y cambie el color como que esta sleeccionado */}
        <Dropdown.Menu>
          {/* <div className={classes.optionsContainer} ref={optionsContainerRef}> */}
          {ideasTableTitles.map((filter: IdeaTableTitle, key: number) => {
            if (filter.active) {
              return (
                <Dropdown.Item
                  key={key}
                  active={true}
                  onClick={() => handleClickIdeaTableTitle(key)}
                >
                  <Check2Circle className={classes.checkIcon} />
                  {filter.name}
                </Dropdown.Item>
              );
            }

            return (
              <Dropdown.Item
                key={key}
                onClick={() => handleClickIdeaTableTitle(key)}
              >
                {filter.name}
              </Dropdown.Item>
            );
          })}
          {/* </div> */}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default GearDropDown;
