import React, { useState, useEffect } from "react";
import checkSync from "../../../utilities/checkSync";
import { Modal, Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Prompt } from "react-router-dom";
import saveBeforeExitAlert from "../../../utilities/saveBeforeExitAlert";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import apiBrands from "../../../api/brands";
import apiLiterals from "../../../api/literals";
import MainTitle from "../../../components/MainTitle/MainTitle";
import SaveButton from "../../../components/SaveButton/SaveButton";
import TextInput from "../../../components/TextInput/TextInput";
import ModalSaveAndSyncButtons from "../../../components/ModalSaveAndyncButtons/ModalSaveAndSyncButtons";
import classes from "./Literals.module.css";
import Spinner from "../../../components/Spinner/Spinner";
import displayNotification from "../../../utilities/displayNotification";

const states = {
  LOADING: "LOADING",
  SUCCEDDED: "SUCCEDDED",
  PROCESSING: "PROCESSING",
  ERROR: "ERROR",
};

const Literals = (props) => {
  const accesToken = useSelector((state) => state.user.access);

  const id = !props.modal ? Number(props.match.params.id) : props.id;

  const [singular, setSingular] = useState(states.LOADING);
  const [plural, setPlural] = useState(states.LOADING);
  const [name, setName] = useState(states.LOADING);

  const [formProcessPhase, setFormProcessPhase] = useState(states.LOADING);

  const disabled = formProcessPhase === states.PROCESSING;
  const [syncError, setSyncError] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [mustSync, setMustSync] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [showFormError, setShowErrorForm] = useState(true);

  const [exitPath, setExitPath] = useState(false);

  useEffect(() => {
    if (exitPath) {
      props.history.push(exitPath);
    }
  }, [exitPath]);

  useEffect(() => {
    const saveTimer = setTimeout(async () => {
      if (hasChanged && (singular.length <= 0 || plural.length <= 0)) {
        try {
          setIsSaving(true);

          await apiLiterals.editLiteral(accesToken, {
            id,
            singular,
            plural,
          });

          setIsSaving(false);
          setHasChanged(false);
          setMustSync(true);
        } catch (e) {}
      }
    }, 10000);

    return () => clearTimeout(saveTimer);
  }, [id, singular, plural]);

  useEffect(() => {
    if (hasChanged) {
      window.onbeforeunload = () =>
        saveBeforeExitAlert().then(async (value) => {
          switch (value) {
            case "save":
              try {
                await save();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "saveSync":
              try {
                await save();
                // await sync();
                swal.close();
              } catch (e) {
                swal.close();
              }
              break;

            case "exit":
              swal.close();
              break;

            default:
              break;
          }
        });
    } else {
      window.onbeforeunload = undefined;
    }
  }, [hasChanged]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await apiLiterals.filter(accesToken, {
          filtername: "id",
          id: id,
        });
        const singularName = data[0].singular ?? "";
        const pluralName = data[0].plural ?? "";

        setSingular(singularName);
        setPlural(pluralName);

        setFormProcessPhase(states.SUCCEDDED);
      } catch (e) {
        console.log(e);
      }
    })();

    // setIsSyncing(true);
    // (async () => {
    //   try {
    //     const checkResponse = await checkSync.checkBrandSync(accesToken, {
    //       brandId: id,
    //     });

    //     if (checkResponse) {
    //       setMustSync(false);
    //     } else {
    //       setMustSync(true);
    //     }

    //     setIsSyncing(false);
    //     setSyncError(false);
    //   } catch (e) {
    //     displayNotification({
    //       title: "Ha habido un error en la sync",
    //       message: "Ha ocurrido un error en la sync, por favor vuelve a intentarlo pasado unos minutos",
    //       type: "danger",
    //     });

    //     setSyncError(true);
    //     setIsSyncing(false);
    //   }
    // })();
  }, [setSingular, setPlural]);

  const save = async () => {
    try {
      if (singular.length <= 0 || plural.length <= 0) {
        setFormProcessPhase(states.ERROR);
        setShowErrorForm(true);

        throw new Error();
      }
      setIsSaving(true);
      await apiLiterals.editLiteral(accesToken, id, singular, plural);

      setIsSaving(false);
      setHasChanged(false);
      setMustSync(true);
      setFormProcessPhase(states.SUCCEDDED);
      setHasSaved(true);

      return true;
    } catch (e) {
      if (e === "DUPLICATE NAME") {
        setFormProcessPhase(states.ERROR);
        setShowErrorForm(true);
      }
      console.log(e);
    }
  };

  // const sync = async () => {
  //   try {
  //     setIsSyncing(true);

  //     const hasSavedSuccesFully = await save();
  //     if (!hasSavedSuccesFully) {
  //       setIsSyncing(false);
  //       return;
  //     }

  //     const syncResponse = await apiBrands.sync(accesToken, {
  //       manufacturerId: id,
  //     });
  //     console.log(syncResponse);
  //     await checkSync.checkBrandSync(accesToken, {
  //       brandId: id,
  //       setIsSyncing,
  //     });

  //     setIsSyncing(false);
  //     setMustSync(false);
  //     setSyncError(false);
  //   } catch (e) {
  //     setMustSync(false);
  //     setSyncError(true);
  //   }
  // };

  const content = (
    <>
      <div className="px-5 py-2">
        <Container fluid className={classes.marginBottom}>
          <Row className={classes.datasContainer}>
            <Col md={12} className={classes.nameContainer}>
              <h5>
                <b>Singular:</b>
              </h5>
              <TextInput
                value={singular}
                onChange={(event) => {
                  // * Make the first letter always upperCase
                  const singularFirstUpperCase =
                    event.target.value.charAt(0).toUpperCase() +
                    event.target.value.slice(1);
                  setSingular(singularFirstUpperCase);
                  setHasChanged(true);
                }}
                error={singular.length <= 0}
                errorMessage="El singular no puede estar vacío"
              />
            </Col>

            <Col md={12} className={classes.nameContainer}>
              <h5>
                <b>Plural:</b>
              </h5>
              <TextInput
                value={plural}
                onChange={(event) => {
                  // * Make the first letter always upperCase
                  const pluralFirstUpperCase =
                    event.target.value.charAt(0).toUpperCase() +
                    event.target.value.slice(1);
                  setPlural(pluralFirstUpperCase);
                  setHasChanged(true);
                }}
                error={plural.length <= 0}
                errorMessage="El plural no puede estar vacío"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );

  if (formProcessPhase === "LOADING") {
    return <Spinner />;
  }

  let syncButtonVariant;
  let textSync;

  if (isSyncing) {
    syncButtonVariant = "warning";
    textSync = "Sincronizando";
  } else if (mustSync) {
    syncButtonVariant = "danger";
    textSync = "Sincronizar";
  } else if (syncError) {
    syncButtonVariant = "danger";
    textSync = "Reintentar sincronización";
  } else {
    syncButtonVariant = "success";
    textSync = "Sincronizado";
  }

  let saveButtonVariant;
  let textSave;

  if (hasChanged) {
    saveButtonVariant = "danger";
    textSave = "Guardar";
  } else {
    saveButtonVariant = "success";
    textSave = "Guardado";
  }

  if (props.modal) {
    return (
      <Modal
        show={true}
        size="lg"
        className={`bigModal ${classes.modifyModal}`}
        onHide={() => {
          if (hasSaved) props.beforeClose({ singular, plural });
          props.close();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Literals: {singular}/{plural}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer className={classes.modifyBottomModal}>
          <ModalSaveAndSyncButtons
            hideSyncButton="hideSyncButton"
            variant={saveButtonVariant}
            hasChanged={hasChanged}
            isSyncing={isSyncing}
            isSaving={isSaving}
            mustSync={mustSync}
            syncError={syncError}
            onClick={async () => {
              try {
                await save();
              } catch (error) {}
            }}
            error={formProcessPhase === states.ERROR}
          />
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <Prompt
        when={hasChanged && !exitPath}
        message={({ pathname }) => {
          saveBeforeExitAlert(true).then(async (value) => {
            switch (value) {
              case "save":
                try {
                  await save();
                  swal.close();
                } catch (e) {
                  swal.close();
                }
                break;

              case "saveSync":
                try {
                  await save();
                  // await sync();
                  swal.close();
                } catch (e) {
                  swal.close();
                }
                break;

              case "exit":
                setExitPath(pathname);
                swal.close();
                break;

              default:
                break;
            }
          });

          return false;
        }}
      />
      <SaveButton
        onClickSave={async () => {
          try {
            await save();
          } catch (e) {}
        }}
        // onClickSync={sync}
        syncError={syncError}
        mustSave={hasChanged}
        processingSave={isSaving}
        mustSync={mustSync}
        processingSync={isSyncing}
        onClose={() => setShowErrorForm(false)}
        error={formProcessPhase === states.ERROR && showFormError === true}
        errorMessage="Hay errores en el formulario,cierre esta ventana, revíselo y corrija los errores antes de guardar"
      />
      <MainTitle>Literals: {name}</MainTitle>
      {content}
    </>
  );
};

export default Literals;
