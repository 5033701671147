import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";

import CardSeed from "./CardSeed/CardSeed";

const SeedDropdownList = ({
  seedsData,
  handleClickGroup,
  handleClickPlus,
  handleClickKeywordX,
  handleClickGroupX,
  isProcessing,
}) => {
  return (
    <>
      <Accordion>
        {seedsData.map((seed, i) => {
          return (
            <CardSeed
              key={i}
              i={i}
              seed={seed}
              handleClick={() => handleClickGroup(i)}
              handleClickX={() => handleClickGroupX(i)}
              handleClickPlus={handleClickPlus}
              handleClickKeywordX={handleClickKeywordX}
            />
          );
        })}
        {isProcessing && (
          <Card className="p-2">
            <Spinner animation="border" />
          </Card>
        )}
      </Accordion>
    </>
  );
};

export default SeedDropdownList;
