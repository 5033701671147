import { useState } from "react";
import { useSelector } from "react-redux";

import apiSync from "../../../../api/sync";

const useBrands = () => {
  const [brands, setBrands] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(0);

  const accesToken = useSelector((state: any) => state.user.access);

  const processDbResponse = (unprocessedBrands: any[], count: number) => {
    setBrands(unprocessedBrands);
    setTotalCount(count);
  };

  const handleClickBrandsPage = async (value: number) => {
    try {
      const clickPageResponse: any = await apiSync.getSyncErrors(accesToken, {
        filtername: "brands",
        syncFail: false,
        needSync: false,
        synchronizing: false,
        noId: false,
        page: value + 1,
      });

      setBrands(clickPageResponse.data.results);
      setActivePage(value);
    } catch (error) {
      console.log(error);
    }
  };

  const totalPagesNumber = Math.ceil(totalCount / 20);

  return {
    brands,
    setBrands,
    totalBrandsCount: totalCount,
    processBrandsDbResponse: processDbResponse,
    handleClickBrandsPage,
    totalBrandPagesNumber: totalPagesNumber,
    activeBrandsPage: activePage,
  };
};

export default useBrands;
