import React, { useState } from "react";
import ReactNotification from "react-notifications-component";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { composeWithDevTools } from "redux-devtools-extension";
import history from "./history";
import Search from "./containers/Search/Search";
import Category from "./containers/Edit/Category/Category";
import CreateCampaign from "./containers/Sem/SemTab/SemTab";
import CampaignTable from "./components/CampaignTab/CampaignTab";
import CreateCategory from "./containers/Create/CreateCategory/CreateCategory";
import Article from "./containers/Edit/Article/Article";
import ProductArticle from "./containers/ProductArticle/ProductArticle";
import Product from "./containers/Edit/Product/Product/Product";
import AddArticleToProduct from "./containers/AddArticleToProduct/AddArticleToProduct";
import SelectFatherCategory from "./containers/SelectFatherCategory/SelectFatherCategory";
import VerticalNavBar from "./containers/VerticalNavBar/VerticalNavBar";
import CreateProductProcess from "./containers/Create/CreateProductProcess/CreateProductProcess";
import "./App.css";
import Login from "./containers/Login/Login";
import Register from "./containers/Register/Register";
import Filter from "./Filter/Filter";
import Error404 from "./containers/Error404/Error404";

// LISTS
import CategoriesList from "./containers/Lists/CategoriesList/CategoriesList";
import ArticlesList from "./containers/Lists/ArticlesList/ArticlesList";
import ProductsList from "./containers/Lists/ProductsList/ProductsList";
import BrandsList from "./containers/Lists/BrandsList/BrandsList";
import SyncList from "./containers/Lists/SyncList/SyncList";
import AttributesList from "./containers/Lists/AttributesList/AttributesList";
import AttributesValueList from "./containers/Lists/AttributesValueList/AttributesValueList";
import FeaturesList from "./containers/Lists/FeaturesList/FeaturesList";
import FeatureValuesList from "./containers/Lists/FeatureValuesList/FeatureValuesList";
import LiteralsProductList from "./containers/Lists/LiteralsProductList/LiteralsProductList";
import LiteralsList from "./containers/Lists/LiteralsList/LiteralsList";
import OrphansList from "./containers/Lists/OrphansList/OrphansList";
import ProductPreview from "./ProductPreview/ProductPreview";
import Feature from "./containers/Edit/Feature/Feature";
import FeatureValue from "./containers/Edit/FeatureValue/FeatureValue";
import AttributeValue from "./containers/Edit/AttributeValue/AttributeValue";
import Brand from "./containers/Edit/Brand/Brand";
import Attribute from "./containers/Edit/Attribute/Attribute";
import CreateBrand from "./containers/Create/CreateBrand/CreateBrand";

import user from "./store/reducers/user";
import orphans from "./store/reducers/orphans";
import profiles from "./store/reducers/profiles";

function App() {
  

  const rootReducer = combineReducers({
    user,
    orphans,
    profiles,
  });

  const persistConfig = {
    key: "root",
    storage,
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(persistedReducer, composeWithDevTools());
  const persistor = persistStore(store);

  return (
    <Router history={history}>
      <Provider store={store}>
        <ReactNotification />
        <PersistGate loading={null} persistor={persistor}>
          <Switch>
            {/*  //* Path for the preview of product, no login required*/}
            <Route
              path="/productPreview/:id"
              exact
              component={ProductPreview}
            />
            <Route path="/filter/:id" exact component={Filter} />

            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route path="/login" component={Login} />
            
            <Route path="/register" component={Register} />
            <Route path="/404" component={Error404} />
            <Route
              path="*"
              component={(props) => (
                <VerticalNavBar {...props}>
                  <Switch>
                    <Route path="/search" component={Search} />

                    {/* LISTS */}
                    <Route path="/categoriesList" component={CategoriesList} />
                    <Route path="/productsList" component={ProductsList} />
                    <Route path="/articlesList" component={ArticlesList} />
                    <Route path="/brandsList" component={BrandsList} />
                    <Route path="/attributesList" component={AttributesList} />
                    <Route
                      path="/attributesValueList/:id"
                      component={AttributesValueList}
                    />
                    <Route path="/featuresList" component={FeaturesList} />
                    <Route
                      path="/featureValuesList/:id"
                      component={FeatureValuesList}
                    />
                    <Route path="/syncList" component={SyncList} />
                    <Route path="/orphansList" component={OrphansList} />
                    <Route path="/literalsList" component={LiteralsList} />

                    <Route
                      path="/literalsProductList/:id"
                      component={LiteralsProductList}
                    />

                    <Route path="/feature/:id" component={Feature} />
                    <Route path="/feature-value/:id" component={FeatureValue} />
                    <Route
                      path="/attribute-value/:id"
                      component={AttributeValue}
                    />
                    <Route path="/brand/:id" component={Brand} />
                    <Route path="/attribute/:id" component={Attribute} />

                    {/* {/* END OF LISTS */}
                    {/* !!!! dario */}
                    <Route path="/createCampaign" component={CreateCampaign} />
                    <Route path="/campaignTable" component={CampaignTable} />
                    {/* !!!! */}
                    <Route path="/category/:id" component={Category} />
                    <Route
                      path="/search/edit-category/:id/categoryes"
                      component={SelectFatherCategory}
                    />
                    <Route path="/product/:id" component={Product} />
                    
                    <Route
                      path="/product/:id/addArticle"
                      component={AddArticleToProduct}
                    />
                    <Route
                      path="/product/:productId/article/:articleId"
                      component={ProductArticle}
                    />
                    <Route path="/article/:articleId" component={Article} />
                    <Route
                      path="create/product/editCategory/:categoryId"
                      component={Category}
                    />

                    <Route
                      path="/create/product"
                      component={CreateProductProcess}
                    />
                    <Route path="/create/category" component={CreateCategory} />
                    <Route path="/create/brand" component={CreateBrand} />
                    <Route path="*">
                      <Redirect to="/404" />
                    </Route>
                  </Switch>
                </VerticalNavBar>
              )}
            />
          </Switch>
        </PersistGate>
      </Provider>
    </Router>
  );
}

export default App;
