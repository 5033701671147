// import classes from "./ModalSelectLanguage.module.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const ModalSelectLanguage = ({ show, handleClose, handleClickNext }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Selecione el idioma</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Control as="select" className="btn">
              <option>Español</option>
              <option>Gabacho</option>
              <option>Japonés</option>
              <option>Inglés</option>
              <option>Indio</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleClickNext}>
          Siguiente
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSelectLanguage;
