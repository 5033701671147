import React from "react";
import { Table, Button, Alert } from "react-bootstrap";
import { Plus, ArrowDownUp, X } from "react-bootstrap-icons";
import ButtonSpinner from "../../components/ButtonSpinner/ButtonSpinner";
import ButtonTableOption from "../../components/ButtonTableOption/ButtonTableOption";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import classes from "./TableOptionsAdd.module.css";

const TableOptionsAdd = (props) => {
  if (props.noResults) {
    return (
      <Alert variant="info" className="mt-2">
        Su busqueda no ha tenido ningun resultado
      </Alert>
    );
  }

  return (
    <Table className={classes.table} striped bordered>
      <thead>
        <tr>
          <th>id</th>
          <th>Nombre</th>
          <th width="130" className="text-center">
            Opciones
          </th>
        </tr>
      </thead>
      <tbody>
        <TransitionGroup
          component={null}
          enter={props.enableAnimation}
          exit={props.enableAnimation}
        >
          {props.items.map((item) => {
            let plusButton = (
              <ButtonTableOption
                variant="success"
                onClick={() => props.addItem(item.id)}
                disabled={props.processing}
              >
                <Plus />
              </ButtonTableOption>
            );

            if (props.processing) {
              plusButton = (
                <ButtonTableOption
                  variant="success"
                  onClick={() => {}}
                  disabled={props.processing}
                >
                  {item.selected && props.processingSaveItems ? (
                    <ButtonSpinner />
                  ) : (
                    <Plus />
                  )}
                </ButtonTableOption>
              );
            }

            if (item.selected) {
              plusButton = (
                <ButtonTableOption
                  variant="success"
                  onClick={() => props.unselect(item.id)}
                  disabled={props.processing}
                >
                  {props.processing ? <ButtonSpinner /> : <X />}
                </ButtonTableOption>
              );
            }

            let rowClass;
            if ((item.father || item.copy) && props.selectCopiesMode) {
              rowClass = "table-warning";
            } else if (item.selected) {
              rowClass = "table-success";
            }

            return (
              <CSSTransition
                key={item.id}
                timeout={1000}
                classNames={{
                  enter: classes.rowEnter,
                  enterActive: classes.rowEnterActive,
                  exit: classes.noAnimation,
                  exitActive: classes.animated,
                }}
              >
                <tr className={rowClass} key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>
                    <div className={classes.optionsButtonsContainer}>
                      {props.selectCopiesMode ? (
                        <Button
                          className={classes.addDuplicateButton}
                          variant="warning"
                          disabled={props.processing}
                          onClick={
                            item.copy || item.father
                              ? () => props.unselectCopy(item)
                              : () => props.selectCopy(item.id)
                          }
                        >
                          {item.copy || item.father ? <X /> : <ArrowDownUp />}
                        </Button>
                      ) : (
                        plusButton
                      )}
                    </div>
                  </td>
                </tr>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </tbody>
    </Table>
  );
};

export default TableOptionsAdd;
