import { useState, useEffect } from "react";

const useAddCertificatesModal = (show: boolean) => {
  const [displayCreateCertificate, setDisplayCreateCertificate] =
    useState(false);
  const [certificateIdToEdit, setCertificateIdToEdit] = useState<any>();
  const [showSwitchCreateOption, setShowSwitchCreateOption] = useState(true);

  useEffect(() => {
    if (show === true) {
      setDisplayCreateCertificate(false);
    }
  }, [show]);

  let switchMethodMessage = "Elegir certificado de la lista de disponibles";
  if (!displayCreateCertificate) {
    switchMethodMessage = "Crear certificado nuevo";
  }

  const handleSwitchMethod = () => setDisplayCreateCertificate((prev) => !prev);

  const handleClickEditCertificate = (index: number) => {
    setCertificateIdToEdit(index);
  };

  const handleCloseEditCertificate = () => {
    setCertificateIdToEdit(undefined);
  };

  const handleHideSwitchCreateOption = () => {
    setShowSwitchCreateOption(false);
  };

  const handleShowSwitchCreateOption = () => {
    setShowSwitchCreateOption(true);
  };

  const showEditCertificateModal = certificateIdToEdit !== undefined;

  return {
    displayCreateCertificate,
    switchMethodMessage,
    handleSwitchMethod,
    handleClickEditCertificate,
    handleCloseEditCertificate,
    showEditCertificateModal,
    certificateIdToEdit,
    showSwitchCreateOption,
    handleHideSwitchCreateOption,
    handleShowSwitchCreateOption,
  };
};

export default useAddCertificatesModal;
