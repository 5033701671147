import React, { useState, useEffect } from "react";
import apiProducts from "../../api/products";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Article from "../Edit/Article/Article";
import Attribute from "../Edit/Attribute/Attribute";
import Brand from "../Edit/Brand/Brand";
import Category from "../Edit/Category/Category";
import AttributeValue from "../Edit/AttributeValue/AttributeValue";
import Feature from "../Edit/Feature/Feature";
import FeatureValue from "../Edit/FeatureValue/FeatureValue";
import { useSelector } from "react-redux";
import checkSync from "../../utilities/checkSync";
import { Col } from "react-bootstrap";
import apiAttributes from "../../api/attributes";
import apiArticles from "../../api/articles";
import AdministerCopies from "../AdministerCopies/AdministerCopies";
import TabContainer from "../../components/TabContainer/TabContainer";
import TabMainRow from "../../components/TabMainRow/TabMainRow";
import ProductProblemsAccordeon from "../../components/ProductProblemsAccordeon/ProductProblemsAccordeon";
import classes from "./ProductProblems.module.css";
import displayNotification from "../../utilities/displayNotification";
import later from "../../utilities/later";
import syncPrices from "../../utilities/syncPrices";

const LOADING = "LOADING";

const ProductProblems = (props) => {
  const {
    productId,
    articles,
    syncProblemsList,
    // !!!! dario
    //  a ver de donde traigo la informacion de problems para meterle tb la referencia q necesito en los errores
    problems,
    setProblems,
    getProblemsFromApi,
    solveProblem,
    setSolveProblem,
    setSyncProblemsList,
    setHideFloatingButtons,
    tasks,
    onClickSyncProblems,
    onClickSyncAllProblems,
    setKey,
    hasPhotos,
    checkingProblems,
    checkSyncProblems,
    totalPercentatge,
    seTotalPercentatge,
  } = props;

  const accesToken = useSelector((state) => state.user.access);

  const [showModalElementToEdit, setShowModalElementToEdit] = useState(false);
  const [syncingAll, setSyncingAll] = useState(false);

  const editTableWhenCloseEdit = (updatedValue) => {
    setSyncProblemsList((problemsList) => {
      const { identifier, id } = showModalElementToEdit;

      const updatedProblemsList = problemsList.map((problem) => {
        if (problem.identifier === identifier) {
          problem.values = problem.values.map((value) => {
            if (value.id === id) {
              const valueKeys = Object.keys(value);
              const updatedValueValues = Object.values(updatedValue);

              for (let index = 0; index < updatedValueValues.length; index++) {
                value[valueKeys[index]] = updatedValueValues[index];
              }

              return value;
            }

            return value;
          });

          return problem;
        }

        return problem;
      });

      return updatedProblemsList;
    });
  };

  let syncProblemsProgressBars;
  if (syncProblemsList === LOADING) {
    syncProblemsProgressBars = <></>;
  } else {
    syncProblemsProgressBars = (
      <>
        {syncProblemsList
          .filter((prb) => prb.processing !== false)
          .map((problem) => {
            return (
              <Col md={3} key={problem.identifier}>
                <h6>{problem.title}</h6>
                <div className={classes.progressBarContainer}>
                  <CircularProgressbar
                    value={problem.processing}
                    text={`${problem.processing}%`}
                  />
                </div>
              </Col>
            );
          })}
      </>
    );
  }

  let attributeId, articleId;

  if (solveProblem) {
    attributeId = solveProblem.attribute.id;
    articleId = solveProblem.article.id;
  }

  return (
    <>
      <TabContainer>
        <TabMainRow>
          {syncProblemsProgressBars}
          <Col md={12}>
            <ProductProblemsAccordeon
              getProblemsFromApi={getProblemsFromApi}
              syncProblemsList={syncProblemsList}
              setSyncProblemsList={setSyncProblemsList}
              problems={problems}
              onClickSolveProblem={(problem) => {
                setHideFloatingButtons(true);
                setSolveProblem(problem);
              }}
              onClickSyncProblems={onClickSyncProblems}
              onClickEdit={(id, identifier) => {
                setHideFloatingButtons(true);
                setShowModalElementToEdit({ id, identifier });
              }}
              tasks={tasks}
              checkSyncProblems={checkSyncProblems}
              checkingProblems={checkingProblems}
              onClickSyncAllProblems={onClickSyncAllProblems}
              syncingAll={syncingAll}
              setSyncingAll={setSyncingAll}
              totalPercentatge={totalPercentatge}
              setKey={setKey}
              hasPhotos={hasPhotos}
            />
          </Col>
        </TabMainRow>
      </TabContainer>
      {solveProblem
        ? (() => {
            const attributeId = solveProblem.attribute.id;
            const articleId = solveProblem.article.id;

            if (document.querySelector(".modal-dialog")) {
              return;
            }

            return (
              <AdministerCopies
                addButtonDescription="hide"
                buttonDescription="hide"
                title="Añadir valor de attributo"
                addTableTitle="Valores a añadir"
                copiesTableTitle="Categoría original"
                loadItems={async () => {
                  const { data } = await apiAttributes.getValuesList(
                    accesToken,
                    {
                      filtername: "attributeId",
                      value: attributeId,
                    }
                  );

                  return {
                    data: data.map((item) => ({
                      id: item.id,
                      name: item.value,
                    })),
                  };
                }}
                onSave={() => {}}
                onSaved={() => {}}
                applySelection={async (selectedValue) => {
                  await apiArticles.addAttributeValue(accesToken, {
                    articleId,
                    attributeValueId: selectedValue.id,
                  });

                  syncPrices(accesToken, { productId });

                  const updatedProblems = problems.filter((item) => {
                    return !(
                      item.attribute.id === attributeId &&
                      item.article.id === articleId
                    );
                  });

                  setProblems(updatedProblems);
                  setSolveProblem(undefined);
                  setHideFloatingButtons(false);
                }}
                selectedItems={[articles.map((article) => article.id)]}
                close={() => {
                  setSolveProblem(undefined);
                  setHideFloatingButtons(false);
                }}
                onHide={() => {
                  setHideFloatingButtons(false);
                }}
                createItem={async (value) => {
                  const response = await apiAttributes.addValue(accesToken, {
                    attributeId,
                    value,
                  });

                  const attributeValueId = response.data.id;
                  (async () => {
                    try {
                      await apiAttributes.syncValue(accesToken, {
                        attributeValueId,
                      });

                      await checkSync.checkAttributeValueSync(accesToken, {
                        attributeValueId,
                      });

                      displayNotification({
                        title: "Se ha sincronizado correctamente",
                        message: "La sync del atributo ha sido exitosa",
                        type: "success",
                      });
                    } catch (error) {
                      displayNotification({
                        title: "Ha habido un error en la sync",
                        message: "La sync del atributo ha fallado",
                        type: "danger",
                      });
                    }
                  })();

                  return response;
                }}
              />
            );
          })()
        : undefined}
      {Boolean(showModalElementToEdit)
        ? (() => {
            switch (showModalElementToEdit.identifier) {
              case "articles":
                return (
                  <Article
                    modal
                    id={showModalElementToEdit.id}
                    onHide={() => {
                      setHideFloatingButtons(false);
                      setShowModalElementToEdit(false);
                    }}
                    beforeClose={editTableWhenCloseEdit}
                  />
                );
                break;

              case "attributes":
                return (
                  <Attribute
                    modal
                    id={showModalElementToEdit.id}
                    onHide={() => {
                      setHideFloatingButtons(false);
                      setShowModalElementToEdit(false);
                    }}
                    beforeClose={editTableWhenCloseEdit}
                  />
                );
                break;

              case "attribute_values":
                return (
                  <AttributeValue
                    modal
                    id={showModalElementToEdit.id}
                    onHide={() => {
                      setHideFloatingButtons(false);
                      setShowModalElementToEdit(false);
                    }}
                    beforeClose={editTableWhenCloseEdit}
                  />
                );
                break;

              case "brand":
                return (
                  <Brand
                    modal
                    id={showModalElementToEdit.id}
                    onHide={() => {
                      setHideFloatingButtons(false);
                      setShowModalElementToEdit(false);
                    }}
                    beforeClose={editTableWhenCloseEdit}
                  />
                );
                break;

              case "category":
                return (
                  <Category
                    modal
                    id={showModalElementToEdit.id}
                    onHide={() => {
                      setHideFloatingButtons(false);
                      setShowModalElementToEdit(false);
                    }}
                    beforeClose={editTableWhenCloseEdit}
                  />
                );
                break;

              case "features":
                return (
                  <Feature
                    modal
                    id={showModalElementToEdit.id}
                    onHide={() => {
                      setHideFloatingButtons(false);
                      setShowModalElementToEdit(false);
                    }}
                    beforeClose={editTableWhenCloseEdit}
                  />
                );
                break;

              case "feature_values":
                return (
                  <FeatureValue
                    modal
                    id={showModalElementToEdit.id}
                    onHide={() => {
                      setHideFloatingButtons(false);
                      setShowModalElementToEdit(false);
                    }}
                    beforeClose={editTableWhenCloseEdit}
                  />
                );
                break;

              default:
                break;
            }
          })()
        : undefined}
    </>
  );
};

export default ProductProblems;
