import { useState } from "react";
import { useSelector } from "react-redux";

import checkSync from "../../../../utilities/checkSync";
import apiSync from "../../../../api/sync";

const useSyncArticles = () => {
  const [articles, setArticles] = useState<any[]>([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [activeArticlesPage, setActiveArticlesPage] = useState<number>(0);

  const accesToken = useSelector((state: any) => state.user.access);

  const processDatabaseArticles = (unprocessedArticles: any[]) => {
    const updatedArticles = unprocessedArticles.map((article) => ({
      ...article,
      name: article.description,
    }));

    setArticles(updatedArticles);
  };

  const handleClickArticlesPage = async (value: number) => {
    try {
      const articlesFromPageResponse = await apiSync.getSyncErrors(accesToken, {
        filtername: "articles",
        syncFail: false,
        needSync: false,
        synchronizing: false,
        noId: false,
        page: value + 1,
      });

      processDatabaseArticles(articlesFromPageResponse.data.results)
      setActiveArticlesPage(value);
    } catch (error) {
      console.log(error);
    }
  };

  const totalArticlePages = Math.ceil(totalArticles / 20);

  return {
    articles,
    setArticles,
    processDatabaseArticles,
    totalArticles,
    setTotalArticles,
    activeArticlesPage,
    handleClickArticlesPage,
    totalArticlePages,
  };
};

export default useSyncArticles;
