import React from "react";
import customAxios from "../customAxios";

const getAll = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/brands/all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const add = (
  access,
  {
    name,
    description,
    shortDescription,
    reWrite,
    metaTitle,
    metaDescription,
    active,
  }
) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/brands/add/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      name,
      description,
      short_description: shortDescription,
      url_rewrite: reWrite,
      meta_title: metaTitle,
      meta_description: metaDescription,
      active,
    }),
  });
};

const filter = (access, { filtername, value }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/brands/filter-by/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ filtername, value }),
  });
};
const edit = (
  access,
  {
    id,
    name,
    description,
    shortDescription,
    reWrite,
    metaTitle,
    metaDescription,
    active,
  }
) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/brands/edit/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      id,
      name,
      description,
      short_description: shortDescription,
      url_rewrite: reWrite,
      meta_title: metaTitle,
      meta_description: metaDescription,
      active,
    }),
  });
};

const sync = (access, { brandId }) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/manufacturers/sinc/id/${brandId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const checkSync = (access, { brandId }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/brands/check-sync/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      brandId,
    }),
  });
};

export default { getAll, add, filter, edit, sync, checkSync };
