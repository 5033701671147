import React from "react";
import { Button } from "react-bootstrap";
import ButtonSpinner from "../ButtonSpinner/ButtonSpinner";
import classes from "./ResyncWhenErrorButton.module.css";

const ResyncWhenErrorButton = (props) => (
  <Button
    variant="danger"
    className={classes.reSyncButton}
    onClick={props.onClick}
    disabled={props.isSyncing}
  >
    {!props.isSyncing ? "Volver a intentar sincronizar" : <ButtonSpinner />}
  </Button>
);

export default ResyncWhenErrorButton;
