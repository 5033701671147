import { useState } from "react";
import { useSelector } from "react-redux";

import displayNotification from "../../../../../utilities/displayNotification";
import checkSync from "../../../../../utilities/checkSync";

import apiProducts from "../../../../../api/products";
import apiCategories from "../../../../../api/categories";

const useCategories: (productId?: number) => any = (productId) => {
  const [categories, setCategories] = useState<any>("LOADING");
  const [mainCategory, setMainCategory] = useState<any>();

  const accesToken = useSelector((state: any) => state.user.access);

  const setEmpytCategories = () => {
    setCategories([]);
  };

  const processDbCategories = (product: any) => {
    const mainCategory = {
      id: product.category__id,
      name: product.category__name,
      active: product.category__active,
    };

    const categories = product.categories.map((category: any) => {
      return {
        id: category.id,
        name: category.name,
        active: category.active,
      };
    });

    const categoriesIds = categories.map((cat: any) => cat.id);

    if (!categoriesIds.includes(mainCategory.id)) {
      categories.unshift(mainCategory);
    }

    setMainCategory(mainCategory);
    setCategories(categories);
  };

  const handleChangeMainCategory = async (category: any) => {
    const prevMainCategory = { ...mainCategory };
    const newMainCategory = {
      id: category.id,
      name: category.name,
      active: category.active,
    };

    setCategories((categories: any[]) => {
      const categoriesIds = categories.map((cat) => cat.id);

      if (categoriesIds.includes(category.id)) {
        return categories;
      }

      return [newMainCategory, ...categories];
    });
    setMainCategory(newMainCategory);

    try {
      await apiProducts.addCategories(accesToken, {
        productId,
        categoryIds: prevMainCategory.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditCategory = (category: any) => {
    const newCategory = {
      id: category.id,
      name: category.name,
      active: category.active,
    };

    if (mainCategory.id === category.id) {
      setMainCategory(newCategory);
    }

    setCategories((cats: any) => {
      const updatedCategories = cats.map((cat: any) => {
        if (cat.id === category.id) {
          return newCategory;
        } else {
          return cat;
        }
      });

      return updatedCategories;
    });
  };

  const handleCreateCategory = async (name: string) => {
    const responseCreateCategory = await apiCategories.add(accesToken, {
      name,
    });

    (async () => {
      const createdCategoryId = responseCreateCategory.data.id;

      try {
        await apiCategories.sync(accesToken, {
          categoryId: createdCategoryId,
        });

        await checkSync.checkCategorySync(accesToken, {
          categoryId: createdCategoryId,
        });

        displayNotification({
          title: "Se ha sincronizado correctamente",
          message: "La sync de la categoría creada ha sido exitosa",
          type: "success",
          dismiss: undefined,
        });
      } catch (error) {
        displayNotification({
          title: "Ha habido un error en la sync",
          message: "La sync de la categoría creada ha fallado",
          type: "danger",
          dismiss: undefined,
        });
      }
    })();

    return responseCreateCategory;
  };

  return {
    categories,
    setCategories,
    mainCategory,
    setMainCategory,
    processDbCategories,
    handleChangeMainCategory,
    handleEditCategory,
    setEmpytCategories,
    handleCreateCategory,
  };
};

export default useCategories;
