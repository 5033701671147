import { Accordion, Card, Button } from "react-bootstrap";
import { Plus, X } from "react-bootstrap-icons";

import classes from "./CardSeed.module.scss";

const CardSeed = ({
  i,
  seed,
  handleClick,
  handleClickX,
  handleClickPlus,
  handleClickKeywordX,
}) => {
  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        onClick={handleClick}
        eventKey={String(i)}
        className={classes.accordionHeader}
      >
        <span>{seed.name}</span>
        <button className={classes.removeSeedGroup} onClick={handleClickX}>
          <X />
        </button>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={String(i)}>
        <Card.Body>
          <div className={classes.keywordsContainer}>
            {Object.keys(seed.keywordStrings).map((sKey, i) => {
              return (
                <div className={classes.keywordStringContainer} key={i}>
                  <span>{seed.keywordStrings[sKey].name}</span>
                  <button
                    className={classes.xDeletekwstring}
                    onClick={() => handleClickKeywordX(i)}
                  >
                    <X />
                  </button>
                </div>
              );
            })}
            <Button
              onClick={handleClickPlus}
              className={classes.plusAddKeywordString}
              variant="success"
            >
              <Plus />
            </Button>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default CardSeed;
