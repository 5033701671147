import React from "react";
import Accordion from "../Accordion/Accordion";
import { ArrowRight } from "react-bootstrap-icons";
import classes from "./AvailableFilters.module.css";

const AvaiableFilters = (props) => {
  return (
    <Accordion.Accordion>
      {props.items.map((availableFilter, key) => (
        <Accordion.Item
          key={key}
          className="accordion-item"
          onClick={
            props.disabled
              ? () => {}
              : () => props.onClickItem(availableFilter.id)
          }
        >
          <Accordion.Header className={classes.availableItem}>
            <span>
              {availableFilter.name}{" "}
              {availableFilter.type === "info" && (
                <span className={classes.type}>- info</span>
              )}
            </span>
            <button>
              <ArrowRight />
            </button>
          </Accordion.Header>
        </Accordion.Item>
      ))}
    </Accordion.Accordion>
  );
};

export default AvaiableFilters;
