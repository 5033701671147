import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { XCircle } from "react-bootstrap-icons";
import classes from "./DraggableSortableFilter.module.css";

const DraggableSortableFilter = (props) => (
  <DragDropContext onDragEnd={!props.disabled ? props.handleDranEnd : () => {}}>
    <Droppable droppableId={props.droppableId}>
      {(provided) => {
        return (
          <div
            className="accordion"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {props.items.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={String(item.id)}
                index={index}
              >
                {(provided) => (
                  <div
                    className="accordion-item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <h2
                      className={`accordion-header ${classes.draggableItem} ${
                        props.disabled ? classes.disabled : ""
                      }`}
                    >
                      <div
                        className={`accordion-button collapsed ${classes.accordionButton}`}
                        type="button"
                      >
                        <span>
                          {item.name}
                          {item.type === "info" && (
                            <span className={classes.infoType}> - info</span>
                          )}
                        </span>
                        <button
                          onClick={() => props.onClickRemove(item.id)}
                          className={classes.unselect}
                        >
                          <XCircle />
                        </button>
                      </div>
                    </h2>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  </DragDropContext>
);

export default DraggableSortableFilter;
