import { useState } from "react";

const useCreateCampaign = () => {
  // states for the inputs
  const [inputAmount, setInputAmount] = useState<number>(0);
  const [inputMaxCPC, setInputMaxCPC] = useState<number>(0.0);

  const handleInputAmount = (event: any) => {
    setInputAmount(event.target.value);
  };
  const handleInputMaxCPC = (event: any) => {
    setInputMaxCPC(event.target.value);
  };

  return { inputAmount, inputMaxCPC, handleInputAmount, handleInputMaxCPC };
};

export default useCreateCampaign;
