import React, { useState } from "react";

import { Plus } from "react-bootstrap-icons";
import { Modal, Button, Alert } from "react-bootstrap";

import AddCertificateModal from "./AddCertificateModal/AddCertificateModal";
import CertificatesList from "../components/CertificatesList/CertificatesList";
import EditCertificate from "./EditCertificate/EditCertificate";
import DataTitle from "../../../../../components/DataTitle/DataTitle";

import useCertificatesList from "./hooks/use-certificatesList";

import classes from "./CertificatesList.module.scss";

const AdministerCertificates: React.FC<{ productId: number }> = ({
  productId,
}) => {
  const {
    certificates,
    showAddCertificateModal,
    handleAddCertificateModal,
    handleCloseAddCertificateModal,
    handleLinkCertificates,
    certificateIds,
    handleClickRemoveCertificate,
    handleClickOpenPdf,
    handleClickEditCertificate,
    handleCloseEditCertificateModal,
    showEditCertificateModal,
    certificateIdToEdit,
    handleSaveAndClose,
    addCertificate,
  } = useCertificatesList(productId);

  const [showCertificatesModal, setShowCertificatesModal] = useState(false);

  const closeModal = () => setShowCertificatesModal(false);

  return (
    <>
      <div>
        <DataTitle>Certificados</DataTitle>
        <Button className="p-2" onClick={() => setShowCertificatesModal(true)}>
          Administrar certificados
        </Button>
      </div>
      <Modal
        show={
          showCertificatesModal &&
          !showAddCertificateModal &&
          !showEditCertificateModal
        }
        onHide={closeModal}
        className="mediumModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Administrar certificados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="success" onClick={handleAddCertificateModal}>
            Añadir certificado <Plus />
          </Button>
          {certificates.length > 0 ? (
            <CertificatesList
              certificates={certificates}
              handleClickRemove={handleClickRemoveCertificate}
              handleClickPdf={handleClickOpenPdf}
              handleClickEdit={handleClickEditCertificate}
            />
          ) : (
            <Alert variant="info" className="mt-2">
              No hay ningun certificado asociado a este producto
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <AddCertificateModal
        productId={productId}
        show={showAddCertificateModal}
        handleClose={handleCloseAddCertificateModal}
        linkedCertificatesIds={certificateIds}
        handleLinkCertificates={handleLinkCertificates}
        handleFinishSave={addCertificate}
      />
      <EditCertificate
        show={showEditCertificateModal}
        handleCloseModal={handleCloseEditCertificateModal}
        certificateToEditId={certificateIdToEdit}
        handleSaveAndClose={handleSaveAndClose}
      />
    </>
  );
};

export default AdministerCertificates;
