import React from "react";

import CategoriesList from "../CategoriesList/CategoriesList";
import Category from "../Category";

import useSubCategories from "./hooks/use-useSubcategories";

import classes from "./CategorySubcategories.module.scss";

const CategorySubcategories: React.FC<{
  categories: any[];
  handleDropCategory: (result: any) => void;
  handleUpdateEditedCategory: (editecCategory: any) => void;
  handleClickSyncCategory: (index: number) => void;
}> = ({
  categories,
  handleDropCategory,
  handleUpdateEditedCategory,
  handleClickSyncCategory,
}) => {
  const {
    categoryIdToEdit,
    showEditCategoryModal,
    handleClickEditCategory,
    handleCloseEditCategoryModal,
  } = useSubCategories(categories);

  return (
    <>
      <div className={classes.categoriesHeader}>
        <h3>Categorías:</h3>
      </div>
      <CategoriesList
        categories={categories}
        handleDropCategory={handleDropCategory}
        handleClickEditCategory={handleClickEditCategory}
        handleClickSyncCategory={handleClickSyncCategory}
      />
      {showEditCategoryModal ? (
        <Category
          modal
          id={categoryIdToEdit}
          onHide={handleCloseEditCategoryModal}
          beforeClose={handleUpdateEditedCategory}
        />
      ) : undefined}
    </>
  );
};

export default CategorySubcategories;
