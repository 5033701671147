import React from "react";
import { Col } from "react-bootstrap";
import classes from "./DataItem.module.css";

const DataItem = (props) => (
  <Col md={props.md} className={classes.dataItem}>
    {props.children}
  </Col>
);

export default DataItem;
