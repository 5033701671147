import { useState } from "react";
import { useSelector } from "react-redux";

import apiAttributes from "../../../../api/attributes";
import apiSync from "../../../../api/sync";

const processAttributeValues = (preprocessedValues: any[]) => {
  return preprocessedValues.map((attributeValue: any) => ({
    ...attributeValue,
    name: attributeValue.value,
  }));
};

const useSyncAttributeValues = () => {
  const [attributeValues, setAttributeValues] = useState<any[]>([]);
  const [totalAttributeValuesNumber, setTotalAttributeValuesNumber] =
    useState(0);
  const [activeAttributeValuePage, setactiveAttributeValuePage] = useState(0);

  const accesToken = useSelector((state: any) => state.user.access);

  const processAttributeValuesDatabaseResponse = (
    preprocessedValues: any[],
    totalValuesNumber: number
  ) => {
    const updatedAttributeValues = processAttributeValues(preprocessedValues);

    setAttributeValues(updatedAttributeValues);
    setTotalAttributeValuesNumber(totalValuesNumber);
  };

  const handleClickSyncAll = async () => {
    setAttributeValues((prevAttributes) => {
      const updatedAttributes = prevAttributes.map((attr) => ({
        ...attr,
        processing: true,
      }));

      return [...updatedAttributes];
    });

    let pageToSync = 0;
    let nextPageExists = true;

    while (nextPageExists) {
      const attributeValuesResponse = await apiSync.getSyncErrors(accesToken, {
        filtername: "attributeValues",
        syncFail: false,
        needSync: false,
        synchronizing: false,
        noId: false,
        page: pageToSync + 1,
      });

      const attributeValuesToSync = attributeValuesResponse.data.results;

      nextPageExists = !!attributeValuesResponse.data.links.next;

      await Promise.allSettled(
        attributeValuesToSync.map((val: any) => {
          return (async () => {
            try {
              await apiAttributes.syncValue(accesToken, {
                attributeValueId: val.id,
              });
            } catch (error) {
              console.log(error);
            }
          })();
        })
      );

      pageToSync += 1;
    }
  };

  const handleClickAttributeValuePage = async (value: number) => {
    try {
      const attributeValuesFromPage = await apiSync.getSyncErrors(accesToken, {
        filtername: "attributeValues",
        syncFail: false,
        needSync: false,
        synchronizing: false,
        noId: false,
        page: value + 1,
      });

      const processedAttributeValues = processAttributeValues(
        attributeValuesFromPage.data.results
      );
      setAttributeValues(processedAttributeValues);
      setactiveAttributeValuePage(value);
    } catch (error) {
      console.log(error);
    }
  };

  let isProcessing = false;

  if (Array.isArray(attributeValues)) {
    attributeValues.find((val) => val.processing);
  }

  const pagesNumber = Math.ceil(totalAttributeValuesNumber / 20);

  return {
    attributeValues,
    attributeValuesPagesNumber: pagesNumber,
    totalAttributeValuesCount: totalAttributeValuesNumber,
    setAttributeValues,
    handleClickSyncAll,
    isProcessingAttributeValues: isProcessing,
    activeAttributeValuePage,
    handleClickAttributeValuePage,
    processAttributeValuesDatabaseResponse,
  };
};

export default useSyncAttributeValues;
