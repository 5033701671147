import React from "react";
import { Button } from "react-bootstrap";
import ButtonSpinner from "../ButtonSpinner/ButtonSpinner";
import classes from "./SaveButton.module.css";

const SaveButton = (props) => {
  let syncButtonVariant;
  let textSync;

  if (props.processingSync) {
    syncButtonVariant = "warning";
    textSync = "Sincronizando";
  } else if (props.syncError) {
    syncButtonVariant = "danger";
    textSync = "Reintentar sincronización";
  } else if (props.mustSync) {
    syncButtonVariant = "danger";
    textSync = "Sincronizar";
  } else if (props.correctSync) {
    syncButtonVariant = "success";
    textSync = "Sincronizado";
  } else {
    syncButtonVariant = "secondary";
    textSync = "Evaluando estado";
  }

  let saveButtonVariant;
  let textSave;

  if (props.mustSave) {
    saveButtonVariant = "danger";
    textSave = "Guardar";
  } else {
    saveButtonVariant = "success";
    textSave = "Guardado";
  }

  const buttons = (
    <>
      {!props.dontSync ? (
        <Button
          variant={syncButtonVariant}
          disabled={props.processing}
          onClick={
            !props.processing || !props.correctSync
              ? props.onClickSync
              : () => {}
          }
        >
          {props.processingSync ? (
            <div className={classes.processingSync}>
              <ButtonSpinner /> <span className="ml-2">{textSync}</span>
            </div>
          ) : (
            <span>{textSync}</span>
          )}
        </Button>
      ) : undefined}
      <Button
        className="ml-2"
        variant={saveButtonVariant}
        disabled={props.processing}
        onClick={!props.processing ? props.onClickSave : () => {}}
      >
        {props.processingSave ? <ButtonSpinner /> : textSave}
      </Button>
    </>
  );

  if (props.modal) {
    return buttons;
  }

  return (
    <div
      className={`${classes.saveButtonFixed} ${
        props.hidden ? "d-none" : undefined
      }`}
    >
      <div className={classes.buttonsContainer}>{buttons}</div>
    </div>
  );
};

export default SaveButton;
