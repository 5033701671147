import React from "react";
import classes from "./DataTitle.module.css";

const DataTitle = (props) => (
  <h5
    className={`${classes.title} ${props.firstTitle ? classes.firstTitle : ""}`}
  >
    {props.children}
  </h5>
);

export default DataTitle;
