import customAxios from "../customAxios";

const getAttributes = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/attributes/all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const getNoInfoAttributes = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/attributes/list-no-info-all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const getAttributesInfo = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/attributes/info/all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const getAttributesList = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/attributes/list-all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const createAttribute = (access, { name, type }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/attributes/add/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ name, type }),
  });
};

const unify = (access, fatherId, unifyList) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/attributes/unify/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ father: fatherId, unifyList }),
  });
};

const getValues = (access, { filtername, value }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/attribute-values/filter-by/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ filtername, value }),
  });
};

const getValuesList = (access, { filtername, value }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/attribute-values/list-filter-by/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ filtername, value }),
  });
};

const editValue = (access, { id, value, color }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/attribute-values/edit/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ id, value, color }),
  });
};

const addValue = (access, { attributeId, value }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/attribute-values/add/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ attributeId, value }),
  });
};

const filter = (access, { filtername, value }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/attributes/filter-by/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ filtername, value }),
  });
};
const listFilter = (access, { filtername, value }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/attributes/list-filter-by/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ filtername, value }),
  });
};

const edit = (access, { id, name, type, description }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/attributes/edit/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ id, name, type, description }),
  });
};

const syncValue = (access, { attributeValueId }) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/products-options-values/sinc/id/${attributeValueId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const valueCheckSync = (access, { attributeValueId }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/attribute-values/check-sync/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      attributeValueId,
    }),
  });
};

const sync = (access, { attributeId }) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/products-options/sinc/id/${attributeId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const checkSync = (access, { attributeId }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/attributes/check-sync/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      attributeId,
    }),
  });
};

const duplicateAttributeToInfo = (access, { productId, attributeId }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/articles/duplicate-attribute-to-info/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      productId,
      attributeId,
    }),
  });
};

export default {
  createAttribute,
  getAttributes,
  getAttributesList,
  getNoInfoAttributes,
  getAttributesInfo,
  unify,
  getValues,
  getValuesList,
  editValue,
  addValue,
  filter,
  listFilter,
  edit,
  sync,
  checkSync,
  syncValue,
  valueCheckSync,
  duplicateAttributeToInfo,
};
