import { useReducer, useState } from "react";
import Language from "../models/language";
import Country from "../models/country";

interface State {
  amount: number;
  maxPC: number;
  languages: Language[];
  countries: Country[];
}

type Action =
  | { type: "SET_AMOUNT"; payload: number }
  | { type: "SET_MAXPC"; payload: number }
  | { type: "ADD_LANGUAGES"; payload: Language[] }
  | { type: "REMOVE_LANGUAGE"; payload: number }
  | { type: "ADD_COUNTRIES"; payload: Country[] }
  | { type: "REMOVE_COUNTRY"; payload: number };

const initialState = {
  amount: 0,
  maxPC: 0,
  languages: [],
  countries: [],
};

const campaignReducer = (curCampiagnState: State, action: Action): State => {
  switch (action.type) {
    case "SET_AMOUNT":
      return {
        ...curCampiagnState,
        amount: action.payload!,
      };
    case "SET_MAXPC":
      return {
        ...curCampiagnState,
        maxPC: action.payload!,
      };
    case "ADD_LANGUAGES":
      return {
        ...curCampiagnState,
        languages: [...curCampiagnState.languages, ...action.payload!],
      };
    case "REMOVE_LANGUAGE":
      return {
        ...curCampiagnState,
        languages: curCampiagnState.languages.filter(
          (lang) => lang.id !== action.payload
        ),
      };
    case "ADD_COUNTRIES":
      return {
        ...curCampiagnState,
        countries: [...curCampiagnState.countries, ...action.payload!],
      };
    case "REMOVE_COUNTRY":
      return {
        ...curCampiagnState,
        countries: curCampiagnState.countries.filter(
          (country) => country.id !== action.payload
        ),
      };
    default:
      return curCampiagnState;
  }
};

const useCampaignForm = () => {
  const [campaignInfo, disptach] = useReducer(campaignReducer, initialState);
  const [hasSubmitted, setHassubmitted] = useState<boolean>(false);

  const amountError = campaignInfo.amount <= 0;
  const maxPCtError = campaignInfo.maxPC <= 0;
  const languagestError = campaignInfo.languages.length === 0;
  const countriesError = campaignInfo.countries.length === 0;
  const formError =
    amountError || maxPCtError || languagestError || countriesError;

  return {
    amount: campaignInfo.amount,
    setAmount: (val: number) => disptach({ type: "SET_AMOUNT", payload: val }),
    maxPC: campaignInfo.maxPC,
    setMaxPC: (val: number) => disptach({ type: "SET_MAXPC", payload: val }),
    languages: campaignInfo.languages,
    addLanguages: (languages: Language[]) =>
      disptach({ type: "ADD_LANGUAGES", payload: languages }),
    removeLanguage: (languageId: number) =>
      disptach({ type: "REMOVE_LANGUAGE", payload: languageId }),
    countries: campaignInfo.countries,
    addCountries: (countries: Country[]) =>
      disptach({ type: "ADD_COUNTRIES", payload: countries }),
    removeCountry: (countryId: number) =>
      disptach({ type: "REMOVE_COUNTRY", payload: countryId }),
    handleSubmit: () => {
      setHassubmitted(true);
    },
    amountError: hasSubmitted && amountError,
    maxPCtError: hasSubmitted && maxPCtError,
    languagestError: hasSubmitted && languagestError,
    countriesError: hasSubmitted && countriesError,
    formError: hasSubmitted && formError,
  };
};

export default useCampaignForm;
