import React, { useState, useEffect } from "react";
import classes from "./AdGroups.module.css";
import { Button, ButtonGroup, Col, Dropdown, Form, Row } from "react-bootstrap";
import {
  Circle,
  Check2Circle,
  GearFill,
  CaretDownFill,
  PlusLg,
  Square,
  CheckSquare,
  CircleFill,
} from "react-bootstrap-icons";

interface Props {
  setProccessNumber: any;
  setadTypeSelection: any;
}
const AdGroups: React.FC<Props> = ({
  setProccessNumber,
  setadTypeSelection,
}) => {
  const [toggleAd, setToggleAd] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [colors, setColors] = useState("yellow");
  // contenido de los botones desplegables de la columna derecha
  const checkBoxContent = (
    <>
      <div key="radio" className={classes.checkBoxContent}>
        <Form>
          <Form.Check
            className={`pl-5 ${classes.checkBoxRow}`}
            name="Eleccion" //mismo nombre en todos los que quieras que solo se elija uno de ellos
            type="radio"
            label="Cajas"
          ></Form.Check>
          <Form.Check
            className={`pl-5 ${classes.checkBoxRow}`}
            name="Eleccion"
            type="radio"
            label="Cartón"
          ></Form.Check>
          <Form.Check
            className={`pl-5 ${classes.checkBoxRow}`}
            name="Eleccion"
            type="radio"
            label="ondulado"
          ></Form.Check>
          <Form.Check
            className={`pl-5 ${classes.checkBoxRow}`}
            name="Eleccion"
            type="radio"
            label="barata"
          ></Form.Check>
          <Form.Check
            className={`pl-5 ${classes.checkBoxRow}`}
            name="Eleccion"
            type="radio"
            label="plástico"
          ></Form.Check>
        </Form>
      </div>
      <Button
        onClick={() => {
          setProccessNumber(4);
        }}
        variant="success"
      >
        Crear grupo de anuncios
      </Button>
    </>
  );
  const contentList = (
    <>
      <li className={`${classes.checkWidth} ${classes.checkAndActions}`}>
        {checked ? (
          <CheckSquare
            onClick={() => {
              setChecked(!checked);
            }}
          />
        ) : (
          <Square
            onClick={() => {
              setChecked(!checked);
            }}
          />
        )}

        <CircleFill
          className={`${colors === "yellow" ? classes.yellow : null} ${
            colors === "red" ? classes.red : null
          } ${colors === "green" ? classes.green : null}`}
        />
      </li>
      <li className={classes.nameWidth}>Cajas baratas</li>
      <li className={classes.typeWidth}>ETA</li>
    </>
  );
  return (
    <>
      <div className={classes.container}>
        {/* menu de la lista */}
        <div className={classes.list}>
          <div className={classes.listTitle}>
            <h2>Lista de anuncios</h2>
          </div>
          <div className={classes.header}>
            <li className={classes.checkWidth}>#</li>
            <li className={classes.nameWidth}>Nombre</li>
            <li className={classes.typeWidth}>
              Tipo
              <select className="ml-5">
                <option onClick={() => {}} selected>
                  ETA
                </option>
                <option onClick={() => {}}>DSA</option>
                <option onClick={() => {}}>RSA</option>
              </select>
            </li>
          </div>
          <div className={classes.contentList}>{contentList}</div>
          <div className={classes.createAd}>
            <Button
              onClick={() => {
                setProccessNumber(6);
              }}
              className={classes.createAdButton}
              variant="success"
            >
              <PlusLg
                onClick={() => {
                  setadTypeSelection("");
                }}
              />{" "}
              Crear anuncio
            </Button>
          </div>
        </div>
        {/* Menu de la columna */}
        <div className={classes.column}>
          <div className={classes.columnHeader}>
            <div className={classes.columnSelect}>
              <h4>Seleccionado:</h4>
              <h4 className="mt-2">Acciones:</h4>
            </div>
            <div className={classes.columnSelect}>
              <Dropdown>
                <Dropdown.Toggle variant="info">Grupo 2</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>Grupo 1</Dropdown.Item>
                  <Dropdown.Item>Grupo 2</Dropdown.Item>
                  <Dropdown.Item>Grupo 3</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="mt-2">
                <Dropdown.Toggle variant="info">Pausar</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setColors("yellow");
                    }}
                  >
                    Pausar
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setColors("green");
                    }}
                  >
                    Activar
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setColors("red");
                    }}
                  >
                    Detener
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className={classes.columnContent}>
            <Button
              onClick={() => {
                setToggleAd(!toggleAd);
              }}
              variant="secondary"
              className={`d-grid mt-1`}
            >
              Grupos de anuncios <CaretDownFill />
            </Button>
            {/* Contenido desplegable de los botones  */}
            {toggleAd ? checkBoxContent : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdGroups;
