import axios from "axios";
import displayUnauthorizedNotification from "./utilities/displayUnauthorizedNotification";
import displayErrorNotification from "./utilities/displayErrorNotification";
import history from "./history";

// axios instance for making requests
const axiosInstance = axios.create();

// request interceptor for adding token
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      history.push("/logIn");
    }
    throw error;
  }
);

export default axiosInstance;
