import React from "react";

const Container = (props) => <div className="accordion">{props.children}</div>;

const Item = (props) => (
  <div className="accordion-collapsed">
    <h2 className="accordion-header" onClick={props.onClick}>
      <button
        className={`accordion-button ${props.collapsed ? "collapsed" : ""}`}
        type="button"
      >
        {props.title}
      </button>
    </h2>
    <div
      className={`accordion-collapse collapse ${
        !props.collapsed ? "show" : ""
      }`}
    >
      <div className="accordion-body">{props.children}</div>
    </div>
  </div>
);

export default { Container, Item };
