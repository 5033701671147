import React from "react";
import { PencilSquare } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import classes from "./EditButton.module.css";

const EditButton = (props) => (
  <Button
    onClick={props.click}
    className={classes.editButton}
    type="primary"
    disabled={props.disabled}
  >
    <span>{props.children}</span> <PencilSquare />
  </Button>
);

export default EditButton;
