import React from "react";
import { Col } from "react-bootstrap";
import TextInput from "../../../components/TextInput/TextInput";
import DataTitle from "../../../components/DataTitle/DataTitle";
import TabContainer from "../../../components/TabContainer/TabContainer";
import TabMainRow from "../../../components/TabMainRow/TabMainRow";
import { useSelector } from "react-redux";

// const LOADING = "LOADING";
// const PROCESSING = "PROCESSING";
// const ERROR = "ERROR";

const PrePhaseCreateProduct = (props) => {
  const accesToken = useSelector((state) => state.user.access);

  const {
    name,
    onChangeName,
    error,
    // duplicateNameError,
    process,
    nameErrorMessage,
    // specialCharacters,
    // disabled,
  } = props;

  return (
    <>
      <TabContainer>
        <TabMainRow>
          <Col md={12}>
            <DataTitle firstTitle>Nombre del producto</DataTitle>
            <TextInput
              controlId="editCategory"
              placeholder={name}
              value={name}
              disabled={process}
              onChange={onChangeName}
              error={error}
              errorMessage={nameErrorMessage}
            />
          </Col>
        </TabMainRow>
      </TabContainer>
    </>
  );
};

export default PrePhaseCreateProduct;
