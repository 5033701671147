import React from "react";
import ButtonSpinner from "../ButtonSpinner/ButtonSpinner";
import { Button } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import classes from "./AddButton.module.css";

const AddButton = (props) => (
  <Button
    variant="success"
    className={classes.addButton}
    onClick={props.onClick}
    disabled={props.disabled}
  >
    {props.loading ? <ButtonSpinner /> : <Plus />}
  </Button>
);

export default AddButton;
