import React from "react";
import { useSelector } from "react-redux";
import apiFeatures from "../../../api/features";
import checkSync from "../../../utilities/checkSync";
import EditValue from "../EditValue/EditValue";

const Feature = (props) => {
  const id = props.modal ? props.id : Number(props.match.params.id);
  const accesToken = useSelector((state) => state.user.access);

  return (
    <EditValue
      modal={props.modal}
      beforeClose={props.beforeClose}
      title="Característica"
      url={"/feature/:id"}
      onLoad={async () => {
        const { data } = await apiFeatures.filter(accesToken, {
          filtername: "id",
          value: Number(id),
        });
        return data[0].name;
      }}
      onSave={(name) => apiFeatures.edit(accesToken, { id, name })}
      onSync={() => apiFeatures.sync(accesToken, { featureId: id })}
      onCheckSync={() =>
        checkSync.checkFeatureSync(accesToken, { featureId: id })
      }
      loadSyncCheck={async () => {
        const response = await apiFeatures.checkSync(accesToken, {
          featureId: id,
        });

        return response.data.feature[0];
      }}
      close={props.close}
    />
  );
};

export default Feature;
