import customAxios from "../customAxios";

const all = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/features/all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};
const listAll = (access) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/features/list-all/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const filter = (access, { filtername, value }) => {
  const data = JSON.stringify({ filtername, value });

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/features/filter-by/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  return customAxios(config);
};

const addValue = (access, { featureId, value }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/feature-values/add/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      featureId,
      value,
    }),
  });
};

const filterValues = (access, { filtername, value }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/feature-values/filter-by/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ filtername, value }),
  });
};

const edit = (access, { id, name }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/features/edit/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ id, name }),
  });
};

const editValue = (access, { id, value }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/feature-values/edit/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ id, value }),
  });
};

const sync = (access, { featureId }) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/products-features/sinc/id/${featureId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const checkSync = (access, { featureId }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/features/check-sync/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      featureId,
    }),
  });
};

const syncValue = (access, { featureValueId }) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/ps/products-feature-values/sinc/id/${featureValueId}/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

const checkValueSync = (access, { featureValueId }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/feature-values/check-sync/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      featureValueId,
    }),
  });
};

const add = (access, { name }) => {
  return customAxios({
    method: "put",
    url: `${process.env.REACT_APP_API_SERVER}/api/features/add/`,
    headers: {
      Authorization: `Bearer ${access}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      name,
    }),
  });
};

export default {
  all,
  listAll,
  filter,
  addValue,
  filterValues,
  edit,
  editValue,
  sync,
  checkSync,
  syncValue,
  checkValueSync,
  add,
};
