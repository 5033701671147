import customAxios from "../customAxios";

const entriesNoSync = (acces, { productId, type, sync }) => {
  return customAxios({
    method: "post",
    url: `${process.env.REACT_APP_API_SERVER}/api/products/entities-no-sync/`,
    headers: {
      Authorization: `Bearer ${acces}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ productId, type, sync }),
  });
};

export default { entriesNoSync };
