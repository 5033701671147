import React from "react";

import { Pagination } from "react-bootstrap";

import classes from "./UpgradedPagination.module.scss";

const UpgradedPagination: React.FC<{
  totalPagesNumber: number;
  activepage: number;
  handleClickPage: (val: number) => void;
}> = ({ totalPagesNumber, activepage, handleClickPage }) => {
  console.log(totalPagesNumber);

  const totalPages = Array.from(Array(totalPagesNumber).keys());

  if (totalPages.length < 2) {
    return <></>;
  }

  let paginationItemsToGo: number[] = [];
  let prev5pages = activepage - 5;
  let next5pages = activepage + 5;

  if (activepage < 5) {
    prev5pages = 0;

    paginationItemsToGo = totalPages.slice(0, 5);

    if (activepage + 5 >= totalPagesNumber) {
      next5pages = totalPagesNumber - 1;
    }
  }

  if (activepage > totalPagesNumber - 5) {
    next5pages = totalPagesNumber - 1;

    paginationItemsToGo = totalPages.slice(
      totalPagesNumber - 5,
      totalPagesNumber
    );
  }

  if (!(activepage < 5) && !(activepage > totalPagesNumber - 5)) {
    paginationItemsToGo = totalPages.slice(activepage - 2, activepage + 3);
  }

  if (totalPagesNumber < 5) {
    paginationItemsToGo = totalPages;
  }

  const handleClickBeginning = () => {
    handleClickPage(0);
  };

  const handleClickSkipPrev = () => {
    handleClickPage(prev5pages);
  };

  const handleClickPrev = () => {
    if (activepage < 1) {
      return;
    }

    handleClickPage(activepage - 1);
  };

  const handleClickNext = () => {
    if (activepage + 1 >= totalPagesNumber) {
      return;
    }

    handleClickPage(activepage + 1);
  };

  const handleClickSkipNext = () => {
    handleClickPage(next5pages);
  };

  const handleClickEnd = () => {
    handleClickPage(totalPagesNumber - 1);
  };

  return (
    <div className={classes.paginationContainer}>
      <Pagination className={classes.pagination}>
        <Pagination.First onClick={handleClickBeginning} />
        <Pagination.Prev onClick={handleClickPrev} />
        <Pagination.Item onClick={handleClickSkipPrev}>
          {prev5pages + 1}
        </Pagination.Item>
        <Pagination.Ellipsis onClick={handleClickSkipPrev} />

        {paginationItemsToGo.map((page) => (
          <Pagination.Item
            key={page}
            active={page === activepage}
            onClick={() => handleClickPage(page)}
          >
            {page + 1}
          </Pagination.Item>
        ))}

        <Pagination.Ellipsis onClick={handleClickSkipNext} />
        <Pagination.Item onClick={handleClickSkipNext}>
          {next5pages + 1}
        </Pagination.Item>
        <Pagination.Next onClick={handleClickNext} />
        <Pagination.Last onClick={handleClickEnd} />
      </Pagination>
    </div>
  );
};

export default UpgradedPagination;
