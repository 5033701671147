import React from "react";
import { Spinner } from "react-bootstrap";
import { Form } from "react-bootstrap";

import classes from "./TextInput.module.css";

const TextInput = (props) => (
  <div className={props.error ? classes.inputError : undefined}>
    <Form.Group className={classes.textInput} controlId={props.controllerId}>
      <Form.Control
        type={props.type ? props.type : 'text'}
        placeholder={props.placeholder}
        value={props.value}
        defaultValue={props.defaultValue}
        disabled={props.disabled || props.processing}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
        onBlur={props.onBlur}
      />
      {props.processing && (
        <Spinner className={classes.spinner} animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
    </Form.Group>
    <span className={classes.errorMessage}>{props.errorMessage}</span>
  </div>
);


export default TextInput;
