import React from "react";

import { Modal, Button } from "react-bootstrap";

import CreateCertificateModal from "../CreateCertificateModal/CreateCertificateModal";
import SelectCertificateFromList from "../SelectCertificateFromList/SelectCertificateFromList";
import EditCertificate from "../EditCertificate/EditCertificate";

import useAddCertificatesModal from "./hooks/use-addCertificatesModal";

import classes from "./AddCertificateModal.module.scss";

const AddCertificateModal: React.FC<{
  show: boolean;
  handleClose: () => void;
  productId: number;
  linkedCertificatesIds: number[];
  handleLinkCertificates: (certificatesToLink: any[]) => any;
  handleFinishSave?: (cert: any) => void;
}> = ({
  show,
  handleClose,
  productId,
  linkedCertificatesIds,
  handleLinkCertificates,
  handleFinishSave,
}) => {
  const {
    displayCreateCertificate,
    switchMethodMessage,
    handleSwitchMethod,
    handleClickEditCertificate,
    handleCloseEditCertificate,
    showEditCertificateModal,
    certificateIdToEdit,
    showSwitchCreateOption,
    handleHideSwitchCreateOption,
    handleShowSwitchCreateOption,
  } = useAddCertificatesModal(show);

  const handleFinishCreate = (cert: any) => {
    if (handleFinishSave) {
      handleFinishSave(cert);
    }

    handleClose();
  };

  return (
    <>
      <Modal
        show={show && !showEditCertificateModal}
        onHide={handleClose}
        size="lg"
        className="mediumModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Añadir certificados</Modal.Title>
        </Modal.Header>
        {showSwitchCreateOption && (
          <div className={classes.switchAddCertificateMethod}>
            <Button variant="link" onClick={handleSwitchMethod}>
              {switchMethodMessage}
            </Button>
          </div>
        )}
        {displayCreateCertificate ? (
          <CreateCertificateModal
            productId={productId}
            handleFinishCreate={handleFinishCreate}
            handleClose={handleClose}
            handleOpenCropImage={handleHideSwitchCreateOption}
            handleCloseCropImage={handleShowSwitchCreateOption}
          />
        ) : (
          <SelectCertificateFromList
            productId={productId}
            linkedCertificatesIds={linkedCertificatesIds}
            handleLinkCertificates={handleLinkCertificates}
            handleClickEditCertificate={handleClickEditCertificate}
            handleClose={handleClose}
          />
        )}
      </Modal>
      <EditCertificate
        show={showEditCertificateModal}
        handleCloseModal={handleCloseEditCertificate}
        certificateToEditId={certificateIdToEdit}
        handleSaveAndClose={() => handleCloseEditCertificate()}
      />
    </>
  );
};

export default AddCertificateModal;
