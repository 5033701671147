import React from "react";
import { Modal } from "react-bootstrap";
import Article from "../Edit/Article/Article";
import classes from "./ProductArticle.module.css";

const ProductArticle = (props) => (
  
  <Modal
  className={classes.modifyModal}
    show={true}
    onHide={() => props.history.push(`/product/${props.match.params.pId}`)}
    size="lg"
  >
    <Modal.Header closeButton>
      <Modal.Title>Edit article</Modal.Title>
    </Modal.Header>
    <Modal.Body className={classes.modifyBottomModal}>
      <Article {...props} />
    </Modal.Body>
  </Modal>
);

export default ProductArticle;
