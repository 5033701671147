import React, { useState } from "react";
import { Row, Col, Container, Alert, Form } from "react-bootstrap";
import TabContainer from "../TabContainer/TabContainer";
import TabMainRow from "../TabMainRow/TabMainRow";
import OptionsButton from "../OptionsButton/OptionsButton";
import AdministerCopies from "../../containers/AdministerCopies/AdministerCopies";
import DataTitle from "../DataTitle/DataTitle";
import AddButton from "../AddButton/AddButton";
import InputErrorMessage from "../InputErrorMessage/InputErrorMessage";

import useCampaignForm from "./hooks/use-campaignForm";
import Language from "./models/language";
import Country from "./models/country";

import classes from "./CampaignTab.module.css";

const CampaignTab: React.FC<{}> = (props) => {
  const {
    amount,
    setAmount,
    maxPC,
    setMaxPC,
    languages,
    addLanguages,
    removeLanguage,
    countries,
    addCountries,
    removeCountry,
    handleSubmit,
    amountError,
    maxPCtError,
    languagestError,
    countriesError,
    formError,
  } = useCampaignForm();

  const [showLanguagesModal, setShowLanguagesModal] = useState<boolean>(false);
  const [showCountriesModal, setShowCountriesModal] = useState<boolean>(false);

  let languagesContent;
  if (languages.length > 0) {
    languagesContent = (
      <div className={classes.buttonsListContainer}>
        {languages.map((lang: Language) => {
          return (
            <OptionsButton
              key={lang.id}
              x={() => {
                removeLanguage(lang.id);
              }}
            >
              {lang.name}
            </OptionsButton>
          );
        })}
        <AddButton onClick={() => setShowLanguagesModal(true)} />
      </div>
    );
  } else {
    languagesContent = (
      <>
        <OptionsButton
          click={() => setShowLanguagesModal(true)}
          chevronExpand={() => {
            setShowLanguagesModal(true);
          }}
        >
          Seleccionar idiomas
        </OptionsButton>
        {languagestError && (
          <InputErrorMessage>
            Debe seleccionar al menos un idioma
          </InputErrorMessage>
        )}
        <Alert variant="info">No hay ningun idioma seleccionado</Alert>
      </>
    );
  }

  let countriesContent;
  if (countries.length > 0) {
    countriesContent = (
      <div className={classes.buttonsListContainer}>
        {countries.map((country: Country) => {
          return (
            <OptionsButton
              key={country.id}
              x={() => {
                removeCountry(country.id);
              }}
            >
              {country.name}
            </OptionsButton>
          );
        })}
        <AddButton onClick={() => setShowCountriesModal(true)} />
      </div>
    );
  } else {
    countriesContent = (
      <>
        <OptionsButton
          click={() => setShowCountriesModal(true)}
          chevronExpand={() => {
            setShowCountriesModal(true);
          }}
        >
          Seleccionar paises
        </OptionsButton>
        {countriesError && (
          <InputErrorMessage>
            Debe seleccionar al menos un pais
          </InputErrorMessage>
        )}
        <Alert variant="info">No hay ningun pais seleccionado</Alert>
      </>
    );
  }

  return (
    <>
      <TabContainer>
        <TabMainRow>
          <Col md={12}>
            <Container fluid>
              <Row>
                <Col>
                  <Form.Group controlId="campaignAmount">
                    <Form.Label>Cantidad</Form.Label>
                    <Form.Control
                      value={amount}
                      onChange={(event) =>
                        setAmount(Number(event.target.value))
                      }
                      type="number"
                      placeholder="1"
                    />
                    {amountError && (
                      <InputErrorMessage>
                        Debe especificar una cantidad valida de (X a Y)
                      </InputErrorMessage>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="MaxPC">
                    <Form.Label>MaxPC</Form.Label>
                    <Form.Control
                      value={maxPC}
                      onChange={(event) => setMaxPC(Number(event.target.value))}
                      type="number"
                      placeholder="1.2"
                    />
                    {maxPCtError && (
                      <InputErrorMessage>
                        Debe especificar una MAXPC valido de (X a Y)
                      </InputErrorMessage>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DataTitle>Idiomas</DataTitle>
                  {languagesContent}
                </Col>
                <Col>
                  <DataTitle>Paises</DataTitle>
                  {countriesContent}
                </Col>
              </Row>
              <Row>
                <Col>
                  <OptionsButton
                    variant="success"
                    checkSquare={handleSubmit}
                    click={handleSubmit}
                  >
                    Crear campaña
                  </OptionsButton>
                  {formError && (
                    <InputErrorMessage>
                      Hay campos invalidos corríjalos antes de continuar
                    </InputErrorMessage>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </TabMainRow>
      </TabContainer>
      {showLanguagesModal ? (
        <AdministerCopies
          addButtonDescription="hide"
          buttonDescription="hide"
          title="Seleccionar idiomas de la campaña"
          addTableTitle="Idiomas a añadir"
          copiesTableTitle="Artìculo original"
          loadItems={async () => {
            return {
              data: [
                new Language(1, "Español"),
                new Language(2, "Frances (putos franceses)"),
                new Language(3, "Aleman"),
                new Language(4, "Chino"),
                new Language(5, "Catalan"),
              ],
            };
          }}
          onSave={async () => {}}
          onSaved={async (languages: Language[]) => {
            addLanguages(languages);
          }}
          applySelection={async () => {}}
          selectedItems={languages.map((lang) => lang.id)}
          multiSelect
          close={() => setShowLanguagesModal(false)}
        />
      ) : undefined}
      {showCountriesModal ? (
        <AdministerCopies
          addButtonDescription="hide"
          buttonDescription="hide"
          title="Seleccionar paises de la campaña"
          addTableTitle="Paises a añadir"
          copiesTableTitle="Artìculo original"
          loadItems={async () => {
            return {
              data: [
                new Country(1, "España"),
                new Language(2, "Francia"),
                new Language(3, "Alemania"),
                new Language(4, "China"),
                new Language(5, "Andorra"),
              ],
            };
          }}
          onSave={async () => {}}
          onSaved={async (countries: Country[]) => {
            addCountries(countries);
          }}
          applySelection={async () => {}}
          selectedItems={countries.map((country) => country.id)}
          multiSelect
          close={() => setShowCountriesModal(false)}
        />
      ) : undefined}
    </>
  );
};

export default CampaignTab;
