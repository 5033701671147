import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import apiCategories from "../../../api/categories";
import apiProducts from "../../../api/products";
import {
  Spinner,
  Col,
  Alert,
  Tabs,
  Badge,
  Card,
  Accordion,
  Modal,
  Button,
} from "react-bootstrap";
import OptionsButton from "../../../components/OptionsButton/OptionsButton";
import InputErrorMessage from "../../../components/InputErrorMessage/InputErrorMessage";
import DraggableSortableFilter from "../../../components/DraggableSortableFilter/DraggableSortableFilter";
import CustomAccordion from "../../../components/Accordion/Accordion";
import TextInput from "../../../components/TextInput/TextInput";
import AdministerCopies from "../../AdministerCopies/AdministerCopies";
import Product from "../../Edit/Product/Product/Product";
import TitleContainer from "../../../components/TitleContainer/TitleContainer";
import EditButton from "../../../components/EditButton/EditButton";
import CheckSphere from "../../../components/CheckSphere/CheckSphere";
import { ArrowRight } from "react-bootstrap-icons";
import classes from "./CreateCategory.module.css";
import ProductsTable from "../../../components/ProductsTable/ProductsTable";
import handleApiErrors from "../../../utilities/handleApiErrors";
import displayNotification from "../../../utilities/displayNotification";
import checkSync from "../../../utilities/checkSync";
import { Tab } from "bootstrap";
import SEOTab from "../../SEOTab/SEOTab";
import DataTitle from "../../../components/DataTitle/DataTitle";
import TextEditor from "../../../components/TextEditor/TextEditor";
import MainTitle from "../../../components/MainTitle/MainTitle";
import ResyncWhenErrorButton from "../../../components/ResyncWhenErrorButton/ResyncWhenErrorButton";
import TabErrorIcon from "../../../components/TabErrorIcon/TabErrorIcon";
import TabContainer from "../../../components/TabContainer/TabContainer";
import TabMainRow from "../../../components/TabMainRow/TabMainRow";
import generateUrlValidation from "../../../utilities/GenerateUrlValidation";
import ReactTooltip from "react-tooltip";

import CategorySubcategories from "../../Edit/Category/CategorySubcategories/CategorySubcategories";

import useCategories from "../../Edit/Product/Product/hooks/use-categories";
import useProducts from "../../Edit/Category/hooks/use-products";

const states = {
  LOADING: "LOADING",
  SUCCEDDED: "SUCCEDDED",
  PROCESSING: "PROCESSING",
  ERROR: "ERROR",
};

const CreateCategory = (props) => {
  const accesToken = useSelector((state) => state.user.access);

  // * Regular expressions
  const regExpURL =
    /^[^~"<+>^.:;\\,%\]_`!\[{|}&€‚ƒ$=…†'‡ˆ‰Š?‹ŒŽ‘’“”•)*#(–—˜™š@›œžŸ¡¢£¥|§¨©ª«¬¯®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ]+$/;
  const regExpURL2 =
    /^[^~"<+>^.:;\\,%\]_`!\[{|}&€‚ƒ$=…†'‡ˆ‰?‹Œ‘’“”•)*#(–—˜™@›œ¡¢£¥|§¨©ª«¬¯®¯°±²³´µ¶·¸¹º»¼½¾¿Þ÷þ]+$/;
  // const [specialCharacters, setSpecialCharacters] = useState(true);
  const [specialCharactersURL, setSpecialCharactersURL] = useState(true);
  const [specialCharactersName, setSpecialCharactersName] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  // *  SEO
  const [reWrite, setReWrite] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  const [description, setDescription] = useState("");
  const [key, setKey] = useState(0);
  const [id, setId] = useState();
  const [fatherCategory, setFatherCategory] = useState();
  const [name, setName] = useState("");
  const [active, setActive] = useState();
  const [features, setFeatures] = useState([]);

  const { products, setProducts, handleOnDragEndProduct } = useProducts(id);
  const {
    categories,
    handleDropCategory,
    handleUpdateEditedCategory,
    handleClickSyncCategory,
    setEmpytCategories,
  } = useCategories(id);

  const productsRef = useRef();
  productsRef.current = products;

  const [filterFeatures, setFilterFeatures] = useState([]);
  const [availableFilterFeatures, setAvailableFilterFeatures] = useState([]);
  const [filterAttributes, setFilterAttributes] = useState([]);
  const [availableFilterAttributes, setAvailableFilterAttributes] = useState(
    []
  );
  const [problems, setProblems] = useState([]);
  const [proccessErrorTab, setProccessErrorTab] = useState(false);

  const [processProducts, setProcessProducts] = useState(false);
  const [formProcessPhase, setFormProcessPhase] = useState();
  const [errors, setErrors] = useState(false);
  const [duplicateNameError, setDuplicateNameError] = useState(false);
  const [process, setProcess] = useState(false);
  const [isSyncing, setIsSyncing] = useState();
  const [hasSynced, setHasSynced] = useState(false);

  const [showFatherCategory, setShowFatherCategory] = useState();
  const [showLinkProducts, setShowLinkProducts] = useState();
  const [showProduct, setShowProduct] = useState();

  const [syncError, setSyncError] = useState();
  const [noSyncUnactive, setNoSyncNoUnactive] = useState(false);

  useEffect(() => {
    setProducts([]);
    setEmpytCategories();
  }, []);

  //  * Refactor for the tab navigation
  const invalidFieldMessage = () => {
    setErrors(true);
    displayNotification({
      title: "Hay campos inválidos",
      message: `Completa correctamente todos los campos requeridos para continuar`,
      // message: `No es posible seguir con la creación, por favor rellena los campos de manera correcta. `,
      type: "danger",
    });
  };

  const shortSync = async () => {
    try {
      setIsSyncing(true);

      await apiCategories.sync(accesToken, { categoryId: id });
      await checkSync.checkCategorySync(accesToken, { categoryId: id });

      setIsSyncing(false);
      setSyncError(false);
      setHasSynced(true);
    } catch (error) {
      setIsSyncing(false);
      setSyncError(true);
    }
  };

  const switchPhase = async () => {
    setErrors(false);

    switch (Number(key)) {
      case 0:
        if (
          name.length <= 0 ||
          !specialCharactersName ||
          duplicateNameError ||
          !fatherCategory
        ) {
          setErrors(true);
          throw new Error();
        } else {
          setProcess(true);

          let categoryId = id;
          // * Set reWrite with the name in the first tab / Generate friendly URL with translation of some special characters
          const generatedUrl = generateUrlValidation({
            specialCharactersName: specialCharactersName,
            name: name,
            reWrite: reWrite,
            setSpecialCharactersURL: setSpecialCharactersURL,
          });

          if (generatedUrl) {
            setReWrite(generatedUrl);
          }
          try {
            console.log(categoryId);

            if (!categoryId) {
              const createCategoryResponse = await apiCategories.add(
                accesToken,
                { name }
              );
              categoryId = createCategoryResponse.data.id;
              setId(createCategoryResponse.data.id);

              await apiCategories.edit(accesToken, {
                id: categoryId,
                father: fatherCategory.id,
              });

              setErrors(false);
            } else {
              await apiCategories.edit(accesToken, {
                id: categoryId,
                name,
                father: fatherCategory.id,
              });
              setErrors(false);
            }

            setProcess(false);
            setKey(1);
          } catch (e) {
            if (e.response.status === 409) {
              setDuplicateNameError(true);
              setErrors(true);
              setProcess(false);
            } else if (e === "SYNC ERROR") {
              displayNotification({
                title: "Ha habido un error en la sync",
                message:
                  "Ha ocurrido un error en la sincronización, prueba otra vez dentro de unos minutos, si el problema persiste ponte en contacto con el servicio técnico",
                type: "error",
              });
            }

            throw new Error();
          }
        }
        break;

      case 1:
        if (isSeoTabWrong) {
          throw new Error();
        }

        setKey(2);
        break;

      case 2:
        if (!active || description.length <= 0 || description.length > 50000) {
          setErrors(true);
          throw new Error();
        } else {
          setProcess(true);
          setErrors(false);

          let categoryId = id;
          const params = {
            id: categoryId,
            name,
            father: fatherCategory.id,
            active,
            url_rewrite: reWrite,
            description,
            meta_title: metaTitle.substring(0, 71),
            meta_description: metaDescription.substring(0, 300),
          };

          try {
            await apiCategories.edit(accesToken, params);
            shortSync();

            setKey(2);
            setProcess(false);
            setErrors(false);
          } catch (e) {
            if (e.response.status === 409) {
              setDuplicateNameError(true);
              setErrors(true);
              setProcess(false);
            }

            handleApiErrors(e);
          }
        }
        break;

      case 3:
        setProcess(true);

        try {
          await apiCategories.changeFilterOrder(accesToken, {
            category: id,
            filtername: "features",
            valuesList: filterFeatures.map((item) => item.id),
          });

          setProcess(false);
          setKey(4);
        } catch (e) {
          handleApiErrors(e);
        }
        break;

      default:
        setKey(Number(key) + 1);
        break;
    }
  };

  const nextPhase = async () => {
    try {
      await switchPhase(key);
      setKey(key + 1);
    } catch (e) {
      invalidFieldMessage();
    }
  };

  const prevPhase = async () => {
    try {
      await switchPhase(key);
      setKey(key - 1);
    } catch (e) {
      invalidFieldMessage();
    }
  };

  const saveCategory = async () => {
    setProccessErrorTab(true);
    try {
      if (
        duplicateNameError ||
        name.length <= 0 ||
        !specialCharactersName ||
        !active ||
        description.length <= 0 ||
        description.length > 50000 ||
        !fatherCategory ||
        metaTitle.length <= 0 ||
        metaDescription.length <= 0 ||
        metaTitle.length >= 70 ||
        metaDescription.length >= 300 ||
        !specialCharactersName ||
        !specialCharactersURL
      ) {
        setErrors(true);
        throw "ERROR";
      } else {
        setErrors(false);
        setProcess(true);

        const params = {
          id,
          name,
          active,
          father: fatherCategory.id,
          url_rewrite: reWrite,
          description,
          meta_title: metaTitle.substring(0, 71),
          meta_description: metaDescription.substring(0, 300),
        };

        await apiCategories.edit(accesToken, params);
        // TODO puesto aqui el push para que solo lo haga si pasa todas las comprobaciones de errores en los campos

        if (!props.modal) {
          props.history.push(`/category/${id}`);
        } else {
          props.afterClose({
            id,
            name,
          });
          props.handleClose();
        }

        displayNotification({
          title: "Categoría creada",
          message: `La categoría ${name} se ha creado de forma correcta`,
          type: "success",
        });
      }
    } catch (e) {
      console.log(e);

      displayNotification({
        title: "Hay errores que necesitan arreglarse",
        message:
          "Comprueba todos los campos, especialmente aquellos marcados con un icono rojo de admiración en la pestaña",
        type: "danger",
      });

      throw new Error();
    }
  };
  const saveAndSyncCategory = async () => {
    setProccessErrorTab(true);
    setProcess(true);
    const params = {
      id,
      name,
      father: fatherCategory.id,
      active,
      url_rewrite: reWrite,
      description,
      meta_title: metaTitle.substring(0, 71),
      meta_description: metaDescription.substring(0, 300),
    };
    try {
      if (!active) {
        throw "NO_SYNC_UNACTIVE";
      }

      await saveCategory();
      apiCategories.sync(accesToken, { categoryId: id });
    } catch (e) {
      setProcess(false);

      if (e === "NO_SYNC_UNACTIVE") {
        setNoSyncNoUnactive(true);
      }

      throw new Error();
    }
  };

  const syncCategory = (categoryId) => {
    setIsSyncing(true);
    apiCategories.sync(accesToken, { categoryId }).then(() => {
      checkSync
        .checkCategorySync(accesToken, {
          categoryId,
        })
        .then(() => {
          setIsSyncing(false);
          setSyncError(false);
        })
        .catch((e) => {
          setIsSyncing(false);
          setSyncError(true);
        });
    });
  };

  const doesCategoryHaveErrors =
    duplicateNameError ||
    name.length <= 0 ||
    !specialCharactersName ||
    !active ||
    description.length <= 0 ||
    description.length > 50000 ||
    !fatherCategory ||
    metaTitle.length <= 0 ||
    metaDescription.length <= 0 ||
    metaTitle.length >= 70 ||
    metaDescription.length >= 300 ||
    !specialCharactersName ||
    !specialCharactersURL;

  const saveButtons = (
    <>
      <OptionsButton
        check={async () => {
          try {
            await saveCategory();
            // TODO comentado porque daba problemas con errores y dejaba crear, esta puesto en el save si pasa todas las comprobaciones

            // props.history.push(`/category/${id}`);
          } catch (e) {}
        }}
        click={async () => {
          try {
            await saveCategory();
            // TODO comentado porque daba problemas con errores y dejaba crear, esta puesto en el save si pasa todas las comprobaciones

            // props.history.push(`/category/${id}`);
          } catch (e) {}
        }}
        disabled={process || isSyncing}
        loading={process}
      >
        Guardar
      </OptionsButton>
      <OptionsButton
        checkSquare={async () => {
          try {
            await saveAndSyncCategory();
            props.history.push(`/category/${id}`);
          } catch (e) {}
        }}
        click={async () => {
          try {
            await saveAndSyncCategory();
            props.history.push(`/category/${id}`);
          } catch (e) {}
        }}
        disabled={process || isSyncing}
        loading={process}
      >
        Guardar y sincronizar
      </OptionsButton>
    </>
  );

  const handleDragAndDrop = (items, setItems) => (result) => {
    if (!result.destination) return;

    const origin = result.source.index;
    const destination = result.destination.index;

    const upatedItems = [...items];
    const [reorderedItems] = upatedItems.splice(origin, 1);
    upatedItems.splice(destination, 0, reorderedItems);

    setItems(upatedItems);
  };

  const selectUnselectToRemove = (id) => {
    const updatedProducts = products.map((product) => {
      if (product.id === id) {
        return {
          ...product,
          remove: !product.remove,
        };
      }

      return {
        ...product,
      };
    });

    setProducts(updatedProducts);
  };

  const includeExcludeFeatureFilter = (
    id,
    { availableFilters, setAvailableFilters, filters, setFilters }
  ) => {
    const filterinActive = filters.find((filter) => filter.id === id);

    if (filterinActive) {
      // If filter is already included, remove it from active filters and add it to available filters
      setAvailableFilters([...availableFilters, filterinActive]);

      const updatededFilters = filters.filter((filter) => filter.id !== id);
      setFilters(updatededFilters);
    } else {
      // If filter is not include it, add it to filters and remove it from available filters
      const filterToInclude = availableFilters.find(
        (filter) => filter.id === id
      );
      setFilters([...filters, filterToInclude]);

      const updatedAvailableFilters = availableFilters.filter(
        (filter) => filter.id !== id
      );
      setAvailableFilters(updatedAvailableFilters);
    }
  };

  let content;

  const disabled = formProcessPhase === states.PROCESSING;

  let nameErrorMessage;

  if (name.length <= 0) {
    nameErrorMessage = "No puede estar el valor vacío";
  } else if (!specialCharactersName) {
    nameErrorMessage = "No está permitido usar caracteres especiales";
  } else if (duplicateNameError) {
    nameErrorMessage = "No puede haber 2 categoría con el mismo nombre";
  }

  let activeErrorMessage =
    "Es necesario que la categoría esté activa para poder crearla";
  if (noSyncUnactive) {
    activeErrorMessage = "No se pueden sincronizar categorías no activas";
  }

  const tabsNavigationCreateCategory = async (kToGo) => {
    try {
      await switchPhase(key);
      setKey(Number(kToGo));
      setErrors(false);
    } catch (e) {
      setErrors(true);
      invalidFieldMessage();
    }
  };

  // SYNC PRODUCTS
  const setProductSync = async ({ id, sync }) => {
    setProducts((oldProducts) => {
      const updatedProducts = oldProducts.map((product) => {
        if (product.id === id) {
          return {
            ...product,
            sync,
          };
        }

        return product;
      });

      return updatedProducts;
    });
  };

  const syncItem = async (id) => {
    setProductSync({ id, sync: undefined });

    try {
      await apiProducts.sync(accesToken, {
        productId: id,
      });

      await checkSync.checkProductSync(accesToken, {
        productId: id,
      });

      setProductSync({ id, sync: true });
    } catch (error) {
      setProductSync({ id, sync: false });
    }
  };

  const syncAllProducts = async () => {
    try {
      setProcessProducts(true);

      await Promise.allSettled(products.map((product) => syncItem(product.id)));

      setProcessProducts(false);
    } catch (e) {}
  };

  const clickUnklink = async () => {
    setProcessProducts(true);

    const productsToUnlink = products.filter((product) => product.remove);

    const productIdsToUnlink = productsToUnlink.map((item) => item.id);

    try {
      await apiProducts.removeCategories(accesToken, {
        productIds: productIdsToUnlink,
        categoryIds: [id],
      });

      const updatedProducts = products.filter((product) => !product.remove);

      setProducts(updatedProducts);
      setProcessProducts(false);

      (async () => {
        for (const productId of productIdsToUnlink) {
          try {
            await apiProducts.sync(accesToken, {
              productId,
            });

            await checkSync.checkProductSync(accesToken, {
              productId,
            });
          } catch (e) {
            displayNotification({
              title: `Ha habido un error en la sync del producto con id ${productId}`,
              message:
                "Revise el error y contacte con asistencia si este persiste",
              type: "danger",
            });
          }
        }
      })();
    } catch (e) {}
  };

  const clickUnlinkSingle = async (productId) => {
    setProcessProducts(true);

    try {
      await apiProducts.removeCategories(accesToken, {
        productIds: [productId],
        categoryIds: [id],
      });

      const updatedProducts = products.filter((item) => item.id !== productId);
      setProducts(updatedProducts);

      setProcessProducts(false);
    } catch (e) {
      setProcessProducts(false);
    }
  };

  // const syncLinkedProducts = async (productsToSync, setSyncState) => {
  //   setSyncState(true);
  //   for (const key in productsToSync) {
  //     try {
  //       await apiProducts.sync(accesToken, {
  //         productId: productsToSync[key].id,
  //       });

  //       await checkSync.checkProductSync(accesToken, {
  //         productId: productsToSync[key].id,
  //       });
  //     } catch (e) {
  //       displayNotification({
  //         title: "Algun producto vinculado no se ha sincronizado correctamente",
  //         message: `Vaya a la categoría con ID:${id} y repita la sincronización de productos`,
  //         type: "danger",
  //       });
  //       setSyncState(false);
  //     }
  //   }
  //   setSyncState(false);
  // };

  const nameTitle = (
    <div className="d-flex align-items-center">
      {(syncError && !isSyncing) ||
      (duplicateNameError && proccessErrorTab) ||
      (name.length <= 0 && proccessErrorTab) ||
      (!specialCharactersName && proccessErrorTab) ? (
        <TabErrorIcon />
      ) : undefined}
      Nombre
      {isSyncing ? (
        <Badge className="ml-2 p-2" pill variant="primary">
          <Spinner animation="border" size="sm" />
        </Badge>
      ) : undefined}
    </div>
  );

  const nameBody = (
    <TabContainer>
      <TabMainRow>
        <Col md={12} className="pr-4">
          <DataTitle firstTitle>Nombre de la categoría</DataTitle>
          <TextInput
            controlId="editCategory"
            placeholder={name}
            value={name}
            onChange={(event) => {
              setSpecialCharactersName(regExpURL2.test(event.target.value)); //devuelve false si coincide con alguno de los caracteres de la regex
              setName(event.target.value);
              setDuplicateNameError(false);
            }}
            error={
              (name.length <= 0 && errors) ||
              duplicateNameError ||
              !specialCharactersName
            }
            errorMessage={nameErrorMessage}
            disabled={process}
          />
        </Col>
        <Col md={12}>
          <DataTitle>Categoría padre</DataTitle>
          {!fatherCategory ? (
            <>
              <OptionsButton
                variant="success"
                plus={() => {}}
                click={() => {
                  setShowFatherCategory(true);
                }}
              >
                Añadir categoría padre
              </OptionsButton>
              {!fatherCategory && errors ? (
                <InputErrorMessage>
                  Necesitas especificar una categoría padre
                </InputErrorMessage>
              ) : undefined}
            </>
          ) : (
            <EditButton
              click={() => setShowFatherCategory(true)}
              disabled={process}
            >
              {fatherCategory.name}
            </EditButton>
          )}
        </Col>
      </TabMainRow>
    </TabContainer>
  );

  const dataTitle = (
    <div className="d-flex align-items-center">
      {proccessErrorTab &&
      (noSyncUnactive || !active || description.length > 50000) ? (
        <TabErrorIcon />
      ) : undefined}
      Datos
    </div>
  );

  const dataBody = (
    <TabContainer>
      <TabMainRow>
        <Col className="my-1" md={6}>
          <CheckSphere
            check={active === true}
            x={active === false}
            onClick={() => {
              setNoSyncNoUnactive(false);
              if (active === undefined) {
                setActive(true);
              } else {
                setActive(!active);
              }
            }}
            error={(errors && !active) || noSyncUnactive}
            errorMessage={activeErrorMessage}
            disabled={process}
          >
            Activo
          </CheckSphere>
        </Col>
        <Col md={12}>
          <DataTitle className={classes.title}>Descripción:</DataTitle>
          <TextEditor
            value={description}
            onEditorChange={(content) => {
              setDescription(content);
            }}
            disabled={process}
            error={
              (description.length <= 0 || description.length > 50000) && errors
            }
            errorMessage="Necesita introducir una descripción para guardar el producto de menos de 50000 caracteres"
          />
        </Col>
      </TabMainRow>
    </TabContainer>
  );

  const productTitle = (
    <>
      Productos
      <Badge
        className="ml-2"
        pill
        variant={products.length === 0 ? "danger" : "primary"}
      >
        {products.length}
      </Badge>
    </>
  );

  const productBody = (
    <TabContainer>
      <TabMainRow>
        <Col md={12}>
          {hasSynced ? (
            <>
              <TitleContainer>
                <h3>Productos:</h3>
                <div>
                  <OptionsButton
                    variant="primary"
                    arrowRepeat={() => {}}
                    click={syncAllProducts}
                    disabled={processProducts}
                    loading={processProducts}
                  >
                    {products.find((product) => product.sync === undefined)
                      ? "Sincronizando productos vinculados"
                      : "Sincronizar productos vinculados"}
                  </OptionsButton>
                  <OptionsButton
                    variant="success"
                    plus={() => {}}
                    click={() => setShowLinkProducts(true)}
                  >
                    Vincular productos
                  </OptionsButton>
                  {products.find((product) => product.remove) ? (
                    <OptionsButton
                      variant="danger"
                      x={() => {}}
                      click={clickUnklink}
                      disabled={processProducts}
                      loading={processProducts}
                    >
                      Desvincular
                    </OptionsButton>
                  ) : undefined}
                </div>
              </TitleContainer>
              <ProductsTable
                products={productsRef.current}
                disabled={processProducts}
                procesing={processProducts}
                selectUnselectToRemove={selectUnselectToRemove}
                x={clickUnlinkSingle}
                onEdit={(id) => setShowProduct(id)}
                onClickSync={syncItem}
                handleDragEnd={handleOnDragEndProduct}
              />
              <div className="pt-2"></div>
              <CategorySubcategories
                categories={categories}
                handleDropCategory={handleDropCategory}
                handleUpdateEditedCategory={handleUpdateEditedCategory}
                handleClickSyncCategory={handleClickSyncCategory}
              />
            </>
          ) : (
            <Alert variant="info">
              Para añadir productos asegurese de que las pestañas previas esten
              rellenadas y la categoría sincronizada
            </Alert>
          )}
        </Col>
      </TabMainRow>
    </TabContainer>
  );

  const filtersTitle = <>Filtros</>;

  const filtersBody = (
    <TabContainer>
      <TabMainRow>
        {features === states.LOADING ||
        availableFilterFeatures === states.LOADING ? (
          <Spinner
            className="mt-3"
            as="svg"
            animation="border"
            size="md"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <>
            <Col md={12} className={"p-0 m-0"}>
              <TitleContainer>
                <h3>Filtros</h3>
                <OptionsButton
                  variant="success"
                  plus={() => {}}
                  click={() =>
                    props.history.push(`/create/category/add-filter-features/`)
                  }
                  disabled={process}
                >
                  Añadir filtros de característica
                </OptionsButton>
              </TitleContainer>
            </Col>
            <Col md={6} className={"pt-0 mt-0"}>
              <h5 className="mb-3">Características de productos</h5>
              <div className={process ? classes.disabled : ""}>
                <CustomAccordion.Accordion>
                  {availableFilterFeatures.map((filterFeature, key) => (
                    <CustomAccordion.Item
                      key={key}
                      className="accordion-item"
                      onClick={
                        !process
                          ? () =>
                              includeExcludeFeatureFilter(filterFeature.id, {
                                availableFilters: availableFilterFeatures,
                                setAvailableFilters: setAvailableFilterFeatures,
                                filters: filterFeatures,
                                setFilters: setFilterFeatures,
                              })
                          : () => {}
                      }
                    >
                      <CustomAccordion.Header className={classes.availableItem}>
                        <span>{filterFeature.name}</span>
                        <button>
                          <ArrowRight />
                        </button>
                      </CustomAccordion.Header>
                    </CustomAccordion.Item>
                  ))}
                </CustomAccordion.Accordion>
              </div>
            </Col>
            <Col md={6} className={"pt-0 mt-0 pl-3"}>
              <h5 className="mb-3"> Características en filtro</h5>
              {filterFeatures.length > 0 ? (
                <DraggableSortableFilter
                  items={filterFeatures}
                  droppableId="featuresFilter"
                  disabled={process}
                  handleDranEnd={(result) =>
                    handleDragAndDrop(filterFeatures, setFilterFeatures)(result)
                  }
                  onClickRemove={
                    !process
                      ? (id) =>
                          includeExcludeFeatureFilter(id, {
                            availableFilters: availableFilterFeatures,
                            setAvailableFilters: setAvailableFilterFeatures,
                            filters: filterFeatures,
                            setFilters: setFilterFeatures,
                          })
                      : () => {}
                  }
                />
              ) : (
                <Alert variant="info" className={classes.noItemsAlert}>
                  No hay filtros asociados a esta categoría
                </Alert>
              )}
            </Col>
            <Col md={6} className={"pt-0 mt-0"}>
              <h5 className="mb-3">Atributos de productos</h5>
              <CustomAccordion.Accordion>
                {availableFilterAttributes.map((attributeFilter, key) => (
                  <CustomAccordion.Item
                    key={key}
                    className="accordion-item"
                    onClick={includeExcludeFeatureFilter(attributeFilter.id, {
                      availableFilters: availableFilterAttributes,
                      setAvailableFilters: setAvailableFilterAttributes,
                      filters: filterAttributes,
                      setFilters: setFilterAttributes,
                    })}
                  >
                    <CustomAccordion.Header className={classes.availableItem}>
                      <span>{attributeFilter.name}</span>
                      <button>
                        <ArrowRight />
                      </button>
                    </CustomAccordion.Header>
                  </CustomAccordion.Item>
                ))}
              </CustomAccordion.Accordion>
            </Col>
            <Col md={6} className={"pt-0 mt-0 pl-3"}>
              <h5 className="mb-3"> Atributos en filtro</h5>
              {filterAttributes.length > 0 ? (
                <DraggableSortableFilter
                  items={filterAttributes}
                  droppableId="attributesFiltees"
                  disabled={process}
                  handleDranEnd={(result) =>
                    handleDragAndDrop(
                      filterAttributes,
                      setFilterAttributes
                    )(result)
                  }
                  onClickRemove={(id) =>
                    includeExcludeFeatureFilter(id, {
                      availableFilters: availableFilterAttributes,
                      setAvailableFilters: setAvailableFilterAttributes,
                      filters: filterAttributes,
                      setFilters: setFilterAttributes,
                    })
                  }
                />
              ) : (
                <Alert variant="info" className={classes.noItemsAlert}>
                  No hay filtros asociados a esta categoría
                </Alert>
              )}
            </Col>
          </>
        )}
      </TabMainRow>
    </TabContainer>
  );

  const isSeoTabWrong =
    reWrite.length <= 0 ||
    !specialCharactersURL ||
    metaTitle.length <= 0 ||
    metaDescription.length <= 0 ||
    metaTitle.length >= 70 ||
    metaDescription.length > 300;

  const seoTitle = (
    <div className="d-flex align-items-center">
      {proccessErrorTab && isSeoTabWrong ? <TabErrorIcon /> : undefined}
      SEO
    </div>
  );

  const seoBody = (
    <SEOTab
      // Nuevos props
      reWrite={reWrite}
      setSpecialCharactersURL={setSpecialCharactersURL}
      regExpURL={regExpURL}
      setReWrite={setReWrite}
      setHasChanged={setHasChanged}
      specialCharactersURL={specialCharactersURL}
      specialCharactersName={specialCharactersName}
      name={name}
      // Fin de nuevos props
      metaTitle={metaTitle}
      setMetaTitle={(newValue) => {
        setMetaTitle(newValue);
        setHasChanged(true);
      }}
      metaDescription={metaDescription}
      setMetaDescription={(newValue) => {
        setMetaDescription(newValue);
        setHasChanged(true);
      }}
      error={errors}
    />
  );

  content = (
    <div className="position-relative">
      {key === 3 && !syncError && !props.modal ? (
        <>
          <div className={classes.topSaveOptions}>
            <div>{saveButtons}</div>
            {noSyncUnactive ? (
              <div className="text-right">
                <InputErrorMessage>
                  No se pueden sincronizar productos sin sincronizar
                </InputErrorMessage>
              </div>
            ) : undefined}
          </div>
        </>
      ) : undefined}
      {/* Principio de la paginación */}
      {!props.modal ? (
        <MainTitle>
          {/* // TODO Margin top for not overlaping save buttons with TItle  */}
          <h1 className={classes.marginTop}>Crear nueva categoría: {name}</h1>
        </MainTitle>
      ) : undefined}
      {syncError ? (
        <ResyncWhenErrorButton
          onClick={() => syncCategory(id)}
          isSyncing={isSyncing}
        />
      ) : undefined}

      <div className="hideOnMobile">
        <Tabs
          className={classes.tabs}
          id="productPhases"
          activeKey={key}
          onSelect={(k) => {
            tabsNavigationCreateCategory(k);
          }}
        >
          {/* Datos iniciales de producto */}

          <Tab eventKey={0} title={nameTitle}>
            {nameBody}
          </Tab>

          {/* Datos de SEO */}
          <Tab eventKey={1} title={seoTitle} disabled={!id}>
            {seoBody}
          </Tab>

          <Tab eventKey={2} title={dataTitle} disabled={!id}>
            {dataBody}
          </Tab>

          {/* Datos de productos */}
          <Tab eventKey={3} title={productTitle} disabled={!id}>
            {productBody}
          </Tab>

          {/* Datos de filtros */}
          {/* <Tab eventKey={3} title={filtersTitle} disabled={!id}>
            {filtersBody}
          </Tab> */}

          {/* {//FIXME @Xavier, para cuando máximo le entregue endpoints} */}

          {/* <Col md={6}>
                  <h5>Atributos del filtro:</h5>
                  {filterAttributes.length > 0 ? (
                    <div className={classes.filtersContainer}>
                      <DraggableSortableFilter
                        items={filterAttributes}
                        droppableId="attributesFilter"
                        disabled={formProcessPhase === states.PROCESSING}
                        handleDranEnd={(result) =>
                          handleDragAndDrop(
                            filterAttributes,
                            setFilterAttributes
                          )(result)
                        }
                      />
                    </div>
                  ) : (
                    <Alert variant="info" className={classes.noItemsAlert}>
                      No hay filtros asociados a esta categoría
                    </Alert>
                  )}
                </Col> */}
        </Tabs>
      </div>
      <div className={classes.hideOnPC}>
        <Accordion
          defaultActiveKey="0"
          activeKey={String(key)}
          onSelect={(k) => {
            tabsNavigationCreateCategory(k);
          }}
        >
          <Card id="accordion-card-0">
            <Accordion.Toggle as={Card.Header} eventKey="0">
              {nameTitle}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"0"}>
              <Card.Body>{nameBody}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card id="accordion-card-1">
            <Accordion.Toggle as={Card.Header} eventKey="1">
              {dataTitle}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"1"}>
              <Card.Body>{dataBody}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card id="accordion-card-2">
            <Accordion.Toggle as={Card.Header} eventKey="2">
              {productTitle}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"2"}>
              <Card.Body>{productBody}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card id="accordion-card-3">
            <Accordion.Toggle as={Card.Header} eventKey="3">
              {filtersTitle}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"3"}>
              <Card.Body>{filtersBody}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card id="accordion-card-4">
            <Accordion.Toggle as={Card.Header} eventKey="4">
              {seoTitle}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"4"}>
              <Card.Body>{seoBody}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
      {noSyncUnactive ? (
        <div className="text-right">
          <InputErrorMessage>
            No se pueden sincronizar categorías no activas
          </InputErrorMessage>
        </div>
      ) : undefined}
      <div className={classes.actionButtons}>
        {key !== 0 ? (
          <div className={classes.reverseButton}>
            <OptionsButton
              variant="success"
              arrowLeft={prevPhase}
              click={prevPhase}
              disabled={process}
              loading={process}
            >
              Fase previa
            </OptionsButton>
          </div>
        ) : undefined}
        {key !== 3 ? (
          <OptionsButton
            variant="success"
            arrowRight={async () => {
              await nextPhase();
            }}
            click={async () => {
              await nextPhase();
            }}
            disabled={process}
            loading={process}
          >
            Siguiente fase
          </OptionsButton>
        ) : (
          saveButtons
        )}
      </div>
    </div>
  );

  const modalsContent = (
    <>
      {showFatherCategory
        ? (() => {
            let selectedItems = [];
            if (fatherCategory) {
              selectedItems = [fatherCategory.id];
            }

            return (
              <AdministerCopies
                {...props}
                addButtonDescription="Añadir nuevo valor de categoría"
                buttonDescription="hide"
                title="Cambiar categoría principal"
                addTableTitle="Categoría a añadir"
                copiesTableTitle="Categoría original"
                createItem={async (name) => {
                  const responseCreateCategory = await apiCategories.add(
                    accesToken,
                    { name }
                  );

                  (async () => {
                    try {
                      await apiCategories.sync(accesToken, {
                        categoryId: id,
                      });

                      await checkSync.checkCategorySync(accesToken, {
                        categoryId: id,
                      });

                      displayNotification({
                        title: "Se ha sincronizado correctamente",
                        message: "La sync de la categoría ha sido exitosa",
                        type: "success",
                      });
                    } catch (error) {
                      displayNotification({
                        title: "Ha habido un error en la sync",
                        message: "La sync de la categoría ha fallado",
                        type: "danger",
                      });
                    }
                  })();

                  return responseCreateCategory;
                }}
                loadItems={() =>
                  apiCategories.listAll(accesToken).then(({ data }) => {
                    const items = data.map((item) => ({
                      id: item.id,
                      name: item.name,
                    }));

                    return { data: items };
                  })
                }
                applySelection={(category) => {
                  setFatherCategory({ id: category.id, name: category.name });
                  setShowFatherCategory(false);
                }}
                selectedItems={selectedItems}
                close={() => {
                  setShowFatherCategory(false);
                }}
              />
            );
          })()
        : undefined}
      {showLinkProducts
        ? (() => {
            let selectedItems = [];
            if (products !== states.LOADING) {
              selectedItems = products.map((product) => product.id);
            }

            return (
              <AdministerCopies
                addButtonDescription="hide"
                buttonDescription="hide"
                title="Productos a vincular"
                loadItems={() =>
                  apiProducts.listAll(accesToken).then(({ data }) => {
                    const items = data.map((item) => ({
                      id: item.id,
                      name: item.name,
                    }));

                    return { data: items };
                  })
                }
                onSave={async (items) => {
                  try {
                    await apiProducts.addCategoriesToMultipleProducts(
                      accesToken,
                      {
                        productIds: items.map((item) => item.id),
                        categoryIds: [Number(id)],
                      }
                    );

                    const linkedProducts = [];

                    for (const key in items) {
                      const response = await apiProducts.filter(accesToken, {
                        filtername: "id",
                        value: items[key].id,
                      });

                      const product = response.data[0];

                      linkedProducts.push({
                        id: product.id,
                        name: product.name,
                        active: product.active,
                      });

                      setProducts([...products, ...linkedProducts]);
                    }

                    (async () => {
                      for (const item of items) {
                        await syncItem(item.id);
                      }
                    })();
                  } catch (e) {}
                }}
                onSaved={async () => {}}
                applySelection={() => {}}
                selectedItems={selectedItems}
                close={() => {
                  setShowLinkProducts(false);
                }}
                multiSelect
              />
            );
          })()
        : undefined}
      {showProduct ? (
        <Product
          modal
          id={showProduct}
          onHide={() => setShowProduct(false)}
          beforeClose={(editedProduct) => {
            console.log(editedProduct);

            setProducts((products) => {
              const updatedProducts = products.map((product) => {
                if (product.id === editedProduct.id) {
                  product.name = editedProduct.name;
                  product.sync = editedProduct.sync;
                }

                return product;
              });

              return updatedProducts;
            });
          }}
        />
      ) : undefined}
    </>
  );

  if (props.modal) {
    return (
      <>
        <Modal
          className="bigModal"
          show={!showProduct && !showFatherCategory && !showLinkProducts}
          onHide={() => props.handleClose()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Crear categoria</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactTooltip delayShow={1000} place="top" type="info" />
            {content}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        {modalsContent}
      </>
    );
  } else {
    return (
      <>
        <ReactTooltip delayShow={1000} place="top" type="info" />
        {content}
        {modalsContent}
      </>
    );
  }
};

export default CreateCategory;
