const initialState = {
  access: "",
  refresh: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACCESS_TOKEN":
      return {
        ...state,
        access: action.access,
      };
    case "SET_REFRESH_TOKEN":
      return {
        ...state,
        refresh: action.refresh,
      };
    default:
      return state;
  }
};
export default reducer;
