import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import apiProducts from "../../../api/products";
import apiArticles from "../../../api/articles";
import api from "../../../api/api";
import { Accordion, Card, Tabs, Tab, Badge, Spinner } from "react-bootstrap";
import later from "../../../utilities/later";
import OptionsButton from "../../../components/OptionsButton/OptionsButton";
import PrePhaseCreateProduct from "../../Create/PrePhaseCreateProduct/PrePhaseCreateProduct";
import FirstCreateProductPhase from "../FirstCreateProductPhase/FirstCreateProductPhase";
import SecondCreateProductPhase from "../../Create/SecondCreateProductPhase/SecondCreateProductPhase";
import ThirdPhaseContainer from "../../PhotoTab/PhotoTab";
import classes from "./CreateProductProcess.module.css";
import ProductAttributesTab from "../../ProductAttributesTab/ProductAttributesTab";
import SEOTab from "../../SEOTab/SEOTab";
import displayNotification from "../../../utilities/displayNotification";
import checkSync from "../../../utilities/checkSync";
import MainTitle from "../../../components/MainTitle/MainTitle";
import TabErrorIcon from "../../../components/TabErrorIcon/TabErrorIcon";
import ProductProblems from "../../../containers/ProductProblems/ProductProblems";
import ResyncWhenErrorButton from "../../../components/ResyncWhenErrorButton/ResyncWhenErrorButton";
import generateUrlValidation from "../../../utilities/GenerateUrlValidation";
import InputErrorMessage from "../../../components/InputErrorMessage/InputErrorMessage";
import CreateCategory from "../CreateCategory/CreateCategory";

import CreateBrand from "../CreateBrand/CreateBrand";

import useCategories from "./hooks/use-categories";
import useAttributes from "../../Edit/Product/Product/hooks/use-attributes";
import useProduct from "../../Edit/Product/Product/hooks/use-product";

const LOADING = "LOADING";

const CreateProductProcess = (props) => {
  const accesToken = useSelector((state) => state.user.access);
  // * Regular expressions
  const regExpURL =
    /^[^~"<+>^.:;\\,%\]_`!\[{|}&€‚ƒ$=…†'‡ˆ‰Š?‹ŒŽ‘’“”•)*#(–—˜™š@›œžŸ¡¢£¥|§¨©ª«¬¯®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ]+$/;
  const regExpURL2 =
    /^[^~"<+>^.:;\\,%\]_`!\[{|}&€‚ƒ$=…†'‡ˆ‰?‹Œ‘’“”•)*#(–—˜™@›œ¡¢£¥|§¨©ª«¬¯®¯°±²³´µ¶·¸¹º»¼½¾¿Þ÷þ]+$/;
  const [specialCharactersURL, setSpecialCharactersURL] = useState(true);
  const [specialCharactersName, setSpecialCharactersName] = useState(true);
  // * Literals
  // ! Harcodeado el Id perteneciente a Unidad/Unidades para que salga por defecto por el momento
  const [literalId, setLiteralId] = useState(1);
  const [singular, setSingular] = useState("Unidad");
  const [plural, setPlural] = useState("Unidades");
  const [literalMessageError, setLiteralMessageError] = useState();
  // * SEO
  const [reWrite, setReWrite] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [key, setKey] = useState(0);
  const [error, setError] = useState(false);
  // REDUX
  const dispatch = useDispatch(); // * allows to set the value in any place
  const setOrphanNumber = useCallback(
    (orphanNumber) => dispatch({ type: "CHANGE_NUMBER", orphanNumber }),
    [dispatch]
  );
  const [productId, setProductId] = useState();
  const [name, setName] = useState("");
  const [duplicateNameError, setDuplicateNameError] = useState(false);
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [active, setActive] = useState();
  const [underRequest, setUnderRequest] = useState();
  const [brand, setBrand] = useState();
  const {
    categories,
    setCategories,
    mainCategory,
    setMainCategory,
    handleEditCategory,
    handleCreateCategory,
  } = useCategories();
  const [articles, setArticles] = useState([]);

  const [photos, setPhotos] = useState([]);
  const [processForm, setProcessForm] = useState(false);
  const [proccessErrorTab, setProccessErrorTab] = useState(false);

  // Problems states
  const [problems, setProblems] = useState([]);
  const [checkingProblems, setCheckingProblems] = useState(false);
  const [syncProblemsList, setSyncProblemsList] = useState(LOADING);

  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);

  const {
    availableAttributes,
    setAvailableAttributes,
    availableAttributesRef,
    attributes,
    setAttributes,
    attributesRef,
    attributesList,
    setAttributesList,
    attributesListRef,
    addedAttributes,
    setAddedAttributes,
    addedAttributesRef,
    informativeAttributes,
    setInformativeAttributes,
    informativeAttributesRef,
    handleEditAttribute,
    handleClickDuplicateAttribute,
    getAttributesFromDb,
  } = useAttributes({ initialLoading: false });

  const {
    onRequest,
    setOnRequest,
    showPrice,
    setShowPrice,
    handleClickShowPrice,
    handleClickUnderRequest,
    isValid,
    validation,
    errorMessages,
  } = useProduct();

  const [isSyncing, setIsSyncing] = useState(false);
  const [syncError, setSyncError] = useState(false);

  const [solveProblem, setSolveProblem] = useState(false);

  const [noSyncUnactive, setNoSyncNoUnactive] = useState(false);

  const [showAddArticles, setshowAddArticle] = useState();
  const [showCreateCategory, setShowCreateCategory] = useState(false);
  const [showCreateBrand, setShowCreateBrand] = useState(false);

  //  * Refactor for the tab navigation
  const invalidFieldMessage = () => {
    displayNotification({
      title: "Hay campos inválidos",
      message: `Completa correctamente todos los campos requeridos para continuar`,
      type: "danger",
    });
  };
  const comprobationErrors = () => {
    return (
      duplicateNameError ||
      name.length <= 0 ||
      !specialCharactersName ||
      active === undefined ||
      description.length <= 0 ||
      description.length > 50000 ||
      shortDescription.length <= 0 ||
      shortDescription.length > 400 ||
      singular === "" ||
      plural === "" ||
      !brand ||
      !mainCategory ||
      !categories
    );
  };
  const genericCaseComprobation = (pK) => {
    if (pK < key) {
      setKey(Number(pK));
    } else if (comprobationErrors()) {
      setError(true);

      invalidFieldMessage();
    } else {
      setKey(Number(pK));
    }
  };
  // *

  const getProblemsFromApi = async () => {
    setProblems(LOADING);
    try {
      await apiProducts.checkProblems(accesToken, {
        productId: Number(productId),
      });

      const { data } = await apiProducts.getProblems(accesToken, {
        productId: Number(productId),
      });

      const processedProblems = data.attribute_values.map((problem) => ({
        article: {
          id: problem.article_id,
          name: problem.article_name ?? problem.description_erp,
        },
        attribute: {
          id: problem.attribute_id,
          name: problem.attribute_name,
        },
      }));
      setProblems(processedProblems);
    } catch (e) {
      console.log(e);
    }
  };

  const syncProduct = async (productId) => {
    setIsSyncing(true);

    try {
      await apiProducts.sync(accesToken, { productId });
      await checkSync.checkProductSync(accesToken, { productId });

      setIsSyncing(false);
      setSyncError(false);
    } catch (error) {
      setIsSyncing(false);
      setSyncError(true);
    }
  };

  // Prev and next phase
  const switchPhase = async () => {
    switch (key) {
      case 0:
        /*
        The function tests if the id is set (which means the prouct has been created). 
        If so it edits the product with the new name, otherwise it creates the product
        and starts the sync process.
        */

        if (name.length <= 0 || !specialCharactersName || duplicateNameError) {
          setError(true);
          throw new Error();
        } else {
          setProcessForm(true);
          // * Set reWrite with the name in the first tab / Generate friendly URL with translation of some special characters
          const generatedUrl = generateUrlValidation({
            specialCharactersName: specialCharactersName,
            name: name,
            reWrite: reWrite,
            setSpecialCharactersURL: setSpecialCharactersURL,
          });
          if (generatedUrl) {
            setReWrite(generatedUrl);
          }

          let id = productId;

          try {
            if (!id) {
              let mockCreate = false;

              if (!mockCreate) {
                const createProductResponse = await apiProducts.create(
                  accesToken,
                  {
                    name,
                  }
                );

                id = createProductResponse.data.id;
              } else {
                id = 645;
              }

              setProductId(id);
              // syncProduct(id);

              /*
              The sync process starts, dislaying a spinner meanwile.
              When the promise fulills ends, a recursive function checks if the process has finished.
              If so it removes the spinner.
              */

              setError(false);
            } else {
              await apiProducts.edit(accesToken, {
                id,
                name,
              });
              setError(false);
            }

            setProcessForm(false);
          } catch (e) {
            if (e.response.status === 409) {
              setDuplicateNameError(true);
              setError(true);
              setProcessForm(false);
            }

            throw new Error("ERROR");
          }
        }
        break;
      case 1:
        if (singular === "" || plural === "") {
          setLiteralMessageError(true);
        } else {
          setLiteralMessageError(false);
        }

        if (
          !name ||
          description <= 0 ||
          description.length > 50000 ||
          shortDescription.length <= 0 ||
          shortDescription.length > 400 ||
          !mainCategory ||
          !brand ||
          singular === "" ||
          plural === "" ||
          !validation.showPriceUnderRequest
        ) {
          setError(true);
          throw new Error();
        } else {
          setProcessForm(true);
          setError(false);

          let id = productId;

          /*

          If no id is set (no product has been created) it creates it and then edits it.
          If the product has already been created it simply edits it.

          In both cases categories are added.

          */
          try {
            if (!id) {
              const { data } = await apiProducts.create(accesToken);

              id = data.product.id;

              await apiProducts.syncWithPrestashop(accesToken, {
                productId: id,
              });

              setProductId(id);
            }
            await apiProducts.edit(accesToken, {
              id,
              name,
              description,
              shortDescription,
              brandId: brand.id,
              active,
              showPrice: showPrice,
              onRequest: onRequest,
              mainCategoryId: mainCategory.id,
              reWrite,
              metaTitle: metaTitle.substring(0, 71),
              metaDescription: metaDescription.substring(0, 161),
              literalId,
            });

            // FIXME @Xavier // ! Remove unselected categories
            await apiProducts.addCategories(accesToken, {
              productId: id,
              categoryIds: categories.map((category) => category.id),
            });

            setProcessForm(false);
          } catch (e) {}
        }
        break;
      case 2:
        if (
          metaTitle.length <= 0 ||
          metaDescription.length <= 0 ||
          !reWrite ||
          metaTitle.length >= 70 ||
          metaDescription.length >= 300
        ) {
          setError(true);
          throw new Error();
        }
        break;
      case 4:
        if (
          !name ||
          description <= 0 ||
          shortDescription.length <= 0 ||
          shortDescription.length > 400 ||
          !mainCategory ||
          !brand ||
          singular === "" ||
          plural === "" ||
          metaTitle.length <= 0 ||
          metaDescription.length <= 0 ||
          !reWrite ||
          metaTitle.length >= 70 ||
          metaDescription.length >= 300 ||
          !specialCharactersURL
        ) {
          setError(true);
          throw new Error();
        } else {
          setProcessForm(true);
          setError(false);

          try {
            await apiProducts.edit(accesToken, {
              id: productId,
              name,
              description,
              shortDescription,
              brandId: brand.id,
              active,
              showPrice: showPrice,
              onRequest: onRequest,
              mainCategoryId: mainCategory.id,
              reWrite,
              metaTitle: metaTitle.substring(0, 71),
              metaDescription: metaDescription.substring(0, 161),
              literalId,
            });

            setProcessForm(false);
          } catch (e) {
            throw e;
          }
        }
        break;
      default:
        break;
    }
  };

  const nextPhase = async () => {
    try {
      await switchPhase(key);
      setKey(key + 1);
    } catch (e) {
      invalidFieldMessage();
    }
  };

  const prevPhase = async () => {
    try {
      await switchPhase(key);
      setKey(key - 1);
    } catch (e) {
      invalidFieldMessage();
    }
  };

  // Prephase
  const onChangeName = (event) => {
    setSpecialCharactersName(regExpURL2.test(event.target.value)); //devuelve false si coincide con alguno de los caracteres de la regex
    setName(event.target.value);
    setDuplicateNameError(false);
  };

  // Product
  const editBrand = (item) => {
    setBrand({
      id: item.id,
      name: item.name,
    });

    props.history.push("/create/product");
  };

  // Articles
  const loadArticles = () => {
    return apiArticles.getOrphans(accesToken).then(({ data }) => {
      const items = data.map((item) => ({
        id: item.id,
        name:
          item.description === null
            ? String(item.description_erp)
            : String(item.description),
        active: item.active,
      }));

      return { data: items };
    });
  };

  const saveArticles = async (items) => {
    const updatedArticles = [...articles];

    for (let item of items) {
      await apiArticles.assignProduct(accesToken, {
        id: item.id,
        productId: Number(productId),
      });

      const response = await apiArticles.filter(accesToken, {
        filtername: "id",
        value: item.id,
      });

      const article = response.data[0];

      updatedArticles.push({
        id: article.id,
        reference: article.ref,
        description: article.description ?? article.description_erp,
        active: article.active,
        activeSales: article.active_sales,
      });
    }

    // Refresh product attributes
    const getProductResponse = await apiProducts.filter(accesToken, {
      filtername: "id",
      value: productId,
    });

    const product = getProductResponse.data[0];

    setAttributesList(
      product.article_attributes.map((attribute) => ({
        id: attribute.id,
        name: attribute.name,
      }))
    );

    const processedAvailableAttributes = product.not_in_table_attributes.map(
      (item) => ({
        id: item.id,
        name: item.name,
      })
    );
    setAvailableAttributes(processedAvailableAttributes);

    const processedInformativeAttributes = product.info_attributes.map(
      (item) => ({
        id: item.id,
        name: item.name,
      })
    );
    setInformativeAttributes(processedInformativeAttributes);

    getProblemsFromApi();
    checkSyncProblems();

    setArticles(updatedArticles);
    // * REDUX VALUE SETTING
    await apiArticles
      .getOrphansLength(accesToken)
      .then(({ data }) => {
        setOrphanNumber(data.length);
      })
      .catch((e) => {});
  };

  const unlinkArticle = async (id) => {
    const updatedArticles = articles.map((article) => {
      if (article.id === id) {
        article.processing = true;
      }

      return article;
    });

    setArticles(updatedArticles);

    apiArticles.orphanize(accesToken, { articleIds: [id] }).then(() => {
      const updatedArticles = articles.filter((article) => article.id !== id);

      setArticles(updatedArticles);
    });
  };

  const selectToUnlink = (id) => {
    const updatedArticles = articles.map((art) => {
      if (art.id === id) {
        return {
          ...art,
          remove: !art.remove,
        };
      }

      return art;
    });

    setArticles(updatedArticles);
  };

  const unlinkArticles = async () => {
    try {
      const articlesToUnlink = articles.filter((article) => article.remove);

      await apiArticles.orphanize(accesToken, {
        articleIds: articlesToUnlink.map((item) => item.id),
      });

      const updatedArticles = articles.filter((article) => !article.remove);
      setArticles(updatedArticles);
      // * REDUX VALUE SETTING
      await apiArticles
        .getOrphansLength(accesToken)
        .then(({ data }) => {
          setOrphanNumber(data.length);
        })
        .catch((e) => {});
    } catch (e) {}
  };

  // Photos
  const getPhotosFromApi = async (productId) =>
    apiProducts.getPhotos(accesToken, { productId }).then(({ data }) => {
      const coverImageId = data.product.id_default_image;

      const photos = data.product.associations.images.map((photo) => ({
        id: photo.id,
        src: photo.large_default,
        cover: photo.id === coverImageId,
      }));

      setPhotos(photos);
    });

  const uploadPhotos = (response) =>
    new Promise((resolve) => {
      setProcessForm(true);

      if (!productId) {
        return apiProducts.create(accesToken).then(({ data }) => {
          setProductId(data.product.id);
          resolve(data.product.id);
        });
      } else {
        resolve(productId);
      }
    }).then((productId) => {
      const files = [...response.target.files];

      Promise.all(
        files.map((file) => {
          const data = new FormData();
          data.append("image", file);
          data.append("productId", productId);

          setProcessForm(true);

          return apiProducts.addPhoto(accesToken, data);
        })
      )
        .then(() => getPhotosFromApi(productId))
        .then(() => setProcessForm(false))
        .catch((e) => {});
    });

  const makeCoverPhoto = (id) => {
    const updatedPhotos = photos.map((photo) => {
      if (photo.id === id) {
        return { ...photo, loading: true };
      }

      return photo;
    });

    setProcessForm(true);
    setPhotos(updatedPhotos);

    apiProducts
      .makeCover(accesToken, {
        productId,
        photoId: id,
      })
      .then(() => {
        const updatedPhotos = photos.map((photo) => {
          delete photo["cover"];

          if (photo.id === id) {
            delete photo["loading"];
            return { ...photo, cover: true };
          }

          return photo;
        });

        setProcessForm(false);
        setPhotos(updatedPhotos);
      })
      .catch((e) => {});
  };

  const removePhoto = (id) => {
    const updatedPhotos = photos.map((photo) => {
      if (photo.id === id) {
        return { ...photo, loading: true };
      }

      return photo;
    });

    apiProducts
      .removePhoto(accesToken, { images: [id], productId })
      .then(() => {
        let updatedPhotos = photos.filter((photo) => photo.id !== id);
        updatedPhotos = updatedPhotos.map((photo) => {
          return photo;
        });

        setProcessForm(false);
        setPhotos(updatedPhotos);
      })
      .catch((e) => {});

    setProcessForm(true);
    setPhotos(updatedPhotos);
  };

  // const removeDuplicatedPhotos = () => {
  //   setProcessForm(true);

  //   apiProducts
  //     .removeDuplicatedPhotos(accesToken, { productId })
  //     .then(() => getPhotosFromApi())
  //     .then(() => setProcessForm(false));
  // };

  const hasPhotos = photos !== LOADING && photos.length > 0;

  // PROBLEMS TAB
  const checkSyncProblems = async () => {
    setCheckingProblems(true);

    try {
      const responseSyncErrors = await api.entriesNoSync(accesToken, {
        productId,
        type: ["all"],
      });

      const response = await apiProducts.getTasks(accesToken, { productId });
      const tasks = response.data.tasks;

      const syncProblems = responseSyncErrors.data;
      const syncProblemsList = [];

      for (const key in syncProblems.data) {
        const problem = {
          ...syncProblems.data[key],
          values: [],
          identifier: key,
        };

        if (syncProblems.needSync[key]) {
          problem.values = [...problem.values, ...syncProblems.needSync[key]];
        }

        if (syncProblems.syncError[key]) {
          problem.values = [...problem.values, ...syncProblems.syncError[key]];
        }

        const problemProcess = tasks.find(
          (tsk) => tsk.frontIdentifier === problem.identifier
        );

        if (problemProcess) {
          problem.processing = Number(problemProcess.percent);
        } else {
          problem.processing = false;
        }

        syncProblemsList.push(problem);
      }

      setCheckingProblems(false);
      setSyncProblemsList(syncProblemsList);
    } catch (error) {
      setCheckingProblems(false);
    }
  };

  const onClickSyncProblems = async (identifier) => {
    setSyncProblemsList((problemsList) => {
      const updatedProblemsList = problemsList.map((problem) => {
        if (problem.identifier === identifier) {
          return {
            ...problem,
            processing: 0,
          };
        }

        return problem;
      });

      return updatedProblemsList;
    });

    try {
      await api.entriesNoSync(accesToken, {
        productId,
        type: [identifier],
        sync: true,
      });

      controlSyncingAndupdateValues(identifier);
    } catch (e) {
      setSyncProblemsList((problemsList) => {
        const updatedProblemsList = problemsList.map((problem) => {
          if (problem.identifier === identifier) {
            return {
              ...problem,
              processing: false,
            };
          }

          return problem;
        });

        return updatedProblemsList;
      });
    }
  };

  const onClickSyncAllProblems = async () => {
    try {
      const identifiers = [];

      syncProblemsList.forEach((problem) => {
        if (problem.values.length > 0) {
          identifiers.push(problem.identifier);
        }
      });

      setSyncProblemsList((problemsList) => {
        const updatedProblemsList = problemsList.map((problem) => {
          if (identifiers.includes(problem.identifier)) {
            return {
              ...problem,
              processing: 0,
            };
          }

          return problem;
        });

        return updatedProblemsList;
      });

      await api.entriesNoSync(accesToken, {
        productId,
        type: identifiers,
        sync: true,
      });

      await Promise.allSettled(
        identifiers.map((identifier) =>
          controlSyncingAndupdateValues(identifier)
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const controlSyncingAndupdateValues = async (identifier) => {
    let isSolving = true;
    while (isSolving) {
      const result = await apiProducts.getTasks(accesToken, { productId });
      const tasks = result.data.tasks;

      const problemTask = tasks.find(
        (tsk) => tsk.frontIdentifier === identifier
      );

      if (!problemTask) {
        const { data } = await api.entriesNoSync(accesToken, {
          productId,
          type: [identifier],
        });

        setSyncProblemsList((problemsList) => {
          const updatedProblemsList = problemsList.map((problem) => {
            if (
              Object.keys(data.needSync).includes(identifier) ||
              Object.keys(data.syncError).includes(identifier)
            ) {
              problem.values = [];

              if (data.needSync[identifier]) {
                problem.values = [...data.needSync[identifier]];
              }

              if (data.syncError[identifier]) {
                problem.values = [
                  ...problem.values,
                  ...data.syncError[identifier],
                ];
              }
            }

            return problem;
          });

          return updatedProblemsList;
        });

        break;
      }

      await later(5000);
    }
  };

  const saveAttributes = () => {
    return apiProducts.saveAtributeFilters(accesToken, {
      productId: Number(productId),
      attributeIds: attributes.map((item) => item.id),
    });
  };

  // Save product
  const saveProduct = async () => {
    setProccessErrorTab(true);
    setProcessForm(true);
    try {
      if (
        duplicateNameError ||
        name.length <= 0 ||
        !specialCharactersName ||
        active === undefined ||
        description.length <= 0 ||
        description.length > 50000 ||
        shortDescription.length <= 0 ||
        shortDescription.length > 400 ||
        metaTitle.length <= 0 ||
        metaDescription.length <= 0 ||
        metaTitle.length >= 70 ||
        metaDescription.length >= 300 ||
        !specialCharactersURL ||
        singular === "" ||
        plural === "" ||
        !brand ||
        !mainCategory ||
        !categories
      ) {
        setError(true);
        throw new Error();
      } else {
        await apiProducts.edit(accesToken, {
          id: productId,
          name,
          description,
          shortDescription,
          brandId: brand.id,
          active,
          showPrice: showPrice,
          onRequest: onRequest,
          mainCategoryId: mainCategory.id,
          reWrite,
          metaTitle: metaTitle.substring(0, 71),
          metaDescription: metaDescription.substring(0, 161),
          literalId,
        });

        await saveAttributes();

        const firstArticle = articles[0];
        if (firstArticle) {
          for (const attribute of addedAttributes) {
            await apiArticles.addAttribute(
              accesToken,
              firstArticle.id,
              attribute.id
            );
          }
          await apiArticles.checkAttribute(accesToken, { productId });
        }

        displayNotification({
          title: "Producto creado",
          message: `El producto ${name} se ha creado de forma correcta`,
          type: "success",
        });
      }
      setProcessForm(false);
    } catch (e) {
      displayNotification({
        title: "Hay errores que necesitan arreglarse",
        message:
          "Comprueba todos los campos, especialmente aquellos marcados con un icono rojo de admiración en la pestaña",
        type: "danger",
      });

      setProcessForm(false);
      throw e;
    }
  };

  const syncAndSave = async () => {
    setProccessErrorTab(true);
    try {
      await saveProduct();

      setProcessForm(true);
      await apiProducts.sync(accesToken, { productId });
      setProcessForm(false);
    } catch (e) {
      throw e;
    }
  };

  // TODO quitado porque daba problemas con errores dejaba crear
  // const clickSave = async () => {
  //   try {
  //     await saveProduct();
  //     // props.history.push(`/product/${productId}`);
  //   } catch (e) {}
  // };

  const clickSaveAndSync = async () => {
    try {
      if (!active) {
        throw new Error("NO_SYNC_UNACTIVE");
      }

      await syncAndSave();

      props.history.push(`/product/${productId}`);
    } catch (e) {
      if (e.message === "NO_SYNC_UNACTIVE") {
        setNoSyncNoUnactive(true);
        return;
      }
    }
  };

  const areErrors =
    duplicateNameError ||
    name.length <= 0 ||
    !specialCharactersName ||
    active === undefined ||
    description.length <= 0 ||
    description.length > 50000 ||
    shortDescription.length <= 0 ||
    shortDescription.length > 400 ||
    metaTitle.length <= 0 ||
    metaDescription.length <= 0 ||
    metaTitle.length >= 70 ||
    metaDescription.length >= 300 ||
    !specialCharactersURL ||
    singular === "" ||
    plural === "" ||
    !brand ||
    !mainCategory ||
    !categories;

  const saveButtons = (
    <div className={classes.saveButtonsContainer}>
      <OptionsButton
        // TODO quitado el clicksave porque te redirigia siempre aunq no se guardara porque tenia errores
        check={async () => {
          try {
            await saveProduct();
            // TODO mientras comprobaciones que no te haga el push
            // props.history.push(`/product/${productId}`);
          } catch (e) {
            console.log(e);
          }
        }}
        click={async () => {
          try {
            await saveProduct();
            // TODO mientras comprobaciones que no te haga el push
            // props.history.push(`/product/${productId}`);
          } catch (e) {
            console.log(e);
          }
        }}
        disabled={processForm}
        loading={processForm}
      >
        Guardar
      </OptionsButton>
      <OptionsButton
        checkSquare={clickSaveAndSync}
        click={clickSaveAndSync}
        disabled={processForm}
        loading={processForm}
      >
        Guardar y sincronizar
      </OptionsButton>
    </div>
  );

  let nameErrorMessage;

  if (name.length <= 0) {
    nameErrorMessage = "No puede estar el valor vacío";
  } else if (!specialCharactersName) {
    nameErrorMessage = "No está permitido usar caracteres especiales";
  } else if (duplicateNameError) {
    nameErrorMessage = "No puede haber 2 productos con el mismo nombre";
  }

  const tabsNavigationCreateProduct = async (kToGo) => {
    try {
      await switchPhase(key);
      setKey(Number(kToGo));
      setError(false);
    } catch (e) {
      setError(true);
      invalidFieldMessage();
    }
  };

  let totalProblemsNumber = problems.length;
  if (syncProblemsList !== LOADING) {
    syncProblemsList.forEach((problem) => {
      totalProblemsNumber += problem.values.length;
    });
  }

  const productNameTitle = (
    <div className="d-flex align-items-center">
      {(syncError && !isSyncing) ||
      // TODO asi estaban antes cada uno de los errores con el proccesErrorTab, los he quitado para que salga el simbolo
      // (duplicateNameError&& proccessErrorTab ) ||
      (duplicateNameError && proccessErrorTab) ||
      (name.length <= 0 && proccessErrorTab) ||
      (!specialCharactersName && proccessErrorTab) ? (
        <TabErrorIcon />
      ) : undefined}
      Nombre
      {isSyncing ? (
        <Badge className="ml-2 p-2" pill variant="primary">
          <Spinner animation="border" size="sm" />
        </Badge>
      ) : undefined}
    </div>
  );

  const productNameContent = (
    <PrePhaseCreateProduct
      name={name}
      disabled={processForm}
      onChangeName={onChangeName}
      error={
        (name.length <= 0 && error) ||
        !specialCharactersName ||
        duplicateNameError
      }
      duplicateNameError={duplicateNameError}
      process={processForm}
      nameErrorMessage={nameErrorMessage}
    />
  );

  const dataTitle = (
    <div className="d-flex align-items-center">
      {proccessErrorTab &&
      (noSyncUnactive ||
        active === undefined ||
        description.length <= 0 ||
        description.length > 50000 ||
        shortDescription.length <= 0 ||
        shortDescription.length > 400 ||
        !mainCategory ||
        !brand ||
        singular === "" ||
        plural === "") ? (
        <TabErrorIcon />
      ) : undefined}
      Datos
    </div>
  );

  const dataContent = (
    <FirstCreateProductPhase
      id={productId}
      accesToken={accesToken}
      processForm={processForm}
      name={name}
      setName={setName}
      description={description}
      setDescription={setDescription}
      shortDescription={shortDescription}
      setShortDescription={setShortDescription}
      active={active}
      setActive={(value) => {
        setNoSyncNoUnactive(false);
        setActive(value);
      }}
      visiblePrice={showPrice}
      setVisiblePrice={setShowPrice}
      underRequest={onRequest}
      setUnderRequest={setOnRequest}
      mainCategory={mainCategory}
      setMainCategory={setMainCategory}
      categories={categories}
      setCategories={setCategories}
      brand={brand}
      setBrand={setBrand}
      editBrand={editBrand}
      error={error}
      history={props.history}
      nameErrorMessage={nameErrorMessage}
      singular={singular}
      plural={plural}
      literalId={literalId}
      setSingular={setSingular}
      setPlural={setPlural}
      setLiteralId={setLiteralId}
      literalMessageError={literalMessageError}
      setLiteralMessageError={setLiteralMessageError}
      noSyncUnactive={noSyncUnactive}
      handleEditCategory={handleEditCategory}
      handleClickShowPrice={handleClickShowPrice}
      handleClickUnderRequest={handleClickUnderRequest}
      validation={validation}
      errorMessages={errorMessages}
      handleCreateCategory={handleCreateCategory}
      handleOpenCreateCategory={() => setShowCreateCategory(true)}
      handleOpenCreateBrand={() => setShowCreateBrand(true)}
    />
  );

  const articlesTitle = (
    <>
      Artículos
      <Badge
        className="ml-2"
        pill
        variant={articles.length === 0 ? "danger" : "primary"}
      >
        {articles.length === 0 ? 0 : articles.length}
      </Badge>
    </>
  );

  const articlesContent = (
    <SecondCreateProductPhase
      productId={productId}
      accesToken={accesToken}
      history={props.history}
      articles={articles}
      saveArticles={saveArticles}
      unlinkArticle={unlinkArticle}
      loadArticles={loadArticles}
      beforeCloseArticle={(updatedArticle) => {
        const updatedArticles = articles.map((article) => {
          if (article.id === updatedArticle.id) {
            return updatedArticle;
          }

          return article;
        });

        setArticles(updatedArticles);
      }}
      beforeCloseArticleNoSave={(updatedArticle) => {
        const articleAttributes = updatedArticle.attributes;

        const productAttributeIds = attributesList.map((attr) => attr.id);

        const attributesToAdd = articleAttributes.filter(
          (attr) => !productAttributeIds.includes(attr.id)
        );

        const processedAttributesToAdd = attributesToAdd.map((attr) => ({
          id: attr.id,
          name: attr.name,
        }));

        setAttributesList((prev) => [...prev, ...processedAttributesToAdd]);
        setAvailableAttributes((prev) => [
          ...prev,
          ...processedAttributesToAdd,
        ]);
      }}
      selectToUnlink={selectToUnlink}
      unlinkArticles={unlinkArticles}
      showAddArticles={showAddArticles}
      setshowAddArticle={setshowAddArticle}
      openArticle={() => saveAttributes()}
      beforeCloseArticleNoSave={() => getAttributesFromDb(productId)}
    />
  );

  const photosTitle = (
    <>
      Fotos
      <Badge
        className="ml-2"
        pill
        variant={photos.length === 0 ? "danger" : "primary"}
      >
        {photos.length}
      </Badge>
    </>
  );

  const photosContent = (
    <ThirdPhaseContainer
      isSyncing={isSyncing}
      photos={photos}
      processForm={processForm}
      onUploadPhotos={uploadPhotos}
      makeCoverPhoto={makeCoverPhoto}
      remove={removePhoto}
    />
  );

  const attributesTitle = (
    <>
      Atributos
      <Badge
        className="ml-2"
        pill
        variant={attributes.length === 0 ? "danger" : "primary"}
      >
        {attributesList.length === 0 ? 0 : attributesList.length}
      </Badge>
    </>
  );

  const attributesContent = (
    <ProductAttributesTab
      isSaving={processForm}
      productId={productId}
      availableAttributes={availableAttributes}
      attributes={attributes}
      setAttributes={setAttributes}
      attributesRef={attributesRef}
      setHasChanged={() => {}}
      attributesList={attributesList}
      setAttributesList={setAttributesList}
      attributesListRef={attributesListRef}
      setAvailableAttributes={setAvailableAttributes}
      availableAttributesRef={availableAttributesRef}
      addedAttributes={addedAttributes}
      setAddedAttributes={setAddedAttributes}
      addedAttributesRef={addedAttributesRef}
      informativeAttributes={informativeAttributes}
      setInformativeAttributes={setInformativeAttributes}
      informativeAttributesRef={informativeAttributesRef}
      setHideFloatingButtons={() => {}}
      areLinkedArticles={articles.length > 0}
      clickAddArticles={() => {
        setKey(2);
        setshowAddArticle(true);
      }}
      handleEditAttribute={handleEditAttribute}
      handleClickDuplicateAttributeToInfo={handleClickDuplicateAttribute}
    />
  );

  const seoTitle = (
    <div className="d-flex align-items-center">
      {proccessErrorTab &&
      (reWrite.length <= 0 ||
        !specialCharactersURL ||
        metaTitle.length <= 0 ||
        metaDescription.length <= 0 ||
        metaTitle.length >= 70 ||
        metaDescription.length > 300) ? (
        <TabErrorIcon />
      ) : undefined}
      SEO
    </div>
  );

  const seoContent = (
    <SEOTab
      // Nuevos props
      reWrite={reWrite}
      setSpecialCharactersURL={setSpecialCharactersURL}
      regExpURL={regExpURL}
      setReWrite={setReWrite}
      setHasChanged={() => {}}
      specialCharactersURL={specialCharactersURL}
      specialCharactersName={specialCharactersName}
      name={name}
      // Fin de nuevos props
      metaTitle={metaTitle}
      setMetaTitle={(newValue) => {
        setMetaTitle(newValue);
      }}
      metaDescription={metaDescription}
      setMetaDescription={(newValue) => {
        setMetaDescription(newValue);
      }}
      error={error}
    />
  );

  const problemsTitle = (
    <>
      Problemas
      <Badge className="ml-2" pill variant="danger">
        {problems.length === 0 ? null : problems.length}
      </Badge>
    </>
  );

  const problemsContent = (
    <ProductProblems
      productId={productId}
      problems={problems}
      setProblems={setProblems}
      syncProblemsList={syncProblemsList}
      setSyncProblemsList={setSyncProblemsList}
      articles={articles}
      getProblemsFromApi={getProblemsFromApi}
      solveProblem={solveProblem}
      setSolveProblem={setSolveProblem}
      onClickSyncProblems={onClickSyncProblems}
      onClickSyncAllProblems={onClickSyncAllProblems}
      setKey={setKey}
      hasPhotos={hasPhotos}
      checkingProblems={checkingProblems}
      setCheckingProblems={setCheckingProblems}
      checkSyncProblems={checkSyncProblems}
      setHideFloatingButtons={() => {}}
    />
  );

  return (
    <>
      <div className="position-relative">
        {key === 2 && !syncError ? (
          <>
            <div className={classes.topSaveOptions}>
              {saveButtons}
              {noSyncUnactive ? (
                <div className="text-right">
                  <InputErrorMessage>
                    No se pueden sincronizar productos sin sincronizar
                  </InputErrorMessage>
                </div>
              ) : undefined}
            </div>
          </>
        ) : undefined}
        <MainTitle>
          {/* // TODO Margin top for not overlaping save buttons with TItle  */}
          <h1 className={classes.mainTitleMargin}>
            Crear nuevo producto: {name}
          </h1>
        </MainTitle>
        {syncError ? (
          <ResyncWhenErrorButton
            onClick={() => syncProduct(productId)}
            isSyncing={isSyncing}
          />
        ) : undefined}
        <div className="hideOnMobile">
          <Tabs
            className={classes.tabs}
            id="productCreationPhases"
            activeKey={key}
            onSelect={(k) => {
              tabsNavigationCreateProduct(k);
            }}
          >
            <Tab eventKey={0} title={productNameTitle}>
              {productNameContent}
            </Tab>
            <Tab eventKey={1} title={dataTitle} disabled={!productId}>
              {dataContent}
            </Tab>
            {/* <Tab eventKey={2} title={articlesTitle} disabled={!productId}>
              {articlesContent}
            </Tab> */}
            {/* <Tab eventKey={2} title={photosTitle} disabled={!productId}>
              {photosContent}
            </Tab>{" "} */}
            {/* <Tab eventKey={4} title={attributesTitle} disabled={!productId}>
              {attributesContent}
            </Tab> */}
            <Tab eventKey={2} title={seoTitle} disabled={!productId}>
              {seoContent}
            </Tab>
            {/* <Tab eventKey={4} title={problemsTitle} disabled={!productId}>
              {problemsContent}
            </Tab> */}
          </Tabs>
        </div>
        <div className={classes.hideOnPC}>
          <Accordion
            defaultActiveKey="0"
            activeKey={String(key)}
            onSelect={(k) => {
              tabsNavigationCreateProduct(k);
            }}
          >
            <Card id="accordion-card-0">
              <Accordion.Toggle as={Card.Header} eventKey="0">
                {productNameTitle}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={"0"}>
                <Card.Body>{productNameContent}</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="accordion-card-1">
              <Accordion.Toggle as={Card.Header} eventKey="1">
                {dataTitle}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={"1"}>
                <Card.Body>{dataContent}</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="accordion-card-2">
              <Accordion.Toggle as={Card.Header} eventKey="2">
                {articlesTitle}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={"2"}>
                <Card.Body>{articlesContent}</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="accordion-card-3">
              <Accordion.Toggle as={Card.Header} eventKey="3">
                {photosTitle}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={"3"}>
                <Card.Body>{photosContent}</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="accordion-card-4">
              <Accordion.Toggle as={Card.Header} eventKey="4">
                {attributesTitle}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={"4"}>
                <Card.Body>{attributesContent}</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="accordion-card-5">
              <Accordion.Toggle as={Card.Header} eventKey="5">
                {seoTitle}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={"5"}>
                <Card.Body>{seoContent}</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="accordion-card-6">
              <Accordion.Toggle as={Card.Header} eventKey="6">
                {problemsTitle}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={"6"}>
                <Card.Body>{problemsContent}</Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
      <div className={classes.actionButtonContainer}>
        {noSyncUnactive ? (
          <div className="text-right">
            <InputErrorMessage>
              No se pueden sincronizar productos sin sincronizar
            </InputErrorMessage>
          </div>
        ) : undefined}
        <div className={classes.saveButtonsContainer}>
          {key !== 0 ? (
            <div className={classes.reverseButton}>
              <OptionsButton
                variant="success"
                arrowLeft={prevPhase}
                click={prevPhase}
                disabled={processForm}
                loading={processForm}
              >
                Fase previa
              </OptionsButton>
            </div>
          ) : undefined}
          {key !== 2 ? (
            <div className={classes.nextPhaseButton}>
              <OptionsButton
                variant="success"
                arrowRight={nextPhase}
                click={nextPhase}
                disabled={processForm}
                loading={processForm}
              >
                Siguiente fase
              </OptionsButton>
            </div>
          ) : (
            saveButtons
          )}
        </div>
      </div>
      {showCreateCategory ? (
        <CreateCategory
          modal
          afterClose={(category) => {
            setMainCategory(category);
          }}
          handleClose={() => setShowCreateCategory(false)}
        />
      ) : undefined}
      {showCreateBrand ? (
        <CreateBrand
          modal
          handleClose={() => setShowCreateBrand(false)}
          handleFinishCreate={(brand) => setBrand(brand)}
        />
      ) : undefined}
    </>
  );
};

export default CreateProductProcess;
