import { useState, useEffect } from "react";

const useProduct = () => {
  const [onRequest, setOnRequest] = useState<boolean | undefined>(undefined);
  const [showPrice, setShowPrice] = useState<boolean | undefined>(undefined);
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    setHasChanged(false);
  }, []);

  const handleClickShowPrice = () => {
    setHasChanged(true);
    setShowPrice((prevShowPrice) => {
      const updatedShowPrice = !prevShowPrice;

      if (updatedShowPrice) {
        setOnRequest(false);
      }

      return updatedShowPrice;
    });
  };

  const handleClickUnderRequest = () => {
    setHasChanged(true);
    setOnRequest((prevOnRequest) => {
      const updatedOnRequest = !prevOnRequest;

      if (updatedOnRequest) {
        setShowPrice(false);
      }

      return updatedOnRequest;
    });
  };

  let isValid = true;

  const validation = {
    showPriceUnderRequest: true,
  };

  const errorMessages = {
    showPriceUnderRequest: "",
  };

  if (showPrice === true && onRequest === true) {
    validation.showPriceUnderRequest = false;
    errorMessages.showPriceUnderRequest =
      '"Ver precio" y "Bajo pedido" no pueden estar checkeadas al mismo tiempo';
  } else if (showPrice === undefined || onRequest === undefined) {
    validation.showPriceUnderRequest = false;
    errorMessages.showPriceUnderRequest =
      'Debe especificar "Ver precio" y "Bajo pedido"';
  }

  Object.keys(validation).forEach((_, i) => {
    const d = Object.values(validation)[i];
    if (!d) {
      isValid = false;
      return;
    }
  });

  return {
    hasChanged,
    setHasChanged,
    onRequest,
    setOnRequest,
    showPrice,
    setShowPrice,
    handleClickShowPrice,
    handleClickUnderRequest,
    isValid,
    validation,
    errorMessages,
  };
};

export default useProduct;
