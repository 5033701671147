import React from "react";
import { Button } from "react-bootstrap";
import displayNotification from "../displayNotification";

const notificationErrorMessage = ({ title, onClickSolve }) => {
  displayNotification({
    title,
    message: (
      <div className="mb-3">
        <p>
          Pulse el botón para dirigirse a la pestaña adecuada y corregir el
          error.
        </p>
        <Button variant="outline-light" size="sm" onClick={onClickSolve}>
          Solucionar
        </Button>
      </div>
    ),
    type: "danger",
    dismiss: {
      click: true,
      touch: false,
    },
  });
};

export default notificationErrorMessage;
