import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
//BOOTSTRAP
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import Container from "react-bootstrap/Container";
// STYLES
import classes from "./Login.module.css";
// API
import auth from "../../api/auth";
import apiSem from "../../api/sem/profiles";
// COMPONENTS
import TextInput from "../../components/TextInput/TextInput";
import Spinner from "../../components/Spinner/Spinner";
// UTILITIES
import handleApiErrors from "../../utilities/handleApiErrors";

// PROCESSING MESSAGES
const LOADING = "LOADING";
const LOADED = "LOADED";
const ERROR = "ERROR";

const Login = (props) => {
  // INPUT VALUES
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  // ERROR
  const [loginError, setLoginError] = useState(false);

  // PROCESS STATE
  const [processState, setProcessState] = useState(LOADED);

  // AUTH
  const dispatch = useDispatch();
  const setAccess = useCallback((access) =>
    dispatch({ type: "SET_ACCESS_TOKEN", access }, [dispatch])
  );
  const setRefresh = useCallback((refresh) =>
    dispatch({ type: "SET_REFRESH_TOKEN", refresh }, [dispatch])
  );

  /**
   * @author Darío Barrionuevo <UpTeImpulsaDario@gmx.es>
   * @function sendInfo
   * @description Send information for authentication
   * @param {Object} event - For preventing the page reload
   * @returns {Object}  access token
   */
  const sendInfo = (event) => {
    // PREVENTING THE PAGE FROM RELOADING
    event.preventDefault();

    setProcessState(LOADING);

    if (userName === "" || password === "") {
      setProcessState(ERROR);
    } else {
      auth
        .getTokens(userName, password)
        .then((response) => {
          setRefresh(response.data.refresh);
          setAccess(response.data.access);

          const makeRefreshTokenPetition = (refreshToken) => {
            setTimeout(() => {
              auth
                .refreshToken(refreshToken)
                .then(({ data }) => {
                  setAccess(data.access);
                  makeRefreshTokenPetition(refreshToken);
                })
                .catch((e) => {
                  handleApiErrors(e);
                });
            }, 180000);
          };

          makeRefreshTokenPetition(response.data.refresh);

          // !!!!dario
          (async () => {
            try {
              const profileData = await apiSem.profileGadsData(
                response.data.access
              );
              //  Seteo el prop que me he traido desde app.js con el profile de Gads
              console.log("GADS PROFILE", profileData.data);
              dispatch({ type: "SET_GADS", data: profileData.data });
              // console.log(profileData.data["gadsAccountId:"]);
            } catch (e) {
              console.log("🚀 ~ file: Login.js ~ line 70 ~ sendInfo ~ e", e);
            }
          })();
          // !!!!dario
          (async () => {
            try {
              const profileData = await apiSem.profileUserData(
                response.data.access
              );
              //  Seteo el prop que me he traido desde app.js con el profile de User
              console.log("USER PROFILE", profileData.data);
              dispatch({ type: "SET_USER", data: profileData.data });
              // console.log(profileData.data["gadsAccountId:"]);
            } catch (e) {
              console.log("🚀 ~ file: Login.js ~ line 70 ~ sendInfo ~ e", e);
            }
          })();
          //

          props.history.push("/search");

          return { access: response.data.access };
        })
        .catch((e) => {
          console.log("🚀 ~ file: Login.js ~ line 70 ~ sendInfo ~ e", e);

          setProcessState(LOADED);
          setLoginError(true);
        });
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Form className={classes.container} onSubmit={sendInfo}>
            <h1>Login</h1>

            <div className={classes.inputForm}>
              <TextInput
                placeholder="Introduce nombre de usuario"
                onChange={(event) => {
                  setUserName(event.target.value);
                }}
                value={userName}
                error={processState === ERROR && userName.length <= 0}
                errorMessage="El usuario no puede estar vacío"
                disabled={processState === LOADING}
              />
            </div>

            <div className={classes.inputForm}>
              <TextInput
                type="password"
                placeholder="Introduce password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                value={password}
                error={processState === ERROR && password.length <= 0}
                errorMessage="El password no puede estar vacío"
                disabled={processState === LOADING}
              />
            </div>
            {!loginError ? undefined : (
              <Alert className="m-2" variant="danger">
                Usuario y/o password no válido, inténtalo de nuevo
              </Alert>
            )}

            <div>
              {processState === LOADING ? (
                <Spinner />
              ) : (
                <Row>
                  <Col>
                    <Button
                      className="m-1"
                      variant="primary"
                      size="lg"
                      block
                      type="submit"
                    >
                      Entrar
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
