import { useState, useEffect } from "react";

const useInfiniteScroll = (callback) => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    callback();
  }, [isFetching]);

  function handleScroll() {
    // * Original function that exit when the scroll is not at the end of the page
    // ? if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight  || isFetching) return;
    // FIXME @Darío buscar la manera de que se active cuando llegue al 80% de abajo y no al 100%

    // * Adjust the actual scroll adding a number of pixels for trigger before the setIsFetching(true) wich launch the adding of results in the infinite scroll
    let sumaAlturaScroll = window.innerHeight + 150;
    if (
      sumaAlturaScroll + document.documentElement.scrollTop >
        document.documentElement.offsetHeight ||
      isFetching
    ) {
      setIsFetching(true);
    } else {
      return;
    }
  }

  return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
