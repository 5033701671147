import React from "react";
import { Spinner } from "react-bootstrap";
import classes from "./Spinner.module.css";

const CustomSpinner = () => {
  return (
    <div className={classes.spinnerContainer}>
      <Spinner animation="border" role="status" className={classes.spinner}>
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};

export default CustomSpinner;
