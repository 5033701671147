import customAxios from "../../customAxios";

const profileGadsData = (access: string) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/sem/profile/gads-data/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};
const profileUserData = (access: string) => {
  return customAxios({
    method: "get",
    url: `${process.env.REACT_APP_API_SERVER}/api/sem/profile/user-data/`,
    headers: {
      Authorization: `Bearer ${access}`,
    },
  });
};

export default {
  profileGadsData,
  profileUserData,
};
