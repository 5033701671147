import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import apiSeeds from "../../../../../api/sem/sem-seeds";
import apiSeedKeywords from "../../../../../api/sem/keywords";

import { PROCESSING } from "../../../../../utilities/processStates";

import Keyword from "../../types/Keyword";

const useModalKeywordsList = ({
  selectedKeywords,
}: {
  selectedKeywords: Keyword[];
}) => {
  // DATA
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [queryedKeywords, setQueryedKeywords] = useState<Keyword[]>([]);

  // NAVIGATION
  const [totalKeywordsNumber, setTotalKeywordsNumber] = useState<number>(0);
  const [selectedKeywordsPage, setSelectedKeywordsPage] = useState<number>(0);

  // PROCESSES
  const [loadKeywordProcess, setLoadKeywordsProcess] = useState<string>();

  const accessToken = useSelector((state: any) => state.user.access);

  const handleTypeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      searchKeywords();
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [searchQuery]);

  const handleSubmitSearchForm = async (e: React.FormEvent) => {
    e.preventDefault();

    setSelectedKeywordsPage(0);
    searchKeywords();
  };

  const loadKeywords = async (page?: number) => {
    setLoadKeywordsProcess(PROCESSING);

    try {
      const response = await apiSeedKeywords.filter(accessToken, {
        filter: "all",
        value: "",
        page,
      });

      setQueryedKeywords(response.data.results);
      setTotalKeywordsNumber(response.data.count);
    } catch (error) {
      console.log(error);
    }

    setLoadKeywordsProcess(undefined);
  };

  const searchKeywords = async (page?: number) => {
    setLoadKeywordsProcess(PROCESSING);

    try {
      if (searchQuery.length > 0) {
        const response = await apiSeedKeywords.filter(accessToken, {
          filter: "search",
          value: searchQuery,
          page,
        });

        setQueryedKeywords(response.data.results);
        setTotalKeywordsNumber(response.data.count);
      } else {
        loadKeywords(page);
      }
    } catch (error) {
      console.log(error);
    }

    setLoadKeywordsProcess(undefined);
  };

  const handleClickPage = (pageNumber: number) => {
    setSelectedKeywordsPage(pageNumber);
    searchKeywords(pageNumber);
  };

  const selectedKeywordsIds = selectedKeywords.map((keyword) => keyword.id);
  const processedQueryedKeywords = queryedKeywords.map((keyword) => {
    return {
      ...keyword,
      alreadySelected: selectedKeywordsIds.includes(keyword.id),
    };
  });

  useEffect(() => {
    loadKeywords();
  }, []);

  const isLoadingKeywords = loadKeywordProcess === PROCESSING;

  return {
    searchQuery,
    handleTypeQuery,
    queryedKeywords: processedQueryedKeywords,
    handleSubmitSearchForm,
    isLoadingKeywords,
    totalKeywordsNumber,
    handleClickPage,
    selectedKeywordsPage,
  };
};

export default useModalKeywordsList;
