import { useState, useRef } from "react";
import { useSelector } from "react-redux";

import apiAttributes from "../../../../../api/attributes";
import apiProducts from "../../../../../api/products";

interface Attribute {
  id: number;
  name: string;
  type?: string;
}

const returnUpdatedAttributesList = (
  prevAttributes: Attribute[],
  editedAttribute: Attribute
) => {
  const updatedAttributesList = prevAttributes.map((attribute: any) => {
    if (attribute.id === editedAttribute.id) {
      return {
        ...editedAttribute,
      };
    }

    return attribute;
  });

  return updatedAttributesList;
};

const useAttributes: (data: { initialLoading?: boolean }) => any = ({
  initialLoading,
}) => {
  const initalState = initialLoading ? "LOADING" : [];

  const [availableAttributes, setAvailableAttributes] =
    useState<any>(initalState);
  const availableAttributesRef = useRef();
  availableAttributesRef.current = availableAttributes;

  const [attributes, setAttributes] = useState<any>(initalState);
  const attributesRef = useRef();
  attributesRef.current = attributes;

  const [attributesList, setAttributesList] = useState<any>(initalState);
  const attributesListRef = useRef();
  attributesListRef.current = attributesList;

  const [addedAttributes, setAddedAttributes] = useState<any>([]);
  const addedAttributesRef = useRef();
  addedAttributesRef.current = addedAttributes;

  const [informativeAttributes, setInformativeAttributes] = useState<any>([]);
  const informativeAttributesRef = useRef();
  informativeAttributesRef.current = informativeAttributes;

  const accesToken = useSelector((state: any) => state.user.access);

  const handleEditAttribute = (editedAttribute: {
    id: number;
    name: string;
  }) => {
    setAttributesList((prevAttributes: Attribute[]) =>
      returnUpdatedAttributesList(prevAttributes, editedAttribute)
    );

    setAttributes((prevAttributes: Attribute[]) =>
      returnUpdatedAttributesList(prevAttributes, editedAttribute)
    );

    setAvailableAttributes((prevAttributes: Attribute[]) =>
      returnUpdatedAttributesList(prevAttributes, editedAttribute)
    );
  };

  const handleClickDuplicateAttribute = async (
    productId: number,
    attributeId: number
  ) => {
    const attributeToDuplicate = attributesList.find(
      (attr: Attribute) => attr.id === attributeId
    );

    const infoAttributeNames = informativeAttributes.map(
      (attr: Attribute) => attr.name
    );

    if (infoAttributeNames.includes(attributeToDuplicate.name)) {
      return;
    }

    try {
      const response = await apiAttributes.duplicateAttributeToInfo(
        accesToken,
        {
          productId,
          attributeId,
        }
      );

      const addedAttribute = {
        id: response.data.attribute.id,
        name: response.data.attribute.name,
      };

      setAvailableAttributes((attrs: Attribute[]) => [
        ...attrs,
        { ...addedAttribute, type: "info" },
      ]);
      setInformativeAttributes((attrs: Attribute[]) => [
        ...attrs,
        addedAttribute,
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const loadTableAttributes = (unprocessedAttributes: any) => {
    if (unprocessedAttributes) {
      setAttributes(
        unprocessedAttributes.map((item: any) => ({
          id: item.attribute_id,
          name: item.name,
          type: item.type,
        }))
      );
    } else {
      setAttributes([]);
    }
  };

  const loadAttributesList = (unprocessedAttributes: any) => {
    setAttributesList(
      unprocessedAttributes.map((attribute: any) => ({
        id: attribute.id,
        name: attribute.name,
      }))
    );
  };

  const loadAvailableAttributes = (unprocessedAttributes: any) => {
    setAvailableAttributes(
      unprocessedAttributes.map((item: any) => ({
        id: item.id,
        name: item.name,
        type: item.attribute_type,
      }))
    );
  };

  const getAttributesFromDb = async (productId: number) => {
    const response = await apiProducts.filter(accesToken, {
      filtername: "id",
      value: productId,
    });

    const product = response.data[0];

    loadTableAttributes(product.table_attributes);
    loadAttributesList(product.article_attributes);
    loadAvailableAttributes(product.not_in_table_attributes);
  };

  return {
    availableAttributes,
    setAvailableAttributes,
    availableAttributesRef,
    attributes,
    setAttributes,
    attributesRef,
    attributesList,
    setAttributesList,
    attributesListRef,
    addedAttributes,
    setAddedAttributes,
    addedAttributesRef,
    informativeAttributes,
    setInformativeAttributes,
    informativeAttributesRef,
    handleEditAttribute,
    handleClickDuplicateAttribute,
    loadTableAttributes,
    loadAttributesList,
    loadAvailableAttributes,
    getAttributesFromDb,
  };
};

export default useAttributes;
